import * as React from 'react';
import {Link} from 'react-router-dom';
import {CONTENT_SITE} from '../content/content-site';

/**
 * A component to convert Deasil Links
 *
 * @param name
 * @param onClick
 * @param title
 * @param text
 * @param button
 * @param classes
 * @returns {Element}
 * @constructor
 */
const TextLink = (
    {name, onClick, title = '', text = '', button = 'none', classes = ''}
) => {
    let path = (CONTENT_SITE[name] && CONTENT_SITE[name].path) ? CONTENT_SITE[name].path : '/';
    let titleAttr = (CONTENT_SITE[name] && CONTENT_SITE[name].title) ? CONTENT_SITE[name].title : '';
    if (!titleAttr || title) {
        titleAttr = title;
    }
    let textAttr = (CONTENT_SITE[name] && CONTENT_SITE[name].title) ? CONTENT_SITE[name].title : '';
    if (!textAttr || text) {
        textAttr = text;
    }

    if (button !== 'none') {
        return (
            <Link to={path} title={titleAttr} className={`btn btn-${button} ${classes}`}
                  onClick={(onClick) ? onClick : undefined}>{textAttr}</Link>
        )
    }

    return (
        <Link to={path} title={titleAttr} onClick={(onClick) ? onClick : undefined}>{textAttr}</Link>
    )
};

export default TextLink;
