import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import MainNavigation from '../components/MainNavigation';
import {ReactComponent as DeasilLogo} from '../assets/images/Deasil-logo.svg';
import '../assets/css/header.css';

const Header = () => {

    const [scrolled, setScrolled] = useState(false);

    // Effect: Apply shadow to fixed header if scrolling
    useEffect(() => {
        const handleScroll = (e) => {
            if (window.scrollY > 20 && !scrolled) {
                setScrolled(true);
            } else if (window.scrollY <= 20 && scrolled) {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);

    const headerClass = (scrolled) ? 'scrolled' : '';

    return (
        <header className={headerClass}>
            <div className="container">
                <Link to={`/`} className="site-logo">
                    <DeasilLogo/>
                    <span className="screen-reader-text">Home</span>
                </Link>

                <MainNavigation/>
            </div>
        </header>
    )
};

export default Header;
