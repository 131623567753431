import * as React from 'react';
import Hero from '../components/Hero';
import TextLink from '../components/TextLink';

const Work = () => {

    return (
        <>
            <Hero page="work" classes="has-overlay"/>

            <section className="bg overlay-top mb-4">
                <p>
                    We develop software for any platform and design custom platforms for any domain—innovative custom
                    solutions, for online, automotive, manufacturing, academia, retail, marketing, and entertainment.
                </p>
                <p className="mb-0">
                    Deasil has developed groundbreaking technologies for top content providers and platforms, and
                    played a pivotal role in the digital transformation journeys of notable entertainment giants.
                </p>

                <TextLink name="contact" text="Contact Us" button="center mt-2"/>
            </section>

            <section className="bg bg-primary full pb-2">
                <div className="mb-2 pt-3 pl-1 bg bg-primary full">
                    <h2>Industries</h2>

                    <div className="cols mobile mr-1">
                        <div className="col-6 mobile">
                            <ul className="service-links light">
                                <li><TextLink name="industry-creative-agencies"/></li>
                                <li><TextLink name="industry-manufacturing"/></li>
                                <li><TextLink name="industry-retail"/></li>
                                <li><TextLink name="industry-ecommerce"/></li>
                                <li><TextLink name="industry-automotive"/></li>
                                <li><TextLink name="industry-transportation"/></li>
                                <li><TextLink name="industry-logistics"/></li>
                                <li><TextLink name="industry-energy"/></li>
                                <li><TextLink name="industry-academia"/></li>
                                <li><TextLink name="industry-entertainment"/></li>
                            </ul>
                        </div>
                        <div className="col-6 mobile">
                            <ul className="service-links light">
                                <li><TextLink name="industry-nonprofit"/></li>
                                <li><TextLink name="industry-government"/></li>
                                <li><TextLink name="industry-data-brokers"/></li>
                                <li><TextLink name="industry-health"/></li>
                                <li><TextLink name="industry-food-beverage"/></li>
                                <li><TextLink name="industry-music"/></li>
                                <li><TextLink name="industry-broadcasting"/></li>
                                <li><TextLink name="industry-banking-finance"/></li>
                                <li><TextLink name="industry-mortgage"/></li>
                                <li><TextLink name="industry-gaming"/></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Work;
