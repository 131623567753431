import * as React from 'react';
import {Link} from 'react-router-dom';
import Background from '../../components/Background';
import ContactBlock from '../../components/ContactBlock';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import ServicePanel from '../../components/ServicePanel';
import TextLink from '../../components/TextLink';

const Nonprofit = () => {
    return (
        <>
            <Hero page="industry-nonprofit" classes="has-overlay"/>

            <section className="bg bg-primary overlay-top mb-4">
                <p className="mb-0">
                    Deasil, with a rich heritage spanning over 25 years, stands at the forefront
                    of <TextLink name="service-custom-software-development" text="custom software development"/>,
                    infrastructure management,
                    and <TextLink name="service-digital-transformation" text="digital transformation"/>. Catering to
                    various sectors, from manufacturing and retail to entertainment and academia, Deasil has honed its
                    capabilities to craft tailored solutions, particularly for nonprofit organizations.
                </p>
            </section>

            <div className="divider spacer pt-2"></div>

            <section className="bg bg-primary full">
                <Background path="bg-Social-Network-Connections-bw.jpg" opacity={0.12}/>

                <h2>Improving Nonprofit Organizations</h2>

                <ul className="service-links light">
                    <li className="mb-2">
                        <strong>Cost Efficiency</strong>: Nonprofits operate on limited budgets and often rely on
                        donations and grants. They require cost-effective solutions to maximize the use of their
                        resources. Deasil does not resell overpriced public cloud offerings but leverages its
                        extensive <TextLink name="service-compute-resources"
                                            text="infrastructure and data center presence"/> to pass on massive
                        savings to clients.
                    </li>
                    <li className="mb-2">
                        <strong>Customization</strong>: Nonprofits have specialized needs based on their mission and
                        target audience. Custom solutions are essential to cater to these unique requirements. Deasil's
                        core capability
                        is <TextLink name="service-compute-resources" text="in-house custom software development"/> for
                        mobile, IoT, and web services. Deasil tailors solutions specifically for the nonprofit sector.
                    </li>
                    <li className="mb-2">
                        <strong>Data Management & Analysis</strong>: Nonprofits collect and handle vast amounts of data,
                        from donor information to program impact statistics. Efficient data management and analysis can
                        enhance their decision-making and demonstrate their impact on stakeholders. With expertise
                        in <TextLink name="oss-databases-data-warehousing" text="Databases"/> and <TextLink
                        name="service-data-warehouses" text="Data Warehousing"/>, <TextLink
                        name="service-etl-elt-operations" text="Data Integration & ETL"/>, <TextLink
                        name="oss-data-visualization-business-intelligence"/>, Deasil can offer nonprofits
                        comprehensive data management and analysis solutions.
                    </li>
                    <li className="mb-2">
                        <strong>Outreach & Engagement</strong>: Nonprofits must engage with donors, volunteers,
                        beneficiaries, and the general public. Effective online presence and engagement tools are
                        crucial.
                        Deasil's <TextLink name="service-seo-analytics"
                                           text="SEO and performance analytics"/> proficiency helps nonprofits
                        enhance their online visibility and engagement.
                    </li>
                    <li className="mb-2">
                        <strong>Operational Efficiency</strong>: Streamlining operations can allow nonprofits to serve
                        more people with the same or fewer resources. Process automation
                        and <TextLink name="service-digital-transformation" text="digital transformation"/> services
                        by Deasil can help nonprofits optimize their operations.
                    </li>
                    <li className="mb-2">
                        <strong>Security & Privacy</strong>: Nonprofits handle sensitive data, including personal donor
                        information, which requires robust security and privacy measures. Deasil's expertise
                        in <TextLink name="oss-identity-access-management"/> and familiarity with secure platforms
                        like <TextLink name="oss-keycloak"/> ensures that nonprofits' data is secure and private.
                    </li>
                    <li className="mb-2">
                        <strong>Collaboration & Accessibility</strong>: Nonprofits often collaborate with various
                        stakeholders, and their systems should be accessible and user-friendly for diverse
                        users. <TextLink name="oss-interactive-computing-development-environments"/>, Container
                        Orchestration using <TextLink name="oss-kubernetes"/>, and other tools offered by Deasil
                        facilitate seamless collaboration and accessibility.
                    </li>
                    <li className="mb-2">
                        <strong>Training & Support</strong>: As nonprofits might only sometimes have in-house tech
                        experts, they need training and continuous support for their systems. Given Deasil's wide range
                        of services and experience in diverse sectors, Deasil is well-equipped to offer training and
                        support to nonprofits.
                    </li>
                    <li className="mb-2">
                        <strong>Scalability</strong>: As nonprofits grow, they need systems that can scale with their
                        expanding operations. Deasil's expertise
                        in <TextLink name="oss-distributed-storage-systems"/> and solutions
                        like <TextLink name="oss-apache-nifi"/> ensures that nonprofits have scalable systems.
                    </li>
                </ul>
            </section>

            <section className="bg full cols wrap">
                <h2>Deasil's Platform Services and Custom Development</h2>

                <ServicePanel
                    name="service-open-source-expertise"
                    children={<>Deasil possesses profound expertise in enterprise-grade open-source software solutions
                        like <TextLink name="oss-apache-cassandra"/>, <TextLink name="oss-apache-nifi"/>, and more. For
                        nonprofit organizations, this means leveraging globally recognized platforms without the high
                        cost of proprietary software, ensuring highly targeted, effective use of limited budgets.</>
                    }
                />
                <ServicePanel
                    name="service-custom-data-platforms"
                    children={<>Deasil understands the unique data requirements of nonprofits. Deasil crafts custom data
                        platforms that aid in seamless information collection, management, and analysis, enabling
                        nonprofits to make data-driven decisions, optimize their operations, and maximize their
                        impact.</>
                    }
                />
                <ServicePanel
                    name="service-sso-iam"
                    children={<>Through platforms like <TextLink name="oss-keycloak"/>, Deasil ensures nonprofits have
                        streamlined, secure, and efficient access management systems. Single Sign-On (SSO) capabilities
                        allow team members to securely access multiple services with one set of credentials, simplifying
                        user management and enhancing security.</>
                    }
                />
                <ServicePanel
                    name="service-custom-software-development"
                    children={<>Tailoring solutions to the specific needs of nonprofits, Deasil's in-house team creates
                        bespoke software that addresses unique challenges, from donor management to event planning,
                        ensuring efficient operations and enhanced stakeholder engagement.</>
                    }
                />
                <ServicePanel
                    name="service-mobile-applications"
                    children={<>Recognizing the growing importance of mobile outreach, Deasil develops intuitive mobile
                        apps that enable nonprofits to engage their audience, drive donations, and disseminate
                        information on the go.</>
                    }
                />
                <ServicePanel
                    name="service-hosting-managed-infrastructure"
                    children={<>Bypassing overpriced public cloud services, Deasil leverages its vast infrastructure to
                        offer nonprofits exceptional hosting performance at reduced costs, ensuring their digital
                        assets are always accessible and secure.</>
                    }
                />
                <ServicePanel
                    name="service-compute-resources"
                    children={<>Deasil provides nonprofits with a spectrum of computing resources, from bare metal
                        servers to sophisticated multi-cloud environments, ensuring scalability and flexibility in
                        operations.</>
                    }
                />
                <ServicePanel
                    name="service-data-center-network-operations"
                    children={<> With its extensive data center presence, Deasil offers nonprofits robust network
                        operations, ensuring optimal uptime, data integrity, and swift data transfers.</>
                    }
                />
                <ServicePanel
                    name="service-high-availability-performance-engineering"
                    children={<>Deasil prioritizes uptime and performance, ensuring that nonprofit platforms remain
                        accessible and perform optimally even during peak loads, offering stakeholders a seamless
                        experience.</>
                    }
                />
                <ServicePanel
                    name="service-seo-analytics"
                    children={<>Understanding the importance of visibility, Deasil provides nonprofits with SEO and
                        analytics solutions to enhance their online presence, drive organic traffic, and measure their
                        digital impact.</>
                    }
                />
                <ServicePanel
                    name="service-data-management-pipelines"
                    children={<>From collecting to processing data, Deasil crafts efficient data pipelines for
                        nonprofits, ensuring timely access to valuable insights.</>
                    }
                />
                <ServicePanel
                    name="service-data-science-environments"
                    children={<>Deasil creates interactive computing and development environments
                        like <TextLink name="oss-jupyterhub"/>, enabling nonprofits to delve into data analytics and
                        derive actionable insights.</>
                    }
                />
                <ServicePanel
                    name="service-etl-elt-operations"
                    children={<>Streamlining data integration, Deasil offers ETL and ELT services to nonprofits,
                        ensuring seamless data flow between different platforms and databases.</>
                    }
                />
                <div className="panel cols col-6">
                    <Icon img="icon-Database-Architecture"
                          name="Data Warehouses and Data Lakes"
                          classes="primary medium"/>
                    <span>
                        <h3>
                            <Link to="/service/data-warehouses">Data Warehouses</Link> and <Link
                            to="/service/data-lakes">Data Lakes</Link>
                        </h3>
                        <p className="text-small mb-2">
                            Deasil creates centralized data repositories, be it data warehouses or lakes, enabling
                            nonprofits to efficiently store, manage, and access vast amounts of data.
                        </p>
                    </span>
                </div>
                <ServicePanel
                    name="service-data-visualization-exploration"
                    children={<>Using tools like <TextLink name="oss-apache-superset"/>, Deasil empowers nonprofits to
                        visualize their data, facilitating better understanding and decision-making. Nonprofit
                        organizations have unique requirements that distinguish them from profit-driven businesses;
                        Deasil addresses these needs.
                    </>
                    }
                />
                <ServicePanel
                    name="service-platform-data-migration"
                    children={<>Ensuring seamless transitions, Deasil aids nonprofits in migrating their platforms and
                        data without any loss or downtime.</>
                    }
                />
                <ServicePanel
                    name="service-private-custom-clouds"
                    children={<>Offering more control and customization, Deasil sets up private and bespoke cloud
                        environments for nonprofits, aligning with their specific requirements.</>
                    }
                />
                <ServicePanel
                    name="service-legacy-systems-integration"
                    children={<>Understanding that many nonprofits may still rely on older systems, Deasil ensures
                        seamless integration with newer technologies, preserving data and ensuring continuity.</>
                    }
                />
                <ServicePanel
                    name="service-machine-learning-infrastructure"
                    children={<>Harnessing the power of AI, Deasil provides nonprofits with robust machine learning
                        infrastructures, enabling them to predict trends, automate tasks, and enhance stakeholder
                        engagement.</>
                    }
                />
                <ServicePanel
                    name="service-digital-transformation"
                    children={<>Deasil drives the digital transformation journey of nonprofits, ensuring they leverage
                        the latest technologies, optimize operations, and deliver enhanced value to their beneficiaries
                        and stakeholders.</>
                    }
                />
            </section>

            <ContactBlock label="Contact Deasil"/>
        </>
    )
};

export default Nonprofit;
