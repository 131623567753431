import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';
import Icon from '../../components/Icon';

const PostgreSQL = () => {
    return (
        <>
            <Hero page="oss-postgresql"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/PostgreSQL-elephant-logo.svg" name="PostgreSQL" classes="logo client mr-2"/>
                    <div>
                        <p>
                            PostgreSQL stands out as a leading <TextLink
                            name="service-open-source-expertise" text="open-source"/> object-relational <TextLink
                            name="oss-databases-data-warehousing" text="database management system"/> (RDBMS)
                            due to its advanced features, extensibility, and adherence to SQL standards. Unlike many
                            RDBMSs, PostgreSQL supports JSON and table formats, allowing for more flexible data
                            representation. It also offers powerful indexing, optimization strategies, and concurrency
                            control mechanisms that enhance performance for large-scale applications. Additionally,
                            PostgreSQL's commitment to continuous updates and its supportive community ensure that it
                            remains at the forefront of RDBMS technology, providing users with up-to-date features and
                            security measures.
                        </p>
                        <p>
                            Recognizing PostgreSQL's renown for its extensibility, SQL compliance, and capability to
                            handle vast data volumes with high concurrency, Deasil offers managed PostgreSQL services.
                            By doing so, Deasil assumes the comprehensive responsibilities of setting up, maintaining,
                            optimizing, and securing PostgreSQL instances, so clients benefit from an efficient, secure,
                            and consistently available database solution.
                        </p>
                    </div>
                </div>
                <p>
                    The provision of managed PostgreSQL services by Deasil carries significant value for businesses. As
                    data needs of companies escalate, the demand for a reliable database solution becomes paramount.
                    While businesses can manage databases in-house, such an approach often diverts critical resources
                    from essential operations. By entrusting database management to Deasil, organizations ensure their
                    PostgreSQL instances achieve peak performance, and they can redirect their internal resources
                    towards growth and core functions. In addition, Deasil's expertise safeguards businesses from
                    potential pitfalls, ensuring the database's continual optimization, security, and timely
                    backups.
                </p>
                <p>
                    Deasil offers managed <TextLink name="oss-pgadmin"/> to any client utilizing PostgreSQL
                    service, providing the luxury of a web-based interface for their PostgreSQL deployments.
                </p>
                <p>
                    Deasil leverages its <TextLink name="service-compute-resources" text="infrastructure"/> and
                    avoids the resale of marked-up public cloud offerings, allowing clients to access high-caliber
                    PostgreSQL services without prohibitive costs. Such cost-effective, high-performance services,
                    paired with Deasil's <TextLink name="service-open-source-expertise" text="expertise"/>, make
                    it a preferred choice for businesses, providing clients with operational excellence and fiscal
                    prudence in their database management endeavors.
                </p>
            </section>

            <section>
                <div className="panel bg bg-secondary cols">
                    <Icon img="icon-Performance" name="Highly Available and High Performance" classes="white mb-1"/>
                    <div>
                        <h3>
                            Highly Available and High Performance PostgreSQL Clusters
                        </h3>
                        <p className="mb-0">
                            Deasil integrates Patroni and Spilo into its Kubernetes infrastructure to streamline and
                            enhance PostgreSQL cluster management. Within the Kubernetes environment, Patroni
                            orchestrates PostgreSQL's high availability by providing automated failover capabilities.
                            When a master node encounters an issue, Patroni swiftly designates one of the standby
                            replicas as the new master, ensuring uninterrupted database operations. By capitalizing on
                            Kubernetes' dynamic nature, Patroni achieves seamless node election and recovery,
                            guaranteeing database resilience and minimizing downtime.
                        </p>
                    </div>
                </div>
                <div className="panel bg bg-secondary cols mb-0">
                    <Icon img="icon-Drive-Recovery" name="Backup and Disaster Recovery" classes="white mb-1"/>
                    <div>
                        <h3>
                            Backup and Disaster Recovery
                        </h3>
                        <p>
                            Deasil implements WAL-E to capture and store Write-Ahead Logging (WAL) segments. These
                            segments play a pivotal role in capturing every change made to the data, making it a
                            cornerstone of the backup strategy.
                        </p>
                        <p className="mb-0">
                            Furthermore, with Spilo's support for point-in-time recovery, Deasil provides its clients
                            with the confidence that it can restore their data to any specific moment in the past. This
                            capability is vital for scenarios where data anomalies or unintended changes occur.
                        </p>
                    </div>
                </div>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <h2>Deasil's Custom Data Platforms</h2>
                    <p>
                        In addition to offering PostgreSQL as a standalone service, Deasil designs custom data platforms
                        and warehouses by integrating it with several other state-of-the-art OSS
                        tools. <TextLink name="oss-trino"/> is a robust query engine facilitating swift querying across
                        diverse databases. <TextLink name="oss-apache-superset"/> enhances data visualization, granting
                        users insightful business intelligence
                        capabilities. <TextLink name="oss-opensearch"/> streamlines full-text search operations,
                        ensuring quick and accurate data retrieval. <TextLink name="oss-apache-nifi"/> adeptly
                        manages the data flow between systems, guaranteeing real-time data updates for analysis in
                        PostgreSQL. By intertwining these advanced tools, Deasil delivers an all-encompassing data
                        solution that meets the multifaceted needs of modern data management, all unified under the
                        orchestration of <TextLink name="oss-kubernetes"/>.
                    </p>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Related Services and Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-pgadmin"/></li>
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-apache-nifi"/></li>
                            <li><TextLink name="oss-apache-superset"/></li>
                            <li><TextLink name="oss-opensearch"/></li>
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-open-source-expertise"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default PostgreSQL;
