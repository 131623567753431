import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';

const Services = () => {
    return (
        <>
            <Hero page="service"/>

            <section className="bg bg-primary full mt-4 mb-4">
                <h2>Services</h2>
                <div className="cols">
                    <div className="col-4 mr-1 ml-1">
                        <ul className="service-links light">
                            <li><TextLink name="service-open-source-expertise"/></li>
                            <li><TextLink name="service-custom-data-platforms"/></li>
                            <li><TextLink name="service-sso-iam"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-mobile-applications"/></li>
                            <li><TextLink name="service-hosting-managed-infrastructure"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-data-center-network-operations"/></li>
                        </ul>
                    </div>
                    <div className="col-4 mr-1 ml-1">
                        <ul className="service-links light">
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-seo-analytics"/></li>
                            <li><TextLink name="service-data-management-pipelines"/></li>
                            <li><TextLink name="service-data-science-environments"/></li>
                            <li><TextLink name="service-etl-elt-operations"/></li>
                            <li><TextLink name="service-data-warehouses"/></li>
                            <li><TextLink name="service-data-lakes"/></li>
                        </ul>
                    </div>
                    <div className="col-4 mr-1 ml-1">
                        <ul className="service-links light">
                            <li><TextLink name="service-vector-database-hosting-management-services"/></li>
                            <li><TextLink name="service-data-visualization-exploration"/></li>
                            <li><TextLink name="service-platform-data-migration"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-legacy-systems-integration"/></li>
                            <li><TextLink name="service-machine-learning-infrastructure"/></li>
                            <li><TextLink name="service-digital-transformation"/></li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>
        </>
    )
};

export default Services;
