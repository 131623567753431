/**
 * Title, Subtitle, and Background content for all pages
 *
 */
export const CONTENT_SITE = {
    'home': {
        title: 'We are Deasil',
        subtitle: `Over 25 years of software development, systems integration, and infrastructure management.`,
        path: '/',
        cta: '',
        link: '',
        image: 'images/header-D-Radiance.jpg',
        video: 'media/header-D-Radiance.mp4',
        heightPercent: 67,
        top: -25
    },
    'about': {
        title: 'Why Deasil?',
        path: '/about',
        image: 'images/header-D-2m-C8.jpg',
        video: 'media/header-D-2m-C8.mp4',
        heightPercent: 67,
        top: -12
    },
    'our-history': {
        title: 'Our History',
        path: '/about/our-history',
        image: 'images/header-RETRO-08.jpg',
        video: 'media/header-RETRO-08.mp4',
        heightPercent: 25
    },
    'team': {
        title: 'Who is Deasil?',
        path: '/about/team',
        image: 'images/header-RETRO-08.jpg',
        video: 'media/header-RETRO-08.mp4',
        heightPercent: 40,
        top: -1
    },
    'work': {
        title: 'Our Clients',
        path: '/work',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 40,
        top: -1
    },
    'services': {
        title: 'What We Do',
        path: '/services',
        image: 'images/header-Deasil-C2.jpg',
        video: 'media/header-Deasil-C2.mp4',
        heightPercent: 40,
        top: -1
    },
    'contact': {
        title: 'Contact Us',
        path: '/contact',
        image: 'images/header-D-2m-C6.jpg',
        video: 'media/header-D-2m-C6.mp4',
        heightPercent: 67,
        top: -12
    },
    'privacy-policy': {
        title: 'Privacy Policy',
        path: '/privacy-policy',
        image: 'images/bg-Square-Crystallize-Pattern-bw.jpg',
        heightPercent: 18
    },
    'terms': {
        title: 'Terms & Conditions',
        path: '/terms-conditions',
        image: 'images/bg-Square-Crystallize-Pattern-bw.jpg',
        heightPercent: 18
    },
    'cookie-policy': {
        title: 'Cookie Policy',
        path: '/cookie-policy',
        image: 'images/bg-Square-Crystallize-Pattern-bw.jpg',
        heightPercent: 18
    },
    'not-found': {
        image: 'images/header-D-Red-Octagon.jpg',
        path: '',
    },
    'industry': {
        title: 'Industries',
        path: '/industry',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 40,
        top: -1
    },
    'industry-academia': {
        title: 'Academia',
        path: '/industry/academia',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-automotive': {
        title: 'Automotive',
        path: '/industry/automotive',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-banking-finance': {
        title: 'Banking and Finance',
        path: '/industry/banking-finance',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-broadcasting': {
        title: 'Broadcasting',
        path: '/industry/broadcasting',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-creative-agencies': {
        title: 'Creative Industries',
        path: '/industry/creative-agencies',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-data-brokers': {
        title: 'Data Brokers',
        path: '/industry/data-brokers',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-ecommerce': {
        title: 'eCommerce',
        path: '/industry/ecommerce',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-energy': {
        title: 'Energy',
        path: '/industry/energy',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-entertainment': {
        title: 'Entertainment',
        path: '/industry/entertainment',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-food-beverage': {
        title: 'Food and Beverage',
        path: '/industry/food-beverage',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-gaming': {
        title: 'Gaming',
        path: '/industry/gaming',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-government': {
        title: 'Government',
        path: '/industry/government',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-health': {
        title: 'Health',
        path: '/industry/health',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-logistics': {
        title: 'Logistics',
        path: '/industry/logistics',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-manufacturing': {
        title: 'Manufacturing',
        path: '/industry/manufacturing',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-mortgage': {
        title: 'Mortgage',
        path: '/industry/mortgage',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-music': {
        title: 'Music',
        path: '/industry/music',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-nonprofit': {
        title: 'Nonprofit',
        path: '/industry/nonprofit',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-retail': {
        title: 'Retail',
        path: '/industry/retail',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'industry-transportation': {
        title: 'Transportation',
        path: '/industry/transportation',
        image: 'images/header-Deasil-C6.jpg',
        video: 'media/header-Deasil-C6.mp4',
        heightPercent: 25
    },
    'oss': {
        title: 'Managed OSS',
        path: '/managed-oss',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 40,
        top: -1
    },
    'oss-apache-cassandra': {
        title: 'Apache Cassandra',
        path: '/managed-oss/apache-cassandra',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-apache-hive': {
        title: 'Apache Hive',
        path: '/managed-oss/apache-hive',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-apache-kafka': {
        title: 'Apache Kafka',
        path: '/managed-oss/apache-kafka',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-apache-nifi': {
        title: 'Apache NiFi',
        path: '/managed-oss/apache-nifi',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-apache-spark': {
        title: 'Apache Spark',
        path: '/managed-oss/apache-spark',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-apache-superset': {
        title: 'Apache Superset',
        path: '/managed-oss/apache-superset',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-ceph': {
        title: 'Ceph',
        path: '/managed-oss/ceph',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-container-management-infrastructure': {
        title: 'Container Management Infrastructure',
        path: '/managed-oss/container-management-infrastructure',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-databases-data-warehousing': {
        title: 'Databases & Data Warehousing',
        path: '/managed-oss/databases-data-warehousing',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-data-integration-etl': {
        title: 'Data Integration & ETL',
        path: '/managed-oss/data-integration-etl',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-data-visualization-business-intelligence': {
        title: 'Data Visualization & Business Intelligence',
        path: '/managed-oss/data-visualization-business-intelligence',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25,
        icon: 'icon-Infographic'
    },
    'oss-distributed-storage-systems': {
        title: 'Distributed Storage Systems',
        path: '/managed-oss/distributed-storage-systems',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-identity-access-management': {
        title: 'Identity & Access Management',
        path: '/managed-oss/identity-access-management',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-interactive-computing-development-environments': {
        title: 'Interactive Computing & Development Environments',
        path: '/managed-oss/interactive-computing-development-environments',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-jupyterhub': {
        title: 'JupyterHub',
        path: '/managed-oss/jupyterhub',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-jupyterlab': {
        title: 'JupyterLab',
        path: '/managed-oss/jupyterlab',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-keycloak': {
        title: 'Keycloak',
        path: '/managed-oss/keycloak',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-kubernetes': {
        title: 'Kubernetes',
        path: '/managed-oss/kubernetes',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-opensearch': {
        title: 'OpenSearch',
        path: '/managed-oss/opensearch',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-opensearch-dashboards': {
        title: 'OpenSearch Dashboards',
        path: '/managed-oss/opensearch-dashboards',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-postgresql': {
        title: 'PostgreSQL',
        path: '/managed-oss/postgresql',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-pgadmin': {
        title: 'pgAdmin',
        path: '/managed-oss/pgadmin',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-query-search-engines': {
        title: 'Query & Search Engines',
        path: '/managed-oss/query-search-engines',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-seaweedfs': {
        title: 'SeaweedFS',
        path: '/managed-oss/seaweedfs',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'oss-trino': {
        title: 'Trino',
        path: '/managed-oss/trino',
        image: 'images/header-Deasil-C3.jpg',
        video: 'media/header-Deasil-C3.mp4',
        heightPercent: 25
    },
    'service': {
        title: 'Services',
        path: '/service',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 40,
        top: -1
    },
    'service-compute-resources': {
        title: 'Compute Resources from Bare Metal to Multi-Cloud',
        path: '/service/compute-resources',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Processing'
    },
    'service-custom-data-platforms': {
        title: 'Custom Data Platforms',
        path: '/service/custom-data-platforms',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Frameworks'
    },
    'service-custom-software-development': {
        title: 'Custom Software Development',
        path: '/service/custom-software-development',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Code-Engineering'
    },
    'service-data-center-network-operations': {
        title: 'Data Center and Network Operations',
        path: '/service/data-center-network-operations',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Data-Network'
    },
    'service-data-lakes': {
        title: 'Data Lakes',
        path: '/service/data-lakes',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Data-Organization'
    },
    'service-data-management-pipelines': {
        title: 'Data Management and Pipelines',
        path: '/service/data-management-pipelines',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Data-Organization'
    },
    'service-data-science-environments': {
        title: 'Data Science Environments',
        path: '/service/data-science-environments',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Data-Science'
    },
    'service-data-visualization-exploration': {
        title: 'Data Visualization and Exploration',
        path: '/service/data-visualization-exploration',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Dashboard'
    },
    'service-data-warehouses': {
        title: 'Data Warehouses',
        path: '/service/data-warehouses',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Database-Architecture'
    },
    'service-devops': {
        title: 'Development Operations (DevOps)',
        path: '/service/devops',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-IT-Department'
    },
    'service-digital-transformation': {
        title: 'Digital Transformation',
        path: '/service/digital-transformation',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Filter-Funnel'
    },
    'service-etl-elt-operations': {
        title: 'ETL and ELT Operations',
        path: '/service/etl-elt-operations',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Data-Structure'
    },
    'service-high-availability-performance-engineering': {
        title: 'High Availability and Performance Engineering',
        path: '/service/high-availability-performance-engineering',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Performance'
    },
    'service-hosting-managed-infrastructure': {
        title: 'Hosting and Managed Infrastructure',
        path: '/service/hosting-managed-infrastructure',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Cloud-Hosting'
    },
    'service-legacy-systems-integration': {
        title: 'Legacy Systems Integration',
        path: '/service/legacy-systems-integration',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Puzzle-Pieces'
    },
    'service-machine-learning-infrastructure': {
        title: 'Machine Learning Infrastructure',
        path: '/service/machine-learning-infrastructure',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Machine-Learning'
    },
    'service-mobile-applications': {
        title: 'Mobile Applications',
        path: '/service/mobile-applications',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-mobile-settings'
    },
    'service-open-source-expertise': {
        title: 'Open Source Expertise',
        path: '/service/open-source-expertise',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Person-IT-Idea'
    },
    'service-platform-data-migration': {
        title: 'Platform and Data Migration',
        path: '/service/platform-data-migration',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Cloud-Database-Transfer'
    },
    'service-private-custom-clouds': {
        title: 'Private and Custom Clouds',
        path: '/service/private-custom-clouds',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Cloud-Secure'
    },
    'service-seo-analytics': {
        title: 'SEO and Analytics',
        path: '/service/seo-analytics',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Code-User-Prediction'
    },
    'service-sso-iam': {
        title: 'SSO, Identity and Access Management',
        path: '/service/sso-iam',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Person-Identity-Search'
    },
    'service-vector-database-hosting-management-services': {
        title: 'Vector Database Hosting and Management Services',
        path: '/service/vector-database-hosting-management-services',
        image: 'images/header-Deasil-C9.jpg',
        video: 'media/header-Deasil-C9.mp4',
        heightPercent: 25,
        icon: 'icon-Database-Architecture'
    },
    'case-study': {
        title: 'Case Studies',
        path: '/case-study',
    },
    'case-study-screen-printing': {
        title: 'Case Study: Screen Printing',
        subtitle: 'Transforming Screen Printing Operations with Deasil\'s Innovative Data Solutions',
        path: '/case-study/screen-printing',
        name: 'A leading North American screen printing company',
        modified: '2023-10-30',
        published: '2023-10-30',
        schemaDescription: `A leading screen printing company was propelled to new heights of operational excellence by Deasil's 
expertise in in data platforms, machine learning, and enterprise-grade open-source solutions.`,
        schemaImage: null,
    },
    'deasil-vs-diesel': {
        title: '',
        path: '/deasil-vs-diesel',
        image: 'images/header-Deasil-Not-Diesel.jpg',
        video: 'media/header-Deasil-Not-Diesel.mp4',
        heightPercent: 67,
        top: -21
    },
    'huddle': {
        title: '',
        path: '/huddle',
        image: 'images/header-Huddle.jpg',
        video: 'media/header-Huddle.mp4',
        heightPercent: 100
    },
    'deasil-works-d': {
        title: '',
        path: '/deasil-works-d',
        image: 'images/header-Deasil-d-Logo.jpg',
        video: 'media/header-Deasil-d-Logo.mp4',
        heightPercent: 100
    }
};
