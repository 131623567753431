import * as React from 'react';
import {Link} from 'react-router-dom';
import {CONTENT_SITE} from '../content/content-site';
import Icon from './Icon';

const ServicePanel = ({name, title, children, classes = 'cols col-6'}) => {

    const content = CONTENT_SITE[name];
    const icon = (content.icon) ? content.icon : 'icon-Code-Engineering';
    let contentTitle = (title) ? title : (content.title) ? content.title : '';
    const url = (content.path) ? content.path : '';

    return (
        <div className={`panel service-panel ${classes}`}>
            <Link to={url}>
                <Icon img={icon} name={contentTitle} classes="primary medium"/>
            </Link>
            <span>
                <h3>
                    <Link to={url}>{contentTitle}</Link>
                </h3>
                <p className="text-small mb-2">
                    {children}
                </p>
            </span>
        </div>
    )
};

export default ServicePanel;
