import * as React from 'react';
import Hero from '../components/Hero';
import TextLink from '../components/TextLink';

const Services = () => {
    return (
        <>
            <Hero page="services"/>

            <section className="services bg full mb-4">
                <div className="bg bg-white cols mr-3 ml-3">
                    <div className="col-6">
                        <h3><TextLink name="service"/>:</h3>
                        <ul className="service-links">
                            <li><TextLink name="service-open-source-expertise"/></li>
                            <li><TextLink name="service-custom-data-platforms"/></li>
                            <li><TextLink name="service-sso-iam"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-mobile-applications"/></li>
                            <li><TextLink name="service-hosting-managed-infrastructure"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-data-center-network-operations"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-seo-analytics"/></li>
                            <li><TextLink name="service-data-management-pipelines"/></li>
                            <li><TextLink name="service-data-science-environments"/></li>
                            <li><TextLink name="service-etl-elt-operations"/></li>
                            <li><TextLink name="service-data-warehouses"/></li>
                            <li><TextLink name="service-data-lakes"/></li>
                            <li><TextLink name="service-vector-database-hosting-management-services"/></li>
                            <li><TextLink name="service-data-visualization-exploration"/></li>
                            <li><TextLink name="service-platform-data-migration"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-legacy-systems-integration"/></li>
                            <li><TextLink name="service-machine-learning-infrastructure"/></li>
                            <li><TextLink name="service-digital-transformation"/></li>
                        </ul>
                    </div>
                    <div className="col-6">
                    <h3><TextLink name="oss"/>:</h3>
                        <ul className="service-links">
                            <li>
                                <TextLink name="oss-databases-data-warehousing"/>
                                <ul>
                                    <li><TextLink name="oss-apache-cassandra"/></li>
                                    <li><TextLink name="oss-apache-hive"/></li>
                                    <li><TextLink name="oss-postgresql"/></li>
                                    <li><TextLink name="oss-pgadmin"/></li>
                                </ul>
                            </li>
                            <li>
                                <TextLink name="oss-data-integration-etl"/>
                                <ul>
                                    <li><TextLink name="oss-apache-nifi"/></li>
                                    <li><TextLink name="oss-apache-kafka"/></li>
                                </ul>
                            </li>
                            <li>
                                <TextLink name="oss-data-visualization-business-intelligence"/>
                                <ul>
                                    <li><TextLink name="oss-apache-superset"/></li>
                                    <li><TextLink name="oss-opensearch-dashboards"/></li>
                                </ul>
                            </li>
                            <li>
                                <TextLink name="oss-distributed-storage-systems"/>
                                <ul>
                                    <li><TextLink name="oss-ceph"/></li>
                                    <li><TextLink name="oss-seaweedfs"/></li>
                                </ul>
                            </li>
                            <li>
                                <TextLink name="oss-query-search-engines"/>
                                <ul>
                                    <li><TextLink name="oss-trino"/></li>
                                    <li><TextLink name="oss-opensearch"/></li>
                                </ul>
                            </li>
                            <li>
                                <TextLink name="oss-identity-access-management"/>
                                <ul>
                                    <li><TextLink name="oss-keycloak"/></li>
                                </ul>
                            </li>
                            <li>
                                <TextLink name="oss-interactive-computing-development-environments"/>
                                <ul>
                                    <li><TextLink name="oss-jupyterhub"/></li>
                                    <li><TextLink name="oss-jupyterlab"/></li>
                                </ul>
                            </li>
                            <li>
                                <TextLink name="oss-container-management-infrastructure"/>
                                <ul>
                                    <li><TextLink name="oss-kubernetes"/></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Services;
