import * as React from 'react';
import {Link} from 'react-router-dom';
import Blank from '../../assets/images/icon-blank.png';
import Hero from '../../components/Hero';
import Image from '../../components/Image';

const Huddle = () => {

    let instructions = '';
    try {
        const file = process.env.PUBLIC_URL + '/static/files/Huddle-Instructions.pdf';
        instructions = (file) ? file : <Blank/>;
    } catch (err) {
    }

    return (
        <>
            <Hero page="huddle"/>

            <section className="huddle">
                <h1>Huddle: The Team-Building Team-Building Game.</h1>
                <p>
                    We like games. We play a lot of them. But we like examining the engineering aspect behind them
                    just as much. Learning the rules is fun, but understanding the theory and themes underlying
                    the game, how it's balanced, and reducing its play structure to core concepts, illustrates
                    how much commonality exists between gameplay and the creative process.
                </p>
                <p>
                    This translates to Deasil's approach for creating things for people who need them. Not only do
                    we want to identify what's needed and address a given problem, but establish a sound rationale
                    behind it before we build anything.
                </p>
                <p>
                    So who are these people in Huddle? They might be familiar: Coworkers. Friends. Also, the dog.
                    Whether you work in an agency, startup, or band, chances are you know them or someone like them.
                </p>
                <p>
                    In making Huddle, we also wanted to explore the concept of making matches and forming teams,
                    representing ourselves and what the modern organization has become, with a humorous nod to the
                    individualism and creative nature of those people who populate our relationships.
                </p>
                <p>
                    (If you have Huddle and lost the
                    instructions, <Link to={instructions} title="Huddle Instructions" target="_blank">download
                    them here</Link>. If you don't have Huddle and want to know how to get
                    it, <Link to="/contact" title="Contact Us">Contact Us</Link>.)
                </p>
            </section>
            <div className="full flex">
                <Image path={`${process.env.PUBLIC_URL}/img-huddle-1.jpg`} name="Huddle 1" classes="full"/>
            </div>
            <div className="full flex cols mt-0">
                <Image path={`${process.env.PUBLIC_URL}/img-huddle-2.jpg`} name="Huddle 2" classes="col-6"/>
                <Image path={`${process.env.PUBLIC_URL}/img-huddle-3.jpg`} name="Huddle 3" classes="col-6"/>
            </div>
        </>
    )
};

export default Huddle;
