import * as React from 'react';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';

const DataWarehouses = () => {
    return (
        <>
            <Hero page="service-data-warehouses"/>

            <section className="bg full cols">
                <span>
                    <p className="mb-0">
                        Data isn't just an asset; it's the lifeblood that drives decision-making and strategic
                        planning across various industries. Deasil offers data warehousing solutions,
                        empowering businesses to convert disparate data sources into coherent, valuable
                        insights. Utilizing a robust ecosystem of open standards and
                        enterprise-grade <TextLink name="service-open-source-expertise"
                                                   text="Open Source Software (OSS)"/>, Deasil offers a
                        platform that is not only highly scalable but also vendor-neutral.
                    </p>
                </span>
            </section>

            <section className="bg bg-primary full mb-4">
                <h2>What is a Data Warehouse?</h2>
                <p>
                    A Data Warehouse centralizes data collection, storage, and management from various sources
                    across an organization. Unlike operational databases, developers specifically engineer data
                    warehouses to execute complex queries and analyses. They are the backbone
                    for <TextLink name="oss-data-visualization-business-intelligence"
                                  text="Business Intelligence"/> activities and enable organizations to make
                    effective, data-driven decisions.
                </p>
                <h2>Why Choose a Data Warehouse?</h2>
                <p>
                    Data Warehouses facilitate improved decision-making. Providing immediate access to organized
                    data helps make more timely and better-informed decisions. A well-designed data warehouse can
                    drastically reduce the time spent extracting, cleaning, and analyzing data, streamlining
                    these business operations. Data warehouses provide a unified view of information, which
                    leads to improved data quality, consistency, and accuracy.
                </p>
                <h2>Competitive Advantages of Data Warehousing</h2>
                <p className="mb-0">
                    Deasil's Data Warehouses help organizations harness real-time analytics capabilities to get
                    immediate insights into customer behavior, supply chain activities, and market trends. They allow
                    for a deep dive into historical data, making it possible to spot trends, patterns, and areas of
                    growth or concern. Finally, centralizing data in a data warehouse facilitates data access and adds
                    an extra layer of security, making data governance easier.
                </p>
            </section>

            <section className="bg bg-secondary mb-4 ml-2">
                <h2>Deasil's Commitment to Open Standards and Enterprise-grade OSS</h2>
                <p>
                    By committing to open standards and enterprise-grade OSS
                    like <TextLink name="oss-postgresql"/>, <TextLink name="oss-apache-nifi"/>, <TextLink
                    name="oss-trino"/>, and <TextLink name="oss-apache-superset"/>, Deasil guarantees interoperability
                    and future-proofs your investment. Deasil's clients invest in a system that doesn't tie them down
                    to a proprietary vendor, offering flexibility, expert and community support, and a more
                    comprehensive range of capabilities. Deasil-managed technologies form a powerful matrix for
                    collecting, transforming, accessing, analyzing, and presenting data.
                </p>
                <h3>The Cost-Effective Edge of Deasil's Data Warehouse Solutions</h3>
                <p>
                    While cloud-based data warehouse solutions are ubiquitous in the market, they often come with
                    hidden costs and the shackles of vendor lock-in. Deasil's data warehousing solutions break free
                    from this mold. With over 25 years of experience managing its infrastructure housed in
                    top-tier,
                    secure <TextLink name="service-data-center-network-operations" text="data centers"/> across the
                    United States, Deasil offers its clients a cost-effective, robust, and secure
                    data warehouse environment.
                </p>
                <h3>No Markup Costs</h3>
                <p>
                    Unlike vendors that resell public cloud services at marked-up prices, Deasil's use of its own
                    infrastructure eliminates the middleman, translating to direct cost savings for our clients.
                </p>
                <h3>Predictable Pricing</h3>
                <p>
                    Third-party cloud providers often introduce complex pricing models that can become prohibitive
                    as your data needs scale. With Deasil, you get predictable, straightforward pricing, making
                    budgeting easier. Deasil works with our clients to ensure they get a platform that fits their
                    budget.
                </p>
                <h3>Direct Control Over Security Protocols</h3>
                <p>
                    Your data is housed in top-tier, secure data centers managed directly by Deasil, meaning more
                    rigorous security measures customized according to your requirements without relying on
                    third-party policies.
                </p>
                <h3>Cost-Effectiveness Meets Performance</h3>
                <p>
                    By managing its own infrastructure, Deasil not only slashes the costs but also boosts performance
                    and security, offering a compelling mix that public cloud services find hard to match.
                </p>
                <ul className="service-links light">
                    <li>
                        <strong>Open Standards and Enterprise-grade OSS:</strong> Experience the benefits of a
                        vendor-neutral, scalable solution built on PostgreSQL, Apache NiFi, Trino, and Apache Superset.
                    </li>
                    <li>
                        <strong>Holistic Data Management:</strong> Deasil's expertise ensures you maximize your data
                        investments from raw data collection to transformation and analytics.
                    </li>
                </ul>
            </section>
        </>
    )
};

export default DataWarehouses;
