import React, {useEffect, useState} from 'react';
import Collapsible from './Collapsible';
import TextLink from './TextLink';

const CookieSettings = ({onUpdate, cookie, open = false}) => {

    const [settingsClass, setSettingsClass] = useState((open) ? ' active' : '');
    const [preferences, setPreferences] = useState({disableAll: false});

    useEffect(() => {
        const toggleSettings = () => {
            if (open) {
                setSettingsClass(' active');
            } else {
                setSettingsClass('');
            }
        };

        return toggleSettings();
    }, [open]);

    // Store Disable All preference
    const setDisableAll = (e) => {
        setPreferences({...preferences, disableAll: e.target.checked});
    };

    // Set preferences to Consent form
    const savePreferences = (type) => {
        if (typeof onUpdate === 'function') {
            onUpdate({type: type, values: preferences});
        }
    };

    return (
        <div className={`cookie-settings${settingsClass}`}>
            <div className="settings-content">
                <div className="panel bg bg-light pt-2 mb-0">
                    <button type="button" className="btn btn-close mr-1 right"
                            onClick={() => savePreferences('acceptAll')}>X
                    </button>
                    <h3>Manage Consent Preferences</h3>
                    <p>
                        When you visit our website, we store cookies on your browser to collect information. The
                        information collected might relate to you, your preferences, or your device, and is mostly used
                        to make the site work as you expect it to. However, you can choose not to allow certain types
                        of cookies, which may impact your experience of the site and the services offered. You may
                        change your default settings according to your preference. You cannot opt-out of Strictly
                        Necessary Cookies as they ensure the proper functioning of this website (including prompting
                        the cookie consent banner and remembering your settings).
                    </p>
                    <p>
                        Please review our <TextLink name="cookie-policy"/>.
                    </p>

                    <Collapsible header="Strictly Necessary Cookies" subheader="Always On">
                        <div className="pt-1">
                            <p className="text-small">
                                These cookies are set to provide the service, application or resource requested.
                                Without these cookies, your request cannot be properly delivered. They are usually
                                set to manage actions made by you, such as requesting website visual elements,
                                page resources, or user login/logoff. We can also use these cookies to set up
                                essential functionality to guarantee the security and efficiency of the service
                                requested, such as authentication and load balancer request.
                            </p>
                            <div className="table-flow">
                                <table className="bg-light mb-1 small">
                                    <tbody>
                                    <tr>
                                        <td>Deasil Cookie Consent</td>
                                        <td>dwCookieConsent</td>
                                        <td>Expiration Period: 1 year</td>
                                        <td>
                                            To store user's cookie
                                            preferences. <TextLink name="cookie-policy" text="Details"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Collapsible>

                    <Collapsible header="Non-Essential Cookies">
                        <div className="cols right mr-1 switch right">
                            <span className="mt-1 mr-1 text-small">Disable Non-Essential Cookies:</span>
                            <span className="form-switch">
                                <input type="checkbox" id="switch-ga"
                                       onChange={(e) => setDisableAll(e)}/>
                                <label htmlFor="switch-ga">Disable All</label>
                            </span>
                        </div>
                        <div className="pt-1">
                            <p className="text-small">
                                Under the California Consumer Privacy Act, you have the right to opt-out of the sale
                                of your personal information to third parties. We do not sell your information, but
                                these cookies collect information for analytics purposes. You may exercise
                                your right to opt out of the sale of personal information. If you opt out we will
                                not make your personal information available to any third parties. Additionally,
                                you may contact us for further clarification about your rights as a California consumer.
                            </p>
                            <h5>Analytics Cookies</h5>
                            <div className="table-flow">
                                <table className="bg-light mb-1 small">
                                    <tbody>
                                    <tr>
                                        <td>Google Analytics</td>
                                        <td>__ga</td>
                                        <td>Expiration Period: 2 years</td>
                                        <td>
                                            To store and count page views. <a
                                            href="https://policies.google.com/technologies/cookies" target="_blank"
                                            rel="noopener noreferrer">Details</a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Collapsible>

                    <div className="cols right btns mt-2">
                        <button type="button" className="btn btn-secondary small sm-center ml-1"
                                onClick={() => savePreferences('settings')}>Save Settings
                        </button>
                        <button type="button" className="btn btn-primary small sm-center ml-1"
                                onClick={() => savePreferences('acceptAll')}>Accept All and close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CookieSettings;
