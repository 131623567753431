import * as React from 'react';
import {Link} from 'react-router-dom';
import Background from '../../components/Background';
import ContactBlock from '../../components/ContactBlock';
import Hero from '../../components/Hero';
import ServicePanel from '../../components/ServicePanel';
import TextLink from '../../components/TextLink';

const FoodBeverage = () => {
    return (
        <>
            <Hero page="industry-food-beverage" classes="has-overlay"/>

            <section className="bg bg-primary overlay-top mb-4">
                <p className="mb-0">
                    Deasil has been at the forefront of the digital revolution, providing various services to clients
                    across multiple industries,
                    including <TextLink name="industry-entertainment" text="entertainment"/>, <TextLink
                    name="industry-logistics" text="logistics"/>, <TextLink name="industry-automotive"
                                                                            text="automotive"/>, and <TextLink
                    name="industry-retail" text="retail"/>. With
                    a <Link to="/about/our-history">rich history</Link> dating back to the early days of the
                    digital age, Deasil's expertise has been instrumental in enabling businesses to navigate the complex
                    digital landscape. For the Food and Beverage industry, which is continually evolving in its customer
                    demands, supply chain complexities, and market trends, Deasil offers bespoke solutions that cater to
                    this sector's unique needs and challenges.
                </p>
            </section>

            <div className="divider spacer pt-2"></div>

            <section className="bg bg-primary full">
                <Background path="bg-Communication-Beams-bw.jpg" opacity={0.15}/>

                <h2>Improving the Food and Beverage Industry</h2>

                <ul className="service-links light">
                    <li className="mb-2">
                        <strong>Supply Chain & Inventory Management</strong>: The Food and Beverage industry requires
                        real-time tracking of inventory, efficient supply chain operations, and the ability to predict
                        demand. With our experience
                        in <TextLink name="service-etl-elt-operations" text="process automation"/>, <TextLink
                        name="service-data-management-pipelines" text="custom data platforms"/>, and <TextLink
                        name="service-data-science-environments" text="data science"/>, Deasil creates systems that
                        optimize supply chain operations, automate inventory tracking, and
                        provide predictive analytics to anticipate demand.
                    </li>
                    <li className="mb-2">
                        <strong>Online Ordering & Delivery Systems</strong>: With the surge in online food ordering,
                        businesses need robust and user-friendly online order and delivery management platforms.
                        Deasil's <TextLink name="service-custom-software-development"
                                           text="in-house custom software development"/> for <TextLink
                        name="service-mobile-applications" text="mobile"/> and web services designs and implements
                        efficient and user-centric online ordering systems.
                        Deasil's <TextLink name="service-hosting-managed-infrastructure"
                                           text="hosting and managed infrastructure"/> expertise ensures these
                        platforms' smooth operation and scalability.
                    </li>
                    <li className="mb-2">
                        <strong>Customer Data Analysis & Personalization</strong>: Food and Beverage businesses want to
                        understand customer behavior to tailor marketing efforts and personalize offers. Businesses gain
                        insights into customer behavior, preferences, and purchasing patterns by leveraging
                        Deasil's <TextLink name="service-machine-learning-infrastructure"
                                           text="data science and machine learning platforms"/>. Deasil's capabilities
                        in data visualization & business intelligence further help make data-driven decisions.
                    </li>
                    <li className="mb-2">
                        <strong>Digital Marketing & SEO</strong>: Effective online visibility and digital marketing are
                        crucial in this age. Deasil
                        provides <TextLink name="service-seo-analytics" text="SEO and performance analytics"/>, ensuring
                        businesses rank higher on search engines, and their digital marketing strategies are data-driven
                        and effective.
                    </li>
                    <li className="mb-2">
                        <strong>Loyalty Programs & Customer Engagement</strong>: Retaining customers and encouraging
                        repeat purchases through loyalty programs. Using
                        our <TextLink name="service-custom-software-development"
                                      text="custom software development capabilities"/>, Deasil builds tailored
                        loyalty point systems, interactive platforms, and applications to engage customers effectively.
                    </li>
                    <li className="mb-2">
                        <strong>Safety & Compliance</strong>: Ensuring food safety, quality, and regulation compliance
                        is vital in the Food and Beverage industry. Deasil automates and streamlines compliance
                        monitoring through process
                        automation, <TextLink name="service-etl-elt-operations" text="data integration, and ETL"/>.
                        Deasil develops systems that alert businesses about deviations or non-compliance.
                    </li>
                    <li className="mb-2">
                        <strong>Payment & POS Systems</strong>: Efficient point-of-sale (POS) systems and seamless
                        payment gateways. With our expertise
                        in <TextLink name="service-custom-software-development" text="custom software development"/>,
                        Deasil develops integrated POS systems, including features including complex coupon development
                        and sales reporting for national retailers.
                    </li>
                    <li className="mb-2">
                        <strong>Sustainability & Waste Management</strong>: With a growing emphasis on sustainability,
                        Food and Beverage businesses need to monitor and reduce wastage. Deasil helps companies track
                        waste, predict potential wastage, and implement sustainability measures by harnessing data
                        science, <TextLink name="service-machine-learning-infrastructure" text="machine learning"/>,
                        and custom data platforms.
                    </li>
                </ul>
            </section>

            <section className="bg full cols wrap">
                <h2>Deasil's Platform Services and Custom Development</h2>

                <ServicePanel
                    name="service-open-source-expertise"
                    children={<>Understanding the ever-evolving dynamics of the Food and Beverage industry requires
                        scalable, adaptable, and cost-effective tools. Deasil's proficiency in managing
                        enterprise-grade Open Source Software (OSS) solutions enables businesses to tap into
                        open-source platforms' vast resources and community-driven updates. Whether managing
                        inventory, analyzing customer preferences, or streamlining supply chain operations, Deasil
                        leverages tools like <TextLink name="oss-apache-cassandra"/>, <TextLink
                            name="oss-apache-hive"/>, <TextLink name="oss-apache-kafka"/>, <TextLink
                            name="oss-opensearch"/>, <TextLink name="oss-postgresql"/>, and <TextLink
                            name="oss-trino"/> to provide real-time insights and robust operational capabilities,
                        ensuring that businesses stay ahead of the curve.</>
                    }
                />
                <ServicePanel
                    name="service-custom-data-platforms"
                    children={<>The Food and Beverage sector produces vast data, from raw material sourcing to customer
                        feedback. Deasil designs custom data platforms tailored to capture, process, and interpret this
                        data, providing businesses with actionable insights. Whether tracking the freshness of produce,
                        predicting demand for seasonal items, or optimizing price points, these platforms empower
                        businesses to make informed decisions that enhance efficiency and profitability.</>
                    }
                />
                <ServicePanel
                    name="service-sso-iam"
                    children={<>Ensuring secure yet seamless access is crucial with multiple systems in place, from
                        procurement to sales. Deasil's expertise in Single Sign-On (SSO) and Identity & Access
                        Management ensures authorized personnel can effortlessly navigate various platforms, enhancing
                        productivity while ensuring data integrity and security, particularly valuable for businesses
                        with multiple outlets or franchises, enabling centralized control with localized access.</>
                    }
                />
                <ServicePanel
                    name="service-custom-software-development"
                    children={<>Every Food and Beverage business has unique operational challenges. Deasil's in-house
                        software development team crafts bespoke solutions, whether for streamlining kitchen
                        operations, enhancing customer experience in restaurants, or optimizing delivery routes. This
                        approach ensures that software aligns perfectly with business objectives, driving efficiency
                        and customer satisfaction.</>
                    }
                />
                <ServicePanel
                    name="service-mobile-applications"
                    children={<>In an age where customers order food, book tables, or read reviews on the go, having a
                        robust mobile presence is indispensable. Deasil develops intuitive and user-friendly mobile
                        applications that cater to the modern-day consumer for a restaurant reservation system, a
                        loyalty program, or an interactive menu. These applications enhance customer engagement and
                        provide businesses with a direct channel for promotions and feedback.</>
                    }
                />
                <ServicePanel
                    name="service-hosting-managed-infrastructure"
                    children={<>The reliability of digital platforms is paramount in the Food and Beverage industry,
                        where downtimes can result in significant losses. Deasil offers robust hosting and managed
                        infrastructure services, ensuring businesses' digital assets are always up and running. Whether
                        it's an online ordering system, a reservation platform, or an inventory management tool,
                        Deasil's infrastructure guarantees peak performance, scalability, and security.</>
                    }
                />
                <ServicePanel
                    name="service-compute-resources"
                    children={<>The dynamic nature of the Food and Beverage industry requires computing resources that
                        can scale as per demand. Be it the launch of a new product, a festive sale, or a marketing
                        campaign, Deasil's resources, ranging from bare metal servers to multi-cloud platforms, ensure
                        that businesses can handle surges without any hitches.</>
                    }
                />
                <ServicePanel
                    name="service-data-center-network-operations"
                    children={<>For a sector that's becoming increasingly digital, ensuring data security,
                        accessibility, and redundancy is vital. Deasil's data center and network operations offer
                        top-tier performance and security. Whether safeguarding an operations database, maintaining a
                        customer CRM, or handling transaction data, businesses can trust Deasil's infrastructure to be
                        reliable and secure.</>
                    }
                />
                <ServicePanel
                    name="service-devops"
                    children={<>Speed and efficiency are of the essence in the Food and Beverage industry. Deasil's
                        DevOps approach ensures rapid development, testing, and deployment of digital solutions. From
                        introducing a new feature in an app to rolling out updates across multiple platforms, the
                        DevOps methodology ensures minimal downtime and maximum agility.</>
                    }
                />
                <ServicePanel
                    name="service-high-availability-performance-engineering"
                    children={<>In the competitive landscape of Food and Beverage, every second counts. Websites, apps,
                        or platforms that are slow to load can result in lost customers. Deasil's expertise in high
                        availability and performance engineering ensures optimized speed, responsiveness, and uptime of
                        businesses' digital assets.</>
                    }
                />
                <ServicePanel
                    name="service-seo-analytics"
                    children={<>Understanding customer preferences, market trends, and operational efficiency is
                        paramount. With Deasil's SEO and analytics services, businesses gain insights into their online
                        visibility, customer behavior, and operational metrics. This data-driven approach aids in
                        refining strategies, optimizing operations, and enhancing customer engagement.</>
                    }
                />
                <ServicePanel
                    name="service-data-management-pipelines"
                    children={<>Effective data management, from raw material sourcing to sales metrics, is crucial for
                        informed decision-making. Deasil creates robust data pipelines that capture, process, and
                        present data in formats that are easy to interpret and act upon, empowering businesses to
                        optimize their supply chain, pricing strategies, and marketing campaigns.</>
                    }
                />
                <ServicePanel
                    name="service-data-science-environments"
                    children={<>The Food and Beverage sector can benefit immensely from predictive analytics, demand
                        forecasting, and customer segmentation. Deasil's advanced data science environments leverage
                        machine learning and AI to give businesses insights that drive profitability and customer
                        satisfaction.</>
                    }
                />
                <ServicePanel
                    name="service-etl-elt-operations"
                    children={<>Efficient data processing is vital for timely decision-making. Deasil's expertise in
                        Extract, Transform, Load (ETL) and Extract, Load, Transform (ELT) operations ensures that data
                        from various sources is seamlessly integrated, processed, and made available for analysis.</>
                    }
                />
                <ServicePanel
                    name="service-data-warehouses"
                    children={<>For large enterprises in the Food and Beverage sector, handling vast amounts of data
                        across multiple outlets, regions, or franchises can be challenging. Deasil designs data
                        warehouses that centralize this data, making it accessible, manageable, and actionable.</>
                    }
                />
                <ServicePanel
                    name="service-data-lakes"
                    children={<>In the era of Big Data, capturing and storing unstructured data—like customer feedback,
                        social media mentions, or IoT sensor data—can provide invaluable insights. Deasil designs data
                        lakes to store this data in its raw form, making it available for future processing and
                        analysis.</>
                    }
                />
                <ServicePanel
                    name="service-data-visualization-exploration"
                    children={<>Businesses need to visualize and explore their data to make data-driven decisions.
                        Deasil offers tools and platforms that transform raw data into intuitive graphs, charts, and
                        dashboards, allowing businesses to gain insights at a glance.</>
                    }
                />
                <ServicePanel
                    name="service-platform-data-migration"
                    children={<>As businesses grow or evolve, their digital platforms may need to be migrated or
                        upgraded. Deasil's expertise ensures smooth migrations, data integrity, minimal downtime, and
                        seamless transition.</>
                    }
                />
                <ServicePanel
                    name="service-private-custom-clouds"
                    children={<>Businesses require bespoke cloud solutions for security, compliance, or operational
                        reasons; Deasil offers private and custom cloud services tailored to the unique needs of the
                        Food and Beverage sector, offering scalability, security, and flexibility.</>
                    }
                />
                <ServicePanel
                    name="service-legacy-systems-integration"
                    children={<>Many Food and Beverage industry businesses rely on legacy systems for operations.
                        Deasil ensures these systems are seamlessly integrated with newer digital platforms, allowing
                        continuity, data integrity, and operational efficiency.</>
                    }
                />
                <ServicePanel
                    name="service-machine-learning-infrastructure"
                    children={<>Harnessing the power of machine learning can revolutionize operations, from demand
                        forecasting to personalized marketing. Deasil provides the infrastructure to develop, train, and
                        deploy machine learning models, ensuring businesses stay ahead of the competition.</>
                    }
                />
                <ServicePanel
                    name="service-digital-transformation"
                    children={<>In the modern era, digitizing operations, customer engagement, and supply chain is not
                        just an option but a necessity. Deasil's expertise in digital transformation ensures that
                        businesses in the Food and Beverage sector can navigate this transition smoothly, benefiting
                        from increased efficiency, customer satisfaction, and profitability.</>
                    }
                />
            </section>

            <ContactBlock label="Contact Deasil"/>
        </>
    )
};

export default FoodBeverage;
