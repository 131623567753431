import * as React from 'react';
import Blank from '../assets/images/icon-blank.png';

const Background = ({path, opacity = 0.1}) => {

    let src = '';
    let style = {};
    try {
        const image = process.env.PUBLIC_URL + '/static/images/' + path;
        src = (image) ? image : <Blank/>;
        style = {
            backgroundImage: 'url(' + src + ')',
            opacity: opacity
        };
    } catch(err) {
    }

    return (
        <div className="bg-section" style={style} role="none presentation">
        </div>
    )
};

export default Background;
