import * as React from 'react';
import Header from '../containers/Header';
import Hero from '../components/Hero';
import Footer from '../containers/Footer';

const NotFound = () => {

    return (
        <>
            <Header/>
            <div className="content">
                <Hero page="not-found"/>
                <section className="not-found">
                    <h1>Not Found</h1>
                    <p>Sorry, this route doesn't seem to go anywhere.</p>
                </section>
            </div>
            <Footer/>
        </>
    )
};

export default NotFound;
