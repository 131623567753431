import * as React from 'react';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';
import Icon from '../../components/Icon';

const OpenSourceExpertise = () => {
    return (
        <>
            <Hero page="service-open-source-expertise"/>

            <section className="bg full cols">
                <span>
                    <p>
                        In today's rapidly evolving technological
                        landscape, <strong>Open Source Software (OSS)</strong> stands
                        out as a resilient, innovative, and community-driven approach to solving complex
                        problems. With wide-ranging benefits from cutting-edge features to an inherent freedom
                        from vendor lock-in, OSS is increasingly becoming the backbone of modern enterprises.
                    </p>
                    <p>
                        Bureaucratic and development limitations often plague proprietary software companies.
                        Internal red tape and a narrow pool of developers often need to reactively improve the
                        adaptability of Software as a Service (SaaS) and closed-source solutions to changing
                        technologies or user needs. These issues do not restrict the proactive Open Source
                        Software community: a diverse and dedicated community of developers, researchers, and
                        experts continuously evolves OSS, bringing innovative features and optimizations.
                    </p>
                    <p>
                        One of the most understated advantages of OSS is its contribution to business continuity.
                        Open standards and the absence of vendor lock-in mean businesses can switch services,
                        scale, or adapt without costly penalties or technical challenges often encountered with
                        proprietary solutions. If developers discontinue an OSS project or if a company decides
                        to take the software in a new direction, the source code stays available for business
                        continuity. In contrast, discontinuation or service changes in SaaS or closed-source
                        software can significantly impact business.
                    </p>
                    <p>
                        Open Source Expertise is the bedrock of many of the other capabilities. Open-source
                        software is often the foundation for custom software development, mobile applications,
                        DevOps, data management, and more. Leveraging open-source technologies can speed up
                        development, lower costs, and offer a level of customization and community support that
                        proprietary software often can't match.
                    </p>
                </span>
            </section>

            <section className="bg bg-primary full">
                <h2>Benefits of Managed Open-Source Software (MOSS)</h2>
                <p>
                    Deasil's <strong><TextLink name="oss" text="Managed Open Source Software (MOSS)"/></strong> offers
                    a balanced approach that combines the flexibility and innovation of open-source solutions with the
                    reliability and support of managed services.
                </p>

                <div className="cols">
                    <div className="col-6 mr-1 ml-1">
                        <div className="panel bg-white">
                            <div className="cols">
                                <Icon img="icon-IT-Department" name="Expert Support and Maintenance"
                                      classes="secondary"/>
                                <div>
                                    <h3>Expert Support and Maintenance</h3>
                                    <p>
                                        Managed services provide you with a team of experts who know the software inside
                                        out, eliminating the need to hire or train in-house staff for specialized tasks,
                                        thereby saving time and resources.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="panel bg-white">
                            <div className="cols">
                                <Icon img="icon-Fast-Processing" name="Rapid Issue Resolution" classes="secondary"/>
                                <div>
                                    <h3>Rapid Issue Resolution</h3>
                                    <p>
                                        When problems arise, you're not alone. A managed service ensures that experts
                                        promptly address bugs or issues, often before they affect your operations, which
                                        ensures minimal downtime.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="panel bg-white">
                            <div className="cols">
                                <Icon img="icon-Checklist" name="Customization with Accountability"
                                      classes="secondary"/>
                                <div>
                                    <h3>Customization with Accountability</h3>
                                    <p>
                                        Open source software is highly customizable, and when managed by Deasil, you get
                                        the added benefit of expert advice on how to tailor the software to your
                                        specific needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="panel bg-white">
                            <div className="cols">
                                <Icon img="icon-Scalability" name="Scalability" classes="secondary"/>
                                <div>
                                    <h3>Scalability</h3>
                                    <p>
                                        Deasil's managed open-source software services often include scalable options
                                        that can grow with your business. This elasticity ensures that you have the
                                        resources you need as your business evolves without the overhead of managing it
                                        yourself.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 mr-1 ml-1">
                        <div className="panel bg-white">
                            <div className="cols">
                                <Icon img="icon-Infographic" name="Data Visualization & Business Intelligence"
                                      classes="secondary"/>
                                <div>
                                    <h3>Focus on Core Business Functions</h3>
                                    <p>
                                        With Deasil's experts managing your open-source software, your team can focus on
                                        what it does best: your core business. You won't have to divert resources for
                                        managing, updating, or troubleshooting software.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="panel bg-white">
                            <div className="cols">
                                <Icon img="icon-Variety" name="Access to the Latest Features" classes="secondary"/>
                                <div>
                                    <h3>Access to the Latest Features</h3>
                                    <p>
                                        Deasil provides regular updates that include new features and optimizations,
                                        allowing you to benefit from the latest advancements without having to monitor
                                        and install updates yourself.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="panel bg-white">
                            <div className="cols">
                                <Icon img="icon-Secure-Shield" name="Security and Compliance" classes="secondary"/>
                                <div>
                                    <h3>Security and Compliance</h3>
                                    <p>
                                        Deasil offers advanced security features, including regular patches and updates.
                                        Moreover, Deasil can help maintain compliance with industry-specific
                                        regulations, giving you peace of mind.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="panel bg-white">
                            <div className="cols">
                                <Icon img="icon-Profits-Chart" name="Cost-Effectiveness" classes="secondary"/>
                                <div>
                                    <h3>Cost-Effectiveness</h3>
                                    <p>
                                        Although managed services come at a price, they often offset costs by minimizing
                                        downtime, increasing operational efficiency, and reducing the need for in-house
                                        staff. Deasil does not re-sell overpriced public cloud services, but utilizes
                                        its extensive private infrastructure, providing enormous cost savings.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg full">
                <h2>The Combinatorial Powerhouse: MOSS Meets Deasil's Kubernetes Clusters</h2>
                <p>
                    Open Source Software (OSS) offers unmatched flexibility for adaptation, integration, and
                    customization to meet specific needs. Combining this flexibility with other OSS solutions
                    unleashes a "combinatorial effect" where the whole becomes greater than the sum of its parts.
                    This results in robust, scalable, and customizable platforms.
                </p>
                <p>
                    Envision Deasil wrapping this combinatorial strength within containerized environments
                    that <TextLink name="oss-kubernetes"/> orchestrates. You unlock exponential possibilities.
                </p>
                <table className="bg-white mb-3">
                    <thead>
                    <tr>
                        <th>Feature</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><span className="service-links nowrap strong">Automated Operations</span></td>
                        <td>
                            Kubernetes automates many aspects of application management, from rolling updates to
                            auto-scaling, enabling real-time adaptation to different conditions.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Interoperability</span></td>
                        <td>
                            Containers encapsulate all the software dependencies each tool needs, simplifying the
                            integration process and enabling seamless function among different OSS tools.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Resource Optimization</span></td>
                        <td>
                            Kubernetes allocates resources efficiently among various containers, ensuring optimal
                            performance and cost efficiency.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Scalability</span></td>
                        <td>
                            Deasil's Kubernetes clusters offers a uniform platform for deployment, scaling, and
                            management, overcoming native limitations in scalability among diverse OSS tools.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Security</span></td>
                        <td>
                            Kubernetes allows for isolated containerized environments, streamlining the management of
                            security credentials and permissions.
                        </td>
                    </tr>
                    </tbody>
                </table>
            </section>

            <section className="bg bg-primary full">
                <h2>Harness the Power of Managed Open Source Software with Deasil</h2>
                <p>
                    Deasil offers Managed Open Source Software (MOSS) that brings you the assurance of stability
                    and support. With over 25 years of experience managing complex workloads and data platforms, we
                    ensure your open-source tools run like clockwork so you can focus on your core business
                    functions.
                </p>

                <div className="bg bg-none">
                    <h3>Deasil's Open Source Expertise</h3>
                    <div className="cols">
                        <ul className="service-links light">
                            <li>
                                <strong>Databases:</strong> <TextLink name="oss-postgresql"/>, <TextLink
                                name="oss-apache-cassandra"/>, <TextLink name="oss-opensearch"/>
                            </li>
                            <li>
                                <strong>Data Analysis Tools:</strong> <TextLink name="oss-apache-superset"/>, <TextLink
                                name="oss-jupyterlab"/>
                            </li>
                            <li>
                                <strong>Data Storage:</strong> <TextLink name="oss-ceph"/>, <TextLink
                                name="oss-seaweedfs"/>
                            </li>
                            <li>
                                <strong>Search & Analytics:</strong> <TextLink name="oss-opensearch"/>
                            </li>
                            <li>
                                <strong>Query Engines:</strong> <TextLink name="oss-trino"
                                                                          text="Trino/Presto"/>, <TextLink
                                name="oss-apache-hive"/>
                            </li>
                        </ul>
                        <ul className="service-links light">
                            <li>
                                <strong>Data Processing & ETL:</strong> <TextLink name="oss-apache-nifi"/>, Apache Spark
                            </li>
                            <li>
                                <strong>Access & Identity Management:</strong> <TextLink name="oss-keycloak"/>
                            </li>
                            <li>
                                <strong>Container Orchestration:</strong> <TextLink name="oss-kubernetes"/>
                            </li>
                            <li>
                                <strong>Message Brokers:</strong> <TextLink name="oss-apache-kafka"/>, RabbitMQ
                            </li>
                            <li>
                                <strong>Machine Learning:</strong> Seldon Core, <TextLink name="oss-jupyterlab"/>
                            </li>
                        </ul>
                    </div>
                    <span className="divider light mt-4 mb-3"></span>

                    <h3>The Combinatorial Effect on Your Application and Data Platforms</h3>
                    <p>
                        Deasil understands that the value of MOSS isn't just in managing individual components, but
                        skillfully integrating them to create a cohesive, high-performing environment. Deasil leverages
                        diverse open-source software's "combinatorial effect" to form powerful and highly customizable
                        platforms.
                    </p>
                    <table className="bg-white mb-3">
                        <tbody>
                        <tr>
                            <td className="mobile-hide">
                                <span className="service-links nowrap strong">Integrated Workflows:</span>
                            </td>
                            <td>
                                <span className="strong desktop-hide">Integrated Workflows:</span>
                                Imagine running <TextLink name="oss-apache-nifi"/> for data
                                ingestion, <TextLink name="oss-postgresql"/> for data storage,
                                and <TextLink name="oss-jupyterlab"/> for data science tasks, all seamlessly integrated
                                and managed.
                            </td>
                        </tr>
                        <tr>
                            <td className="mobile-hide">
                                <span className="service-links nowrap strong">Enhanced Data Capabilities:</span>
                            </td>
                            <td>
                                <span className="strong desktop-hide">Enhanced Data Capabilities:</span>
                                Use <TextLink name="oss-apache-cassandra"/> for high
                                availability, <TextLink name="oss-ceph"/> for robust storage,
                                and <TextLink name="oss-opensearch"/> for analytics&mdash;all working to
                                supercharge your data capabilities.
                            </td>
                        </tr>
                        <tr>
                            <td className="mobile-hide">
                                <span className="service-links nowrap strong">Operational Excellence:</span>
                            </td>
                            <td>
                                <span className="strong desktop-hide">Operational Excellence:</span>
                                From <TextLink name="oss-apache-kafka" text="Kafka's"/> real-time data streaming
                                to <TextLink name="oss-kubernetes" text="Kubernetes'"/> container orchestration, Deasil
                                manages the whole ecosystem to ensure smooth, uninterrupted operations.
                            </td>
                        </tr>
                        <tr>
                            <td className="mobile-hide">
                                <span className="service-links nowrap strong">Scalable Machine Learning:</span>
                            </td>
                            <td>
                                <span className="strong desktop-hide">Scalable Machine Learning:</span>
                                Leverage the integration of Seldon Core with <TextLink name="oss-kubernetes"/> for
                                scalable, robust machine learning solutions that grow as your data grows.
                            </td>
                        </tr>


                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
};

export default OpenSourceExpertise;
