import * as React from 'react';
import TextLink from '../components/TextLink';

const ContactBlock = ({label = 'Contact Deasil'}) => {
    return (
        <section className={`bg bg-primary full center pt-4 pr-2 pb-4 pl-2`}>
            <TextLink name="contact" text={label} button="primary center btn-light"/>
        </section>
    )
};

export default ContactBlock;
