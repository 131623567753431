import * as React from 'react';
import {Link} from 'react-router-dom';
import Background from '../../components/Background';
import ContactBlock from '../../components/ContactBlock';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import ServicePanel from '../../components/ServicePanel';
import TextLink from '../../components/TextLink';

const Academia = () => {
    return (
        <>
            <Hero page="industry-academia" classes="has-overlay"/>

            <section className="bg bg-primary overlay-top mb-4">
                <p className="mb-0">
                    Institutions in the modern academic landscape require specialized technological solutions to foster
                    innovation, streamline processes, and maximize research capabilities. Leveraging over 25 years of
                    experience, Deasil stands at the forefront of this technological revolution, offering an array of
                    services tailor-made for academia. Deasil's comprehensive suite of services revolutionizes how
                    academia interacts with technology, empowering institutions to reach new heights in research,
                    learning, and innovation.
                </p>
            </section>

            <div className="divider spacer pt-2"></div>

            <section className="bg bg-primary full">
                <Background path="bg-Social-Network-Connections-bw.jpg" opacity={0.12}/>

                <h2>Improving Academia</h2>

                <ul className="service-links light">
                    <li className="mb-2">
                        <strong>Research Data Management</strong>: Academic researchers produce vast amounts of data,
                        which need to be stored, managed, and accessed securely and efficiently. This data should be
                        easily retrievable for further analysis and for verifying research results. With its expertise
                        in <TextLink name="oss-distributed-storage-systems"/> and <TextLink
                        name="service-data-warehouses" text="Data Warehousing"/>, Deasil provides academic institutions
                        with robust solutions for securely storing and retrieving vast amounts of research data.
                        Furthermore, tools like <TextLink name="oss-apache-cassandra"/> and <TextLink
                        name="oss-seaweedfs"/> offer scalability and fault tolerance, ensuring that data is always
                        accessible and secure.
                    </li>
                    <li className="mb-2">
                        <strong>Data Analysis & Visualization</strong>: For meaningful insights, the academic sector
                        needs advanced data analysis and visualization tools to interpret complex datasets, especially
                        in fields like biology, physics, or social sciences. Deasil's expertise
                        in <TextLink name="service-data-science-environments" text="Data Science"/> and <TextLink
                        name="service-machine-learning-infrastructure" text="Machine Learning platforms"/>, <TextLink
                        name="oss-data-visualization-business-intelligence"/>, and tools like <TextLink
                        name="oss-apache-superset"/> and <TextLink name="oss-trino"/> means that researchers can derive
                        actionable insights from their data and present it understandably.
                    </li>
                    <li className="mb-2">
                        <strong>Collaboration and Access Management</strong>: Research often involves collaboration
                        between multiple institutions or departments. Secure, controlled access to resources and data is
                        essential. Deasil's Identity & Access Management solutions, especially using tools
                        like <TextLink name="oss-keycloak"/>, ensure that only authorized personnel can access the
                        resources needed, facilitating seamless collaboration while maintaining security.
                    </li>
                    <li className="mb-2">
                        <strong>Interactive Computing & Development Environments</strong>: Researchers and students
                        require platforms where they can run simulations, experiments, and computations interactively,
                        especially in fields like computer science, engineering, and data science.
                        With <TextLink name="oss-jupyterhub"/>, Deasil offers
                        an <TextLink name="oss-interactive-computing-development-environments"
                                     text="interactive computing environment"/> that allows users to create and share
                        live code, equations, visualizations, and narrative text, making it ideal for academic purposes.
                    </li>
                    <li className="mb-2">
                        <strong>Scalable Infrastructure for High Computational Tasks</strong>: Simulations, especially
                        in fields like genomics or astrophysics, can require vast computational resources. Deasil's
                        extensive infrastructure and data center presence, combined with its expertise in Container
                        Orchestration with tools like <TextLink name="oss-kubernetes"/>, ensures that academia can
                        access the necessary computational power on demand.
                    </li>
                    <li className="mb-2">
                        <strong>Digital Transformation and Modernization</strong>: As technology evolves, academic
                        institutions must keep up with the latest tools and platforms to provide the best education and
                        research environments. With
                        its <TextLink name="service-digital-transformation"/> services, Deasil assists institutions in
                        migrating to modern platforms, automating processes, and ensuring they effectively utilize the
                        latest technological advancements.
                    </li>
                    <li className="mb-2">
                        <strong>Cost-effective Solutions</strong>: Budget constraints are typical in academia, and
                        institutions always seek cost-effective IT solutions. By not reselling overpriced public cloud
                        offerings and leveraging
                        its <TextLink name="service-hosting-managed-infrastructure" text="data center infrastructure"/>,
                        Deasil offers solutions that provide exceptional performance at a fraction of the cost, allowing
                        academic institutions to maximize their budgets.
                    </li>
                </ul>
            </section>

            <section className="bg full cols wrap">
                <h2>Deasil's Platform Services and Custom Development</h2>

                <ServicePanel
                    name="service-open-source-expertise"
                    children={<>Deasil boasts expertise in managing and deploying enterprise-grade open-source software
                        solutions, such as <TextLink name="oss-apache-cassandra"/>, <TextLink name="oss-apache-nifi"/>,
                        and <TextLink name="oss-jupyterhub"/>. For academia, this means access to reliable, scalable,
                        and cost-effective tools that can support vast research projects, enabling students and
                        researchers to harness the power of cutting-edge technology without the financial burden.</>
                    }
                />
                <ServicePanel
                    name="service-custom-data-platforms"
                    children={<>Understanding the need for specific data processing and storage platforms in academic
                        research, Deasil develops custom data platforms. These platforms allow institutions to store,
                        process, and retrieve complex datasets seamlessly, ensuring researchers have timely and
                        efficient access to crucial information.</>
                    }
                />
                <ServicePanel
                    name="service-sso-iam"
                    children={<>Deasil implements Single Sign-On (SSO) and Identity & Access Management solutions
                        like <TextLink name="oss-keycloak"/>, ensuring secure, centralized, and streamlined access to
                        multiple academic applications and platforms, enhancing security while providing a seamless
                        experience for students and faculty.</>
                    }
                />
                <ServicePanel
                    name="service-custom-software-development"
                    children={<>With an in-house team dedicated to custom software development, Deasil crafts unique
                        software tailored to academia's diverse needs&mdash;from research tools to administrative
                        applications, ensuring that academic institutions remain at the cutting edge of
                        technology.</>
                    }
                />
                <ServicePanel
                    name="service-mobile-applications"
                    children={<>In today's digital age, mobile accessibility is paramount. Deasil develops intuitive and
                        robust mobile applications catering to academia's various needs, from online learning platforms
                        to campus navigation apps, enhancing the student and faculty experience.</>
                    }
                />
                <ServicePanel
                    name="service-hosting-managed-infrastructure"
                    children={<>Leveraging its vast infrastructure and data center capabilities, Deasil provides
                        reliable hosting and managed infrastructure services, ensuring academic websites, platforms,
                        and tools are always available, scalable, and performant while passing on significant cost
                        savings.</>
                    }
                />
                <ServicePanel
                    name="service-compute-resources"
                    children={<>Deasil offers various computing resources, from bare metal servers to multi-cloud
                        solutions. Academia can therefore scale resources based on their research requirements,
                        ensuring optimal performance without overspending.</>
                    }
                />
                <ServicePanel
                    name="service-data-center-network-operations"
                    children={<>With an extensive data center presence, Deasil manages robust network operations,
                        guaranteeing high-speed data transfers, secure storage, and round-the-clock availability, all
                        crucial for academic research and collaboration.</>
                    }
                />
                <ServicePanel
                    name="service-devops"
                    children={<>Deasil's DevOps practices ensure swift development and deployment of academic tools and
                        platforms, accelerating research processes and facilitating faster results, enabling academia to
                        stay ahead in the competitive research arena.</>
                    }
                />
                <ServicePanel
                    name="service-high-availability-performance-engineering"
                    children={<>Recognizing the importance of constant access to academic resources, Deasil engineers
                        systems for high availability and performance. This means uninterrupted research, learning, and
                        academic operations.</>
                    }
                />
                <ServicePanel
                    name="service-seo-analytics"
                    children={<>Deasil offers SEO and analytics services to amplify academic content's reach, helping
                        institutions maximize online visibility and make data-driven decisions for outreach and content
                        strategies.</>
                    }
                />
                <ServicePanel
                    name="service-data-management-pipelines"
                    children={<>Deasil designs effective data management and pipeline solutions, ensuring data
                        integrity, accessibility, and efficient flow, which is pivotal for research continuity and
                        validity.</>
                    }
                />
                <ServicePanel
                    name="service-data-science-environments"
                    children={<>Academic research often demands specialized data science tools. Deasil provides
                        environments with tools like <TextLink name="oss-jupyterhub"/>, fostering innovation and aiding
                        in-depth analysis.</>
                    }
                />
                <ServicePanel
                    name="service-etl-elt-operations"
                    children={<>By handling Extract, Transform, Load (ETL) and Extract, Load, Transform (ELT)
                        operations, Deasil streamlines data processing for academia, ensuring timely insights and
                        results.</>
                    }
                />
                <div className="panel cols col-6">
                    <Icon img="icon-Database-Architecture"
                          name="Data Warehouses, Data Lakes"
                          classes="primary medium"/>
                    <span>
                        <h3>
                            <Link to="/service/data-warehouses">Data Warehouses</Link>, <Link
                            to="/service/data-lakes">Data Lakes</Link>
                        </h3>
                        <p className="text-small mb-2">
                            Deasil's expertise in creating data warehouses and lakes ensures that academic
                            institutions have a centralized and scalable repository for their vast and varied
                            datasets.
                        </p>
                    </span>
                </div>
                <ServicePanel
                    name="service-data-visualization-exploration"
                    children={<>Harnessing tools like <TextLink name="oss-apache-superset"/>, Deasil enables advanced
                        data visualization and exploration, enabling researchers to derive meaningful insights from
                        their data visually.</>
                    }
                />
                <ServicePanel
                    name="service-platform-data-migration"
                    children={<>With technological advancements, migration becomes essential. Deasil facilitates smooth
                        platform and data migrations, ensuring continuity and integrity for academic operations.</>
                    }
                />
                <ServicePanel
                    name="service-private-custom-clouds"
                    children={<>Deasil's custom cloud solutions provide academia with secure, scalable, and private
                        storage and compute resources, ideal for sensitive research data.</>
                    }
                />
                <ServicePanel
                    name="service-legacy-systems-integration"
                    children={<>Understanding the value of historical data, Deasil integrates legacy systems, ensuring
                        that past research and data remain accessible and relevant in the modern academic landscape.</>
                    }
                />
                <ServicePanel
                    name="service-machine-learning-infrastructure"
                    children={<>Machine Learning is pivotal in contemporary research. Deasil establishes robust
                        infrastructures that support and accelerate machine learning projects, pushing the boundaries
                        of academic research.</>
                    }
                />
                <ServicePanel
                    name="service-digital-transformation"
                    children={<>Deasil drives digital transformation in academia, modernizing institutions with the
                        latest technologies and ensuring they remain relevant, efficient, and innovative in today's
                        fast-paced digital world.</>
                    }
                />
            </section>

            <ContactBlock label="Contact Deasil"/>
        </>
    )
};

export default Academia;
