import * as React from 'react';
import Hero from '../../components/Hero';

const DataCenterNetworkOperations = () => {
    return (
        <>
            <Hero page="service-data-center-network-operations"/>

            <section className="bg full">
                <p>
                    Deasil offers data center access and network operations services to provide scalable, reliable,
                    and secure solutions for your computing, storage, and network requirements.
                </p>
                <p className="quote">
                    Deasil can provide significant savings and powerful modern components in helping customers migrate
                    from overpriced public clouds.
                </p>
                <p>
                    Using a company that provides direct data center access, network operations, and custom software
                    development can enable a business to be more agile, secure, and focused, leveraging the expertise
                    and infrastructure of specialists in the field.
                </p>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <div className="mb-4">
                        <h2 className="mt-2">Deasil offers hands-on custom configuration, support, and monitoring.</h2>
                        <p>
                            Customers work directly with Deasil's expert engineers, providing the following
                            capabilities:
                        </p>
                    </div>

                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Capability</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Cloud Computing</span></td>
                            <td>
                                Deasil offers IaaS, PaaS, and SaaS solutions to manage your infrastructure,
                                applications, and all their components.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Colocation Services</span></td>
                            <td>
                                Provision to rent rack space, power, fiber connectivity, routers, firewalls, load
                                balancers, and remote hands.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Compliance and Certification</span></td>
                            <td>
                                Deasil provides a variety of industry standards and regulations, including PCI-DSS, SOC
                                2, SOC 3, NIST 800-23, HIPAA, and ISO 27001.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Connectivity</span></td>
                            <td>
                                Deasil provides multiple high-speed internet connections, fiber, BGP routing, IP
                                addresses, and direct links to ISPs for redundancy.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Disaster Recovery</span></td>
                            <td>
                                Backup systems and plans for data and network configurations to safeguard against
                                accidental data loss and infrastructure failures.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Firewalls and Security</span></td>
                            <td>
                                Advanced security measures, including intrusion detection systems, DDoS mitigation, and
                                firewalls.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Managed Hosting</span></td>
                            <td>
                                Server and hardware leasing options.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Network Monitoring</span></td>
                            <td>
                                Deasil provides 24/7 network monitoring and oversight to detect and mitigate issues like
                                outages or malicious activities.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Physical Security</span></td>
                            <td>
                                Deasil's data centers provide secure access controls, video surveillance, and biometric
                                security systems to protect your physical infrastructure.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Power Redundancy</span></td>
                            <td>
                                A and B power feeds, generators, and uninterruptible power supply (UPS) systems,
                                including dual power supply servers.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Technical Support</span></td>
                            <td>
                                Deasil offers on-call, in-house technical support teams to handle customer issues.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="panel bg-white">
                    <div className="mb-4">
                        <h2 className="mt-2">Advantages of Deasil's data center access and network operations:.</h2>
                    </div>

                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Advantage</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Business Continuity</span></td>
                            <td>
                                Disaster recovery and backup services ensure business operations can continue during an
                                outage or other catastrophic events.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Compliance</span></td>
                            <td>
                                Deasil's data center partners help businesses comply with legal and industry-specific
                                regulations by providing certified operations, including PCI-DSS, SOC 2, SOC 3, NIST
                                800-23, HIPAA, and ISO 27001.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Cost-Efficiency</span></td>
                            <td>
                                Eliminates the capital expenditure of setting up and maintaining a private data center.
                                Exceptionally more cost-effective than public clouds such as AWS.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Expertise</span></td>
                            <td>
                                Deasil provides access to a team of experts who specialize in data center management and
                                network operations.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Focus on Core Business</span></td>
                            <td>
                                Allows businesses to focus on their core activities by offloading the complexity of data
                                center or public cloud management.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Performance</span></td>
                            <td>
                                Deasil offers high-speed connectivity and low-latency networks from over 25 providers,
                                ensuring the highest application and service performance.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Reliability</span></td>
                            <td>
                                Deasil ensures high uptime through redundant systems and expert maintenance.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Scalability</span></td>
                            <td>
                                Easy to scale up or down as business needs change.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Security</span></td>
                            <td>
                                Deasil's facilities provide physical and digital security measures to protect sensitive
                                data.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">US Topology</span></td>
                            <td>
                                Deasil provides interconnected services from top-tier facilities across multiple US
                                states.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
};

export default DataCenterNetworkOperations;
