import * as React from 'react';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';

const PrivateCustomClouds = () => {
    return (
        <>
            <Hero page="service-private-custom-clouds"/>

            <section className="bg full pb-3">
                <div className="cols">
                    <Icon img="icon-Cloud-Secure" name="Private and Custom Clouds" classes="primary"/>
                    <p className="mb-0">
                        Private clouds offer a dedicated and secure environment for your business operations, providing
                        complete control over your data and infrastructure. Deasil tailors your private cloud to meet
                        your business's unique needs and requirements, and simplifies compliance with industry-specific
                        regulations, as you have complete governance over your environment.
                    </p>
                </div>
            </section>

            <section className="bg bg-primary full pt-2 pb-2">
                <h2>The Downsides of Public Clouds</h2>

                <div className="panel bg-white">
                    <p>
                        Public clouds market themselves as cost-effective solutions. However, as your business scales,
                        so do your expenses. These platforms employ a pay-as-you-go model that can quickly become
                        expensive when dealing with large volumes of data and high computing requirements. The
                        advertised costs are just the tip of the iceberg. Public cloud providers charge extra for
                        services and features you might assume are included. These can range from data transfer costs to
                        charging for individual read and write operations to data storage systems, turning an economical
                        solution into an expensive undertaking.
                    </p>
                    <p className="quote">
                        Deasil works closely with all our private cloud clients to tailor solutions around predictable
                        or fixed monthly or annual budgets.
                    </p>
                    <p>
                        Public clouds come with predefined configurations and limitations. You must determine how well
                        your business requirements fit the services, or be willing to adapt your business. Public clouds
                        provide less flexibility to modify or customize your setup, having a negative impact on your
                        ability to innovate and adapt. Managing and optimizing a public cloud infrastructure requires
                        specialized skills. Advanced configuration of public cloud offerings requires an investment in
                        hiring or training personnel on these proprietary systems, which adds to your overall costs and
                        may provide only short-term value.
                    </p>
                    <p className="quote">
                        Deasil believes your best service investing skills that support OSS, open standards, and your
                        customer applications are not a proprietary vendor.
                    </p>
                    <p>
                        Public clouds make it easy to get started but difficult to leave. They often use proprietary
                        technologies and unique configurations that make it hard to migrate away without incurring high
                        costs and complexities. This vendor lock-in restricts your freedom to change providers and could
                        make you dependent on their pricing and policy changes.
                    </p>
                </div>

                <h2>Hybrid Public/Private Cloud</h2>
                <p>
                    Deasil recognizes the strengths of public clouds and their support for legacy systems. We support
                    public/private cloud hybrid architectures, enabling data sharing and applications between them. This
                    approach offers businesses increased flexibility and deployment options, letting workloads shift
                    between private and public clouds as computing demands and costs evolve.
                </p>
            </section>
        </>
    )
};

export default PrivateCustomClouds;
