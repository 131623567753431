import React from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {useAnalyticsScript} from './utils/analytics';
import routes from './Routes';

const App = ({configParams}) => {

    // Set Analytics scripts if present
    useAnalyticsScript((configParams.google_tag_id) ? configParams.google_tag_id : '');

    // Load all routes including Layout
    const router = createBrowserRouter(routes, {basename: '/'});

    return (
        <RouterProvider router={router}/>
    )
};

export default App;
