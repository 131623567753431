import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import Emitter from './eventEmitter';
import {deleteGACookies, getCookie} from './functions';

const consentCookieName = 'dwCookieConsent';

/**
 * Write analytics scripts if ID is present
 *
 * @param googleTagId
 * @returns {JSX.Element}
 */
export const useAnalyticsScript = (googleTagId) => {

    useEffect(() => {
        const scriptExternal = document.createElement('script');
        const scriptGTag = document.createElement('script');

        const body = document.querySelector('body');

        const consentCookie = getCookie(consentCookieName);
        let enableTag = true;
        if (consentCookie && consentCookie === 'disableNonEssential') {
            enableTag = false;
        }

        if (googleTagId && enableTag) {
            scriptExternal.async = true;
            scriptExternal.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-G7DEFHM11K');
            scriptGTag.text = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${googleTagId}');`;

            body.appendChild(scriptExternal);
            body.appendChild(scriptGTag);
        } else {
            deleteGACookies();
        }

        return () => {
            if (googleTagId && enableTag) {
                if (scriptExternal) {
                    body.removeChild(scriptExternal);
                }
                if (scriptGTag) {
                    body.removeChild(scriptGTag);
                }
            }
        };

    }, [googleTagId]);

    return (
        <>
        </>
    )
};

/**
 * Handle analytics events on route change or custom events
 *
 */
export const useAnalyticsHandler = () => {
    const {pathname} = useLocation();
    const pathRef = useRef(null);

    // Effect: Detect route change
    useEffect(() => {
        pathRef.current = pathname;
        // Submit dataLayer Event to GTM

    });

    // Effect: Handle custom GA event from component
    useEffect(() => {

        const handleGAEvent = (e) => {
            console.log('useAnalyticsHandler gaCustomEvent: ', e);
            // Submit dataLayer Event to GTM

        };

        Emitter.on('gaCustomEvent', (e) => handleGAEvent(e));

        return () => {
            Emitter.off('gaCustomEvent', (e) => handleGAEvent(e));
        };
    }, []);

    return (
        <></>
    );
};

