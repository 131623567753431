import * as React from 'react';
import {CONTENT_SITE} from '../content/content-site';
import HeroBackground from './HeroBackground';

const Hero = ({page, classes = ''}) => {

    const content = CONTENT_SITE[page];
    const bgImage = (content && content.image) ? content.image : '';
    const bgVideo = (content && content.video) ? content.video : '';
    const heightPercent = (content && content.heightPercent) ? content.heightPercent : 100;
    const top = (content && content.top) ? content.top : 0;
    const heroClass = (!content || (!bgImage && !bgVideo)) ? ' no-bg' : '';
    const title = (content && content.title) ? content.title : page;

    return (
        <div className={`hero${heroClass} ${classes}`} role="banner">
            <HeroBackground title={title} image={bgImage} video={bgVideo} heightPercent={heightPercent} top={top}/>
            <div
                className={`hero-text${(content.subtitle) ? ' has-title' : ''}`}
                aria-label="Hero Title Container"
            >
                {content.title &&
                    <h1>{content.title}</h1>
                }
                {content.subtitle &&
                    <h2>{content.subtitle}</h2>
                }
            </div>
        </div>
    )
};

export default Hero;
