import * as React from 'react';
import {CONTENT_SITE} from './content/content-site';
import Academia from './routes/industry/Academia';
import Automotive from './routes/industry/Automotive';
import BankingFinance from './routes/industry/BankingFinance';
import Broadcasting from './routes/industry/Broadcasting';
import CreativeAgencies from './routes/industry/CreativeAgencies';
import DataBrokers from './routes/industry/DataBrokers';
import ECommerce from './routes/industry/ECommerce';
import Energy from './routes/industry/Energy';
import Entertainment from './routes/industry/Entertainment';
import FoodBeverage from './routes/industry/FoodBeverage';
import Gaming from './routes/industry/Gaming';
import Government from './routes/industry/Government';
import Health from './routes/industry/Health';
import Industries from './routes/industry/Industries';
import Logistics from './routes/industry/Logistics';
import Manufacturing from './routes/industry/Manufacturing';
import Mortgage from './routes/industry/Mortgage';
import Music from './routes/industry/Music';
import Nonprofit from './routes/industry/Nonprofit';
import Retail from './routes/industry/Retail';
import Transportation from './routes/industry/Transportation';

const industryRoutes = [
    {
        path: CONTENT_SITE['industry'].path,
        element: <Industries/>
    },
    {
        path: CONTENT_SITE['industry-academia'].path,
        element: <Academia/>
    },
    {
        path: CONTENT_SITE['industry-automotive'].path,
        element: <Automotive/>
    },
    {
        path: CONTENT_SITE['industry-banking-finance'].path,
        element: <BankingFinance/>
    },
    {
        path: CONTENT_SITE['industry-broadcasting'].path,
        element: <Broadcasting/>
    },
    {
        path: CONTENT_SITE['industry-creative-agencies'].path,
        element: <CreativeAgencies/>
    },
    {
        path: CONTENT_SITE['industry-data-brokers'].path,
        element: <DataBrokers/>
    },
    {
        path: CONTENT_SITE['industry-ecommerce'].path,
        element: <ECommerce/>
    },
    {
        path: CONTENT_SITE['industry-energy'].path,
        element: <Energy/>
    },
    {
        path: CONTENT_SITE['industry-entertainment'].path,
        element: <Entertainment/>
    },
    {
        path: CONTENT_SITE['industry-food-beverage'].path,
        element: <FoodBeverage/>
    },
    {
        path: CONTENT_SITE['industry-gaming'].path,
        element: <Gaming/>
    },
    {
        path: CONTENT_SITE['industry-government'].path,
        element: <Government/>
    },
    {
        path: CONTENT_SITE['industry-health'].path,
        element: <Health/>
    },
    {
        path: CONTENT_SITE['industry-logistics'].path,
        element: <Logistics/>
    },
    {
        path: CONTENT_SITE['industry-manufacturing'].path,
        element: <Manufacturing/>
    },
    {
        path: CONTENT_SITE['industry-mortgage'].path,
        element: <Mortgage/>
    },
    {
        path: CONTENT_SITE['industry-music'].path,
        element: <Music/>
    },
    {
        path: CONTENT_SITE['industry-nonprofit'].path,
        element: <Nonprofit/>
    },
    {
        path: CONTENT_SITE['industry-retail'].path,
        element: <Retail/>
    },
    {
        path: CONTENT_SITE['industry-transportation'].path,
        element: <Transportation/>
    }
];

export default industryRoutes;
