import * as React from 'react';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';
import Icon from '../../components/Icon';

const CustomSoftwareDevelopment = () => {
    return (
        <>
            <Hero page="service-custom-software-development"/>

            <section className="bg full cols">
                <span>
                    <p>
                        Deasil is an experienced software development company with over 25 years of experience. The
                        majority of Deasil's developers have been working together for over a decade. Deasil develops
                        all custom software in-house and never outsources.
                    </p>
                    <p>
                        Deasil develops custom software for various platforms, including web services, IoT, kiosks,
                        embedded systems, PBX, control automation, consumer and enterprise mobile, and high-traffic
                        websites. Custom software development is typically the next logical step after exhausting the
                        limits of <TextLink name="service-open-source-expertise" text="open-source software"/>, whether
                        building an application from the ground up or extending OSS.

                    </p>
                </span>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <div className="cols">
                        <Icon img="icon-Problem-Solving" name="Complete End-to-End Solutions" classes="secondary"/>
                        <div>
                            <h2>Complete End-to-End Solutions</h2>
                            <p>
                                As a company that equally excels in software
                                development, <TextLink name="service-private-custom-clouds"
                                                       text="platform architecture"/>,
                                and <TextLink name="service-compute-resources" text="infrastructure"/>, Deasil offers
                                comprehensive, end-to-end solutions under one roof. You don't need to juggle multiple
                                vendors or consultants; we manage every aspect of your project from start to finish.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="panel bg-white">
                    <div className="cols">
                        <Icon img="icon-Frameworks" name="Cutting-Edge, Vendor-Neutral Solutions" classes="secondary"/>
                        <div>
                            <h2>Cutting-Edge, Vendor-Neutral Solutions</h2>
                            <p>
                                Deasil doesn't resell overpriced public cloud services. We utilize our top-tier
                                infrastructure across the United States to provide cutting-edge, vendor-neutral
                                solutions, guaranteeing you the most efficient, tailored services without being
                                locked into a single vendor's ecosystem.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg full">
                <h2>Why Choose Deasil?</h2>
                <ul className="service-links">
                    <li>
                        Get the benefit of a highly experienced team working together for decades, offering a level of
                        cohesion and expertise that off-shore agencies can't match.
                    </li>
                    <li>
                        Leverage our cross-disciplinary skills to ensure your project is technically sound and
                        commercially viable.
                    </li>
                    <li>
                        Enjoy peace of mind knowing that we offer a one-stop-shop solution for all your software
                        development needs, from ideation to deployment to ongoing support.
                    </li>
                </ul>
                <p className="mt-2">
                    Overall, custom software development by Deasil's experienced team offers a strategic advantage by
                    aligning technology with your business objectives and delivering a solution that precisely meets
                    your needs.
                </p>
                <table className="bg-white mb-3">
                    <thead>
                    <tr>
                        <th>Benefit</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><span className="service-links nowrap strong">Adaptability</span></td>
                        <td>
                            Custom software can adapt to changing business requirements, ensuring it remains relevant
                            and valuable.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Competitive Advantage</span></td>
                        <td>
                            Custom software can provide an edge by providing features and functionality unavailable in
                            off-the-shelf solutions.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Compliance</span></td>
                        <td>
                            Deasil tailors your software to meet industry-specific regulations and compliance
                            requirements.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Cost Efficiency</span></td>
                        <td>
                            Over the long term, custom software may be more cost-effective than off-the-shelf solutions,
                            as you only pay for features you need.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Data Insights</span></td>
                        <td>
                            Custom software can provide priceless data insights and analytics tools, helping you make
                            informed business decisions.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Documentation</span></td>
                        <td>
                            Comprehensive documentation makes it easier for your team, other vendors, and Deasil to
                            understand and maintain your software for the long term.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Enhanced Security</span></td>
                        <td>
                            Deasil designs custom software with security in mind, reducing vulnerabilities and the risk
                            of data breaches.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Improved Productivity</span></td>
                        <td>
                            Tailored software can streamline processes and automate repetitive tasks, increasing
                            productivity and efficiency.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Innovation</span></td>
                        <td>
                            Deasil's experienced development teams bring innovative ideas and approaches to your
                            project, helping you stay ahead of the curve in your industry.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Integration</span></td>
                        <td>
                            Custom software can seamlessly integrate with your existing systems, enabling data flow and
                            collaboration across your organization.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Interoperability</span></td>
                        <td>
                            Deasil often develops custom software to interoperate with legacy systems and popular web
                            API provided by Google, Salesforce, AWS, and more.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Long-term Partnership</span></td>
                        <td>
                            Collaborating with Deasil often leads to long-term partnerships, where we understand your
                            business deeply and can provide ongoing solutions and improvements.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Ownership and Control</span></td>
                        <td>
                            You have full ownership and control over custom software, allowing you to make changes and
                            updates as needed without relying on a third-party vendor.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Quality Assurance</span></td>
                        <td>
                            Deasil provides dedicated quality assurance engineers and automated processes, ensuring your
                            software is thoroughly tested, bug-free, and secure.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Rapid Development</span></td>
                        <td>
                            Deasil's experienced development team leverages established frameworks, continuous
                            integration, and automated deployment and testing pipelines to expedite software development
                            while ensuring quality outcomes.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">ROI</span></td>
                        <td>
                            Custom software can offer a high return on investment (ROI) by addressing specific business
                            challenges and delivering value over time.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Scalability</span></td>
                        <td>
                            Deasil develops software to grow with your business, accommodating increased workloads and
                            user numbers as needed.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Support and Maintenance</span></td>
                        <td>
                            Deasil offers ongoing support and maintenance services to keep your software up-to-date and
                            running smoothly.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Tailored Solutions</span></td>
                        <td>
                            Deasil designs and develops software to meet your organization's unique needs and
                            requirements, ensuring that it fits your processes perfectly.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">User Experience</span></td>
                        <td>
                            Deasil designs user interfaces (UI) and user experiences (UX) to be intuitive and
                            user-friendly, resulting in higher adoption rates and productivity.
                        </td>
                    </tr>
                    </tbody>
                </table>
            </section>

            <section className="bg bg-primary full">
                <h2>Deasil's Cross-Disciplinary Approach to Custom Software Development and Mobile Applications</h2>
                <p>
                    Having a team with cross-disciplinary skills can make all the difference. A cross-disciplinary team,
                    like the one at Deasil, has expertise in various domains such as user interface
                    design, <TextLink name="service-private-custom-clouds" text="backend
                    development"/>, <TextLink name="service-data-management-pipelines" text="data security"/>, <TextLink
                    name="service-devops" text="development operations"/>, <TextLink
                    name="service-high-availability-performance-engineering"
                    text="high availability and performance engineering"/>, <TextLink
                    name="service-platform-data-migration" text="data management"/> and <TextLink
                    name="service-etl-elt-operations" text="pipelines"/>, <TextLink
                    name="service-data-visualization-exploration" text="data visualization"/>, <TextLink
                    name="service-sso-iam" text="identity and access management"/>, and more,
                    allowing for a more holistic approach to app development.
                </p>
            </section>
        </>
    )
};

export default CustomSoftwareDevelopment;
