import * as React from 'react';
import {CONTENT_SITE} from './content/content-site';
import ApacheCassandra from './routes/oss/ApacheCassandra';
import ApacheHive from './routes/oss/ApacheHive';
import ApacheKafka from './routes/oss/ApacheKafka';
import ApacheNiFi from './routes/oss/ApacheNiFi';
import ApacheSpark from './routes/oss/ApacheSpark';
import ApacheSuperset from './routes/oss/ApacheSuperset';
import Ceph from './routes/oss/Ceph';
import ContainerManagementInfrastructure from './routes/oss/ContainerManagementInfrastructure';
import DatabasesDataWarehousing from './routes/oss/DatabasesDataWarehousing';
import DataIntegrationETL from './routes/oss/DataIntegrationETL';
import DataVisualizationBusinessIntelligence from './routes/oss/DataVisualizationBusinessIntelligence';
import DistributedStorageSystems from './routes/oss/DistributedStorageSystems';
import IdentityAccessManagement from './routes/oss/IdentityAccessManagement';
import InteractiveComputingDevelopmentEnvironments from './routes/oss/InteractiveComputingDevelopmentEnvironments';
import JupyterHub from './routes/oss/JupyterHub';
import JupyterLab from './routes/oss/JupyterLab';
import Keycloak from './routes/oss/Keycloak';
import Kubernetes from './routes/oss/Kubernetes';
import ManagedOSS from './routes/oss/ManagedOSS';
import OpenSearch from './routes/oss/OpenSearch';
import OpenSearchDashboards from './routes/oss/OpenSearchDashboards';
import PgAdmin from './routes/oss/PgAdmin';
import PostgreSQL from './routes/oss/PostgreSQL';
import QuerySearchEngines from './routes/oss/QuerySearchEngines';
import SeaweedFS from './routes/oss/SeaweedFS';
import Trino from './routes/oss/Trino';

const ossRoutes = [
    {
        path: CONTENT_SITE['oss'].path,
        element: <ManagedOSS/>
    },
    {
        path: CONTENT_SITE['oss-apache-cassandra'].path,
        element: <ApacheCassandra/>
    },
    {
        path: CONTENT_SITE['oss-apache-hive'].path,
        element: <ApacheHive/>
    },
    {
        path: CONTENT_SITE['oss-apache-kafka'].path,
        element: <ApacheKafka/>
    },
    {
        path: CONTENT_SITE['oss-apache-nifi'].path,
        element: <ApacheNiFi/>
    },
    {
        path: CONTENT_SITE['oss-apache-spark'].path,
        element: <ApacheSpark/>
    },
    {
        path: CONTENT_SITE['oss-apache-superset'].path,
        element: <ApacheSuperset/>
    },
    {
        path: CONTENT_SITE['oss-ceph'].path,
        element: <Ceph/>
    },
    {
        path: CONTENT_SITE['oss-container-management-infrastructure'].path,
        element: <ContainerManagementInfrastructure/>
    },
    {
        path: CONTENT_SITE['oss-databases-data-warehousing'].path,
        element: <DatabasesDataWarehousing/>
    },
    {
        path: CONTENT_SITE['oss-data-integration-etl'].path,
        element: <DataIntegrationETL/>
    },
    {
        path: CONTENT_SITE['oss-data-visualization-business-intelligence'].path,
        element: <DataVisualizationBusinessIntelligence/>
    },
    {
        path: CONTENT_SITE['oss-distributed-storage-systems'].path,
        element: <DistributedStorageSystems/>
    },
    {
        path: CONTENT_SITE['oss-identity-access-management'].path,
        element: <IdentityAccessManagement/>
    },
    {
        path: CONTENT_SITE['oss-interactive-computing-development-environments'].path,
        element: <InteractiveComputingDevelopmentEnvironments/>
    },
    {
        path: CONTENT_SITE['oss-jupyterhub'].path,
        element: <JupyterHub/>
    },
    {
        path: CONTENT_SITE['oss-jupyterlab'].path,
        element: <JupyterLab/>
    },
    {
        path: CONTENT_SITE['oss-keycloak'].path,
        element: <Keycloak/>
    },
    {
        path: CONTENT_SITE['oss-kubernetes'].path,
        element: <Kubernetes/>
    },
    {
        path: CONTENT_SITE['oss-opensearch'].path,
        element: <OpenSearch/>
    },
    {
        path: CONTENT_SITE['oss-opensearch-dashboards'].path,
        element: <OpenSearchDashboards/>
    },
    {
        path: CONTENT_SITE['oss-postgresql'].path,
        element: <PostgreSQL/>
    },
    {
        path: CONTENT_SITE['oss-pgadmin'].path,
        element: <PgAdmin/>
    },
    {
        path: CONTENT_SITE['oss-query-search-engines'].path,
        element: <QuerySearchEngines/>
    },
    {
        path: CONTENT_SITE['oss-seaweedfs'].path,
        element: <SeaweedFS/>
    },
    {
        path: CONTENT_SITE['oss-trino'].path,
        element: <Trino/>
    },
];

export default ossRoutes;
