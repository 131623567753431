import * as React from 'react';
import Hero from '../../components/Hero';
import Background from '../../components/Background';
import ContactBlock from '../../components/ContactBlock';
import TextLink from '../../components/TextLink';
import ServicePanel from '../../components/ServicePanel';
import Icon from '../../components/Icon';
import {Link} from 'react-router-dom';

const Energy = () => {
    return (
        <>
            <Hero page="industry-energy" classes="has-overlay"/>

            <section className="bg bg-primary overlay-top mb-4">
                <p className="mb-0">
                    Deasil has been a beacon of innovation for over a quarter of a century, offering tailor-made
                    software and infrastructure solutions. Deasil's diversified services cater to various industries,
                    but alignment with the needs of the Energy and Energy Exploration industry is especially noteworthy.
                    By integrating state-of-the-art technologies with our extensive expertise, Deasil paves the way for
                    enhanced efficiency, security, and innovation in this critical sector. Guiding the Energy and Energy
                    Exploration industry through its digital evolution, Deasil integrates modern technologies,
                    streamlines operations, and positions these sectors at the pinnacle of digital innovation. The
                    Energy and Energy Exploration industries demand scalability, reliability, security, and advanced
                    analytics. With its diversified services and deep expertise, Deasil aptly responds to these needs,
                    ensuring these sectors operate at peak efficiency and innovation.
                </p>
            </section>

            <div className="divider spacer pt-2"></div>

            <section className="bg bg-primary full">
                <Background path="bg-Abstract-Circuit-bw.jpg" opacity={0.12}/>

                <h2>Improving Energy and Energy Exploration</h2>

                <ul className="service-links light">
                    <li className="mb-2">
                        <strong>High Scalability and Performance</strong>: As energy projects scale or exploration sites
                        diversify, the corresponding data and systems must scale seamlessly. By offering services
                        like <TextLink name="oss-distributed-storage-systems"/> and <TextLink
                        name="service-open-source-expertise" text="enterprise-grade OSS solutions"/> such
                        as <TextLink name="oss-apache-cassandra"/> and <TextLink name="oss-trino"/>, Deasil ensures high
                        scalability and performance, accommodating large datasets and simultaneous operations.

                        Manufacturing
                    </li>
                    <li className="mb-2">
                        <strong>Robust Data Management</strong>: These sectors generate vast amounts of data daily,
                        requiring structured storage, quick retrieval, and advanced analytics. Deasil
                        provides <TextLink name="oss-distributed-storage-systems" text="centralized storage"/> and
                        efficient data management solutions with
                        its <TextLink name="service-data-management-pipelines" text="Custom Data Platforms"/>, <TextLink
                        name="service-data-warehouses"/>, and <TextLink name="service-data-lakes"/>. Tools
                        like <TextLink name="oss-apache-hive"/> and <TextLink name="oss-postgresql"/> further streamline
                        data processing.
                    </li>
                    <li className="mb-2">
                        <strong>Real-time Data Access and Mobility</strong>: Field personnel need access
                        to <TextLink name="oss-apache-kafka" text="real-time data"/> for onsite decisions and
                        operational adjustments.
                        Deasil's <TextLink name="service-mobile-applications"
                                           text="Mobile Application development"/> capabilities ensure that users
                        can access crucial information on the go, facilitating prompt decision-making.
                    </li>
                    <li className="mb-2">
                        <strong>High Availability and Uptime</strong>: Energy operations are often 24/7, demanding
                        reliable systems with minimal downtimes. Through
                        its <TextLink name="service-hosting-managed-infrastructure"/>services, combined
                        with <TextLink name="service-high-availability-performance-engineering"/>, Deasil guarantees
                        dependable uptime for critical systems.
                    </li>
                    <li className="mb-2">
                        <strong>Secure Access and Authentication</strong>: Given the sensitive nature of data in the
                        energy sector, secure access and authentication become paramount. By
                        implementing <TextLink name="oss-identity-access-management"/> solutions
                        like <TextLink name="oss-keycloak"/>, Deasil ensures that only authorized personnel can access
                        sensitive systems and data.
                    </li>
                    <li className="mb-2">
                        <strong>Intuitive Data Visualization</strong>: Energy operations require actionable insights and
                        data represented in an easily understandable manner. With tools
                        like <TextLink name="oss-apache-superset"/>, Deasil's expertise
                        in <TextLink name="oss-data-visualization-business-intelligence"/> transforms complex data sets
                        into visual insights, aiding in data-driven decision-making.
                    </li>
                    <li className="mb-2">
                        <strong>Integration of Legacy Systems</strong>: Many energy companies operate on older systems
                        but must integrate these with newer technologies. Deasil's services
                        in <TextLink name="service-legacy-systems-integration"/> ensure that companies can retain their
                        trusted older systems while embracing modern technology.
                    </li>
                    <li className="mb-2">
                        <strong>Predictive Analysis and Machine Learning</strong>: Predicting maintenance needs, energy
                        demands, or potential discovery sites can save time and resources. By
                        offering <TextLink name="service-machine-learning-infrastructure"/> and <TextLink
                        name="service-data-science-environments" text="Data Science platforms"/>, Deasil allows
                        companies to harness the power of AI for predictive analytics.
                    </li>
                    <li className="mb-2">
                        <strong>Digital Transformation</strong>: With the evolving tech landscape, there's a continuous
                        push towards digital transformation in the energy sectors.
                        Deasil's <TextLink name="service-digital-transformation"/> services ensure that energy companies
                        are always at the forefront of technological innovation, streamlining operations and enhancing
                        efficiency.
                    </li>
                    <li className="mb-2">
                        <strong>Cost-Effective Solutions</strong>: With its thin margins, the energy sector always seeks
                        cost-effective IT solutions. By leveraging
                        its <TextLink name="service-compute-resources" text="extensive infrastructure"/> and not
                        reselling overpriced public cloud offerings, Deasil provides clients with substantial savings
                        without compromising performance.
                    </li>
                </ul>
            </section>

            <section className="bg full cols wrap">
                <h2>Deasil's Platform Services and Custom Development</h2>

                <ServicePanel
                    name="service-open-source-expertise"
                    children={<>Harnessing robust enterprise-grade open-source solutions such
                        as <TextLink name="oss-apache-cassandra"/>, <TextLink name="oss-apache-hive"/>, and <TextLink
                            name="oss-trino"/>, Deasil offers unparalleled flexibility and scalability to the Energy and
                        Energy Exploration industry. These tools, known for their robustness, empower the sector to
                        manage vast data sets, ensuring adaptability and cost-effectiveness.</>
                    }
                />
                <ServicePanel
                    name="service-custom-data-platforms"
                    children={<>Deasil crafts specialized data platforms tailored for the Energy and Energy Exploration
                        industry. These platforms enable firms to assimilate, analyze, and interpret complex datasets,
                        driving insights into exploration patterns, resource allocation, and energy consumption, thereby
                        optimizing operations.</>
                    }
                />
                <ServicePanel
                    name="service-custom-software-development"
                    children={<>Deasil's custom development addresses challenges like resource mapping, exploration
                        analytics, and grid management by tailoring software solutions to the unique demands of the
                        Energy and Energy Exploration industry. These bespoke tools amplify operational efficiency and
                        responsiveness.</>
                    }
                />
                <ServicePanel
                    name="service-mobile-applications"
                    children={<>Mobile solutions developed by Deasil equip the Energy and Energy Exploration sector with
                        real-time data access, facilitating on-site exploration decisions, immediate maintenance
                        responses, and consumer-centric energy management.</>
                    }
                />
                <ServicePanel
                    name="service-hosting-managed-infrastructure"
                    children={<>By leveraging our vast infrastructure and eschewing costly public cloud offerings,
                        Deasil delivers premium hosting and managed infrastructure services to the Energy industry,
                        resulting in enhanced performance, dependable uptime, and tangible cost savings, all essential
                        for the high-stakes operations of energy exploration.</>
                    }
                />
                <ServicePanel
                    name="service-compute-resources"
                    children={<>Deasil's spectrum of compute resources, ranging from bare metal to intricate multi-cloud
                        environments, grants the Energy and Energy Exploration industry the agility to scale, manage
                        diverse workloads, and maintain peak performance across varying computational needs.</>
                    }
                />
                <ServicePanel
                    name="service-data-center-network-operations"
                    children={<>With a formidable presence in data center operations, Deasil ensures that the Energy and
                        Energy Exploration industry benefits from secure, reliable, and optimized data storage and
                        networking. This foundation is essential for seamless energy operations and exploration data
                        analytics.</>
                    }
                />
                <ServicePanel
                    name="service-high-availability-performance-engineering"
                    children={<>Deasil's commitment to high availability and performance ensures that energy platforms
                        and exploration tools remain accessible and efficient, establishing trust and minimizing
                        operational interruptions.</>
                    }
                />
                <ServicePanel
                    name="service-data-management-pipelines"
                    children={<>Deasil's advanced data management and pipeline services streamline data flow,
                        processing, and interpretation for the Energy and Energy Exploration industry, ensuring timely
                        insights and operational excellence.</>
                    }
                />
                <ServicePanel
                    name="service-data-science-environments"
                    children={<>Deasil's robust data science environments empower the Energy and Energy Exploration
                        industry with predictive analytics capabilities, facilitating better resource allocation, demand
                        forecasting, and risk assessment.</>
                    }
                />
                <ServicePanel
                    name="service-etl-elt-operations"
                    children={<>Deasil's sophisticated ETL and ELT processes optimize data integration and processing,
                        ensuring consistent, accurate, and readily available data for the Energy and Energy Exploration
                        sector.</>
                    }
                />
                <div className="panel cols col-6">
                    <Icon img="icon-Database-Architecture"
                          name="Data Warehouses, Data Lakes"
                          classes="primary medium"/>
                    <span>
                        <h3>
                            <Link to="/service/data-warehouses">Data Warehouses</Link>, <Link
                            to="/service/data-lakes">Data Lakes</Link>
                        </h3>
                        <p className="text-small mb-2">
                            Tailored for the Energy domain, Deasil's data warehouses and lakes centralize critical
                            data, laying the foundation for advanced analytics, forecasting, and data-driven
                            strategies.
                        </p>
                    </span>
                </div>
                <ServicePanel
                    name="service-data-visualization-exploration"
                    children={<>Through dynamic data visualization tools
                        like <TextLink name="oss-apache-superset"/>, Deasil translates intricate exploration and energy
                        data into actionable visual insights, fostering informed decision-making.
                    </>
                    }
                />
                <ServicePanel
                    name="service-platform-data-migration"
                    children={<>Deasil ensures a seamless transition during platform and data migration, safeguarding
                        data integrity and ensuring continuity for Energy and Energy Exploration operations.</>
                    }
                />
                <ServicePanel
                    name="service-private-custom-clouds"
                    children={<>Deasil offers dedicated private and custom cloud solutions and provides the Energy and
                        Energy Exploration sector with scalable, secure environments that cater to their unique
                        operational needs.</>
                    }
                />
                <ServicePanel
                    name="service-legacy-systems-integration"
                    children={<>Recognizing the value of legacy systems in the Energy and Energy Exploration industry,
                        Deasil seamlessly integrates them with newer technologies, ensuring operational continuity and
                        data coherence.</>
                    }
                />
                <ServicePanel
                    name="service-machine-learning-infrastructure"
                    children={<>Deasil's cutting-edge machine learning infrastructure allows the Energy and Energy
                        Exploration sector to leverage AI-driven insights for predictive maintenance, resource
                        optimization, and demand forecasting.</>
                    }
                />
                <ServicePanel
                    name="service-digital-transformation"
                    children={<>Guiding the Energy and Energy Exploration industry through its digital evolution, Deasil
                        integrates modern technologies, streamlines operations, and positions these sectors at the
                        pinnacle of digital innovation.</>
                    }
                />
            </section>

            <ContactBlock label="Contact Deasil"/>
        </>
    )
};

export default Energy;
