import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const SeaweedFS = () => {
    return (
        <>
            <Hero page="oss-seaweedfs"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/SeaweedFS-logo.png" name="SeaweedFS" classes="logo client mr-2 mb-2"/>
                    <p>
                        SeaweedFS emerged as a response to the ever-growing need for efficient, scalable, and
                        fault-tolerant storage systems in the age of big data. Historically, as businesses expanded,
                        their data storage requirements grew exponentially, demanding solutions that could handle vast
                        quantities of data without sacrificing speed or reliability. Traditional file systems struggled
                        to keep up with this influx, leading to the development of distributed file systems like
                        SeaweedFS, designed from the ground up to address modern data storage and management challenges.
                    </p>
                </div>
                <p>
                    The necessity for SeaweedFS arises from its unique architecture, which separates file metadata from
                    the actual data, enabling rapid file lookups and efficient storage space utilization. Critical
                    features of SeaweedFS include:
                </p>

                <div className="panel bg-white">
                    <ul className="service-links">
                        <li>
                            Its <strong>S3-compatible</strong> API ensures seamless interoperability with existing S3
                            tools.
                        </li>
                        <li>
                            Continuous data replication for high availability.
                        </li>
                        <li>
                            Automatic sharding distributes data across multiple nodes and guarantees resilience against
                            hardware failures.
                        </li>
                        <li>
                            Its ability to handle <strong>billions of files</strong> makes it preferable for enterprises
                            dealing with massive datasets.
                        </li>
                    </ul>
                </div>

                <p>
                    Deasil integrates SeaweedFS into its infrastructure to offer its diverse clientele a robust
                    S3-compatible storage solution. Recognizing the scalability and efficiency of SeaweedFS, Deasil uses
                    it to service billions of files, ensuring rapid access, data durability, and optimal performance.
                    Combining SeaweedFS's Volume Servers and Filer components allows Deasil to store vast data while
                    ensuring quick file retrieval and efficient storage management. As a result, clients benefit from a
                    storage system that meets their current needs and scales effortlessly with their future growth.
                </p>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <h2>Deasil's Custom Big Data Platforms</h2>
                    <p>
                        Deasil exploits SeaweedFS's S3 compatibility to construct a formidable backend for data
                        warehousing systems, particularly when integrating Apache Hive and Trino. SeaweedFS, with its
                        ability to house billions of files, offers a robust storage platform that interfaces seamlessly
                        with the S3 protocol. This compatibility ensures that data stored in SeaweedFS can be
                        effortlessly accessed and managed
                        by <TextLink name="oss-apache-hive"/> and <TextLink name="oss-trino"/>. Apache Hive, renowned
                        for its capability to query and manage vast datasets in distributed storage, acts as the
                        metadata store for Trino.
                    </p>
                    <p>
                        Trino, on the other hand, taps into the metastore capabilities of Apache Hive to efficiently
                        query data residing in the S3-compatible SeaweedFS. When a user submits a query through Trino,
                        it references Hive's meta store to understand the data's structure, layout, and partitions.
                        Then, it directs the query to the appropriate location in SeaweedFS. This synergy between
                        SeaweedFS, Apache Hive, and Trino allows Deasil to deliver a comprehensive data warehousing
                        solution. It ensures high-capacity storage that facilitates rapid data processing and extraction
                        from billions of files, making it a quintessential platform for data-intensive tasks.
                    </p>
                    <p>
                        Deasil employs <TextLink name="oss-postgresql"/> as the foundational storage system for Apache
                        Hive's megastore. Leveraging PostgreSQL's stability, performance, and transactional
                        capabilities, Deasil ensures that all metadata related to tables, schemas, columns, and
                        partitions managed by Apache Hive are stored efficiently and reliably. This integration allows
                        Apache Hive to access and manage metadata swiftly, streamlining the querying process on
                        distributed datasets while maintaining the integrity and consistency of metadata operations.
                    </p>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Related Services and Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-postgresql"/></li>
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-open-source-expertise"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default SeaweedFS;
