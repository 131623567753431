import * as React from 'react';
import {Link} from 'react-router-dom';
import Background from '../../components/Background';
import ContactBlock from '../../components/ContactBlock';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import ServicePanel from '../../components/ServicePanel';
import TextLink from '../../components/TextLink';

const Health = () => {
    return (
        <>
            <Hero page="industry-health" classes="has-overlay"/>

            <section className="bg bg-primary overlay-top mb-4">
                <p className="mb-0">
                    In today's rapidly evolving digital age, businesses in the health industry, much like other sectors,
                    seek innovative solutions that leverage the power of technology to optimize operations, improve
                    patient care, and stay ahead of the competition. With over 25 years of experience, Deasil offers
                    tailored services that address the unique challenges faced by health industry entities. Deasil's
                    rich history of working with leading names in entertainment, logistics, automotive, and more,
                    combined with our profound understanding of technology, makes us a partner of choice for businesses
                    in the vast healthcare ecosystem.
                </p>
            </section>

            <div className="divider spacer pt-2"></div>

            <section className="bg bg-primary full">
                <Background path="bg-Geometric-Big-Data-bw.jpg" opacity={0.12}/>

                <h2>Improving Healthcare</h2>

                <ul className="service-links light">
                    <li className="mb-2">
                        <strong>Data Security and Compliance</strong>: Health institutions manage sensitive patient
                        data, necessitating robust security measures. Deasil's expertise
                        in <TextLink name="oss-identity-access-management"/>, such as with tools
                        like <TextLink name="oss-keycloak"/>, ensures that only authorized personnel can access
                        specific data. Their long history with secure data management assures health businesses of
                        best practices in compliance and security.
                    </li>
                    <li className="mb-2">
                        <strong>Real-time Data Processing</strong>: Efficient patient care often requires real-time
                        data processing, especially in critical care or monitoring situations. Deasil's experience
                        with <TextLink name="oss-apache-nifi"/> and <TextLink name="oss-apache-cassandra"/> enables
                        real-time data flows, ensuring timely interventions and enhanced patient care.
                    </li>
                    <li className="mb-2">
                        <strong>Remote Consultations</strong>: As telehealth gains prominence, reliable and scalable
                        mobile applications become imperative.
                        Deasil's <TextLink name="service-custom-software-development"
                                           text="in-house custom software development"/> for <TextLink
                        name="service-mobile-applications" text="mobile applications"/> ensures health professionals and
                        patients connect seamlessly, providing continuous care even from a distance.
                    </li>
                    <li className="mb-2">
                        <strong>Data-Driven Decision Making</strong>: With the rise of predictive analytics in
                        healthcare, organizations require platforms that can handle complex data sets. Deasil's
                        capabilities in data science
                        and <TextLink name="service-data-science-environments" text="machine learning platforms"/> and
                        our expertise with tools including <TextLink name="oss-apache-hive"/> and <TextLink
                        name="oss-trino"/> allow health businesses to derive insights from their data,
                        leading to improved knowledge gathering and optimized operations.
                    </li>
                    <li className="mb-2">
                        <strong>Streamlined Operations</strong>: Efficient operational processes are the backbone of
                        any health institution. Deasil's process automation
                        and <TextLink name="service-devops" text="DevOps expertise"/> help streamline operations,
                        from patient registration to membership systems and billing, enhancing efficiency and patient
                        satisfaction.
                    </li>
                    <li className="mb-2">
                        <strong>Interoperable Systems</strong>: Health organizations often use various systems that need
                        to communicate with each other. Deasil's experience
                        with <TextLink name="oss-data-integration-etl"/> ensures that different platforms used within a
                        health institution can effectively share and synchronize data, leading to more comprehensive
                        patient profiles and better care.
                    </li>
                    <li className="mb-2">
                        <strong>Infrastructure Scalability and Reliability</strong>: Health data grows exponentially,
                        and infrastructure needs to keep up. By leveraging Deasil's hosting and managed infrastructure
                        services, healthcare organizations achieve high availability, performance engineering, and
                        scalability. Deasil's <TextLink name="service-data-center-network-operations"
                                                        text="data center and network operations"/> expertise provides
                        a consistent and reliable service, which is crucial for medical needs.
                    </li>
                    <li className="mb-2">
                        <strong>Research and Development</strong>: Health institutions, particularly those involved in
                        medical research, require powerful computing resources. Deasil's offerings,
                        from <TextLink name="service-compute-resources" text="bare metal to multi-cloud"/>, ensure
                        researchers have the compute power they need, whether it's for genomics research or drug
                        development simulations.
                    </li>
                    <li className="mb-2">
                        <strong>Legacy Systems Integration</strong>: Many health institutions still operate older
                        systems that contain valuable data. Deasil's proficiency
                        in <TextLink name="service-legacy-systems-integration"
                                     text="legacy systems integration"/> ensures that this data is not siloed but is
                        integrated into modern systems, allowing for a more comprehensive view of patient history.
                    </li>
                    <li className="mb-2">
                        <strong>Digital Transformation</strong>: As health businesses adapt to the digital age, they
                        need partners who understand the nuances of transformation. Deasil's extensive experience
                        in <TextLink name="service-digital-transformation" text="digital transformation"/> ensures that
                        health institutions can smoothly transition, leveraging the best of new technologies without
                        disrupting existing operations.
                    </li>
                </ul>
            </section>

            <section className="bg full cols wrap">
                <h2>Deasil's Platform Services and Custom Development</h2>

                <ServicePanel
                    name="service-open-source-expertise"
                    children={<>Deasil's mastery over enterprise-grade open-source software solutions
                        like <TextLink name="oss-apache-cassandra"/>, <TextLink name="oss-apache-nifi"/>, and <TextLink
                            name="oss-apache-hive"/> is unmatched. This expertise translates into robust,
                        scalable, and efficient systems for the health industry. Open source solutions offer
                        transparency, flexibility, and a collaborative approach to problem-solving, which are crucial
                        for healthcare entities that require secure and efficient management of vast amounts of data,
                        from patient records to medical research data.</>
                    }
                />
                <ServicePanel
                    name="service-custom-data-platforms"
                    children={<>The health industry constantly grapples with vast, diverse data sets, from patient
                        information to real-time health metrics. Deasil crafts bespoke data platforms tailored to the
                        specific needs of healthcare organizations. Doing so enables these entities to streamline data
                        management, ensuring quick access, security, and data integrity, vital for accurate diagnosis,
                        treatment, and research.</>
                    }
                />
                <ServicePanel
                    name="service-sso-iam"
                    children={<>Healthcare organizations deal with sensitive patient data that demands the highest level
                        of security. Deasil's expertise in Single Sign-On (SSO) and Identity and Access Management
                        ensures that only authorized personnel access specific data, safeguarding patient
                        confidentiality and ensuring compliance with regulations, protecting healthcare institutions
                        from potential liabilities.</>
                    }
                />
                <div className="panel cols col-6">
                    <Icon img="icon-Code-Engineering"
                          name="Custom Software Development and Mobile Applications"
                          classes="primary medium"/>
                    <span>
                        <h3>
                            <Link to="/service/custom-software-development">Custom Software Development</Link> and <Link
                            to="/service/mobile-applications">Mobile Applications</Link>
                        </h3>
                        <p className="text-small mb-2">
                            Deasil's prowess in custom software development caters directly to the unique requirements
                            of the health industry. Whether membership management systems, patient portals, or
                            specialized research tools, Deasil crafts solutions that optimize workflows and improve
                            patient care. Deasil's mobile application expertise further ensures that healthcare
                            professionals and patients can access vital information and services on the go, leading
                            to enhanced care and convenience.
                        </p>
                    </span>
                </div>
                <ServicePanel
                    name="service-hosting-managed-infrastructure"
                    children={<>With the health sector's ever-growing digital needs, robust hosting and managed
                        infrastructure are paramount. Deasil capitalizes on its extensive infrastructure and data center
                        presence to offer healthcare entities secure, high-performance hosting solutions, eliminating
                        the need for health institutions to invest heavily in IT infrastructure allowing them to focus
                        on their primary objective: patient care.</>
                    }
                />
                <div className="panel cols col-6">
                    <Icon img="icon-Code-Engineering"
                          name="Compute Resources from Bare Metal to Multi-Cloud & Data Center and Network Operations"
                          classes="primary medium"/>
                    <span>
                        <h3>
                            <Link
                                to="/service/compute-resources">Compute Resources from Bare Metal to Multi-Cloud</Link> and <Link
                            to="/service/data-center-network-operations">Data Center and Network Operations</Link>
                        </h3>
                        <p className="text-small mb-2">
                            Deasil's comprehensive offerings range from bare metal resources to multi-cloud solutions.
                            For health organizations, this means a scalable and resilient IT ecosystem. Deasil's
                            data center and network operations expertise ensures uninterrupted access to crucial
                            health data, optimizing patient care and medical research processes.
                        </p>
                    </span>
                </div>
                <ServicePanel
                    name="service-devops"
                    children={<>Rapid deployment and seamless software operations are crucial for the health industry.
                        Deasil's DevOps expertise ensures that healthcare software solutions are always up-to-date,
                        secure, and high-performing.</>
                    }
                />
                <ServicePanel
                    name="service-high-availability-performance-engineering"
                    children={<>Deasil's focus on high availability and performance engineering ensures that
                        healthcare systems remain operational, even under peak loads, ensuring continuous care
                        provision.</>
                    }
                />
                <ServicePanel
                    name="service-seo-analytics"
                    children={<>Deasil's capabilities in SEO and analytics empower healthcare organizations to improve
                        their online visibility, attracting more patients and stakeholders. Furthermore, healthcare
                        entities can glean insights from their data through advanced analytics, driving improvements
                        in care delivery and operational efficiency.</>
                    }
                />
                <div className="panel cols col-6">
                    <Icon img="icon-Data-Organization"
                          name="Data Management and Pipelines, Data Science Environments, ETL and ELT Operations"
                          classes="primary medium"/>
                    <span>
                        <h3>
                            <Link to="/service/data-management-pipelines">Data Management and Pipelines</Link>, <Link
                            to="/service/data-science-environments">Data Science Environments</Link>, <Link
                            to="/service/etl-elt-operations">ETL and ELT Operations</Link>
                        </h3>
                        <p className="text-small mb-2">
                            The health industry's reliance on data is undeniable. Deasil's solutions in data
                            management and pipelines ensure smooth data flow. Deasil's expertise in data science
                            environments and ETL (Extract, Transform, Load) operations aids in data processing and
                            analysis, helping healthcare entities in research, diagnosis, and predictive care.
                        </p>
                    </span>
                </div>
                <div className="panel cols col-6">
                    <Icon img="icon-Database-Architecture"
                          name="Data Warehouses, Data Lakes, Data Visualization, and Exploration"
                          classes="primary medium"/>
                    <span>
                        <h3>
                            <Link to="/service/data-warehouses">Data Warehouses</Link>, <Link
                            to="/service/data-lakes">Data Lakes</Link>, <Link
                            to="/service/data-visualization-exploration">Data Visualization</Link>, and <Link
                            to="/service/data-visualization-exploration">Exploration</Link>
                        </h3>
                        <p className="text-small mb-2">
                            Storing and making sense of vast healthcare data becomes feasible with Deasil's
                            offerings in data warehouses and data lakes. Deasil's data visualization and exploration
                            tools, including <TextLink name="oss-apache-superset"/>, allow healthcare professionals
                            to interact with and interpret data, driving informed decision-making intuitively.
                        </p>
                    </span>
                </div>
                <div className="panel cols col-6">
                    <Icon img="icon-Cloud-Database-Transfer"
                          name="Platform and Data Migration & Private and Custom Clouds"
                          classes="primary medium"/>
                    <span>
                        <h3>
                            <Link to="/service/platform-data-migration">Platform and Data Migration</Link> &<br/><Link
                            to="/service/private-custom-clouds">Private and Custom Clouds</Link>
                        </h3>
                        <p className="text-small mb-2">
                            As the health industry evolves, there's often a need to migrate to newer platforms or
                            leverage private cloud capabilities. Deasil's expertise ensures seamless migration with
                            minimal downtime. Deasil's custom private cloud solutions provide healthcare entities
                            with scalable, secure, cost-effective IT infrastructure.
                        </p>
                    </span>
                </div>
                <ServicePanel
                    name="service-legacy-systems-integration"
                    children={<>While embracing new technologies, healthcare entities often need to integrate legacy
                        systems. Deasil ensures that these older systems seamlessly work with newer technologies.</>
                    }
                />
                <div className="panel cols col-6">
                    <Icon img="icon-Machine-Learning"
                          name="Machine Learning Infrastructure and Digital Transformation"
                          classes="primary medium"/>
                    <span>
                        <h3>
                            <Link to="/service/machine-learning-infrastructure">Machine Learning Infrastructure</Link>
                            <br/>and <Link to="/service/digital-transformation">Digital Transformation</Link>
                        </h3>
                        <p className="text-small mb-2">
                            Deasil's machine learning infrastructure aids in predictive care and research. By
                            partnering with Deasil, healthcare organizations can also embark on a holistic digital
                            transformation journey, reaping the benefits of modern technology while ensuring
                            patient care remains at the forefront.
                        </p>
                    </span>
                </div>
            </section>

            <ContactBlock label="Contact Deasil"/>
        </>
    )
};

export default Health;
