import * as React from 'react';
import DataManagementPipelines from './routes/service/DataManagementPipelines';
import DeasilWorksD from './routes/lab/DeasilWorksD';
import Home from './routes/Home';
import Huddle from './routes/lab/Huddle';
import MachineLearningInfrastructure from './routes/service/MachineLearningInfrastructure';
import Services from './routes/Services';
import Work from './routes/Work';

const redirectRoutes = [
    {
        path: '/admin/*',
        element: <Home/>
    },
    {
        path: '/admin_menu/*',
        element: <Home/>
    },
    {
        path: '/article/*',
        element: <Home/>
    },
    {
        path: '/cognitive/*',
        element: <Services/>
    },
    {
        path: '/content/*',
        element: <Home/>
    },
    {
        path: '/entry/*',
        element: <Home/>
    },
    {
        path: '/home/*',
        element: <Home/>
    },
    {
        path: '/lab/*',
        element: <Services/>
    },
    {
        path: '/logo/*',
        element: <DeasilWorksD/>
    },
    {
        path: '/nameweasel',
        element: <Home/>
    },
    {
        path: '/project/*',
        element: <Work/>
    },
    {
        path: '/sites/dw/files/atoms/files/dw-logo-instructions.pdf',
        element: <DeasilWorksD/>
    },
    {
        path: '/sites/dw/files/atoms/files/atoms/files/huddle-instructions.pdf',
        element: <Huddle/>
    },
    {
        path: '/solutions/big-data/*',
        element: <DataManagementPipelines/>
    },
    {
        path: '/solutions/cognitive/*',
        element: <MachineLearningInfrastructure/>
    },
    {
        path: '/solutions/*',
        element: <Services/>
    },
    {
        path: '/update.php',
        element: <Home/>
    },
    {
        path: '/user/*',
        element: <Home/>
    },
    {
        path: '/weasel-skill/*',
        element: <Services/>
    },
    {
        path: '/work/*',
        element: <Work/>
    }
];

export default redirectRoutes;
