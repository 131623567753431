import * as React from 'react';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import TextLink from '../../components/TextLink';

const ComputeResourcesBareMetalMultiCloud = () => {
    return (
        <>
            <Hero page="service-compute-resources"/>

            <section className="bg full">
                <p>
                    Deasil provides various computing resources essential for running applications and workloads.
                    Deasil's private infrastructure supports application deployment in numerous forms, ranging
                    from bare metal servers
                    to <TextLink name="service-private-custom-clouds" text="private cloud"/> environments. Each
                    approach offers distinct advantages and trade-offs. Deasil offers these services à la carte
                    or as part of a fully managed custom solution and anywhere in between.
                </p>
                <p className="quote">
                    Deasil's customers range from development shops that want complete control over the stack to
                    businesses wanting Deasil to handle everything.
                </p>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <div className="cols left mb-4">
                        <Icon img="icon-Frameworks" name="Computing resources"
                              classes="secondary"/>
                        <h2 className="mt-2">Computing resources across Deasil's platforms:</h2>
                    </div>

                    <h3>Bare Metal Servers</h3>
                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Attributes</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Physical Hardware</span></td>
                            <td>
                                Bare metal servers are physical machines dedicated to a single tenant. They provide raw
                                computing power without virtualization.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Performance</span></td>
                            <td>
                                High performance due to direct access to hardware resources, making them ideal for
                                resource-intensive tasks like databases or high-performance computing.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Isolation</span></td>
                            <td>
                                Strong isolation between tenants, reducing security risks.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Management</span></td>
                            <td>
                                Requires manual provisioning and maintenance, which can be time-consuming and less
                                flexible than virtualized solutions.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Scalability</span></td>
                            <td>
                                Limited scalability options, as adding or removing servers involves physical hardware
                                changes.
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Virtual Machines (VMs)</h3>
                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Attributes</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Hypervisor-Based Virtualization</span>
                            </td>
                            <td>
                                VMs run on virtualization software (hypervisors) that abstract physical hardware,
                                enabling multiple VMs to run on a single physical server.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Resource Isolation</span></td>
                            <td>
                                VMs provide isolation between workloads, enhancing security and stability.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Resource Efficiency</span></td>
                            <td>
                                Efficient resource utilization as multiple VMs share the same physical hardware.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Flexibility</span></td>
                            <td>
                                VMs can be provisioned, scaled, and managed more dynamically than bare metal, making
                                them suitable for various workloads.
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Containers</h3>
                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Attributes</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Lightweight</span></td>
                            <td>
                                Containers package applications and their dependencies in a consistent, lightweight
                                format, making them highly portable.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Resource Efficiency</span></td>
                            <td>
                                More efficient than VMs as they share the host OS kernel, reducing overhead.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Scalability</span></td>
                            <td>
                                Containers are easy to scale horizontally to handle increased loads.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Container Orchestration</span></td>
                            <td>
                                Deasil uses <TextLink name="oss-kubernetes"/> to enable automated deployment,
                                networking, ingress, security
                                policies, scaling, and management of containerized applications.
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Deasil Private Cloud</h3>
                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Attributes</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Managed Services</span></td>
                            <td>
                                Deasil's <TextLink name="service-private-custom-clouds" text="private cloud"/> platforms
                                offer many of the same managed services as Public Cloud providers, such as serverless
                                computing, enterprise-configured databases, object storage, identity and access
                                management, advanced monitoring, and GPU access.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Cost Optimization</span></td>
                            <td>
                                Most of our clients' services are configured based on fixed annual and monthly pricing.
                                Deasil does not resell overpriced public cloud services.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Interoperability</span></td>
                            <td>
                                <TextLink name="service-open-source-expertise" text="Enterprise-configured OSS"/> and
                                standards-based APIs.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Distributed Workloads / HA</span></td>
                            <td>
                                Kubernetes-managed containers across multiple bare metal servers provide high
                                performance and reliability at scale.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">National Topography</span></td>
                            <td>
                                Deasil's private data center presence in multiple high-profile data centers across the
                                United States provides the highest levels of security, availability, and geographic load
                                balancing.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
};

export default ComputeResourcesBareMetalMultiCloud;
