import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';
import Icon from '../../components/Icon';

const OpenSearch = () => {
    return (
        <>
            <Hero page="oss-opensearch"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/OpenSearch-logo-dark.svg" name="OpenSearch" classes="logo client mr-2"/>
                    <p>
                        OpenSearch began as a community-driven, open-source fork of Elasticsearch and Kibana. The tech
                        industry's growing need for scalable and transparent search solutions led to the birth of
                        OpenSearch, ensuring a platform free from restrictive licensing and open to innovations from a
                        global developer community. The project emerged to keep the promise of open governance and offer
                        features that remained free, community-driven, and in sync with the evolving needs of its
                        diverse user base.
                    </p>
                </div>
                <p>
                    OpenSearch is an inherently distributed system, facilitating the swift and efficient handling of
                    vast data volumes. The platform delivers full-text search capabilities through an HTTP web
                    interface, handling schema-free JSON documents seamlessly. Additionally, OpenSearch encompasses
                    advanced tools, including machine learning, data ingestion mechanisms, and robust alerting. When
                    combined with <TextLink name="oss-opensearch-dashboards"/>, users can visualize data, create
                    insightful dashboards, and develop real-time applications.
                </p>
                <p>
                    <TextLink name="service-open-source-expertise"
                              text="Open Source vendor neutrality and portability"/> are cornerstones for
                    ensuring business continuity. Businesses thrive when not locked into a specific vendor's ecosystem,
                    allowing them to pivot, adapt, and evolve without proprietary restrictions. With Deasil and
                    OpenSearch's vendor-neutral stance, organizations can avoid potential disruptions, achieve more
                    flexibility in their operations, and ensure they can choose the best tools for their needs.
                    Portability ensures businesses can migrate their solutions when necessary, safeguarding their
                    continuity plans and future-proofing their tech investments.
                </p>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <h2>Deasil's Custom Data Platforms</h2>
                    <p>
                        Deasil crafts advanced data platforms by intelligently integrating
                        OpenSearch, <TextLink name="oss-trino"/>, <TextLink name="oss-postgresql"/>, <TextLink
                        name="oss-apache-cassandra"/>, <TextLink name="oss-apache-nifi"/>, <TextLink
                        name="oss-apache-hive"/>, <TextLink name="oss-seaweedfs"/>, <TextLink
                        name="oss-ceph"/>, <TextLink name="oss-apache-superset"/>, <TextLink name="oss-jupyterhub"/>,
                        and <TextLink name="oss-keycloak"/> on <TextLink name="oss-kubernetes"/>. This amalgamation of
                        technologies delivers powerful search and analytics capabilities through OpenSearch, allowing
                        clients to efficiently index, search, and analyze large datasets in real time. Trino facilitates
                        fast and scalable data analytics across various data sources, allowing users to query data where
                        it lives, whether in PostgreSQL, Cassandra, or Hive, promoting efficient data discovery and
                        analytics.
                    </p>
                    <Icon img="icon-Cloud-Database-Transfer" name="fast serving of large volumes of
                        files" classes="secondary mt-1 desktop-hide"/>
                    <div className="cols">
                        <p>
                            Apache NiFi empowers the platform by extracting and directing data flow and transformation
                            between systems. At the same time, Apache Hive offers a mechanism for project structuring,
                            data summarization, and querying deep data lakes. SeaweedFS and Ceph collectively provide a
                            robust and resilient storage layer, ensuring data integrity, accessibility, and S3
                            compatibility. SeaweedFS specifically enables cost-effective storage and fast serving of
                            large volumes of files, complementing Ceph’s capabilities in providing scalable and reliable
                            storage infrastructure.
                        </p>
                        <Icon img="icon-Cloud-Database-Transfer" name="fast serving of large volumes of
                        files" classes="secondary mt-1 mobile-hide"/>
                    </div>
                    <div className="cols">
                        <Icon img="icon-Communication" name="Collaboration" classes="secondary mt-2"/>
                        <p>
                            On the data visualization and business intelligence front, Apache Superset enhances the
                            platform’s ability to explore, visualize, and collaborate on large datasets. Superset
                            integrates seamlessly with various data sources, including OpenSearch, Trino, and
                            PostgreSQL, delivering real-time insights through interactive dashboards. JupyterHub
                            provides a collaborative environment for interactive computing and development, while
                            Keycloak handles identity and access management, ensuring secure and controlled access to
                            resources. Running atop Kubernetes, these technologies achieve scalable, reliable, and
                            easily manageable solutions as Kubernetes orchestrates and automates application container
                            deployment, scaling, and operation, making Deasil’s data platforms robust and future-proof.
                        </p>
                    </div>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Related Services and Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-apache-nifi"/></li>
                            <li><TextLink name="oss-postgresql"/></li>
                            <li><TextLink name="oss-apache-superset"/></li>
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-open-source-expertise"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default OpenSearch;
