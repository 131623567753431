import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const ApacheHive = () => {
    return (
        <>
            <Hero page="oss-apache-hive"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/Apache-Hive-logo.svg" name="Apache Hive" classes="logo client mr-2"/>
                    <div>
                        <p>
                            Apache Hive provides a data warehousing infrastructure atop the Hadoop ecosystem,
                            facilitating structured data processing through an SQL-like interface. Developed initially
                            by Facebook, Hive translates these SQL-like queries into MapReduce jobs underneath, offering
                            the convenience of SQL to those analyzing vast amounts of unstructured data in Hadoop. It
                            structures data into tables like traditional relational databases, making it a crucial
                            bridge between relational database management systems and Hadoop's distributed storage and
                            processing capabilities.

                        </p>
                        <p>
                            <TextLink name="oss-seaweedfs"/>, a scalable
                            and flexible object storage solution, has gained
                            widespread adoption in big data. Integrating Apache Hive with S3 storage delivers a potent
                            combination of structured data querying and affordable, scalable storage. This integration
                            allows Hive to utilize S3's robust scalability and cost-effectiveness, detaching storage
                            concerns from computational ones. As a result, businesses can adjust storage and compute
                            resources independently, optimizing costs and performance based on varying needs.
                        </p>
                    </div>
                </div>
                <p>
                    The significance of Apache Hive stems from its ability to structure and make sense of the enormous
                    volumes of unstructured data in platforms like Hadoop. Its SQL-like interface democratizes access to
                    this data, enabling a wide range of professionals, from business analysts to data scientists, to
                    extract valuable insights without diving deep into the complexities of the Hadoop ecosystem. When
                    Hive leverages S3 storage, it offers organizations an efficient way to manage vast datasets,
                    ensuring durability, flexibility, and significant cost savings.
                </p>
                <div className="cols">
                    <p className="mr-2">
                        Introducing <TextLink name="oss-trino"/> to
                        the mix takes this integration to a new height. Trino, a distributed SQL
                        query engine, can process queries across various data sources, from Hadoop to traditional
                        relational databases and, crucially, S3-based object storage. When used alongside Apache Hive
                        and S3, Trino delivers faster query performance, paving the way for real-time analytics. Its
                        design caters to high-concurrency, low-latency queries, filling a vital gap for users requiring
                        swift data insights.
                    </p>
                    <p className="mr-2">
                        While the pairing of Apache Hive and S3 storage already creates a solid foundation for Big Data
                        warehousing, Trino's inclusion represents a significant advancement in this domain. These
                        technologies offer organizations a comprehensive, efficient, and cost-effective solution to meet
                        the challenges of modern big data analytics, so that businesses can quickly and effectively
                        derive value from their data assets.
                    </p>
                </div>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <h2>Deasil's Custom Data Platforms</h2>
                    <p>
                        Deasil crafts custom comprehensive data platforms by leveraging the power of Apache Hive paired
                        with an S3-compatible storage layer. This integration allows organizations to efficiently
                        process vast datasets using Hive's structured querying capabilities, while the S3 storage offers
                        scalability, durability, and cost-effectiveness. The data stored in this manner becomes easily
                        queryable and benefits from the robust and flexible nature of S3 storage, delivering high
                        availability and resilience. As a result, clients gain a unified platform where structured and
                        unstructured data coexist, ready for extraction, transformation, and loading processes.
                    </p>
                    <p>
                        Building upon this foundation, Deasil
                        integrates <TextLink name="oss-trino"/> and <TextLink name="oss-apache-superset"/> to
                        elevate data querying and visualization capabilities. Trino acts as a distributed SQL query
                        engine, speeding up the data retrieval process across multiple sources, including the S3-based
                        storage populated by Hive. On the other hand, Apache Superset offers an intuitive interface for
                        businesses to visualize, explore, and share the data insights derived from these queries.
                        Furthermore, with the inclusion of <TextLink name="oss-apache-nifi"/>, the
                        data flow becomes streamlined and automated, ensuring timely data ingestion and
                        processing. <TextLink name="oss-opensearch"/> enhances
                        the data platform's capabilities by offering scalable search and analytics,
                        while <TextLink name="oss-jupyterhub"/> provides
                        data scientists and analysts an interactive computing environment, making data exploration and
                        machine learning model development more seamless and collaborative.
                    </p>
                    <p>
                        Central to this intricate data ecosystem is <TextLink name="oss-kubernetes"/>, which
                        Deasil employs to manage and orchestrate all these services. Kubernetes ensures that each
                        platform component, from Apache Hive to JupyterHub, runs efficiently in containerized
                        environments, guaranteeing high availability, fault tolerance, and scalability. This container
                        orchestration allows Deasil to deploy, scale, and manage the entire data platform effortlessly,
                        delivering robust, performant, cost-effective solutions to its clients. In essence, Deasil's
                        comprehensive approach, backed by Kubernetes, creates a cohesive, powerful, and efficient data
                        ecosystem that caters to the multifaceted needs of modern businesses.
                    </p>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Apache Hive Related Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-databases-data-warehousing"/></li>
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-apache-superset"/></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <h3>Apache Hive Related Services:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-apache-nifi"/></li>
                            <li><TextLink name="oss-opensearch"/></li>
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-devops"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default ApacheHive;
