import * as React from 'react';
import Blank from '../assets/images/icon-blank.png';

const Image = ({path, name = '', classes = '', style = {}}) => {

    let src = '';
    try {
        const image = process.env.PUBLIC_URL + '/static/images/' + path;
        src = (image) ? image : <Blank/>;
    } catch(err) {
    }

    return (
        <img src={src} alt={name} loading="lazy" width="200" height="200" className={`${classes}`} style={style}/>
    )
};

export default Image;
