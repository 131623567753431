import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const Kubernetes = () => {
    return (
        <>
            <Hero page="oss-kubernetes"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/Kubernetes-logo-horizontal.svg" name="Kubernetes"
                           classes="logo client mt-2 mr-2"/>
                    <p>
                        Deasil proudly offers managed Kubernetes clusters, enabling businesses and organizations to
                        harness the power and flexibility of container orchestration. Recognizing Kubernetes'
                        unparalleled capacity to manage containerized applications across many hosts, Deasil taps into
                        its core features like automated deployment, scaling, and management capabilities. As the
                        orchestration system gains widespread adoption, our team's expertise ensures clients benefit
                        from high availability, fault tolerance, seamless rolling updates, and a robust ecosystem of
                        integrated tools.
                    </p>
                </div>
                <p>
                    Relevance in today's dynamic tech environment cannot be understated. Kubernetes is the backbone of
                    enterprise platform development, and its popularity attests to its robustness in handling complex
                    container orchestration tasks. Its significance is evident in its adoption rate, with many
                    considering it the leading solution. Deasil has recognized and harnessed this potential, having
                    successfully managed Kubernetes clusters for over five years. Within this timeframe, we have
                    utilized Kubernetes as the keystone for our custom data platforms and highly distributed
                    applications, ensuring our clients' scalability, resilience, and efficiency.
                </p>
                <Image path="Advanced-Platform-Development-with-Kubernetes-banner.jpg"
                       name="Advanced Platform Development with Kubernetes" classes="center full mt-1 mb-3"/>
                <p>
                    Deasil's chief architect, Craig Johnston, presents our position at the forefront of Kubernetes
                    utilization with his book <em>Advanced Platform Development with Kubernetes: Enabling Data
                    Management, the Internet of Things, Blockchain, and Machine Learning.</em> Published by Apress, this
                    comprehensive guide showcases how to leverage Kubernetes to adopt groundbreaking technologies. With
                    a focus on <strong>IoT</strong>, <strong>Blockchain</strong>, <strong>Machine Learning</strong>,
                    and the underlying layers of data and application management, readers gain invaluable insights into
                    building state-of-the-art platforms. The book meticulously walks through various technologies
                    like <TextLink name="oss-apache-kafka" text="Kafka"/>, <TextLink name="oss-apache-nifi"
                                                                                     text="NiFi"/>
                    , Airflow, and more, using Golang and Python to demonstrate custom container and Serverless
                    functions integration.
                </p>
                <p>
                    Deasil's dedication to Kubernetes isn't just about staying current; it's about paving the way for
                    the future of platform development. By leveraging the capabilities of Kubernetes, we empower our
                    clients to craft potent data and application platforms. Our experience, expertise, and insights from
                    Craig Johnston's book reinforce our relationship with Kubernetes. We see its transformative power
                    daily and remain committed to harnessing its strengths to benefit our diverse clientele.
                </p>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg bg-white mb-0">
                    <h2>The Power of Bare-Metal Kubernetes</h2>
                    <p>
                        At Deasil, we harness our expansive infrastructure and data center presence to provide clients
                        with bare-metal Kubernetes solutions. Unlike typical cloud-based offerings, our approach
                        prioritizes direct access to physical servers, eliminating the overhead of virtualization. By
                        deploying Kubernetes directly on these servers, we ensure clients benefit from the raw power and
                        efficiency inherent in bare-metal configurations.
                    </p>
                    <p>
                        Opting for bare-metal Kubernetes offers significant cost and performance advantages. Firstly, by
                        bypassing the need for virtual machine layers, every ounce of computational power and memory
                        from the hardware directly serves the Kubernetes clusters, leading to heightened performance.
                        Clients also receive substantial cost savings since we don't lean on overpriced public cloud
                        solutions but instead utilize our vast infrastructure. Essentially, Deasil's bare-metal
                        Kubernetes strategy merges optimal performance with cost-effectiveness, providing unparalleled
                        value for our clientele.
                    </p>
                </div>
                <div className="panel bg bg-white mt-4 mb-0">
                    <h2>Deasil's Custom Data Platforms</h2>
                    <p>
                        At Deasil, we integrate Kubernetes as the cornerstone of our Custom Data Platforms, optimizing
                        it to act as the unifying layer for numerous essential services and technologies. We leverage
                        the power and flexibility of Kubernetes to deploy and manage benefits such
                        as <TextLink name="oss-jupyterhub"/>, <TextLink name="oss-opensearch"/>, <TextLink
                        name="oss-trino"/>
                        , and <TextLink name="oss-postgresql"/>, ensuring seamless integration and interoperability.
                        These integrations allow for dynamic data handling, analytics, visualization, and management,
                        granting our clients access to robust and comprehensive data solutions.
                    </p>
                    <p>
                        Furthermore, Deasil combines Kubernetes with the strengths
                        of <TextLink name="oss-apache-cassandra"/>, <TextLink name="oss-apache-nifi"/>, <TextLink
                        name="oss-apache-hive"/>, <TextLink name="oss-seaweedfs"/>, <TextLink
                        name="oss-ceph"/>, <TextLink name="oss-apache-superset"/>, and <TextLink name="oss-keycloak"/>.
                        By doing so, we craft platforms that excel in distributed storage, data processing, identity
                        management, and advanced analytics. This combinatorial effect of diverse technologies under the
                        Kubernetes umbrella ensures our Custom Data Platforms remain scalable, resilient, and versatile,
                        meeting the multifarious requirements of our vast clientele
                        across industries.
                    </p>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Related Services and Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-apache-nifi"/></li>
                            <li><TextLink name="oss-postgresql"/></li>
                            <li><TextLink name="oss-apache-superset"/></li>
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-open-source-expertise"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Kubernetes;
