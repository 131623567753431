import React, {useEffect, useState} from 'react';
import {createCookie, deleteGACookies, getCookie} from '../utils/functions';
import CookieSettings from './CookieSettings';
import '../assets/css/cookie.css';

const consentCookieName = 'dwCookieConsent';

const CookieConsent = () => {

    const consentCookie = getCookie(consentCookieName);
    const [bannerClass, setBannerClass] = useState('');
    const [settingsOpen, setSettingsOpen] = useState(false);

    // Effect: Display Consent banner if cookie is absent
    useEffect(() => {
        const setBanner = () => {
            setTimeout(function() {
                setBannerClass((consentCookie) ? '' : ' active');
            }, 1000);
        };

        return setBanner();

    }, [consentCookie]);

    const displaySettings = (show = true) => {
        setSettingsOpen(true);
    };

    // Accept all cookies
    const giveCookieConsent = () => {
        createCookie(consentCookieName, 'acceptAll', 365);
        setBannerClass('')
    };

    // Handle event from Settings panel
    const handleSettingsUpdate = (e) => {
        if (e.type === 'acceptAll') {
            // Accept all cookies and set preference
            giveCookieConsent();
        }
        if (e.type === 'settings') {
            // Deny non-essential cookies and set preference
            let disableAllNonEssential = (e.values.disableAll === true);
            let value = (disableAllNonEssential) ? 'disableNonEssential' : 'acceptAll';
            createCookie(consentCookieName, value, 365);
            if (disableAllNonEssential) {
                // Delete Google Analytics
                deleteGACookies();
            }
        }
        setBannerClass('')
        setSettingsOpen(false);
    };

    return (
        <div className={`cookie-consent${bannerClass}`}>
            <div className="cc-content cols center-vertical">
                <p className="mb-0">
                    We use cookies to enhance your user experience.
                </p>
                <div className="cols btns">
                    <button type="button" className="btn btn-light small sm-center mr-1 ml-1"
                            onClick={() => displaySettings(true)}>
                        Cookie Settings
                    </button>
                    <button type="button" className="btn primary small sm-center mr-1 ml-1"
                            onClick={() => giveCookieConsent()}>
                        Accept All
                    </button>
                </div>
            </div>

            <CookieSettings open={settingsOpen} cookie={consentCookie} onUpdate={(e) => handleSettingsUpdate(e)}/>
        </div>
    )
};

export default CookieConsent;
