import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';

const DistributedStorageSystems = () => {
    return (
        <>
            <Hero page="oss-distributed-storage-systems"/>

            <section className="bg full mb-4 cols">
                <span>
                    <p>
                        Deasil manages <TextLink name="oss-ceph"/> and <TextLink name="oss-seaweedfs"/> storage
                        clusters directly for clients and uses these powerful applications as the persistent storage
                        layer of our custom data platforms. Ceph is a robust distributed storage system that provides
                        highly scalable and redundant storage solutions. Ceph's object storage capabilities,
                        compatible with the S3 API, enable Deasil to offer storage interfaces commonly used by
                        clients, ensuring easy integration and data retrieval on a massive scale.
                    </p>
                    <p>
                        Alongside Ceph, Deasil integrates SeaweedFS, an object store and file system server.
                        SeaweedFS excels in storing anything from millions of multi-terabyte files to billions of
                        small files, enhancing efficiency and streamlining all data operations.
                    </p>
                    <p>
                        Deasil unifies and manages Ceph and SeaweedFS storage clusters as encapsulated
                        containers, and leverages <TextLink name="oss-kubernetes"/>, the industry-standard
                        container orchestration platform, to automate the deployment, scaling, management,
                        security, and health of the Ceph and SeaweedFS containers, granting continuous
                        availability and fault tolerance. Deasil delivers a cohesive, scalable, and
                        high-availability storage platform by orchestrating these containers within
                        Kubernetes.
                    </p>
                </span>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Distributed Storage Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-ceph"/></li>
                            <li><TextLink name="oss-seaweedfs"/></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <h3>Distributed Storage Related Services:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="service-hosting-managed-infrastructure"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-etl-elt-operations"/></li>
                            <li><TextLink name="service-data-management-pipelines"/></li>
                            <li><TextLink name="service-platform-data-migration"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-data-science-environments"/></li>
                            <li><TextLink name="service-machine-learning-infrastructure"/></li>
                            <li><TextLink name="service-data-warehouses"/></li>
                            <li><TextLink name="service-data-lakes"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default DistributedStorageSystems;
