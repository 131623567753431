import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const ApacheSuperset = () => {
    return (
        <>
            <Hero page="oss-apache-superset"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/Apache-Superset-logo-dark.svg" name="Apache Superset Logo"
                           classes="logo client mr-2"/>
                    <div>
                        <p>
                            Apache Superset emerged as a modern, open-source data visualization tool that addressed the
                            evolving data exploration and visualization needs in the Big Data era. Initially developed
                            at Airbnb in 2015 as a hackathon project called "Panoramix," its significance quickly became
                            evident as it evolved into a data exploration platform to cater to Airbnb's dynamic and
                            growing needs. Given its promising capabilities, Airbnb open-sourced the platform in 2016,
                            renaming it "Superset," it rapidly gained traction within the broader developer community.
                        </p>
                    </div>
                </div>
                <p>
                    The significance of Apache Superset lies in its extensive feature set. It supports connections to
                    numerous data sources, from traditional RDBMS to modern cloud-based databases, thus bridging the
                    data sourcing needs of various businesses. Users can produce countless visualizations, from simple
                    bar charts to intricate geospatial analyses, and can consolidate these visualizations into
                    comprehensive dashboards. Additionally, its integrated SQL Lab allows users to write, test, and
                    visualize SQL queries, enhancing data exploration without additional tooling. Apache Superset also
                    champions a robust security model, ensuring data access controls are both fine-grained and
                    customizable.
                </p>
                <p>
                    Deasil, recognizing the power and flexibility of Apache Superset, provides managed Apache Superset
                    services to its clientele. By doing so, Deasil equips businesses with a tailored deployment of
                    Superset, ensuring it seamlessly integrates with the client's existing data infrastructure. This
                    managed service alleviates the challenges of installation, configuration, and scaling, allowing
                    clients to focus on extracting insights rather than grappling with operational hurdles.
                </p>
                <p>
                    Beyond mere deployment, Deasil offers continuous support, monitoring, and optimization for Apache
                    Superset, ensuring that businesses can maximize the platform's utility with the assurance of
                    performance optimization and security. Through Deasil's managed services, clients harness the
                    capabilities of Apache Superset most efficiently, all while benefiting from the expertise and
                    backing of <TextLink name="service-hosting-managed-infrastructure"
                                         text="a seasoned infrastructure company"/>.
                </p>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <h2>Bespoke Data Platforms</h2>
                    <p>
                        Deasil synergistically combines Apache Superset with a suite of other robust enterprise-grade
                        open-source solutions, crafting high-performance, tailored data platforms that cater to diverse
                        client needs. Apache Superset is the forefront visualization tool, enabling users to prepare
                        detailed visual reports and dashboards from data stored across different databases. When paired
                        with <TextLink name="oss-trino"/> and <TextLink name="oss-postgresql"/>, clients gain rapid SQL
                        query capabilities and robust relational data
                        management, ensuring that data retrieval is fast and reliable.
                    </p>
                    <p>
                        Further enriching this data ecosystem is the incorporation
                        of <TextLink name="oss-apache-cassandra"/>, <TextLink name="oss-apache-nifi"/>,
                        and <TextLink name="oss-apache-hive"/>. Apache Cassandra provides a distributed database system,
                        ensuring high availability and scalability, especially for clients with vast data volumes.
                        Apache NiFi facilitates efficient data flow between systems, streamlining the data ingestion,
                        transformation, and distribution process. When used in conjunction, this trio grants clients a
                        holistic approach to data management. Additionally, <TextLink name="oss-opensearch"/> enhances
                        data search and analysis capabilities, while <TextLink name="oss-jupyterhub"/> offers an
                        interactive data science and analytics environment. The integration
                        of <TextLink name="oss-keycloak"/> ensures that all these platforms maintain top-tier security
                        standards with its identity and access management features.
                    </p>
                    <p>
                        Leveraging <TextLink name="oss-kubernetes"/>, Deasil ensures that these diverse tools operate
                        smoothly in concert,
                        benefiting from container orchestration's scalability, reliability, and efficiency. Deploying
                        these platforms on <TextLink name="service-hosting-managed-infrastructure"
                                                     text="Deasil's private infrastructure"/> instead of
                        generic public cloud offerings ensures that clients get a cost-effective solution without
                        compromising performance or security. This cohesive, end-to-end approach by Deasil, from design
                        to deployment, empowers businesses with a data platform that is both comprehensive and finely
                        tuned to their unique requirements.
                    </p>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Related Services and Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-pgadmin"/></li>
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-apache-nifi"/></li>
                            <li><TextLink name="oss-opensearch"/></li>
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-open-source-expertise"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default ApacheSuperset;
