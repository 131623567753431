import * as React from 'react';
import {Link} from 'react-router-dom';
import Background from '../../components/Background';
import ContactBlock from '../../components/ContactBlock';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import ServicePanel from '../../components/ServicePanel';
import TextLink from '../../components/TextLink';

const Government = () => {
    return (
        <>
            <Hero page="industry-government" classes="has-overlay"/>

            <section className="bg bg-primary overlay-top mb-4">
                <p className="mb-0">
                    As a trailblazer in technology and infrastructure, Deasil offers over a quarter of a century's
                    experience in custom software development, managed services, hosting, and infrastructure. From
                    individuals to mammoth industries
                    spanning <TextLink name="industry-creative-agencies" text="creative agencies"/>, <TextLink
                    name="industry-logistics" text="logistics"/>, <TextLink name="industry-academia" text="academia"/>,
                    and more, Deasil's vast portfolio serves an array of clientele. Deasil often tailors its service to
                    optimize, streamline, and innovate critical operational aspects for government organizations,
                    ensuring the public sector benefits from the latest digital technology. Deasil's comprehensive suite
                    of services and expertise aligns well with the multifaceted needs of government organizations.
                    Whether it's security, scalability, data management, or custom software development, Deasil is
                    well-equipped to cater to the unique demands of the public sector.
                </p>
            </section>

            <div className="divider spacer pt-2"></div>

            <section className="bg bg-primary full">
                <Background path="bg-Network-Connection-3D-bw.jpg" opacity={0.18}/>

                <h2>Improving Government</h2>

                <ul className="service-links light">
                    <li className="mb-2">
                        <strong>Security and Compliance</strong>: Government organizations require robust security
                        measures to protect sensitive information and must often adhere to strict regulatory standards.
                        With expertise
                        in <TextLink name="oss-identity-access-management"/>, Deasil ensures that only authorized
                        personnel access sensitive data. Additionally, Deasil offers secure data storage and processing
                        capabilities by managing enterprise-grade OSS solutions.
                    </li>
                    <li className="mb-2">
                        <strong>Scalability and Reliability</strong>: Governments serve large populations, meaning their
                        systems must handle large volumes of data and transactions reliably. Deasil's experience
                        with <TextLink name="oss-distributed-storage-systems"/> and <TextLink
                        name="oss-query-search-engines" text="Distributed Query Engines"/> ensures that systems can
                        scale to meet the demands of any government agency. Deasil's expertise in enterprise-grade OSS
                        solutions
                        like <TextLink name="oss-apache-cassandra"/> and <TextLink name="oss-apache-nifi"/> guarantees
                        high availability and fault tolerance.
                    </li>
                    <li className="mb-2">
                        <strong>Data Management and Analysis</strong>: Governments generate and handle vast amounts of
                        data and require sophisticated tools to analyze this data for efficient operations. With
                        capabilities in <TextLink name="oss-data-integration-etl"/>, <TextLink
                        name="oss-data-visualization-business-intelligence" text="Data Visualization"/>, & <TextLink
                        name="oss-data-visualization-business-intelligence" text="Business Intelligence"/>, Deasil
                        provides the tools and platforms for governments to harness their data
                        effectively. <TextLink name="oss-apache-superset"/>, <TextLink name="oss-postgresql"/>,
                        and <TextLink name="oss-apache-nifi"/> are among the many tools Deasil offers for data analysis
                        and management.
                    </li>
                    <li className="mb-2">
                        <strong>Automation and Efficiency</strong>: Government agencies must streamline processes and
                        minimize manual interventions to serve citizens effectively. Deasil minimizes repetitive tasks
                        with its expertise in process automation, enhancing the efficiency of government operations.
                    </li>
                    <li className="mb-2">
                        <strong>Digital Transformation</strong>: With the world becoming increasingly digital,
                        governments must transform their services to offer digital solutions to their citizens. Deasil
                        offers <TextLink name="service-digital-transformation"/> services, ensuring government
                        organizations can effectively move their operations and services to digital platforms.
                    </li>
                    <li className="mb-2">
                        <strong>Infrastructure and Hosting</strong>: Governments require reliable and cost-effective
                        hosting solutions for their digital assets. Unlike other providers who might resell public cloud
                        offerings at higher prices, Deasil leverages its extensive
                        secure, <TextLink name="service-private-custom-clouds"
                                          text="private infrastructure and data center presence"/> to offer
                        cost-effective hosting and <TextLink name="service-hosting-managed-infrastructure"
                                                             text="managed infrastructure services"/>.
                    </li>
                    <li className="mb-2">
                        <strong>Custom Solutions</strong>: Every government has unique challenges that off-the-shelf
                        software cannot always address. With
                        in-house <TextLink name="service-digital-transformation"
                                           text="custom software development"/> capabilities for various platforms
                        (mobile, IoT, web services), Deasil tailors solutions to meet the specific requirements of any
                        government agency.
                    </li>
                    <li className="mb-2">
                        <strong>Research and Development</strong>: Governments often pursue research and academic
                        pursuits, needing specialized tools and platforms. By offering platforms
                        like <TextLink name="oss-jupyterhub"/> and <TextLink name="oss-jupyterlab"/>, Deasil
                        assists government organizations in their R&D endeavors.
                    </li>
                </ul>
            </section>

            <section className="bg full cols wrap">
                <h2>Deasil's Platform Services and Custom Development</h2>

                <ServicePanel
                    name="service-open-source-expertise"
                    children={<>Deasil's proficiency in managing enterprise-grade open-source software solutions, such
                        as <TextLink name="oss-apache-cassandra"/>, <TextLink name="oss-apache-nifi"/>, <TextLink
                            name="oss-apache-kafka"/>, <TextLink name="oss-postgresql"/>, <TextLink name="oss-trino"/>,
                        and <TextLink name="oss-jupyterhub"/>, empowers government organizations with robust, scalable,
                        and adaptable technologies. This expertise allows for transparent, community-driven updates and
                        flexibility, enabling governments to maintain pace with technology advancements without
                        significant investments in proprietary systems.</>
                    }
                />
                <ServicePanel
                    name="service-custom-data-platforms"
                    children={<>By designing and implementing tailored data platforms, Deasil ensures government
                        entities have streamlined access to and manage their vast data repositories. These platforms,
                        developed with precision, aid in faster decision-making, improve public service delivery and
                        enhance inter-departmental collaboration.</>
                    }
                />
                <ServicePanel
                    name="service-sso-iam"
                    children={<>Through solutions like <TextLink name="oss-keycloak"/>, Deasil strengthens the security
                        framework for governments. Their Single Sign-On (SSO) and Identity and Access Management
                        services ensure that only authorized individuals gain access to sensitive data, thereby
                        safeguarding information while simplifying user access.</>
                    }
                />
                <ServicePanel
                    name="service-custom-software-development"
                    children={<>Tailoring software solutions to the unique needs of government agencies, Deasil crafts
                        tools and applications that address specific challenges, improve operational efficiencies, and
                        promote public engagement. These custom-built solutions ensure a seamless fit with existing
                        systems while fostering innovation.</>
                    }
                />
                <ServicePanel
                    name="service-mobile-applications"
                    children={<>Understanding the ubiquity of mobile devices, Deasil creates intuitive and user-friendly
                        mobile applications. These facilitate on-the-go access to essential services and information,
                        enhancing the public's interaction with government agencies and ensuring more efficient service
                        delivery.</>
                    }
                />
                <ServicePanel
                    name="service-hosting-managed-infrastructure"
                    children={<>By leveraging its
                        expansive <TextLink name="service-data-center-network-operations" text="data center presence"/>,
                        Deasil offers government organizations superior hosting and private infrastructure services,
                        guaranteeing optimal uptime, security, and reliability. It also results in substantial cost
                        savings, as Deasil bypasses the premiums of public cloud offerings.</>
                    }
                />
                <ServicePanel
                    name="service-compute-resources"
                    children={<>Deasil's comprehensive range of computing resources, from bare metal setups to
                        multi-cloud environments, ensures that government agencies scale flexibility and adapt based on
                        their evolving needs. This private, highly secure infrastructure's versatility efficiently
                        responds to public demands.</>
                    }
                />
                <ServicePanel
                    name="service-data-center-network-operations"
                    children={<> With a strong emphasis on efficiency and security, Deasil's data center and network
                        operations provision ensure seamless data flow, storage, and retrieval. This is pivotal for
                        government organizations that manage vast amounts of public data, guaranteeing security,
                        integrity, and availability.</>
                    }
                />
                <ServicePanel
                    name="service-devops"
                    children={<> Deasil's DevOps services incorporate best practices in development and operations,
                        expedite software delivery, reduce downtimes, and ensure continuous integration and deployment,
                        facilitating swift government digital services.</>
                    }
                />
                <ServicePanel
                    name="service-high-availability-performance-engineering"
                    children={<>Deasil's expertise in high availability and performance engineering makes certain
                        that government portals, applications, and systems are consistently available and operate
                        efficiently, enhancing the user experience for employees and the public.</>
                    }
                />
                <ServicePanel
                    name="service-seo-analytics"
                    children={<>To enhance visibility and gauge the impact of digital initiatives, Deasil offers SEO and
                        performance analytics services, so that government websites and platforms rank prominently in
                        search engines, providing insights into user engagement and behavior.</>
                    }
                />
                <ServicePanel
                    name="service-data-management-pipelines"
                    children={<>Efficiently handling the vast amounts of data generated by governments, Deasil's data
                        management and pipeline solutions streamline the process of data ingestion, processing, and
                        distribution with custom software development and enterprise-grade solutions,
                        including <TextLink name="oss-apache-nifi"/>.
                    </>
                    }
                />
                <ServicePanel
                    name="service-data-science-environments"
                    children={<>By setting up tailored data science environments
                        like <TextLink name="oss-jupyterhub"/>, Deasil enables government organizations to delve into
                        advanced analytics, extract meaningful insights, and craft data-driven policies and
                        strategies.</>
                    }
                />
                <ServicePanel
                    name="service-etl-elt-operations"
                    children={<>With expertise in Extract, Transform, Load (ETL) and Extract, Load, Transform (ELT)
                        operations, Deasil ensures that data is appropriately processed, cleaned, and stored, fostering
                        accurate analytics and reporting.</>
                    }
                />
                <div className="panel cols col-6">
                    <Icon img="icon-Database-Architecture"
                          name="Data Warehouses and Data Lakes"
                          classes="primary medium"/>
                    <span>
                        <h3>
                            <Link to="/service/data-warehouses">Data Warehouses</Link> and <Link
                            to="/service/data-lakes">Data Lakes</Link>
                        </h3>
                        <p className="text-small mb-2">
                            Facilitating centralized storage solutions, Deasil's data warehouses and data lakes allow
                            for efficient data consolidation, access, and analytics, enabling governments to harness
                            the full potential of their data resources.
                        </p>
                    </span>
                </div>
                <ServicePanel
                    name="service-data-visualization-exploration"
                    children={<>Utilizing tools like <TextLink name="oss-apache-superset"/>, Deasil aids government
                        organizations in visually representing and exploring their data, facilitating better
                        understanding and informed decision-making.</>
                    }
                />
                <ServicePanel
                    name="service-platform-data-migration"
                    children={<>Recognizing the need for adaptability, Deasil provides seamless platform and data
                        migration services, ensuring that government systems remain updated, integrated, and in line
                        with technological advancements.</>
                    }
                />
                <ServicePanel
                    name="service-private-custom-clouds"
                    children={<>Offering private and custom cloud solutions, Deasil ensures that governments can
                        harness the benefits of cloud computing while retaining greater control and security over their
                        data.</>
                    }
                />
                <ServicePanel
                    name="service-legacy-systems-integration"
                    children={<>Understanding that transitioning entirely from legacy systems is only sometimes
                        feasible, Deasil ensures these older systems are effectively integrated with newer
                        technologies, maximizing utility while minimizing disruption.</>
                    }
                />
                <ServicePanel
                    name="service-machine-learning-infrastructure"
                    children={<>Tapping into the power of machine learning, Deasil provides the necessary infrastructure
                        for governments to implement predictive analytics, automate processes, and enhance public
                        service delivery.</>
                    }
                />
                <ServicePanel
                    name="service-digital-transformation"
                    children={<>At the heart of Deasil's offerings is the promise of digital transformation. By
                        revamping traditional processes and introducing modern digital solutions, Deasil empowers
                        government organizations to operate more efficiently, serve the public better, and remain at
                        the forefront of technological advancements.</>
                    }
                />
            </section>

            <ContactBlock label="Contact Deasil"/>
        </>
    )
};

export default Government;
