import * as React from 'react';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';

const DevelopmentOperations = () => {
    return (
        <>
            <Hero page="service-devops"/>

            <section className="bg full">
                <h2>
                    Accelerate Innovation with 25 Years of Expertise
                </h2>
                <p>
                    Welcome to Deasil's DevOps Solutions, where technology meets experience to empower your team to
                    build faster, smarter, and more efficiently.
                </p>
            </section>

            <section className="bg bg-primary full">
                <h2>What Do We Offer?</h2>
                <div className="cols">
                    <div className="panel bg-white col-4 mr-1 ml-1">
                        <Icon img="icon-Code-Engineering" name="DevOps Platform" classes="secondary mb-2 mr-0"/>
                        <h3>DevOps Platform</h3>
                        <p>
                            Our robust, feature-rich DevOps platform and process integrate seamlessly with many of your
                            existing tools and workflows, automating critical stages of your development pipeline.
                        </p>
                    </div>
                    <div className="panel bg-white col-4 mr-1 ml-1">
                        <Icon img="icon-Person-IT-Idea" name="Consulting" classes="secondary mb-2 mr-0"/>
                        <h3>Consulting</h3>
                        <p>
                            Our DevOps experts analyze your current practices and pain points and offer tailored
                            solutions to optimize your development operations.
                        </p>
                    </div>
                    <div className="panel bg-white col-4 mr-1 ml-1">
                        <Icon img="icon-Learning" name="Training" classes="secondary mb-2 mr-0"/>
                        <h3>Training</h3>
                        <p>
                            Empower your team with the skills they need to excel in a DevOps environment through our
                            comprehensive training programs.
                        </p>
                    </div>
                </div>

                <h2>Why Deasil?</h2>
                <div className="cols">
                    <div className="panel bg-white col-4 mr-1 ml-1">
                        <Icon img="icon-People" name="Proven Expertise" classes="secondary mb-2 mr-0"/>
                        <h3>Proven Expertise</h3>
                        <p>
                            25 years in the software development industry means we've seen and solved it all. Benefit
                            from our deep-rooted expertise.
                        </p>
                    </div>
                    <div className="panel bg-white col-4 mr-1 ml-1">
                        <Icon img="icon-Problem-Solving" name="End-to-End Solutions" classes="secondary mb-2 mr-0"/>
                        <h3>End-to-End Solutions</h3>
                        <p>
                            We cover every aspect of your DevOps transformation, from initial assessment to platform
                            deployment and ongoing support.
                        </p>
                    </div>
                    <div className="panel bg-white col-4 mr-1 ml-1">
                        <Icon img="icon-Code-User-Prediction" name="Customer-Centric Approach"
                              classes="secondary mb-2 mr-0"/>
                        <h3>Customer-Centric Approach</h3>
                        <p>
                            Every business and our approach to each client are unique. We customize solutions to fit
                            your specific needs.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
};

export default DevelopmentOperations;
