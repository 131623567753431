import * as React from 'react';
import {Link} from 'react-router-dom';
import Background from '../../components/Background';
import ContactBlock from '../../components/ContactBlock';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import ServicePanel from '../../components/ServicePanel';
import TextLink from '../../components/TextLink';

const Mortgage = () => {
    return (
        <>
            <Hero page="industry-mortgage" classes="has-overlay"/>

            <section className="bg bg-primary overlay-top mb-4">
                <p className="mb-0">
                    Deasil, a veteran in the technology sector with over two decades of unparalleled expertise,
                    provides a comprehensive range of services. <TextLink name="our-history" text="The journey"/> from
                    the early digital age of entertainment to the sophisticated technology era has equipped us with
                    profound insights and solutions. In the mortgage industry, where precision, scale, and real-time
                    processing are indispensable, Deasil's suite of services and technology becomes particularly
                    invaluable. </p>
            </section>

            <div className="divider spacer pt-2"></div>

            <section className="bg bg-primary full">
                <Background path="bg-Network-Connection-3D-bw.jpg" opacity={0.18}/>

                <h2>Powering the Mortgage Industry</h2>

                <ul className="service-links light">
                    <li className="mb-2">
                        <strong>Data Management & Integration</strong>: The mortgage process involves collecting,
                        storing, and analyzing vast personal and financial data. Deasil
                        offers <TextLink name="service-custom-data-platforms" text="custom data platforms"/>, <TextLink
                        name="oss-databases-data-warehousing"/>, <TextLink name="oss-data-integration-etl"/>,
                        and <TextLink name="oss-distributed-storage-systems"/>, ensuring seamless data integration, safe
                        storage, and efficient retrieval.
                    </li>
                    <li className="mb-2">
                        <strong>Compliance & Security</strong>: The mortgage industry is heavily regulated; assuring
                        compliance is crucial and requires secure systems that handle sensitive personal and financial
                        information. Deasil provides <TextLink name="oss-identity-access-management"/> expertise and
                        leverages enterprise-grade OSS solutions, ensuring data access is restricted, monitored, and
                        compliant with industry standards.
                    </li>
                    <li className="mb-2">
                        <strong>Customer Relationship Management (CRM)</strong>: Keeping track of customer interactions,
                        loan applications, and follow-ups is critical in the mortgage industry. Through custom software
                        development for mobile, IoT, and web services, Deasil designs CRMs tailored to the specific
                        needs of mortgage businesses.
                    </li>
                    <li className="mb-2">
                        <strong>Automated Workflow & Process Automation</strong>: Automating repetitive tasks like
                        document verification, loan approval workflows, and status updates increases efficiency. Deasil
                        specializes in process automation, ensuring businesses can automate mundane tasks, streamline
                        workflows, and reduce human errors.
                    </li>
                    <li className="mb-2">
                        <strong>Analytics & Forecasting</strong>: Mortgage businesses must analyze market trends,
                        customer behaviors, and loan default probabilities to make informed decisions. Deasil
                        offers <TextLink name="oss-data-visualization-business-intelligence"/>, and analytics services.
                        These solutions allow businesses to understand their data better, gain insights, and make
                        data-driven decisions.
                    </li>
                    <li className="mb-2">
                        <strong>Digital Transformation</strong>: As the world becomes more digital, mortgage businesses
                        need to transition from traditional practices to more modern, digital ones. Deasil is an expert
                        in <TextLink name="service-digital-transformation"/>, helping companies to transition seamlessly
                        to contemporary digital strategies.
                    </li>
                    <li className="mb-2">
                        <strong>Machine Learning & Predictive Analysis</strong>: Machine learning can enhance the
                        detection of anomalies, understanding customer behaviors, and automating decision-making
                        processes. Deasil provides expertise
                        in <TextLink name="service-data-science-environments" text="data science"/> and <TextLink
                        name="service-machine-learning-infrastructure" text="machine learning"/> platforms, allowing
                        mortgage businesses to leverage advanced algorithms for predictive analysis.
                    </li>
                    <li className="mb-2">
                        <strong>Scalability & Reliability</strong>: The mortgage industry sees fluctuating demands, and
                        systems need to be scalable to handle increased loads during peak times and reliable to ensure
                        24/7 availability. With Deasil's
                        vast <TextLink name="service-hosting-managed-infrastructure"
                                       text="infrastructure and data center"/> presence, businesses gain high
                        performance, reliability, and scalability. Deasil's expertise
                        in <TextLink name="oss-container-management-infrastructure"
                                     text="Container Orchestration"/> lets applications scale seamlessly as
                        demand increases.
                    </li>
                    <li className="mb-2">
                        <strong>Interactive Platforms for Customers</strong>: Customers nowadays prefer online platforms
                        to track their mortgage application status, get quotes, and interact with customer service.
                        Through Deasil's <TextLink name="service-custom-software-development"
                                                   text="custom software development"/> for mobile and web services,
                        mortgage businesses can offer interactive, user-friendly platforms to their customers.
                    </li>
                </ul>
            </section>

            <section className="bg full cols wrap">
                <h2>Deasil's Platform Services and Custom Development</h2>

                <ServicePanel
                    name="service-open-source-expertise"
                    children={<>In the mortgage sector, where regulations and data security requirements are
                        continuously evolving, open-source tools' flexibility is paramount. Deasil's mastery over
                        enterprise-grade open-source solutions, such
                        as <TextLink name="oss-apache-nifi"/>, <TextLink name="oss-apache-cassandra"/>, and <TextLink
                            name="oss-trino"/>, provides mortgage businesses with adaptable and scalable platforms,
                        ensuring swift adjusted as regulations change or new customer needs arise, without heavy
                        re-engineering costs.</>
                    }
                />
                <ServicePanel
                    name="service-custom-data-platforms"
                    children={<>Mortgage companies deal with vast data, from customer details to property evaluations.
                        Deasil crafts custom data platforms tailored to seamlessly organize, manage, and retrieve this
                        data. Deasil's custom data platforms ensure efficient loan processing, accurate risk
                        assessments, and agile decision-making, placing mortgage companies ahead in the competitive
                        curve.</>
                    }
                />
                <ServicePanel
                    name="service-sso-iam"
                    children={<>Considering the sensitivity of mortgage data, ensuring that only authorized
                        personnel have access is non-negotiable. Deasil's expertise in SSO and Identity & Access
                        Management ensures stringent access controls, enhancing security while providing users with a
                        simplified login process, ensuring efficiency without compromising safety.</>
                    }
                />
                <ServicePanel
                    name="service-custom-software-development"
                    children={<>Every mortgage firm has unique operational intricacies. Deasil's custom software
                        development addresses these specific needs, whether it's automating loan approval processes,
                        integrating disparate data sources, or offering personalized client interfaces, ensuring
                        operational efficiency and customer satisfaction.</>
                    }
                />
                <ServicePanel
                    name="service-mobile-applications"
                    children={<>Today's mortgage customers demand on-the-go solutions. Deasil designs intuitive mobile
                        applications that allow clients to apply for loans, check statuses, or interact with customer
                        services seamlessly from their devices, ensuring businesses stay connected with their customers
                        anytime, anywhere.</>
                    }
                />
                <ServicePanel
                    name="service-hosting-managed-infrastructure"
                    children={<>Mortgage companies generate vast amounts of data daily. Deasil's robust hosting and
                        managed infrastructure services ensure this data is stored, backed up, and retrieved with
                        impeccable speed and security, leading to uninterrupted operations and enhanced customer
                        trust.</>
                    }
                />
                <ServicePanel
                    name="service-compute-resources"
                    children={<>The dynamic nature of the mortgage industry necessitates scalable compute resources.
                        Deasil offers a spectrum, from bare metal to multi-cloud solutions, ensuring that companies can
                        scale up or down based on real-time requirements without operational disruptions.</>
                    }
                />
                <ServicePanel
                    name="service-data-center-network-operations"
                    children={<>Reliability is vital in the mortgage sector. Deasil's state-of-the-art data centers and
                        advanced network operations ensure that mortgage companies' digital operations run smoothly,
                        with a guarantee of uptime, data redundancy, and swift disaster recovery.</>
                    }
                />
                <ServicePanel
                    name="service-devops"
                    children={<>Speed and efficiency in rolling out new software features or fixes set a mortgage firm
                        apart. Deasil's DevOps expertise ensures quick deployments, continuous integration, and
                        uninterrupted service delivery, increasing agility and business responsiveness.</>
                    }
                />
                <ServicePanel
                    name="service-high-availability-performance-engineering"
                    children={<>Downtimes can cost dearly. Deasil's high availability and performance engineering
                        solutions ensure that mortgage companies' platforms are always up and running, catering to
                        customer requests, loan processing, or any other critical operations non-stop.</>
                    }
                />
                <ServicePanel
                    name="service-seo-analytics"
                    children={<>In the digital age, a mortgage company's online presence significantly impacts its
                        success. Deasil's SEO and analytics services ensure businesses rank high on search engines,
                        drive organic traffic, and offer insights into customer behaviors, leading to refined
                        marketing strategies.</>
                    }
                />
                <ServicePanel
                    name="service-data-management-pipelines"
                    children={<>Managing data from various sources like credit score agencies, property databases, or
                        personal financial histories is vital. Deasil creates streamlined data pipelines ensuring swift
                        data ingestion, processing, and retrieval, central to quick loan decisions.</>
                    }
                />
                <ServicePanel
                    name="service-data-science-environments"
                    children={<>Predicting market trends, customer default risks, or property value appreciations
                        require sophisticated data analytics. Deasil's data science environments, backed by advanced
                        tools, empower mortgage companies with predictive insights, leading to informed and profitable
                        decisions.</>
                    }
                />
                <ServicePanel
                    name="service-etl-elt-operations"
                    children={<>Mortgage data comes from varied sources in different formats. Deasil's ETL and ELT
                        operations ensure this data is extracted, transformed, and loaded into systems in a unified
                        structure, for consistency and accuracy.</>
                    }
                />
                <div className="panel cols col-6">
                    <Icon img="icon-Database-Architecture"
                          name="Data Warehouses and Data Lakes"
                          classes="primary medium"/>
                    <span>
                        <h3>
                            <Link to="/service/data-warehouses">Data Warehouses</Link> and <Link
                            to="/service/data-lakes">Data Lakes</Link>
                        </h3>
                        <p className="text-small mb-2">
                            Centralizing data for quick retrieval and analysis is critical. Deasil's data warehouse
                            and lake solutions provide mortgage firms with structured and unstructured data
                            storage solutions, ensuring they have a holistic view of their operations and market
                            trends.
                        </p>
                    </span>
                </div>
                <ServicePanel
                    name="service-data-visualization-exploration"
                    children={<>Mortgage executives need a clear visual representation of their data for effective
                        decision-making. Deasil offers advanced data visualization tools, transforming complex data
                        into understandable visuals, leading to more precise insights and strategies.</>
                    }
                />
                <ServicePanel
                    name="service-platform-data-migration"
                    children={<>Whether adopting a new CRM or moving to a cloud solution, migration can be daunting.
                        Deasil ensures smooth platform and data migration without data loss or operational
                        interruptions, making transitions seamless for mortgage companies.</>
                    }
                />
                <ServicePanel
                    name="service-private-custom-clouds"
                    children={<>For enhanced security and customization, many mortgage businesses prefer private cloud
                        solutions. Deasil's expertise in setting up and managing private and custom clouds ensures
                        companies have dedicated resources, enhancing performance and security.</>
                    }
                />
                <ServicePanel
                    name="service-legacy-systems-integration"
                    children={<>Few mortgage companies can abandon their legacy systems overnight. Deasil excels in
                        integrating these older systems with newer solutions, ensuring continuity and modernization
                        coexist.</>
                    }
                />
                <ServicePanel
                    name="service-machine-learning-infrastructure"
                    children={<>Predicting loan defaults, property value appreciations, or identifying fraud requires
                        machine learning algorithms. Deasil's infrastructure supports these advanced computations,
                        ensuring mortgage firms stay ahead in predictive analytics.</>
                    }
                />
                <ServicePanel
                    name="service-digital-transformation"
                    children={<>The mortgage industry is increasingly going digital. From online loan applications to
                        virtual property valuations, Deasil aids businesses in this transformation journey, keeping
                        them relevant and efficient in the modern age.</>
                    }
                />
            </section>

            <ContactBlock label="Contact Deasil"/>
        </>
    )
};

export default Mortgage;
