import * as React from 'react';
import {Link} from 'react-router-dom';
import {CONTENT_SITE as content} from '../../content/content-site';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';
import Icon from '../../components/Icon';

const ManagedOSS = () => {
    return (
        <>
            <Hero page="oss"/>

            <section className="bg bg-primary full mt-4 mb-4">
                <h2>Managed Open-Source Software (MOSS)</h2>
                <div className="cols">
                    <div className="col-6 mr-1 ml-1">
                        <div className="panel bg-white">
                            <div className="cols">
                                <Link to={content['oss-databases-data-warehousing'].path}
                                      title={content['oss-databases-data-warehousing'].title} className="col-3 mt-1">
                                    <Icon img="icon-Database-Architecture"
                                          name={content['oss-databases-data-warehousing'].title}
                                          classes="secondary"/>
                                </Link>
                                <div className="col-9 mb-1">
                                    <h3 className="mb-1">
                                        <Link to={content['oss-databases-data-warehousing'].path}
                                              title={content['oss-databases-data-warehousing'].title}>
                                            {content['oss-databases-data-warehousing'].title}
                                        </Link>
                                    </h3>
                                    <ul className="service-links">
                                        <li><TextLink name="oss-apache-cassandra"/></li>
                                        <li><TextLink name="oss-apache-hive"/></li>
                                        <li><TextLink name="oss-postgresql"/></li>
                                        <li><TextLink name="oss-pgadmin"/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="panel bg-white">
                            <div className="cols">
                                <Link to={content['oss-data-integration-etl'].path}
                                      title={content['oss-data-integration-etl'].title} className="col-3 mt-1">
                                    <Icon img="icon-Puzzle-Pieces" name={content['oss-data-integration-etl'].title}
                                          classes="secondary"/>
                                </Link>
                                <div className="col-9 mb-1">
                                    <h3 className="mb-1">
                                        <Link to={content['oss-data-integration-etl'].path}
                                              title={content['oss-data-integration-etl'].title}>
                                            {content['oss-data-integration-etl'].title}
                                        </Link>
                                    </h3>
                                    <ul className="service-links">
                                        <li><TextLink name="oss-apache-nifi"/></li>
                                        <li><TextLink name="oss-apache-kafka"/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="panel bg-white">
                            <div className="cols">
                                <Link to={content['oss-data-visualization-business-intelligence'].path}
                                      title={content['oss-data-visualization-business-intelligence'].title}
                                      className="col-3 mt-1">
                                    <Icon img="icon-Dashboard"
                                          name={content['oss-data-visualization-business-intelligence'].title}
                                          classes="secondary"/>
                                </Link>
                                <div className="col-9 mb-1">
                                    <h3 className="mb-1">
                                        <Link to={content['oss-data-visualization-business-intelligence'].path}
                                              title={content['oss-data-visualization-business-intelligence'].title}>
                                            {content['oss-data-visualization-business-intelligence'].title}
                                        </Link>
                                    </h3>
                                    <ul className="service-links">
                                        <li><TextLink name="oss-apache-superset"/></li>
                                        <li><TextLink name="oss-opensearch-dashboards"/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="panel bg-white">
                            <div className="cols">
                                <Link to={content['oss-identity-access-management'].path}
                                      title={content['oss-identity-access-management'].title} className="col-3 mt-1">
                                    <Icon img="icon-Person-Identity-Search"
                                          name={content['oss-identity-access-management'].title}
                                          classes="secondary"/>
                                </Link>
                                <div className="col-9 mb-1">
                                    <h3 className="mb-1">
                                        <Link to={content['oss-identity-access-management'].path}
                                              title={content['oss-identity-access-management'].title}>
                                            {content['oss-identity-access-management'].title}
                                        </Link>
                                    </h3>
                                    <ul className="service-links">
                                        <li><TextLink name="oss-keycloak"/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 mr-1 ml-1">
                        <div className="panel bg-white">
                            <div className="cols">
                                <Link to={content['oss-interactive-computing-development-environments'].path}
                                      title={content['oss-interactive-computing-development-environments'].title}
                                      className="col-3 mt-1">
                                    <Icon img="icon-IT-Department"
                                          name={content['oss-interactive-computing-development-environments'].title}
                                          classes="secondary"/>
                                </Link>
                                <div className="col-9 mb-1">
                                    <h3 className="mb-1">
                                        <Link to={content['oss-interactive-computing-development-environments'].path}
                                              title={content['oss-interactive-computing-development-environments'].title}>
                                            {content['oss-interactive-computing-development-environments'].title}
                                        </Link>
                                    </h3>
                                    <ul className="service-links">
                                        <li><TextLink name="oss-jupyterhub"/></li>
                                        <li><TextLink name="oss-jupyterlab"/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="panel bg-white">
                            <div className="cols">
                                <Link to={content['oss-query-search-engines'].path}
                                      title={content['oss-query-search-engines'].title} className="col-3 mt-1">
                                    <Icon img="icon-Search-Settings" name={content['oss-query-search-engines'].title}
                                          classes="secondary"/>
                                </Link>
                                <div className="col-9 mb-1">
                                    <h3 className="mb-1">
                                        <Link to={content['oss-query-search-engines'].path}
                                              title={content['oss-query-search-engines'].title}>
                                            {content['oss-query-search-engines'].title}
                                        </Link>
                                    </h3>
                                    <ul className="service-links">
                                        <li><TextLink name="oss-trino"/></li>
                                        <li><TextLink name="oss-opensearch"/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="panel bg-white">
                            <div className="cols">
                                <Link to={content['oss-distributed-storage-systems'].path}
                                      title={content['oss-distributed-storage-systems'].title} className="col-3 mt-1">
                                    <Icon img="icon-Distributed-Database"
                                          name={content['oss-distributed-storage-systems'].title}
                                          classes="secondary"/>
                                </Link>
                                <div className="col-9 mb-1">
                                    <h3 className="mb-1">
                                        <Link to={content['oss-distributed-storage-systems'].path}
                                              title={content['oss-distributed-storage-systems'].title}>
                                            {content['oss-distributed-storage-systems'].title}
                                        </Link>
                                    </h3>
                                    <ul className="service-links">
                                        <li><TextLink name="oss-ceph"/></li>
                                        <li><TextLink name="oss-seaweedfs"/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="panel bg-white">
                            <div className="cols">
                                <Link to={content['oss-container-management-infrastructure'].path}
                                      title={content['oss-container-management-infrastructure'].title}
                                      className="col-3 mt-1">
                                    <Icon img="icon-Communication"
                                          name={content['oss-container-management-infrastructure'].title}
                                          classes="secondary"/>
                                </Link>
                                <div className="col-9 mb-1">
                                    <h3 className="mb-1">
                                        <Link to={content['oss-container-management-infrastructure'].path}
                                              title={content['oss-container-management-infrastructure'].title}>
                                            {content['oss-container-management-infrastructure'].title}
                                        </Link>
                                    </h3>
                                    <ul className="service-links">
                                        <li><TextLink name="oss-kubernetes"/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>
        </>
    )
};

export default ManagedOSS;
