import * as React from 'react';
import AboutBlockDeasil from '../../components/AboutBlockDeasil';
import AboutBlockPrivacy from '../../components/AboutBlockPrivacy';
import AboutBlockPrivateClouds from '../../components/AboutPrivateClouds';
import AboutBlockVendorNeutralTechnology from '../../components/AboutBlockVendorNeutralTechnology';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';

const CaseStudyScreenPrinting = () => {

    return (
        <>
            <Hero page="case-study-screen-printing"/>

            <section className="bg full">
                <h3>Introduction</h3>
                <p className="mb-0">
                    In the fast-paced and highly competitive screen printing industry, innovation and efficiency are
                    paramount. A leading North American screen printing company sought to harness the power of data to
                    gain a competitive edge, improve operational efficiency, and optimize machine maintenance schedules.
                    They turned to Deasil, a seasoned custom software development and managed services player, to
                    transform their operations. This case study outlines how Deasil's expertise
                    in <strong>data platforms</strong>, <strong>machine learning</strong>, and <strong>enterprise-grade
                    open-source solutions</strong> propelled the client to new heights of operational excellence.
                </p>
            </section>

            <section>
                <h3>Harnessing Machine Data for Competitive Advantage</h3>
                <p>
                    The client, a leading screen printing company in North America, operates in an industry marred by
                    razor-thin profit margins and the looming threat of cheap overseas labor. Their state-of-the-art
                    screen printing machines were a goldmine of signal data, yet this data needed to be more
                    effectively captured and utilized. The client's objective was clear: leverage this data to
                    unveil hidden insights, optimize operator efficiency, and enhance machine maintenance schedules,
                    all while keeping the solution cost-effective and customizable.
                </p>

                <h3>The Solution: A Customized Hybrid Data Platform</h3>
                <p>
                    Deasil's response was swift and strategic. We developed a customized hybrid data platform,
                    integrating robust open-source applications and utilizing cost-effective Raspberry Pis as signal
                    collection endpoints. The implementation process involved:
                </p>

                <ul className="service-links ml-4 mb-2">
                    <li>
                        Configuring Raspberry Pis to run containerized signal processing software, ensuring
                        self-update capabilities and auto-recovery from hardware or power failures.
                    </li>
                    <li>
                        Implementing onsite <TextLink name="oss-apache-kafka"/> for event-driven data
                        consumption and Prometheus for real-time data aggregation.
                    </li>
                    <li>
                        Establishing connectivity to Deasil's private cloud,
                        running <TextLink name="oss-kubernetes"/>-hosted applications for off-site monitoring and
                        data backup.
                    </li>
                    <li>
                        Installing large video monitors and additional Raspberry Pis across the factory floors,
                        providing operational teams with real-time data visualizations and insights.
                    </li>
                </ul>

                <h3>Results: Enhanced Efficiency and Operational Observability</h3>
                <p>
                    The platform was successfully designed, developed, and deployed within twelve weeks, achieving
                    the client's objectives quickly and paving the way for future upgrades. The modular and stable
                    nature of the platform facilitated continual improvements, while its adherence to open
                    standards ensured easy integration with additional specialized vendors.
                </p>
                <p>
                    The client experienced a significant boost in operational observability, leveraging real-time
                    and historical data for ongoing process improvements and enhanced quality control. The
                    initiative was a resounding success, providing the client with a competitive advantage in a
                    challenging industry landscape.
                </p>

                <h3>Addressing Challenges and Looking Ahead</h3>
                <p>
                    Deasil's innovative approach turned potential roadblocks into opportunities for success. The
                    challenge of converting raw signals and proprietary data was met with a cost-effective solution,
                    utilizing open hardware platforms and off-the-shelf components. Implementing
                    Deasil's <TextLink name="service-private-custom-clouds" text="private cloud solutions"/> drastically
                    reduced data storage costs, granting the client a future-proof, scalable platform.
                </p>

                <h3>Conclusion</h3>
                <p>
                    Deasil's expertly crafted solution met and exceeded the client's expectations, transforming
                    their screen printing operations into a data-driven powerhouse. By leveraging Deasil's
                    unparalleled expertise
                    in <TextLink name="service-open-source-expertise" text="open-source solutions"/>, <TextLink
                    name="service-data-science-environments" text="data science"/>, and <TextLink
                    name="service-custom-software-development" text="custom software development"/>, the client
                    is now perfectly positioned to continue innovating, optimizing, and leading in their industry.
                </p>
                <p className="quote">
                    The case for engaging Deasil's services is clear: unparalleled expertise, cost efficiency, and
                    a proven track record of transformative success.
                </p>
            </section>

            <section className="bg bg-primary full">
                <AboutBlockDeasil/>
                <AboutBlockPrivacy/>
                <AboutBlockVendorNeutralTechnology/>
                <AboutBlockPrivateClouds/>
            </section>
        </>
    )
};
/*


Deasil



 */

export default CaseStudyScreenPrinting;
