import * as React from 'react';
import {Link} from 'react-router-dom';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const ContainerManagementInfrastructure = () => {
    return (
        <>
            <Hero page="oss-container-management-infrastructure"/>

            <section className="bg full mb-4 cols">
                <span className="col-9">
                    <p>
                        Deasil masters the power of <TextLink name="oss-kubernetes"/>, the leading open-source
                        container orchestration platform, to streamline and optimize applications and
                        services' deployment, scaling, and management. Deasil packages the majority of its
                        software solutions into containers, from mobile and IoT applications to complex data
                        platform components. These containers encapsulate the software, its dependencies, and
                        runtime, creating consistency across different deployment environments. By orchestrating
                        these containers with Kubernetes, Deasil ensures that applications can automatically
                        scale based on
                        demand, <TextLink name="service-high-availability-performance-engineering"
                                          text="recover from potential failures"/>, and
                        benefit from load balancing. This automation reduces operational overhead and makes
                        certain that applications are always available and performant, directly aligning with
                        Deasil's commitment to exceptional performance for its clients.
                    </p>
                    <p>
                        Furthermore, Kubernetes is central to Deasil's strategy to offer clients savings and
                        efficiency. By efficiently utilizing resources within Deasil's extensive infrastructure
                        and data center presence, Kubernetes ensures that compute, storage, and network
                        resources are optimally used. The platform's innate ability to manage multi-container
                        applications, handle service discovery, and automate rollouts and rollbacks further
                        underscore its significance in Deasil's offerings. By leveraging Kubernetes, Deasil
                        provides its clients with
                        a <TextLink name="service-high-availability-performance-engineering"
                                    text="resilient, scalable"/>, and unified platform, meeting their diverse
                        software and service needs with unparalleled reliability and efficiency.
                    </p>
                </span>
                <span className="col-3">
                    <Link to="/managed-oss/kubernetes" className="ml-2">
                        <Image path="logos/Kubernetes-logo.svg" name="Kubernetes" classes="logo client square"/>
                    </Link>
                </span>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <h2>Bare Metal Kubernetes</h2>
                    <p>
                        Deasil leverages Bare Metal Kubernetes to optimize application runtimes, eliminating the
                        overhead introduced by virtualized infrastructure layers typically associated with public cloud
                        services. By running Kubernetes directly on bare metal servers, Deasil ensures that applications
                        have direct access to the hardware, translating to faster processing speeds, higher I/O rates,
                        and enhanced performance for compute-intensive tasks. This direct hardware access is invaluable,
                        especially for resource-intensive applications and services like data analytics, machine
                        learning platforms, and large-scale databases. Additionally, with Bare Metal Kubernetes, Deasil
                        avoids the "noisy neighbor" issue, where multiple virtualized applications on the same server
                        compete for resources, confirming that clients' applications receive undiluted computational
                        power.
                    </p>
                    <p>
                        Coupling this <TextLink name="service-high-availability-performance-engineering"
                                                text="high-performance"/> setup
                        with its extensive infrastructure
                        and <TextLink name="service-hosting-managed-infrastructure" text="data center presence"/>,
                        Deasil achieves significant economies of scale, resulting in competitive pricing models that are
                        much more cost-effective compared to the markups seen in public cloud services. By sidestepping
                        the extra costs associated with virtual machine hypervisors and leveraging the efficiencies of
                        container orchestration, Deasil passes on substantial savings to its clients. Clients benefit
                        from reduced costs and
                        the <TextLink name="service-high-availability-performance-engineering"
                                      text="robustness, scalability, and agility"/> of
                        applications running in a Bare Metal Kubernetes environment, making it a win-win for performance
                        and economic viability.
                    </p>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Container Management Infrastructure Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-kubernetes"/></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <h3>Container Management Infrastructure Related Services:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-hosting-managed-infrastructure"/></li>
                            <li><TextLink name="service-machine-learning-infrastructure"/></li>
                            <li><TextLink name="service-open-source-expertise"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default ContainerManagementInfrastructure;
