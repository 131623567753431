import * as React from 'react';
import Hero from '../../components/Hero';
import Background from '../../components/Background';
import ContactBlock from '../../components/ContactBlock';
import ServicePanel from '../../components/ServicePanel';
import TextLink from '../../components/TextLink';

const Logistics = () => {
    return (
        <>
            <Hero page="industry-logistics" classes="has-overlay"/>

            <section className="bg bg-primary overlay-top mb-4">
                <p>
                    For over 25 years, Deasil has carved a niche for itself as a leading custom software development,
                    managed services, hosting, and infrastructure company. Deasil has consistently showcased its
                    adeptness in deploying tailored solutions by partnering with various sectors,
                    from <TextLink name="industry-creative-agencies" text="creative agencies"/> to logistics,
                    particularly within the logistics industry, which thrives on efficiency, timeliness,
                    and accuracy.
                </p>
                <p className="mb-0">
                    The logistics industry has long recognized the need for digital transformation to enhance
                    efficiency, visibility, and customer experience. As global commerce has expanded and customer
                    expectations have evolved, the industry has faced mounting pressures to innovate.
                </p>
            </section>

            <div className="divider spacer pt-2"></div>

            <section className="bg bg-primary full">
                <Background path="bg-Digital-Tech-bw.jpg" opacity={0.12}/>

                <h2>Improving Logistics</h2>

                <ul className="service-links light">
                    <li className="mb-2">
                        <strong>Real-time Tracking and Visibility</strong>: Customers expect real-time updates about
                        the status of their shipments. Technologies like IoT (Internet of Things), GPS, and advanced
                        tracking systems provide transparent, real-time insights into the location and status of
                        shipments.
                    </li>
                    <li className="mb-2">
                        <strong>Advanced Analytics</strong>: With the vast amount of data generated in the logistics
                        chain, advanced analytics can help in demand forecasting, route optimization, and performance
                        analysis.
                    </li>
                    <li className="mb-2">
                        <strong>Integration and API Capabilities</strong>: Seamless integration between different
                        systems, partners, and platforms is
                        vital. <TextLink name="service-data-management-pipelines" text="Data pipelining"/> and
                        custom API development allow real-time data exchange between carriers, shippers, and other
                        stakeholders.
                    </li>
                    <li className="mb-2">
                        <strong>E-commerce Integration</strong>: As e-commerce grows, there's a need for seamless
                        integration between e-commerce platforms and logistics providers to ensure timely and accurate
                        deliveries.
                    </li>
                    <li className="mb-2">
                        <strong>Mobile Solutions</strong>: For drivers, customers, and logistics
                        operators, <TextLink name="service-mobile-applications" text="mobile applications"/> facilitate
                        real-time communication, tracking, and efficient operations.
                    </li>
                    <li className="mb-2">
                        <strong>Digital Training and Skill Development</strong>: As the industry undergoes digital
                        transformation, there's a need to upskill and train employees to harness new technologies
                        effectively.
                    </li>

                </ul>
            </section>

            <section className="bg full cols wrap">
                <h2>Deasil's Platform Services and Custom Development</h2>

                <ServicePanel
                    name="service-machine-learning-infrastructure"
                    children={<>With advanced machine learning infrastructure, Deasil equips logistics companies to
                        leverage AI for demand forecasting, route optimization, and predictive maintenance, driving
                        operational efficiencies and cost savings.</>
                    }
                />
                <ServicePanel
                    name="service-high-availability-performance-engineering"
                    children={<>Deasil's commitment to high availability and performance engineering ensures that
                        logistics systems remain operational despite peak demands, ensuring uninterrupted service
                        delivery.</>
                    }
                />
                <ServicePanel
                    name="service-data-management-pipelines"
                    children={<>Efficient data management and pipelines, designed by Deasil, ensure real-time data flows
                        in logistics operations. From shipment tracking to inventory management, seamless data flow
                        facilitates timely decisions and processes.</>
                    }
                />
                <ServicePanel
                    name="service-digital-transformation"
                    children={<>In conclusion, Deasil's digital transformation services enable logistics businesses to
                        transition from traditional operations to digitized, AI-driven processes, enhancing efficiency
                        and positioning logistics firms as future-ready in a dynamically evolving industry
                        landscape.</>
                    }
                />
                <ServicePanel
                    name="service-open-source-expertise"
                    children={<>Leveraging an impressive array of open-source software, including the likes
                        of <TextLink name="oss-apache-cassandra"/>, <TextLink name="oss-apache-nifi"/>,
                        and <TextLink name="oss-lubernetes"/>, Deasil brings scalability and flexibility to the
                        logistics sector. These OSS tools allow logistics firms to modify and scale their operations
                        based on real-time demands, ensuring cost-effective and efficient workflows.</>
                    }
                />
                <ServicePanel
                    name="service-sso-iam"
                    children={<>Security in logistics is paramount. Through Single Sign-On (SSO) and Identity & Access
                        Management solutions, using platforms
                        like <TextLink name="oss-keycloak"/>, Deasil ensures logistics firms have secure
                        and streamlined access to their systems, enhancing data protection and reducing the risk of
                        unauthorized access, crucial in safeguarding sensitive consignment information.</>
                    }
                />
                <ServicePanel
                    name="service-custom-software-development"
                    children={<>Deasil's prowess in custom software development enables logistics businesses to possess
                        bespoke tools tailored to their unique operational needs. Whether freight tracking systems,
                        warehouse management tools, or route optimization software, Deasil delivers solutions that
                        refine and enhance logistical processes.</>
                    }
                />
                <ServicePanel
                    name="service-custom-data-platforms"
                    children={<>Deasil's expertise in crafting custom data platforms offers logistics companies a
                        distinct advantage. Logistics firms can gain real-time insights into freight movement,
                        storage metrics, and delivery timelines using a tailored data platform. These platforms
                        streamline data-driven decision-making, optimizing cargo management and transportation
                        routes.</>
                    }
                />
                <ServicePanel
                    name="service-mobile-applications"
                    children={<>In the age of mobility, Deasil's mobile application development services ensure
                        logistics operators can monitor consignments, engage with customers, and resolve issues on the
                        go. These mobile solutions amplify responsiveness, drive client satisfaction, and provide
                        real-time oversight of logistics operations.</>
                    }
                />
                <ServicePanel
                    name="service-hosting-managed-infrastructure"
                    children={<>By leveraging its robust infrastructure and data center capabilities, Deasil provides
                        logistics companies with high-performance hosting and managed infrastructure services, ensuring
                        seamless system operations vital in an industry where downtime can result in significant
                        financial and reputational losses.</>
                    }
                />
                <ServicePanel
                    name="service-compute-resources"
                    children={<>Deasil's versatility in offering compute resources, ranging from bare metal servers to
                        multi-cloud environments, provides logistics firms with scalability and performance assurance.
                        This agility ensures logistics businesses can dynamically allocate resources based on
                        operational demands.</>
                    }
                />
                <ServicePanel
                    name="service-data-center-network-operations"
                    children={<>With its advanced data center and network operations, Deasil guarantees logistics
                        businesses fast, consistent, and secure data access where timely data access can make the
                        difference in ensuring punctual deliveries and efficient cargo management.</>
                    }
                />
                <ServicePanel
                    name="service-devops"
                    children={<>Incorporating a DevOps culture, Deasil ensures that software solutions for logistics
                        are consistently updated, reliable, and aligned with the industry's ever-evolving demands.
                        This results in faster software deployments and heightened operational efficiency.</>
                    }
                />
                <ServicePanel
                    name="service-seo-analytics"
                    children={<>In an increasingly digital world, Deasil helps logistics firms enhance their online
                        visibility through SEO, driving more business inquiries. Additionally, with advanced analytics,
                        logistics companies can glean insights into customer behavior and refine their service
                        offerings.</>
                    }
                />
                <ServicePanel
                    name="service-data-science-environments"
                    children={<>Deasil offers comprehensive data science environments for logistics companies, paving
                        the way for predictive analytics, demand forecasting, and optimization models essential for
                        logistics planning.</>
                    }
                />
                <ServicePanel
                    name="service-etl-elt-operations"
                    children={<>By mastering ETL and ELT operations, Deasil aids logistics firms in efficiently
                        processing vast swathes of data, from consignment details to transportation metrics, ensuring
                        insights are quickly derived and actioned upon.</>
                    }
                />
                <ServicePanel
                    name="service-data-warehouses"
                    children={<>Data warehouses constructed by Deasil provide logistics businesses with a centralized
                        storage solution, ensuring data retrieval and analysis are swift and uncomplicated, pivotal in
                        fast-paced logistical decisions.</>
                    }
                />
                <ServicePanel
                    name="service-data-lakes"
                    children={<>By creating expansive data lakes, Deasil ensures logistics companies have comprehensive
                        views of structured and unstructured data. This holistic oversight aids in predictive modeling
                        and operational trend analysis.</>
                    }
                />
                <ServicePanel
                    name="service-data-visualization-exploration"
                    children={<>Through intuitive data visualization tools, logistics firms, with Deasil's help, can
                        quickly grasp operational trends and make informed decisions. Tools
                        like <TextLink name="oss-apache-superset"/> transform raw data into actionable insights for
                        logistics businesses.</>
                    }
                />
                <ServicePanel
                    name="service-platform-data-migration"
                    children={<>Deasil's platform and data migration expertise ensure logistics firms can seamlessly
                        transition to newer, more efficient systems without operational disruptions, staying abreast
                        with technological advancements.</>
                    }
                />
                <ServicePanel
                    name="service-private-custom-clouds"
                    children={<>By offering private and custom cloud solutions, Deasil ensures logistics firms benefit
                        from tailored, secure, high-performance cloud environments, away from generic public cloud
                        offerings.</>
                    }
                />
                <ServicePanel
                    name="service-legacy-systems-integration"
                    children={<>Deasil's skill in integrating legacy systems ensures logistics firms can harness their
                        existing technological assets while benefiting from modern solutions, achieving the best of both
                        worlds.</>
                    }
                />
            </section>

            <ContactBlock label="Contact Deasil"/>
        </>
    )
};

export default Logistics;
