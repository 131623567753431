import * as React from 'react';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const DataScienceEnvironments = () => {
    return (
        <>
            <Hero page="service-data-science-environments"/>

            <section className="bg full">
                <div className="cols">
                    <Icon img="icon-Data-Science" name="Data Science" classes="primary"/>
                    <div>
                        <p>
                            Whether you're a data science veteran, a researcher looking for high computational power, or
                            a newcomer to the field, Deasil engineers its Data Science Environments to make your work
                            easier, more competent, and more effective. Let's unlock the true potential of your data
                            together.
                        </p>
                        <p>
                            At Deasil, we understand the complexities and dynamism of the modern data science world.
                            That's why we offer fully managed Data Science Environments for your entire team, designed
                            to boost productivity and propel analytics and machine learning projects. With seamless
                            support for Python, R, and Julia, coupled with state-of-the-art machine learning libraries
                            and cost-effective GPU resources, Deasil brings you environments as versatile as they are
                            powerful.
                        </p>
                    </div>
                </div>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <div className="mb-2">
                        <h2>Multi-Lingual Programming Support:</h2>
                    </div>
                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Language</th>
                            <th>Strength</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><Image path="logos/Python-logo.svg" name="Python" classes="logo client medium mt-1"/>
                            </td>
                            <td>
                                The go-to language for anything from data wrangling with Pandas to deep learning
                                with TensorFlow and PyTorch.
                            </td>
                        </tr>
                        <tr>
                            <td><Image path="logos/R-logo.svg" name="R" classes="logo client x-small"/></td>
                            <td>
                                Specializing in statistical modeling and known for its rich visualization capabilities.
                            </td>
                        </tr>
                        <tr>
                            <td><Image path="logos/Julia-logo.svg" name="Julia" classes="logo client x-small"/></td>
                            <td>
                                Provides high-speed computational capabilities, perfect for numerical analysis and
                                complex simulations.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel bg-white mt-4">
                    <div className="mb-2">
                        <h2>Benefits:</h2>
                    </div>
                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Benefit</th>
                            <th>Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Consulting and Strategy</span></td>
                            <td>
                                Deasil offers expert consulting services to aid businesses in devising effective data
                                strategies.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Collaboration</span></td>
                            <td>
                                Streamlined sharing capabilities for effortless teamwork and project handover.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Cost-Efficiency</span></td>
                            <td>
                                Access GPU resources without breaking the bank, allowing you to affordably scale up your
                                operations. Deasil is not a public cloud reseller and can provide the most competitive
                                pricing by utilizing our own hardware and mature multi-data-center infrastructure.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Flexibility</span></td>
                            <td>
                                Choose the language that fits your project—Python, R, or Julia—without the hassle of
                                switching between platforms.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Managed Service</span></td>
                            <td>
                                Deasil handles infrastructure complexities under the hood so you can focus on what
                                matters: your data.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Security</span></td>
                            <td>
                                End-to-end encryption and stringent security protocols keep your data confidential and
                                protected.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">User-Friendly</span></td>
                            <td>
                                With the JupyterLab interface, you can visualize, code, and analyze all in one place,
                                simplifying your workflow.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>

            <section className="bg full">
                <p>
                    Deasil's Data Science Environments integrate <TextLink name="oss-jupyterlab"/>, an interactive
                    development environment for working with notebooks, code, and data. It is the next-generation user
                    interface for the Jupyter Notebook ecosystem and offers a wide range of features that facilitate
                    data science and machine learning activities.
                </p>
                <p>
                    Deasil's JupyterLab integration sits atop
                    our <TextLink name="service-custom-data-platforms" text="enterprise data platform"/>, consisting
                    of integrated <TextLink name="service-sso-iam" text="identity and access management"/>, database
                    management systems, object storage, advanced networking, and secure firewalls.
                </p>
                <div className="panel bg-white">
                    <div className="mb-2">
                        <h2>Deasil's JupyterLab features and their benefits:</h2>
                    </div>
                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Feature</th>
                            <th>Benefit</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Multiple Language Support</span></td>
                            <td>
                                Allows you to work with multiple programming languages like Python, R, and Julia,
                                thereby giving you the flexibility to choose the most appropriate language for your data
                                science or machine learning task.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Interactive Notebooks</span></td>
                            <td>
                                Enables interactive computation, data visualization, and the documentation of the whole
                                process, making it easier to share and reproduce work.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Text Editor</span></td>
                            <td>
                                Edit scripts and notebooks in a rich text editor that provides syntax highlighting,
                                making it easier to read and write code.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Terminal Access</span></td>
                            <td>
                                Provides shell access within the JupyterLab environment, allowing you to run shell
                                commands, git operations, or manage packages without leaving the platform.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">File Browser</span></td>
                            <td>
                                Easily manage your project files and directories, which is crucial for better
                                organization and data management.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Multi-Tab and Multi-Panel Layout</span>
                            </td>
                            <td>
                                Run multiple notebooks, scripts, or terminals side-by-side, which is helpful for tasks
                                that require cross-referencing or multitasking
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Code Console</span></td>
                            <td>
                                Execute code flexibly and interactively, particularly useful for debugging or running
                                quick ad-hoc commands.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Markdown Support</span></td>
                            <td>
                                Write rich text documents with Markdown and render them within JupyterLab, aiding with
                                creating documentation or reports.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Real-time Output View</span></td>
                            <td>
                                Watch the output of your code as it runs, which is helpful for long-running processes
                                and real-time data visualization.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Visualization</span></td>
                            <td>
                                Integrates with libraries such as Matplotlib, Seaborn, or Plotly to create interactive
                                plots and dashboards within the notebook.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Export Options</span></td>
                            <td>
                                Easily export your notebooks to formats such as PDF, HTML, or LaTeX, facilitating easy
                                sharing and presentation of your work.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Integrated Git Support</span></td>
                            <td>
                                Manage version control within JupyterLab, making it easier to track changes,
                                collaborate, and manage the lifecycle of your data science project.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <div className="mb-3">
                        <h2>Data Science Team Environments</h2>
                        <p>
                            Deasil takes the burden of setup, maintenance, and management off your shoulders, enabling
                            you to focus solely on deriving actionable insights from your data. Ideal for teams of all
                            sizes, our JupyterHub installations provide a centralized platform that fosters
                            collaboration, enhances reproducibility, and accelerates data science and machine learning
                            innovation.
                        </p>
                        <p>
                            The primary purpose of JupyterHub is to provide a multi-user hub that spawns, manages, and
                            proxies multiple instances of the single-user JupyterLab servers, allowing users to
                            collaborate on data science and machine learning projects by centralizing access to
                            computational environments and resources. JupyterHub makes it easier for teams to
                            collaborate, share data, and produce high-quality, reproducible research.
                        </p>
                    </div>

                    <h3>Deasil's JupyterHub Key Features:</h3>
                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Feature</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Multi-User Access</span></td>
                            <td>
                                Securely serve multiple users, each with their own Jupyter environment.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Custom Environments</span></td>
                            <td>
                                Tailor each JupyterHub environment to include the libraries, kernels, and extensions
                                that suit your specific data science needs.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Resource Allocation</span></td>
                            <td>
                                Shared computational resources among team members, ensuring smooth operation even with
                                multiple users.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Authentication & Security</span></td>
                            <td>
                                Integrated with Deasil's enterprise identity and access management systems to provide
                                secure access to the JupyterHub server.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Scalability</span></td>
                            <td>
                                Deasil seamlessly manages computational resources as your team grows.
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>Benefits:</h3>
                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Benefit</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Collaboration</span></td>
                            <td>
                                Enhanced teamwork with centralized, consistent, and collaborative work environments.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Reduced Overhead</span></td>
                            <td>
                                Skip the tedious setup and the complexities of integrated resources, including GPU,
                                advanced networking, and maintenance—Deasil takes care of everything.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Flexibility</span></td>
                            <td>
                                Customizable to fit your team's unique requirements regarding libraries, languages, and
                                extensions.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Security</span></td>
                            <td>
                                Advanced security protocols to ensure data integrity and confidentiality.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Time-Efficiency</span></td>
                            <td>
                                Accelerate project timelines through streamlined workflows and easy-to-use interfaces.
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <p>
                        The power of JupyterHub and JupyterLab is undeniable, but harnessing that power requires
                        navigating a labyrinth of infrastructure requirements, configurations, and operational
                        intricacies.
                    </p>
                    <p className="quote">
                        That’s where Deasil steps in. We handle the complexity so that you can focus on
                        delivering insights.
                    </p>
                </div>
            </section>
        </>
    )
};

export default DataScienceEnvironments;
