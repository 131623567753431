import * as React from 'react';
import Hero from '../components/Hero';
import TextLink from '../components/TextLink';

const Team = () => {

    return (
        <>
            <Hero page="team"/>

            <section className="bg bg-primary full">
                <div className="panel team bg-white pt-2">
                    <h2>Jeff Masud, CEO / Founder</h2>
                    <p>
                        Jeff has developed software from a simple vision to complex business logic for over twenty
                        years. He harnesses experience from decades of designing, developing and maintaining a wide
                        range of software applications and has managed and deployed hundreds of successful projects from
                        web sites to sophisticated applications supporting millions of users, to advanced analytics, AI
                        and blockchain technologies.
                    </p>
                    <p>
                        Jeff has maintained longstanding relationships and repeat customers, a testament to his superior
                        work and service. Jeff and and his team have been active in the Southern California market for
                        25 years and are spearheading global deployments of technology solutions. He has built a
                        phenomenal base of leading music, entertainment, and automotive clients, as well as hundreds of
                        other businesses over the years.
                    </p>
                    <p>
                        Jeff drives software innovation and direction for Deasil.
                    </p>
                </div>

                <div className="panel team bg-white pt-2">
                    <h2>Craig Johnston, CTO</h2>
                    <p>
                        Craig has led talented teams of software development and creative engineers at Deasil covering
                        many industries, seeking to collect, analyze, move, buffer, queue, process or present data in
                        significant and novel ways. Craig's expertise revolves around microservices, artificial
                        intelligence, algorithms, machine learning, machine reasoning and blockchain technologies.
                        Leveraging <TextLink name="oss-kubernetes"/>, Docker, Golang, <TextLink
                        name="oss-apache-cassandra" text="Cassandra"/>, <TextLink name="oss-apache-kafka"
                                                                                  text="Kafka"/> and Elastic to
                        name a few, Craig has significantly improved productivity and return on investment across nearly
                        all of his clients' projects.
                    </p>
                    <p>
                        Craig and his team are developing more productive, stable, clean and faster applications than
                        they ever have in the past, resulting in beautiful and innovative IOT management systems, IOT
                        implementations, mobile applications and business intelligence data management platforms.
                    </p>
                    <p>
                        Craig drives the development of advanced software applications and customer experience for
                        Deasil.
                    </p>
                </div>

                <div className="panel team bg-white pt-2">
                    <h2>Shu Yang, Sr. Project Manager</h2>
                    <p>
                        Shu is a creative Project Manager with experience in design, implementation, and maintenance of
                        corporate websites, applications, and marketing materials. Noted for her ability to create or
                        reinvent corporate identities to facilitate market acceptance, she remains 'ahead of the game'
                        in knowledge of established and emerging technologies. Shu has a proven record developing
                        solutions for globally recognized companies, as well as smaller regional firms. She also has
                        advanced research and strategic planning abilities and is innovative, intelligent and organized.
                    </p>
                    <p>
                        Shu drives project management and manages the various project team members to ensure quality
                        assurance and that customer expectations are being met. Shu is supported by several software
                        developers working with her in the Los Angeles office.
                    </p>
                </div>

                <div className="panel team bg-white pt-2">
                    <h2>Dan Mehta, QA Engineer, Project Manager</h2>
                    <p>
                        As QA Engineer, Dan specializes in developing and executing exploratory and automated tests '
                        to ensure product quality for all our clients. Responsibilities include designing and
                        implementing tests, documenting, debugging, and defining corrective actions.
                    </p>
                    <p>
                        As a Project Manager / Product Owner, Dan specializes in leading his Team of Developers to
                        complete customer projects by maintaining backlog of Stories/Tasks, planning weekly Sprints,
                        tracking team's time against project budgets, and developing User Stories and Project Proposals
                        per collaboration with Customers.
                    </p>
                </div>

                <div className="panel team bg-white pt-2">
                    <h2>Karl Hirsch, SEO Lead and Digital Marketing Manager</h2>
                    <p>
                        As the SEO Lead and Digital Marketing Manager at Deasil Works, Karl combines extensive expertise
                        in digital marketing with a proven track record in leading successful campaigns. As a top
                        graduate in Public Relations from Cal State University Dominguez Hills, his professional journey
                        since 2012 includes notable achievements with clients such as Boost Mobile, Samsung, Chevrolet,
                        Metrolink, and more.
                    </p>
                    <p>
                        Karl's approach integrates advanced SEO techniques with strategic content planning, ensuring
                        high search rankings and engaging user experiences. In his leadership role, Karl encourages a
                        culture of innovation and collaborative growth, positioning Deasil Works at the forefront of
                        digital marketing trends and practices.
                    </p>
                </div>

                <div className="panel team bg-white pt-2">
                    <h2>David Elsensohn, Developer</h2>
                    <p>
                        David has worked within the sphere of online development and music over the course of
                        twenty-five years, growing more focused in the world of making things work, move, and interact
                        with other things. Applying expertise in front-end software, middleware, graphic design,
                        animation, and writing experience within a painstakingly organized methodology, David has
                        delivered hundreds of web applications for high-profile clients.
                    </p>
                </div>
            </section>
        </>
    )
};

export default Team;
