import * as React from 'react';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';

const SEOAnalytics = () => {
    return (
        <>
            <Hero page="service-seo-analytics"/>

            <section className="bg full">
                <p className="mb-0">
                    In the digital age, discoverability is crucial. SEO and Analytics are more than just digital
                    marketing tools; they are essential elements that can define the success of your online business.
                    Deasil specializes in multiple SEO services to enhance your visibility and engagement in the
                    digital world.
                </p>
            </section>

            <section className="bg bg-primary full">
                <h2>Our Approach</h2>
                <p>
                    Understanding that every business has its own identity and needs, we take the time to tailor our
                    SEO and analytics services to align with your objectives&mdash;which means no cookie-cutter
                    campaigns.
                    Our team employs the latest strategies and processes to ensure your business stands out in the
                    competitive digital landscape and succeeds.
                </p>
                <h2>Services Offered</h2>
                <p>
                    From enhancing your website's SEO to managing your online reputation, Deasil has the expertise to
                    help your business thrive in the digital landscape. Some of the services we provide to businesses
                    like yours include:
                </p>
                <ul className="service-links light">
                    <li>
                        <strong>Fractional SEO Services</strong>: Ideal for larger businesses with an existing marketing
                        team but lacking specific SEO expertise.
                    </li>
                    <li>
                        <strong>Local SEO</strong>: Specialized strategies to boost visibility in local search results,
                        essential for brick-and-mortar businesses.
                    </li>
                    <li>
                        <strong>National SEO</strong>: Broaden your reach with national SEO strategies to establish your
                        brand nationwide.
                    </li>
                    <li>
                        <strong>Shopify SEO</strong>: Customized SEO tactics for your Shopify store to drive traffic and
                        increase sales.
                    </li>
                    <li>
                        <strong>Amazon Product SEO</strong>: Stand out in the Amazon marketplace with our targeted SEO
                        strategies for your products.
                    </li>
                    <li>
                        <strong>SEO Consultations</strong>: Rely on us if you have a question about SEO or need a
                        digital marketing concierge for when you hit a snag.
                    </li>
                    <li>
                        <strong>Paid Ads / PPC</strong>: Leverage the power of pay-per-click advertising to gain
                        immediate visibility and traffic with Google Ads, Facebook Ads, and more.
                    </li>
                    <li>
                        <strong>Digital PR</strong>: Enhance your online presence and reputation with our digital PR
                        services, creating buzz and building your brand's authority.
                    </li>
                    <li>
                        <strong>Reputation Management</strong>: Actively manage and improve how your business is
                        perceived online, safeguarding your brand's reputation.
                    </li>
                    <li>
                        <strong>Google Business Profile SEO</strong>: Optimize your Google Business Profile to improve
                        local search visibility and attract more customers to your physical or digital doorstep.
                    </li>
                </ul>
                <p className="mt-2 mb-0">
                    Let Deasil be your partner in navigating your digital business needs.
                </p>
            </section>

            <section className="bg bg-light full pb-2">
                <h2>GA4 and Advanced Analytics</h2>
                <p>
                    The transition to Google Analytics 4 (GA4) marks a significant advancement in web analytics. GA4
                    offers a more nuanced understanding of user interactions than its predecessors,
                    employing <TextLink name="service-machine-learning-infrastructure" text="machine learning"/> to
                    provide richer insights and cross-device tracking. Our expertise in setting up and customizing GA4
                    ensures that you're not just collecting data but gaining valuable, actionable insights.
                </p>
                <div className="cols">
                    <div className="col-6 panel mr-1 ml-1">
                        <h3>Benefits of Using GA4 for Business</h3>
                        <p>
                            GA4 is more than an analytics tool; it's a comprehensive platform that offers:
                        </p>
                        <ul className="service-links">
                            <li><strong>Enhanced User Tracking</strong>: Follow user journeys across devices for a
                                complete engagement picture.
                            </li>
                            <li>
                                <strong>Machine Learning Insights</strong>: Uncover trends and patterns that might
                                otherwise go unnoticed.
                            </li>
                            <li><strong>Improved Data Privacy</strong>: GA4 is designed with user privacy in mind,
                                ensuring compliance with current regulations.
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 panel mr-1 ml-1">
                        <h3>Custom Analytics Solutions</h3>
                        <p>
                            Deasil’s approach is bespoke: we don't just set up GA4, we match it to your unique business
                            goals, which include:
                        </p>
                        <ul className="service-links">
                            <li><strong>Defining and Tracking Relevant KPIs</strong>: Identifying metrics that matter
                                most to your business.
                            </li>
                            <li><strong>Advanced Segmentation</strong>: Dive deeper into your audience segments for
                                targeted marketing strategies.

                            </li>
                            <li><strong>Integration with Platforms</strong>: Seamless connection with platforms like
                                BigCommerce for holistic business insights.

                            </li>
                        </ul>
                    </div>
                </div>
                <div className="cols">
                    <div className="col-6 panel mr-1 ml-1">
                        <h3>Training and Ongoing Support</h3>
                        <p>
                            We provide comprehensive training and support, ensuring your team is well-equipped to
                            utilize GA4 effectively. This includes understanding the dashboard, interpreting reports,
                            and applying insights to real-world decision-making.
                        </p>
                    </div>
                    <div className="col-6 panel mr-1 ml-1">
                        <h3>Real-Time Data and Reporting</h3>
                        <p>
                            With GA4, access real-time data and reports to make informed decisions swiftly, responding
                            to current trends and user behaviors as they happen.
                        </p>
                    </div>
                </div>
                <div className="cols">
                    <div className="col-6 panel mr-1 ml-1">
                        <h3>Future-Proofing Analytics</h3>
                        <p>
                            Our GA4 setups are designed to be adaptable, ensuring that your analytics strategy stays
                            relevant as digital technologies and user behaviors evolve.
                        </p>
                    </div>
                    <div className="col-6 panel mr-1 ml-1">
                        <h3>Privacy and Compliance</h3>
                        <p>
                            We ensure that all GA4 setups comply with the latest privacy laws and regulations, providing
                            peace of mind in a landscape where user privacy is paramount.
                        </p>
                    </div>
                </div>
                <div className="cols">
                    <div className="col-6 panel mr-1 ml-1">
                        <h3>Custom Dashboard Setup</h3>
                        <p>
                            Our service includes setting up custom dashboards in GA4, tailored to showcase the metrics
                            most vital to your business, ensuring easy access to the data you need when you need it.
                        </p>
                    </div>
                    <div className="col-6 panel mr-1 ml-1">
                        <h3>Harnessing Advanced Analytics for Your Business</h3>
                        <p>
                            At Deasil Works, advanced analytics are key to unlocking new opportunities and driving
                            business growth. By partnering with us for your GA4 and analytics needs, you're not just
                            getting a service provider but a strategic ally committed to propelling your business
                            forward with data-driven decision-making.
                        </p>
                    </div>
                </div>
            </section>

            <section className="bg bg-primary full">
                <h2>Why Deasil Works Stands Out</h2>

                <h3>Experienced Leadership</h3>
                <p>
                    Our SEO Lead is at the helm of Deasil Works, a seasoned expert with over 12 years in the digital
                    marketing industry. This wealth of experience is a testament to our enduring presence in the field
                    and guarantees that your business will benefit from tried-and-tested SEO strategies. Our SEO team's
                    vision and expertise reflect our dedicated professionals, ensuring that every project we undertake
                    is guided by knowledge and innovation.
                </p>

                <h3>Client-Centric Focus</h3>
                <p>
                    What truly sets Deasil apart is our unwavering commitment to our clients. We understand that each
                    business has its own story, challenges, and aspirations. That's why our approach is always
                    client-centric&mdash;we listen to your needs, understand your goals, and align our strategies
                    accordingly. We aim to deliver results and meaningful and measurable success that resonates with
                    what is most important to you and your business. With Deasil, your objectives become our mission,
                    and we dedicate ourselves to helping you achieve them.
                </p>

                <h3>A Partnership for Success</h3>
                <p>
                    Choosing Deasil means selecting a partner dedicated to your success. Our team works tirelessly to
                    ensure that every aspect of your SEO and analytics strategy is tailored to propel your business
                    forward. We don't just offer services; we provide enduring partnerships that grow and evolve with
                    your business. Let us be part of your journey to the top.
                </p>
            </section>

            <section>
                <div className="panel bg bg-secondary mt-1 mb-0 pt-2">
                    <h2>Get Started with Deasil</h2>
                    <p>
                        Elevate your online presence with
                        Deasil. <TextLink name="contact" text="Contact us"/> for a consultation and discover how our SEO
                        and analytics expertise can transform your digital strategy.
                    </p>
                </div>

            </section>
        </>
    )
};

export default SEOAnalytics;
