import * as React from 'react';
import Marquee from 'react-fast-marquee';

import Disney from '../assets/images/logos/client-logo-Disney.png';
import DisneyInteractive from '../assets/images/logos/client-logo-Disney-Interactive.png';
import HarryPotter from '../assets/images/logos/client-logo-Harry-Potter.png';
import JakesFireworks from '../assets/images/logos/client-logo-Jakes-Fireworks.png';
import KCET from '../assets/images/logos/client-logo-KCET.png';
import LIQUIDGraphics from '../assets/images/logos/client-logo-LIQUID-Graphics.png';
import Metrolink from '../assets/images/logos/client-logo-Metrolink.png';
import NBC from '../assets/images/logos/client-logo-NBC.png';
import NBCUniversal from '../assets/images/logos/client-logo-NBC-Universal.png';
import NineInchNails from '../assets/images/logos/client-logo-Nine-Inch-Nails.png';
import OWN from '../assets/images/logos/client-logo-OWN.png';
import Toyota from '../assets/images/logos/client-logo-Toyota.png';
import WarnerBros from '../assets/images/logos/client-logo-Warner-Bros.png';
import Xrathus from '../assets/images/logos/client-logo-Xrathus.png';

const ClientLogos = () => {
    return (
        <>
            <Marquee className="client-logos" speed={30} pauseOnHover={true} gradient={true} gradientWidth={60}>
                <img src={Disney} alt="Disney logo" loading="lazy" className="client-logo"/>
                <img src={NBC} alt="NBC logo" loading="lazy" className="client-logo small"/>
                <img src={HarryPotter} alt="Harry Potter logo" loading="lazy" className="client-logo"/>
                <img src={Metrolink} alt="Metrolink logo" loading="lazy" className="client-logo large"/>
                <img src={NineInchNails} alt="Nine Inch Nails logo" loading="lazy" className="client-logo"/>
                <img src={KCET} alt="KCET logo" loading="lazy" className="client-logo"/>
                <img src={Toyota} alt="Toyota logo" loading="lazy" className="client-logo"/>
                <img src={LIQUIDGraphics} alt="LIQUID Graphics logo" loading="lazy" className="client-logo"/>
                <img src={DisneyInteractive} alt="Disney Interactive logo" loading="lazy"
                     className="client-logo small"/>
                <img src={JakesFireworks} alt="Jake's Fireworks logo" loading="lazy" className="client-logo"/>
                <img src={WarnerBros} alt="Warner Bros. logo" loading="lazy" className="client-logo small"/>
                <img src={NBCUniversal} alt="NBC Universal logo" loading="lazy" className="client-logo large"/>
                <img src={OWN} alt="OWN logo" loading="lazy" className="client-logo"/>
                <img src={Xrathus} alt="Xrathus logo" loading="lazy" className="client-logo large"/>
            </Marquee>
        </>
    )
};

export default ClientLogos;
