import * as React from 'react';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const VectorDatabaseHostingManagementServices = () => {
    return (
        <>
        <Hero page="service-vector-database-hosting-management-services"/>

        <section className="bg full">
            <div className="cols mb-0">
                <p>
                    In an era where data is king, the need for efficient and reliable vector databases is essential.
                    At Deasil Works, we specialize in high-quality vector
                    database <TextLink name="service-hosting-managed-infrastructure"
                                       text="hosting and management solutions"/> specifically to meet your
                    unique requirements.
                </p>
                <Image path="logos/Milvus-logo.svg" name="Milvus Logo"
                       classes="logo client ml-2"/>
            </div>
        </section>

        <section className="bg bg-primary full mb-0">
            <h2>What is a Vector Database?</h2>
            <p>
                A vector database is optimized for storing and querying vector data, crucial in fields like
                AI, <TextLink name="service-machine-learning-infrastructure"
                              text="machine learning"/>, and GIS. Key features include high-dimensional
                data handling, fast similarity searches, and efficient storage solutions. These databases
                manage vector data representations, which are essential for modern applications relying on
                embeddings and high-dimensional vectors.
            </p>
        </section>

        <section className="bg full">
            <div className="panel bg-white">
                <div className="mb-4">
                    <h2 className="mt-2">Our Services</h2>
                </div>

                <table className="bg-light mb-3">
                    <thead>
                    <tr>
                        <th>Capability</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><span className="service-links nowrap strong">Hosting</span></td>
                        <td>
                            Our secure, reliable, and scalable hosting solutions house your data in
                            state-of-the-art facilities. With cloud-based and physical data centers, we ensure
                            uptime and robust infrastructure, guaranteeing optimal performance even under
                            heavy loads.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Management</span></td>
                        <td>
                            We offer continuous monitoring, regular backups, and performance optimization. Our
                            dedicated support staff provides necessary updates and enhanced security measures
                            to keep your data safe and ensure smooth operation.
                        </td>
                    </tr>
                    <tr>
                        <td><span className="service-links nowrap strong">Milvus Vector Databases</span></td>
                        <td>
                            Renowned for their performance in handling vector data, Milvus Vector Databases offer
                            accelerated processing speeds and enhanced scalability. Our expertise in managing
                            these databases allows us to provide custom solutions that optimize workflow and
                            improve efficiency.
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </section>

        <section className="bg bg-secondary mt-4 mb-4">
            <h2>Why Choose Deasil Works?</h2>
            <ul className="service-links light">
                <li>
                    <strong>Expertise</strong>: With over a decade of experience in vector database management,
                    our team delivers top-tier solutions, staying updated on the latest advancements to
                    recommend and implement the best options for your needs.
                </li>
                <li>
                    <strong>Cutting-edge Technology</strong>: We use the latest technologies for secure,
                    scalable, and efficient database solutions, ensuring optimal performance and quick,
                    reliable data access.
                </li>
                <li>
                    <strong>Customer-Centric Approach</strong>: Our customer-first philosophy means we take the
                    time to understand your challenges and goals, delivering tailored solutions and guiding
                    you from selection to ongoing management.
                </li>
            </ul>
        </section>

        <section className="bg full">
            <h2>Features & Benefits</h2>
            <table className="bg-white mb-3">
                <thead>
                <tr>
                    <th>Benefit</th>
                    <th>Description</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td><span className="service-links nowrap strong">Custom scalability</span></td>
                    <td>
                        Evolve with your needs, handling growing data volumes efficiently.
                    </td>
                </tr>
                <tr>
                    <td><span className="service-links nowrap strong">Enhanced security</span></td>
                    <td>
                        Advanced measures safeguard your data from breaches and threats.
                    </td>
                </tr>
                <tr>
                    <td><span className="service-links nowrap strong">24/7 technical support</span></td>
                    <td>
                        Round-the-clock monitoring and support ensure smooth operations.
                    </td>
                </tr>
                <tr>
                    <td><span className="service-links nowrap strong">Cost-effective pricing</span></td>
                    <td>
                        Competitive pricing offers excellent ROI without breaking the bank.
                    </td>
                </tr>
                <tr>
                    <td><span className="service-links nowrap strong">Seamless integration</span></td>
                    <td>
                        Easy integration with existing systems ensures a smooth transition.
                    </td>
                </tr>
                </tbody>
            </table>

            <h3>Get Started Today</h3>
            <p>
                Ready to transform your data management? <TextLink name="contact" text="Contact us today"/> for a
                free consultation, or request a demo to see our solutions in action! Our team is prepared to help
                you unlock the full potential of your vector data and stay ahead in the competitive landscape.
            </p>
        </section>
        </>
    )
};

export default VectorDatabaseHostingManagementServices;
