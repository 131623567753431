import * as React from 'react';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';

const SSOIdentityAccessManagement = () => {
    return (
        <>
            <Hero page="service-sso-iam"/>

            <section className="bg full cols">
                <span>
                    <p>
                        <strong>Single Sign-On (SSO)</strong> allows users to authenticate themselves once and
                        gain access to multiple services without needing to log in separately for each one.
                        Identity and Access Management (IAM) is a broader framework for business processes
                        that facilitates the management of electronic identities. Together, they form the
                        cornerstone of secure and efficient user management.
                    </p>
                    <p>
                        As a full-service software development and private infrastructure company, Deasil
                        uniquely offers highly customized solutions. We tailor configurations and extensions
                        to meet your business needs ideally. Our in-house experts manage complex
                        infrastructures, ensuring you receive a secure, scalable, and tailor-made identity
                        management solution that integrates seamlessly with your existing systems.
                    </p>
                </span>
            </section>

            <section className="bg bg-primary full mb-4">
                <h2>Experts in Apache Keycloak</h2>
                <p>
                    <TextLink name="oss-keycloak" text="Apache Keycloak"/> is an open-source identity and
                    access management solution that we at Deasil specialize in integrating. It comes loaded
                    with out-of-the-box features, including <strong>SSO</strong>, enabling your users to log in once and
                    access multiple services without additional logins; <strong>User Federation</strong> connecting to
                    external user databases and syncing user data;
                    Fine-grained <strong>Authorization</strong> establishing
                    specific roles and permissions for your users, down to the tiniest detail. It scales to efficiently
                    handle large numbers of users, making it suitable for businesses of all sizes.
                </p>
                <p>
                    Managing Keycloak in
                    a <TextLink name="service-private-custom-clouds" text="custom private cloud"/> offers
                    unparalleled advantages over proprietary SaaS solutions, including complete control over your data
                    and user management settings and the ability to tailor the system to meet your specific security and
                    user management needs, something SaaS often can't match. Storing sensitive user data in a private
                    cloud ensures better compliance with regulations and boosts data security. Keycloak is open-source,
                    allowing you to migrate your services without incurring extra costs or technical roadblocks.
                </p>
                <p>
                    Red Hat Software initially developed Apache Keycloak, which the OSS community now maintains. Over
                    the years, the tool has adapted to meet the rising demand for scalable and comprehensive IAM
                    solutions. Its open-source nature encourages a global community of developers to contribute
                    extensively, rapidly advancing its feature set and strengthening its security measures.
                </p>
            </section>
        </>
    )
};

export default SSOIdentityAccessManagement;
