import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';
import Icon from '../../components/Icon';

const Ceph = () => {
    return (
        <>
            <Hero page="oss-ceph"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/Ceph-logo-standard.png" name="Ceph" classes="logo client mr-2"/>
                    <p>
                        In 2004, Ceph emerged as a game-changer in the storage domain. It aimed to address the
                        limitations of existing storage solutions by providing a high-performance, resilient, and
                        scalable platform. Ceph gained rapid traction in the tech community as a testament to its
                        revolutionary design, leading to its incorporation into the Linux kernel in 2010. Since then,
                        numerous companies and organizations have adopted Ceph, recognizing its potential to redefine
                        storage architecture.
                    </p>
                </div>
                <p>
                    The power of Ceph lies in its array of features that elevate it above traditional storage solutions.
                    It offers a unified storage ecosystem, combining object, block, and file storage into one platform.
                    Furthermore, its inherently scalable architecture ensures data redundancy and fault tolerance
                    through its Reliable Autonomic Distributed Object Store (RADOS). Ceph also boasts a dynamic and
                    adaptive load-balancing system, which means data distribution does not bottleneck as it scales,
                    ensuring optimum performance at all times.
                </p>
                <p>
                    Ceph's essence lies in its ability to cater to the ever-evolving demands of modern businesses. In an
                    era where data stands at the heart of operations, having a robust, scalable, and reliable storage
                    solution is essential. Ceph addresses these needs by offering both flexibility and resilience. Its
                    self-managing and self-healing capabilities ensure minimum intervention, enabling businesses to
                    focus on their core operations without worrying about storage infrastructural challenges.
                </p>
                <p>
                    Deasil integrates Ceph into its extensive suite of solutions. By leveraging Ceph's capabilities,
                    Deasil offers its clients a future-proof storage solution that is not only resilient but also
                    adaptable to the fluctuating demands of their operations. Clients benefit from rapid data retrieval,
                    efficient storage management, and assured data integrity. By marrying Ceph's prowess with its
                    technical expertise, Deasil guarantees an infrastructure that remains robust and agile, meeting the
                    storage challenges of today and tomorrow.
                </p>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <h2>Deasil's Kubernetes-Based Storage Clusters</h2>
                    <Icon img="icon-Data-Storage" name="Storage Clusters" classes="secondary mt-1 mb-1 desktop-hide"/>
                    <div className="cols">
                        <p>
                            Deasil crafts bespoke storage architectures tailored to the distinct needs of their diverse
                            clientele. By leveraging Ceph's self-managing and self-healing features, Deasil ensures
                            minimal intervention, allowing businesses to operate seamlessly with an assured, robust
                            storage backend. In pairing Ceph with <TextLink name="oss-kubernetes"/>, Deasil creates a
                            synergistic environment where storage solutions are scalable and effortlessly managed. This
                            fusion facilitates the smooth handling of vast data workloads, ensuring clients experience
                            rapid data retrieval and consistent application performance. By tapping into the strengths
                            of both Ceph and Kubernetes, Deasil positions itself at the forefront of delivering
                            cutting-edge data solutions, maximizing the benefits of these open-source technologies for
                            its clients.
                        </p>
                        <Icon img="icon-Data-Storage" name="Storage Clusters"
                              classes="secondary mt-2 ml-1 mobile-hide"/>
                    </div>
                    <h2>Deasil's Ceph-based Data Platforms</h2>
                    <Icon img="icon-Data-Structure" name="Synergy" classes="secondary mt-1 mb-0 desktop-hide"/>
                    <div className="cols">
                        <p>
                            Atop Ceph's fortified storage layer, Deasil deploys a suite of powerful
                            tools. <TextLink name="oss-postgresql"/> benefits from uninterrupted data access,
                            redundancy, and durability, <TextLink name="oss-trino"/> facilitates efficient distributed
                            SQL queries, <TextLink name="oss-apache-superset"/> provides dynamic data visualization,
                            and <TextLink name="oss-apache-nifi"/> ensures streamlined data
                            flow. <TextLink name="oss-opensearch"/> offers advanced search capabilities
                            and <TextLink name="oss-jupyterhub"/> for collaborative data exploration and analysis. The
                            synergy of these tools, anchored by Ceph's robust storage capabilities, allows Deasil to
                            craft custom data platforms tailored to diverse client needs, driving actionable insights
                            and real-time decision-making optimization.
                        </p>
                        <Icon img="icon-Data-Structure" name="Synergy"
                              classes="secondary mt-2 ml-1 mobile-hide"/>
                    </div>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Related Services and Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-open-source-expertise"/></li>
                            <li><TextLink name="oss-opensearch"/></li>
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-apache-nifi"/></li>
                            <li><TextLink name="oss-postgresql"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Ceph;
