import React, {useState} from 'react';

const Collapsible = ({header, subheader, children, type = 'light'}) => {

    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    };

    const colClass = (type === 'dark') ? 'dark' : 'light';

    return (
        <div className={`collapsible ${colClass}`}>
            <div className="top cols" onClick={() => toggle()}>
                <h4>{header}</h4>
                {subheader &&
                    <h4 className="subheader">{subheader}</h4>
                }
            </div>
            {open && <div className="collapsible-content">{children}</div>}
        </div>
    )
};

export default Collapsible;
