import * as React from 'react';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';

const DataManagementPipelines = () => {
    return (
        <>
            <Hero page="service-data-management-pipelines"/>

            <section className="bg full">
                <p>
                    Deasil specializes in data management and pipelines and offers a broad range of services to handle
                    data efficiently, securely, and in a scalable manner. Deasil designs services to solve critical data
                    storage, processing, analysis, and governance challenges.
                </p>
                <p>
                    By providing a full suite of data services, Deasil enables organizations to focus on deriving
                    actionable insights from their data rather than getting bogged down by the technical complexities
                    involved in managing it.
                </p>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <div className="mb-2">
                        <h2>Deasil Platform Capabilities:</h2>
                    </div>
                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Capability</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Analytics and Visualization</span></td>
                            <td>
                                Tools for analyzing, exploring, and visualizing data, including dashboards, online
                                integrated development environments, and data science utilities.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">APIs and SDKs</span></td>
                            <td>
                                Programmatic access for custom integration with other tools and systems.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Batch Processing</span></td>
                            <td>
                                Processing data in real time for use cases that require immediate insights, such as
                                fraud detection and industrial monitoring.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Governance and Security</span></td>
                            <td>
                                Processing data in chunks to optimize resource use, queueing, and batch creation.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Ingestion</span></td>
                            <td>
                                Collecting or importing data from various sources, including databases, streams, and
                                batch files.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Integration</span></td>
                            <td>
                                Unifying data from multiple sources, providing a complete view across different data
                                silos.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Monitoring and Alerting</span></td>
                            <td>
                                Monitoring data quality and pipeline health and alerting your team when anomalies occur.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Storage and Warehousing</span></td>
                            <td>
                                Providing scalable storage solutions that house vast amounts of structured and
                                unstructured data.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Transformation</span></td>
                            <td>
                                Transforming raw data into a more valuable and structured format through cleaning,
                                enriching, and aggregating processes.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Real-time Processing</span></td>
                            <td>
                                Processing data in real time for use cases that require immediate insights, such as
                                fraud detection or monitoring.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel bg-white">
                    <div className="mb-2">
                        <h2>Deasil's Data Experience:</h2>
                    </div>
                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Experience</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Consulting and Strategy</span></td>
                            <td>
                                Deasil offers expert consulting services to aid businesses in devising effective data
                                strategies.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Disaster Recovery</span></td>
                            <td>
                                Experience implementing effective disaster recovery plans to safeguard data.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Industry-specific Expertise</span></td>
                            <td>
                                Experience serving diverse <TextLink name="industry" text="industries"/>, including
                                healthcare, retail, manufacturing, automotive, transportation, energy, academia,
                                marketing, and entertainment.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Regulatory Compliance</span></td>
                            <td>
                                Expertise in regulatory requirements such as PCI-DSS and HIPAA.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Scalability</span></td>
                            <td>
                                Proven track record in scaling data solutions to handle increased loads and data
                                volumes.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel bg-white">
                    <div className="mb-2">
                        <h2>Deasil's Data Platform Technologies:</h2>
                    </div>
                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Technologies</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Big Data Technologies</span></td>
                            <td>
                                Use of distributed object storage, <TextLink name="oss-apache-hive"/>, <TextLink
                                name="oss-apache-cassandra" text="Cassandra"/>, <TextLink
                                name="oss-postgresql"/> and <TextLink name="oss-trino"/> distributed query systems
                                for large-scale data processing.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Cloud Platforms</span></td>
                            <td>
                                Integrate with major cloud platforms including AWS, Azure, and Google Cloud.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Science Tools</span></td>
                            <td>
                                Built-in data science platforms
                                including <TextLink name="oss-JupyterLab"/> with Python, R, and Julia kernels.
                                Pre configured libraries such as PyTorch, TensorFlow and OpenCV for analytics,
                                computer vision and machine learning.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">ETL Tools</span></td>
                            <td>
                                Extract, Transform, Load (ETL) tools including <TextLink name="oss-apache-nifi"/> for
                                data pipeline management.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">SQL and NoSQL Databases</span></td>
                            <td>
                                Deasil offers traditional SQL database clusters including <TextLink
                                name="oss-postgresql"/>
                                , along with NoSQL distributed databases including Cassandra
                                and <TextLink name="oss-opensearch"/>.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Exploration and Visualization</span></td>
                            <td>
                                Built-in access to <TextLink name="oss-apache-superset"/> and other enterprise
                                grade OSS solutions.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="panel bg-white">
                    <div className="mb-2">
                        <h2>Problems and Solutions:</h2>
                    </div>
                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Problems</th>
                            <th>Solutions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Silos</span></td>
                            <td>
                                Deasil's platform allows customers to eliminate data silos by integrating data from
                                multiple sources into any unified views.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Quality</span></td>
                            <td>
                                Enhance data quality by validating, cleaning, and enriching raw data
                                leveraging <TextLink name="oss-apache-nifi"/> and Deasil's private, custom,
                                and secure
                                integrated <TextLink name="service-data-lakes" text="data lakes"/> and <TextLink
                                name="service-data-warehouses" text="warehouses"/>.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Scalability Issues</span></td>
                            <td>
                                Deasil provides scalable solutions that grow with your business needs&mdash;most of
                                Deasil's data platform components run across many servers. Deasil implements clustered
                                databases and leverages distributed query engines.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Compliance and Security</span></td>
                            <td>
                                Deasil helps businesses comply with regulatory requirements and secure sensitive data.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Operational Efficiency</span></td>
                            <td>
                                Deasil's platform automates data workflows to reduce manual effort and errors, leading
                                to more efficient operations.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Real-time Insights</span></td>
                            <td>
                                Integrated data exploration components such
                                as <TextLink name="oss-apache-superset"/> provide real-time analytics to make quicker
                                business decisions.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Cost Optimization</span></td>
                            <td>
                                Streamline data storage and processing, potentially reducing costs by optimizing
                                resource usage.
                                Deasil's <TextLink name="service-private-custom-clouds"
                                                   text="private cloud infrastructure"/> is
                                considerably more cost-effective than public cloud offerings and often more performant.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Availability</span></td>
                            <td>
                                Deasil's distributed system ensures that data is available when and where needed for
                                analytics, machine learning, or business intelligence.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Time to Market</span></td>
                            <td>
                                Accelerate the time it takes to bring data projects from conception to production.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Monetization</span></td>
                            <td>
                                Deasil helps businesses monetize their data by creating valuable insights or novel data
                                products.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
};

export default DataManagementPipelines;
