import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {getEnvironmentConfig} from './utils/functions';
import './assets/css/index.css';
import './assets/js/deasil.js';

let configParams = {
    google_tag_id: ''
};

const root = ReactDOM.createRoot(document.getElementById('root'));

let envConfigRequest = getEnvironmentConfig();
envConfigRequest
    .then(data => {
        if (data) {
            // Set Google Tag ID
            configParams.google_tag_id = data.google_tag_id;

            // Add a meta tag with site verification
            if (data.google_site_verification !== '') {
                const gsvTag = `<meta name="google-site-verification" content="${data.google_site_verification}"/>`;
                document.head.insertAdjacentHTML('beforeend', gsvTag);
            }
        } else {
            // No data from JSON
            return Promise.resolve();
        }
    })
    .catch(error => {
        // Error loading configuration
        return Promise.resolve();
    })
    .finally(() => {

        // Render application with configuration
        root.render(
            <React.StrictMode>
                <App configParams={configParams}/>
            </React.StrictMode>
        );
    });

reportWebVitals();
