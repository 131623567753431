import * as React from 'react';
import Blank from '../assets/images/icon-blank.png';

const Icon = ({img, name = 'Icon', type = 'png', classes = ''}) => {

    let src = '';
    try {
        const image = process.env.PUBLIC_URL + '/static/images/icons/' + img + '.' + type;
        src = (image) ? image : <Blank/>;
    } catch(err) {
    }

    if (!src) {
        return (
            <>
            </>
        )
    }

    return (
        <span className={`icon ${classes}`}>
            <img src={src} alt={name} loading="lazy" width="96" height="96"/>
        </span>
    )
};

export default Icon;
