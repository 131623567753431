import * as React from 'react';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import TextLink from '../../components/TextLink';

const ETLELTOperations = () => {
    return (
        <>
            <Hero page="service-etl-elt-operations"/>

            <section className="bg full">
                <div className="cols">
                    <Icon img="icon-Data-Structure" name="ETL and ELT Operations" classes="primary"/>
                    <div>
                        <p className="mb-0">
                            Siloed data is a common challenge for companies, often being controlled by various
                            departments and in disparate systems. Deasil commonly employs ETL (Extract, Transform,
                            Load) and ELT (Extract, Load, Transform) operations to integrate this siloed data into
                            a centralized data warehouse, enabling more holistic analytics and data-driven
                            decision-making.
                        </p>
                    </div>
                </div>
            </section>

            <section className="bg bg-primary full">
                <h3>
                    The following categories detail Deasil's experience in unifying siloed data for
                    customers across numerous industries:
                </h3>
                <div className="cols">
                    <div className="panel bg-white col-6 mr-1 ml-1">
                        <h4>Sales and Customer Relationship Management (CRM) Systems</h4>
                        <ul className="service-links text-small">
                            <li>Contact databases containing information about leads and customers</li>
                            <li>Opportunity and Sales Records, including information about sales activities,
                                pipelines, and forecasts
                            </li>
                            <li>Customer interactions from logs of customer emails, calls, and meetings</li>
                        </ul>
                    </div>
                    <div className="panel bg-white col-6 mr-1 ml-1">
                        <h4>Marketing Systems</h4>
                        <ul className="service-links text-small">
                            <li>Campaign data consisting of metrics from various marketing campaigns across multiple
                                channels
                            </li>
                            <li>Customer segmentation data, including information on different customer groups
                                targeted in marketing efforts
                            </li>
                            <li>Web Analytics data, including site traffic, user behavior, and conversion rates
                                from websites, landing pages, mobile, social, and streaming
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="cols">
                    <div className="panel bg-white col-6 mr-1 ml-1">
                        <h4>Marketing Systems</h4>
                        <ul className="service-links text-small">
                            <li>Campaign data consisting of metrics from various marketing campaigns across multiple
                                channels
                            </li>
                            <li>Customer segmentation data, including information on different customer groups
                                targeted in marketing efforts
                            </li>
                            <li>Web Analytics data, including site traffic, user behavior, and conversion rates
                                from websites, landing pages, mobile, social, and streaming
                            </li>
                        </ul>
                    </div>
                    <div className="panel bg-white col-6 mr-1 ml-1">
                        <h4>Finance and Accounting Systems</h4>
                        <ul className="service-links text-small">
                            <li>General ledger data, including information about financial transactions</li>
                            <li>Expense streams, including data related to input costs</li>
                            <li>Revenue streams, including data from various sources of company income, often from
                                disparate billing systems
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="cols">
                    <div className="panel bg-white col-6 mr-1 ml-1">
                        <h4>Operations Systems</h4>
                        <ul className="service-links text-small">
                            <li>Inventory management data, including stock levels, suppliers, and storage
                                conditions
                            </li>
                            <li>Production data, comprising information on production volumes, quality, and
                                timelines
                            </li>
                            <li>Logistics and supply chain data, including shipment information, vehicle tracking,
                                and supplier performance
                            </li>
                        </ul>
                    </div>
                    <div className="panel bg-white col-6 mr-1 ml-1">
                        <h4>E-commerce Systems</h4>
                        <ul className="service-links text-small">
                            <li>Order history, including data about orders, items, amounts, and customer details
                            </li>
                            <li>Customer behavior details, including tracking customer actions such as clicks, time
                                spent on pages, and abandon cart rates
                            </li>
                            <li>Product catalogs containing details about product specs, availability, and pricing
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="cols">
                    <div className="panel bg-white col-6 mr-1 ml-1">
                        <h4>Support and Customer Service Systems</h4>
                        <ul className="service-links text-small">
                            <li>Ticket and PBX systems containing records of customer support interactions and
                                issues
                            </li>
                            <li>Customer feedback systems, including customer survey data, reviews, and social
                                media interactions
                            </li>
                            <li>Knowledge bases, offering solutions to commonly encountered problems</li>
                        </ul>
                    </div>
                    <div className="panel bg-white col-6 mr-1 ml-1">
                        <h4>Enterprise Resource Planning (ERP) Systems</h4>
                        <ul className="service-links text-small">
                            <li>Order Processing, containing details of orders processed and workflow states
                            </li>
                            <li>Manufacturing data, including information about production capacities, raw materials,
                                and production line efficiency
                            </li>
                            <li>Resource allocation, including information about how company resources like labor
                                and capital are allocated
                            </li>
                        </ul>
                    </div>
                </div>
                <p className="mb-0">
                    Deasil employs ETL/ELT processes that extract data from these diverse sources, transform it
                    into standardized formats, and load it into a centralized data warehouse, enabling
                    businesses to run comprehensive analytics and make more informed decisions.
                </p>
            </section>

            <section className="bg full">
                <h3>Third-party data silos:</h3>
                <p>
                    Third-party data silos are another significant challenge for many organizations that desire a
                    unified view of their data for analytics and decision-making. Third-party platforms specialize
                    in specific data types and generally do not natively integrate with other platforms by
                    design (vendor lock-in) or by consequence of their specialty.
                </p>
                <p>
                    The following are examples of the many well-known third-party data sources Deasil has
                    experience using ETL and ELT process to unify data from:
                </p>
                <div className="panel bg-white">
                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Vendor</th>
                            <th>Specialty</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">AWS (Amazon Web Services)</span></td>
                            <td>
                                Cloud storage, computing, databases, and more.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Blackbaud</span></td>
                            <td>
                                Fundraising, financial management, and supporter data primarily for non-profit
                                organizations.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Cloudera</span></td>
                            <td>
                                Big Data, Hadoop ecosystems, analytics.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Databricks</span></td>
                            <td>
                                Big Data analytics, AI, and machine learning.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Domo</span></td>
                            <td>
                                Business intelligence data, aggregated from various sources across the organization.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Google Analytics</span></td>
                            <td>
                                Web and app analytics, user behavior, site traffic, etc.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">IBM (DB2, Cloud, Watson, etc.)</span></td>
                            <td>
                                Databases, cloud computing, AI analytics, and more.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Microsoft (Azure, SQL Server, etc.)</span>
                            </td>
                            <td>
                                Cloud services and databases.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Nielsen</span></td>
                            <td>
                                Media and consumer data, including ratings, sales data, and more.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Oracle</span></td>
                            <td>
                                Relational databases, cloud services, enterprise software data.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Salesforce</span></td>
                            <td>
                                Customer relationship management (CRM) data, including leads, opportunities, customer
                                interactions, and sales transactions.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Snowflake</span></td>
                            <td>
                                Cloud data warehousing itself, but also storing specific siloed data sets for
                                organizations.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Teradata</span></td>
                            <td>
                                Large-scale data warehousing and analytics.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <div className="mb-3">
                        <p>
                            <TextLink name="oss-apache-nifi"/> is Deasil's tool of choice, and a standard component
                            of our custom, private-cloud data platforms. Apache NiFi is an open-source data
                            integration tool providing a robust and flexible ETL/ELT operations platform.

                        </p>
                    </div>

                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Feature</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Ingestion</span></td>
                            <td>
                                NiFi supports a variety of data sources, allowing for easy data ingestion from
                                platforms like Google Analytics, Salesforce, and more. Deasil designs and develops
                                custom processors to handle specialized extractions.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Routing and Enrichment</span></td>
                            <td>
                                NiFi allows for complex routing and conditional flows, enabling advanced data
                                enrichment and quality checks before data is loaded into the warehouse.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Transformation</span></td>
                            <td>
                                NiFi provides a range of processors for data transformation, manipulating data
                                formats, combining data sources, filtering records, and more, making standardizing
                                data for data warehousing easy.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Real-time Integration</span></td>
                            <td>
                                Apache NiFi supports
                                real-time <TextLink name="service-data-management-pipelines" text="data pipelines"/>,
                                allowing up-to-the-minute data in your centralized warehouse.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Scalability</span></td>
                            <td>
                                NiFi is highly scalable, making it possible to handle data from multiple third-party
                                services simultaneously.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Security and Compliance</span></td>
                            <td>
                                With data encryption and access control features, NiFi ensures that sensitive data
                                is securely handled during the ETL/ELT processes.
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <p className="quote">
                        Using Apache NiFi, Deasil can easily unify your siloed data into a purpose-built
                        centralized <TextLink name="service-data-warehouses" text="data warehouse"/>, leading to
                        more effective analytics and better-informed decision-making.
                    </p>
                </div>
            </section>
        </>
    )
};

export default ETLELTOperations;
