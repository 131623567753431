import * as React from 'react';
import Obfuscate from 'react-obfuscate';
import Hero from '../components/Hero';

const Contact = () => {
    return (
        <>
            <Hero page="contact" classes="has-overlay"/>

            <section className="bg overlay-top mb-2">
                <p className="quote2">
                    For more information about our services or to discuss a project, please contact us.
                </p>

                <div className="cols mb-4">
                    <div className="panel col-6 mr-2">
                        <p>
                            Deasil Works, Inc.<br/>
                            121 W. Lexington Drive<br/>
                            Glendale, CA 91203<br/>
                            <Obfuscate tel="1-818-454-1234" aria-label="Phone">818-945-0821</Obfuscate>
                        </p>
                    </div>
                    <div className="panel col-6 mr-2">
                        <p>
                            Email:<br/>
                            <Obfuscate email="info@deasil.works" aria-label="Email">info@deasil.works</Obfuscate>
                        </p>
                    </div>
                </div>

            </section>
        </>
    )
};

export default Contact;
