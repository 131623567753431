import * as React from 'react';
import Layout from './containers/Layout';
import About from './routes/About';
import CookiePolicy from './routes/CookiePolicy';
import HomePage from './routes/Home';
import Contact from './routes/Contact';
import NotFound from './routes/NotFound';
import OurHistory from './routes/OurHistory';
import PrivacyPolicy from './routes/PrivacyPolicy';
import Team from './routes/Team';
import Services from './routes/Services';
import TermsConditions from './routes/Terms';
import Work from './routes/Work';
import DeasilWorksD from './routes/lab/DeasilWorksD';
import Huddle from './routes/lab/Huddle';
import NotDiesel from './routes/NotDiesel';

import clientRoutes from './routesClients';
import industryRoutes from './routesIndustries';
import ossRoutes from './routesOSS';
import serviceRoutes from './routesServices';
import redirectRoutes from './routesRedirects';

const routes = [
    {
        path: '/',
        element: <Layout/>,
        errorElement: <NotFound/>,
        children: [
            {
                path: '/',
                element: <HomePage/>
            },
            {
                path: '/work',
                element: <Work/>
            },
            {
                path: '/services',
                element: <Services/>
            },
            {
                path: '/about',
                element: <About/>
            },
            {
                path: '/about/our-history',
                element: <OurHistory/>
            },
            {
                path: '/about/team',
                element: <Team/>
            },
            {
                path: '/contact',
                element: <Contact/>
            },
            {
                path: '/privacy-policy',
                element: <PrivacyPolicy/>
            },
            {
                path: '/terms-conditions',
                element: <TermsConditions/>
            },
            {
                path: '/cookie-policy',
                element: <CookiePolicy/>
            },
            {
                path: '/deasil-vs-diesel',
                element: <NotDiesel/>
            },
            {
                path: '/deasil-works-d',
                element: <DeasilWorksD/>
            },
            {
                path: '/huddle',
                element: <Huddle/>
            },
            ...clientRoutes,
            ...industryRoutes,
            ...ossRoutes,
            ...serviceRoutes,
            ...redirectRoutes
        ]
    },
    {
        path: '/404',
        element: <NotFound/>
    }
];

export default routes;
