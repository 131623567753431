import * as React from 'react';
import {Link} from 'react-router-dom';
import Blank from '../../assets/images/icon-blank.png';
import Hero from '../../components/Hero';
import Image from '../../components/Image';

const DeasilWorksD = () => {

    let instructions = '';
    try {
        const file = process.env.PUBLIC_URL + '/static/files/Deasil-d-Logo-Instructions.pdf';
        instructions = (file) ? file : <Blank/>;
    } catch (err) {
    }

    return (
        <>
            <Hero page="deasil-works-d"/>

            <section className="deasil-works-d">
                <h1>The Deasil Works LEGO&reg; D.</h1>
                <p>
                    The d is Deasil, of course, but its assembled nature is suggestive of our approach. Projects
                    are developed with conceptualization and an iterative approach, but with possibilities for
                    expansion and reinterpretation.
                </p>
                <h2>This Instructional Sheet is Terrible.</h2>
                <p>
                    Naturally, we provide the very best in customer service, hence our handy painstakingly created
                    and translated assembly instruction sheet. Please refer carefully to the written instructions
                    and diagrams for most effective use and enjoyment. Also, the Deasil stickers look very handsome
                    on any laptop, refrigerator, or file cabinet. If you need replacement assembly
                    instructions, <Link to={instructions} title="Deasil d Logo Instructions" target="_blank">download
                    them here</Link>.
                </p>
                <p>
                    Can Deasil build something for you? <Link to="/contact" title="Contact Us">Contact Us</Link>.
                </p>
                <p className="text-small mb-0">
                    LEGO® is a trademark of the LEGO Group of companies which does not sponsor, authorize or endorse
                    this site.
                </p>
            </section>
            <div className="full flex">
                <Image path={`${process.env.PUBLIC_URL}/static/img-Deasil-d-Logo-1.jpg`} name="Deasil d Logo 1"
                       classes="full"/>
            </div>
            <div className="full flex cols mt-0">
                <Image path={`${process.env.PUBLIC_URL}/static/img-Deasil-d-Logo-2.jpg`} name="Deasil d Logo 2"
                       classes="col-6"/>
                <Image path={`${process.env.PUBLIC_URL}/static/img-Deasil-d-Logo-3.jpg`} name="Deasil d Logo 3"
                       classes="col-6"/>
            </div>
        </>
    )
};

export default DeasilWorksD;
