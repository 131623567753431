import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const ApacheKafka = () => {
    return (
        <>
            <Hero page="oss-apache-kafka"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/Apache-Kafka-logo.png" name="Apache Kafka" classes="logo client mr-2"/>
                    <p>
                    </p>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Related Services and Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-apache-nifi"/></li>
                            <li><TextLink name="oss-apache-superset"/></li>
                            <li><TextLink name="oss-opensearch"/></li>
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-open-source-expertise"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default ApacheKafka;
