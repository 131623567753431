import * as React from 'react';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const DataLakes = () => {
    return (
        <>
            <Hero page="service-data-lakes"/>

            <section className="bg full cols">
                <span>
                    <p>
                        A data lake is a centralized repository that stores a large amount of raw data in its native
                        format, such as structured, semi-structured, and unstructured data. In contrast to traditional
                        databases
                        or <TextLink name="service-data-warehouses" text="data warehouses"/> that optimize for
                        structured data and use predefined schemas, a data lake offers flexibility, scalability, and
                        diverse data storage options. Organizations can store a wide range of data, including log
                        files, social media posts, and traditional databases.
                    </p>
                    <p>
                        Deasil brings 25 years of experience in
                        developing <TextLink name="service-custom-data-platforms" text="custom data platforms"/>. We
                        understand the complexities of efficiently building, managing and scaling a data lake.
                        Deasil takes a cutting-edge, vendor-neutral approach to managing client data lakes by
                        leveraging the power of
                        enterprise-grade <TextLink name="service-open-source-expertise"
                                                   text="open-source applications"/>. In top-tier data centers
                        across the United States, Deasil's infrastructure provides highly secure, scalable,
                        and cost-effective solutions without relying on overpriced public cloud services.
                    </p>
                    <p className="mb-0">
                        Through this potent combination of open-source software, Deasil crafts tailored, scalable,
                        and cost-effective data lakes, empowering businesses to derive actionable insights from
                        their data without the limitations or costs of traditional public cloud solutions.
                    </p>
                </span>
            </section>

            <section className="bg bg-primary full mb-0">
                <div className="panel bg-white">
                    <h2>Object Storage-Backed Data Lakes</h2>
                    <p>
                        Deasil employs object storage-backed data lakes to store billions of files for its clients.
                        Object
                        storage is suitable for managing vast amounts of unstructured data, offering excellent
                        scalability
                        and reliability.
                    </p>

                    <h3>SeaweedFS + Ceph</h3>
                    <ul className="no-bullets mb-1">
                        <li>
                            <div className="cols mb-2">
                                <Image path="logos/SeaweedFS-logo.png" name="SeaweedFS"
                                       classes="logo client x-small square mr-3"/>
                                <p>
                                    <strong><TextLink name="oss-seaweedfs"/></strong>: This fast, straightforward object
                                    storage system provides highly efficient storage capabilities, built to store and
                                    serve billions of files with high fault tolerance and scalability. One key feature
                                    that makes SeaweedFS exceptionally versatile for modern businesses is its S3
                                    API compatibility. SeaweedFS can emulate the behavior of Amazon's Simple Storage
                                    Service (S3), one of the most widely used storage services in cloud computing.
                                    S3 API compatibility allows businesses to easily switch to or integrate with
                                    SeaweedFS without changing their existing codebase, tools, or workflows designed
                                    initially for Amazon S3.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="cols">
                                <Image path="logos/Ceph-logo-standard.png" name="Ceph"
                                       classes="logo client x-small mr-3"/>
                                <p>
                                    <strong><TextLink name="oss-ceph"/></strong>: Known for its robustness and
                                    flexibility, Ceph offers object, block, and file storage in one unified system.
                                    Its self-managing and self-healing capabilities make it ideal for enterprise
                                    storage needs.
                                </p>
                            </div>
                        </li>
                    </ul>

                    <h3>Data Ingestion and Integration</h3>
                    <div className="cols">
                        <Image path="logos/Apache-NiFi-logo.svg" name="Apache NiFi" classes="logo client x-small mr-3"/>
                        <p>
                            <strong><TextLink name="oss-apache-nifi"/></strong> is used for data ingestion, enabling
                            Deasil to efficiently manage data flow from multiple sources into the data lake. Its
                            intuitive drag-and-drop interface allows for easy configuration and management of
                            complex data pipelines, ensuring that data reaches its destination optimally.
                        </p>
                    </div>
                    <div className="cols">
                        <Image path="logos/Apache-Kafka-logo.png" name="Apache Kafka"
                               classes="logo client x-small mr-3"/>
                        <p>
                            <strong><TextLink name="oss-apache-kafka"/></strong> complements Apache NiFi by offering
                            real-time data streaming capabilities, ideal for businesses that require real-time
                            analytics or operational intelligence. Kafka allows for ingesting massive volumes of
                            data in real time, making it perfect for high-velocity data streams.
                        </p>
                    </div>
                </div>
            </section>

            <section className="bg bg-light full">
                <h2>Data Analytics and Querying</h2>
                <p>
                    To ensure that clients can perform rapid analytics on their data, Deasil
                    employs <strong><TextLink name="oss-trino"/></strong>, a distributed SQL query engine that
                    allows for real-time querying across different data repositories, providing the ability to execute
                    complex queries at high speed. It's beneficial for running analytic queries against the large
                    datasets stored in the data lakes.
                </p>

                <h3>Fully Managed and Secure</h3>
                <p>
                    Deasil's infrastructure in top-tier data centers
                    guarantees <TextLink name="service-high-availability-performance-engineering"
                                         text="high availability"/>, fault tolerance, and
                    robust security measures, including data encryption and role-based access controls. Deasil enables
                    clients to focus on their core business needs while trusting us for reliable, scalable, and secure
                    data storage and management solutions.
                </p>
            </section>
        </>
    )
};

export default DataLakes;
