import React from 'react';
import {Outlet} from 'react-router-dom';
import {useSetPageTitle, useScrollToLocation} from '../utils/functions';
import Header from './Header';
import Footer from './Footer';
import {useAnalyticsHandler} from '../utils/analytics';
import CookieConsent from '../components/CookieConsent';
import {StructuredData} from '../utils/schema';

const Layout = () => {

    useScrollToLocation();
    useSetPageTitle();
    useAnalyticsHandler();

    return (
        <>
            <Header/>
            <div id="content" className="content">
                <Outlet/>
            </div>
            <StructuredData/>
            <CookieConsent/>
            <Footer/>
        </>
    )
};

export default Layout;
