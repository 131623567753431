import * as React from 'react';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';

const PlatformDataMigration = () => {
    return (
        <>
            <Hero page="service-platform-data-migration"/>

            <section className="bg full">
                <p>
                    While <TextLink name="service-legacy-systems-integration" text="integrating legacy systems"/> brings
                    numerous advantages to your business, there may come a time when migration to new systems becomes
                    imperative. Factors like service depreciation, increased costs, or security concerns often trigger
                    this need. Deasil specializes in facilitating seamless data migrations, thanks to our extensive
                    experience designing and
                    assembling <TextLink name="service-data-management-pipelines" text="data platforms"/> for various
                    industries.
                </p>
                <p className="mb-0">
                    Deasil begins by thoroughly auditing your existing infrastructure, followed by a comprehensive
                    migration plan tailored to your specific needs. We schedule migration activities to minimize the
                    impact on your business operations. Deasil employs advanced tools and best practices to ensure all
                    your data makes it to the new system without loss or corruption. We follow stringent security
                    protocols to protect your data throughout the migration process, meeting industry standards and
                    regulations. Once the migration is complete, we offer ongoing support and optimization services
                    to ensure you get the most out of your new systems.
                </p>
            </section>

            <section className="bg bg-primary full pt-2 pb-2">
                <h2>Migration Services</h2>

                <div className="panel bg-white">
                    <table className="bg-light mb-4">
                        <thead>
                        <tr>
                            <th>Category</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Application Migration</span></td>
                            <td>
                                Updating your enterprise software? We make sure your data migrates securely and
                                efficiently between different application environments.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Big Data Migration</span></td>
                            <td>
                                Handling large datasets requires specialized tools and expertise. We ensure that your
                                Big Data transitions are seamless, with minimal impact on ongoing operations.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Cloud Migration</span></td>
                            <td>
                                Whether you are moving from an on-premises solution to the cloud or switching between
                                cloud providers, we offer smooth and secure transitions.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Content Management System (CMS) Transitions</span>
                            </td>
                            <td>
                                Migrating websites or large digital content repositories involves complex data
                                transformation tasks. We manage this complexity for you.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Database Migration</span></td>
                            <td>
                                We handle all database migrations from Oracle
                                to <TextLink name="oss-postgresql"/> or MySQL to <TextLink name="oss-apache-cassandra"/>,
                                ensuring data integrity and minimizing downtime.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Legacy System Upgrade</span></td>
                            <td>
                                When old systems reach their end-of-life or lack critical functionalities, we help you
                                transition to more robust and feature-rich platforms.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>

            <section>
                <h2>Beyond Data Migration: Platform Transitions and Custom Software Development</h2>
                <p>
                    At Deasil, we don't stop at migrating your data; we offer an end-to-end suite of services designed
                    to fully transition your business into new platforms or custom environments. With over 25 years of
                    industry experience, our expertise spans data platform design, migration, and in-house software
                    development.
                </p>
                <p>
                    Deasil offers full-scale platform migration. We don't
                    simply <TextLink name="service-etl-elt-operations" text="move your data"/>; we transition your
                    entire operational environment, including features and functionalities, to newly established
                    platforms that better serve your business needs.
                </p>
                <p>
                    Deasil provides custom application development. Our in-house development team can create bespoke
                    applications tailored to your unique requirements. Deasil's extensive experience spans various
                    industries, allowing us to tailor solutions that fit your specific operational and compliance needs.
                </p>

                <h3>How We Do It</h3>
                <p>
                    Deasil conducts an exhaustive evaluation of your current platform to identify your business's
                    essential features and functionalities, followed by a step-by-step migration plan that ensures a
                    smooth transition with minimal business disruption. We work closely with you to understand your
                    specific needs and desired outcomes. Deasil's in-house development team employs agile methodologies
                    to <TextLink name="service-custom-software-development" text="produce high-quality software"/> that
                    can adapt to changing requirements. Rigorous testing protocols ensure that the developed application
                    meets industry standards and your specific needs. Beyond development, we offer continuous support to
                    ensure your custom application remains robust and up-to-date.
                </p>
            </section>
        </>
    )
};

export default PlatformDataMigration;
