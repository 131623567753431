import * as React from 'react';
import {Link} from 'react-router-dom';
import Background from '../../components/Background';
import ContactBlock from '../../components/ContactBlock';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import ServicePanel from '../../components/ServicePanel';
import TextLink from '../../components/TextLink';

const Automotive = () => {
    return (
        <>
            <Hero page="industry-automotive" classes="has-overlay"/>

            <section className="bg bg-primary overlay-top mb-4">
                <p>
                    With a longstanding experience spanning over two decades, Deasil is no stranger to the vast
                    landscape of the automotive industry, from crafting sophisticated online parts catalogs and
                    intuitive dealer locators to developing intricate solutions tailored for dealers and independent
                    service providers. Each service Deasil provides resonates with the industry's needs, driving
                    innovation and efficiency.
                </p>
                <p className="mb-0">
                    The automotive industry encompasses a broad spectrum of activities, from manufacturing to
                    retailing, and as the industry evolves, digital transformation becomes pivotal in enhancing
                    operations, customer experiences, and business models. Deasil's vast services, expertise, and
                    enterprise-grade solutions comprehensively address the automotive industry's multifaceted digital
                    transformation needs. Through tailored approaches, Deasil drives innovation, efficiency, and
                    customer satisfaction in the automotive sector.
                </p>
            </section>

            <div className="divider spacer pt-2"></div>

            <section className="bg bg-primary full">
                <Background path="bg-Hexagons-bw.jpg" opacity={0.14}/>

                <h2>Improving the Automotive Industry</h2>

                <ul className="service-links light">
                    <li className="mb-2">
                        <strong>Digital Showrooms & Virtual Configurators</strong>: Customers expect immersive
                        experiences where they can explore vehicle features, customize configurations, and even take
                        virtual test drives. Deasil develops sophisticated web and mobile applications that offer 3D
                        visualizations, augmented reality (AR) experiences, and virtual configurators.
                    </li>
                    <li className="mb-2">
                        <strong>Supply Chain & Manufacturing Automation</strong>: Manufacturers need efficient,
                        automated supply chains and production lines that adapt quickly to changing demands. Deasil
                        employs advanced data analytics and custom software solutions to drive process automation,
                        optimize supply chains, and streamline manufacturing processes.
                    </li>
                    <li className="mb-2">
                        <strong>eCommerce & Digital Sales Platforms</strong>: A shift towards online vehicle and parts
                        purchases demands robust eCommerce platforms tailored to the automotive buying experience.
                        Leveraging their expertise in custom software development, Deasil can develop or enhance
                        automotive eCommerce platforms, integrating features like online financing, trade-in
                        estimations, and more.
                    </li>
                    <li className="mb-2">
                        <strong>Predictive Maintenance & Service Integration</strong>: Independent service centers
                        require digital tools for predictive maintenance, inventory management, and seamless customer
                        service. With data science and machine learning platforms, Deasil can provide tools that predict
                        maintenance needs, optimize parts inventory, and integrate with other systems for cohesive
                        service experiences.
                    </li>
                    <li className="mb-2">
                        <strong>Vehicle Customization & Enhancement</strong>: Customers seeking vehicle customizations
                        expect digital platforms where they can visualize modifications and enhancements. Deasil creates
                        digital customization tools and visualization technologies to help customers see potential
                        vehicle modifications in real-time.
                    </li>
                    <li className="mb-2">
                        <strong>Data-Driven Insights & Decision Making</strong>: Manufacturers and dealers need
                        data-driven insights for decision-making, from sales forecasting to manufacturing optimization.
                        Deasil provides powerful data visualization, analytics, and business intelligence solutions by
                        leveraging tools
                        like <TextLink name="oss-apache-superset"/> and <TextLink name="oss-apache-hive"/>.
                    </li>
                    <li className="mb-2">
                        <strong>Cloud Infrastructure & Scalability</strong>: The automotive industry's vast data and
                        digital processes necessitate reliable and scalable cloud infrastructure. By offering hosting
                        and managed infrastructure services using their extensive data center presence, Deasil ensures
                        the automotive sector can scale digitally without incurring excessive costs.
                    </li>
                    <li className="mb-2">
                        <strong>Integrated Customer Experience Across Touchpoints</strong>: From initial interest to
                        post-purchase services, customers expect a seamless digital journey. Deasil's holistic approach
                        to digital transformation, from SEO to interactive computing, ensures a unified and enhanced
                        customer experience across all touchpoints.
                    </li>
                </ul>
            </section>

            <section className="bg full cols wrap">
                <h2>Deasil's Platform Services and Custom Development</h2>

                <ServicePanel
                    name="service-data-visualization-exploration"
                    children={<>With tools like <TextLink name="oss-apache-superset"/>, Deasil helps the automotive
                        sector to visualize data, aiding in analysis and strategy formulation.</>
                    }
                />
                <ServicePanel
                    name="service-machine-learning-infrastructure"
                    children={<>Deasil provides the infrastructure for implementing machine learning solutions,
                        driving innovation in areas like autonomous driving and predictive maintenance.</>
                    }
                />
                <ServicePanel
                    name="service-data-science-environments"
                    children={<>Harnessing tools like <TextLink name="oss-jupyterhub"/>, Deasil facilitates advanced
                        data analysis, helping the automotive industry derive insights and predict trends.</>
                    }
                />
                <ServicePanel
                    name="service-etl-elt-operations"
                    children={<>Deasil's prowess ensures seamless extraction, transformation, and loading of data,
                        which is crucial for automotive analytics and reporting.</>
                    }
                />
                <ServicePanel
                    name="service-compute-resources"
                    children={<>To cater to the diverse computational needs of the automotive sector, Deasil offers
                        a spectrum of resources, from raw hardware to sophisticated multi-cloud setups.</>
                    }
                />
                <ServicePanel
                    name="service-platform-data-migration"
                    children={<>As the automotive industry evolves, Deasil assists in transitioning between
                        platforms and migrating data, ensuring continuity and integrity.</>
                    }
                />
                <ServicePanel
                    name="service-legacy-systems-integration"
                    children={<>Deasil understands the value of legacy systems in the automotive domain and ensures
                        their seamless integration with newer digital solutions.</>
                    }
                />
                <ServicePanel
                    name="service-digital-transformation"
                    children={<>In an era of digital disruption, Deasil aids automotive businesses in their digital
                        transformation journey, ensuring they remain competitive and relevant.</>
                    }
                />
                <ServicePanel
                    name="service-open-source-expertise"
                    children={<>Deasil's prowess in open-source solutions
                        like <TextLink name="oss-opensearch"/>, <TextLink name="oss-apache-nifi"/>, and <TextLink
                            name="oss-jupyterlab"/>, to name a few, empowers the automotive industry to harness the
                        power of community-driven software. Automotive businesses leverage this expertise to scale their
                        operations, enable interoperability, and reduce costs. Deasil's multifaceted offerings are both
                        services and catalysts, driving the automotive industry towards greater efficiency, innovation,
                        and success.</>}
                />
                <ServicePanel
                    name="service-custom-data-platforms"
                    children={<>The automotive sector thrives on data&mdash;from manufacturing statistics to sales
                        metrics. Deasil's custom data platforms enable these entities to capture, store, and process
                        this data seamlessly, aiding in informed decision-making and strategy formulation.</>
                    }
                />
                <ServicePanel
                    name="service-sso-iam" title="SSO / Identity and Access Management"
                    children={<>With tools like <TextLink name="oss-keycloak"/>, Deasil offers robust Single Sign-On
                        (SSO) and identity solutions. Automotive businesses must ensure secure access to their
                        digital assets, from dealership portals to internal databases.</>
                    }
                />
                <ServicePanel
                    name="service-custom-software-development"
                    children={<>Deasil crafts bespoke software tailored to the unique challenges of the automotive
                        industry, whether it's inventory management for parts or interactive dashboards for fleet
                        operations.</>
                    }
                />
                <ServicePanel
                    name="service-mobile-applications"
                    children={<>In an increasingly mobile-first world, Deasil develops automotive-centric mobile
                        applications, enhancing user engagement, whether it's for car bookings, service appointments,
                        virtual showrooms, or maintenance reminders and management.</>
                    }
                />
                <ServicePanel
                    name="service-hosting-managed-infrastructure"
                    children={<>Deasil's extensive infrastructure ensures that automotive digital solutions remain
                        up and running, with high availability, be it a dealer portal, e-commerce platform, or any
                        other digital service.</>
                    }
                />
                <ServicePanel
                    name="service-seo-analytics"
                    children={<>Deasil's expertise aids automotive businesses in maximizing their online visibility
                        while deriving insights from web traffic, which is essential for marketing and outreach.</>
                    }
                />
                <ServicePanel
                    name="service-data-management-pipelines"
                    children={<>From collecting data on vehicle location and performance to customer preferences,
                        Deasil manages and orchestrates data flows efficiently.</>
                    }
                />
                <div className="panel cols col-6">
                    <Icon img="icon-Code-Engineering"
                          name="Data Warehouses, Data Lakes"
                          classes="primary medium"/>
                    <span>
                        <h3>
                            <Link to="/service/data-warehouses">Data Warehouses</Link>, <Link
                            to="/service/data-lakes">Data Lakes</Link>
                        </h3>
                        <p className="text-small mb-2">
                            Deasil's solutions ensure easy retrieval and processing of vast amounts of automotive
                            data, from sales figures to parts inventory.
                        </p>
                    </span>
                </div>
            </section>

            <ContactBlock label="Contact Deasil"/>
        </>
    )
};

export default Automotive;
