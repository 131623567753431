import * as React from 'react';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';

const MobileApplications = () => {
    return (
        <>
            <Hero page="service-mobile-applications"/>

            <section className="bg full cols">
                <span>
                    <p>
                        With a quarter-century of experience in the software and infrastructure industry, Deasil has
                        consistently been at the forefront of technological change, not merely adapting but pioneering.
                        Just as Deasil was developing mobile applications for clients before the advent of Apple's
                        iOS and Google's Android&mdash;thereby positioning its clients to seize immediate competitive
                        advantages when these platforms did arrive&mdash;it is equally prepared to navigate the next
                        era of disruptive innovations.
                    </p>
                    <p className="quote">
                        With Deasil as a partner, companies keep pace with rapid
                        technological shifts and often stay ahead of the curve, maintaining a significant edge over
                        new market entrants.
                    </p>
                    <p>
                        Deasil's range of mobile applications is a testament to our capabilities, from award-winning
                        games to innovative enterprise retail point-of-sale systems and even second-screen experiences
                        for Hollywood studios. This broad range of experience means that no matter your sector or
                        specific needs, Deasil has the versatility and expertise to deliver.
                    </p>
                </span>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <div className="cols">
                        <Icon img="icon-mobile-settings" name="Deasil's In-House Team of Senior Developers"
                              classes="secondary"/>
                        <div>
                            <h2>Deasil's In-House Team of Senior Developers</h2>
                            <p>
                                Deasil's in-house team of senior developers and project managers have worked together
                                for years, if not decades. This cohesiveness and shared history translate into a
                                streamlined development process, quick and effective problem-solving, and a quality
                                that is hard to find elsewhere. In contrast, off-shore agencies often suffer from high
                                turnover rates, ambiguous experience, and questionable accountability, all of which
                                can compromise the quality of the end product.
                            </p>
                            <p>
                                When developing mobile applications, more than expertise in one specific area is
                                needed to create a truly transformative product. Deasil offers a wealth of
                                cross-disciplinary experience that spans multiple domains, from software engineering
                                and UI/UX design to data analytics and cloud infrastructure. This holistic approach
                                provides several critical advantages for customers choosing Deasil for their mobile
                                application needs.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="panel bg-white">
                    <div className="cols">
                        <Icon img="icon-Problem-Solving" name="Comprehensive Solutions"
                              classes="secondary"/>
                        <div>
                            <h2>Comprehensive Solutions</h2>
                            <p>
                                Deasil's cross-disciplinary team efficiently and cohesively manages all aspects of
                                mobile application development. Our experts navigate complex terrains like backend
                                architecture, frontend design, data security, and performance optimization to deliver a
                                well-rounded product. Clients avoid juggling multiple vendors or consultants for
                                different project aspects, reducing friction and accelerating time-to-market.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="panel bg-white">
                    <div className="cols">
                        <Icon img="icon-Checkpoints" name="Streamlined Communication and Workflow"
                              classes="secondary mt-1"/>
                        <div>
                            <h2>Streamlined Communication and Workflow</h2>
                            <p>
                                Deasil has all disciplines housed under one roof, ensuring seamless communication and a
                                streamlined workflow, improving the speed and quality of the project, as
                                interdisciplinary teams can easily collaborate and solve problems from various angles.
                                In contrast, less integrated approaches often suffer from communication gaps and delays,
                                leading to suboptimal solutions and prolonged timelines.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="panel bg-white">
                    <div className="cols">
                        <Icon img="icon-Chart-Settings" name="Adaptation to Market Trends"
                              classes="secondary"/>
                        <div>
                            <h2>Adaptation to Market Trends</h2>
                            <p>
                                Deasil's broad expertise means they can quickly pivot or integrate new technologies,
                                whether implementing a machine learning algorithm for personalized user experiences or
                                novel private cloud solutions.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="panel bg-white">
                    <div className="cols">
                        <Icon img="icon-Seal-Quality" name="Domain Mastery"
                              classes="secondary"/>
                        <div>
                            <h2>Domain Mastery</h2>
                            <p>
                                Mobile application development requires Deasil's mastery and deep experience in multiple
                                disciplines and domains that span both application development's technical and business
                                aspects.
                            </p>
                        </div>
                    </div>

                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Domain</th>
                            <th>Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">UI/UX Design</span></td>
                            <td>
                                Deasil excels in the art of user interface and user experience design, ensuring the
                                development of apps that are not just functional but also intuitive and engaging.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Software Engineering</span></td>
                            <td>
                                Proven experience with multiple programming languages, frameworks, and best practices is
                                essential for creating robust mobile applications.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Security</span></td>
                            <td>
                                Deasil has developed specialized knowledge through experience in encryption,
                                authentication, and other security best practices to protect user data and privacy.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">QA and Testing</span></td>
                            <td>
                                Deasil implements rigorous quality assurance processes, including unit tests,
                                integration tests, and automated testing scenarios, to ensure mobile applications are
                                bug-free and stable.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Project Management</span></td>
                            <td>
                                Expertise in planning, executing, and closing projects to meet specific goals within
                                defined timelines and budgets.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Product Management</span></td>
                            <td>
                                Expertise in managing the product lifecycle, from ideation to launch to iteration, based
                                on user feedback and analytics.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Monetization Strategies</span></td>
                            <td>
                                Over the last two decades, Deasil has built a deep understanding of revenue models like
                                in-app purchases, ads, or subscriptions and how to implement them effectively.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Market Research</span></td>
                            <td>
                                Deasil continuously builds on its understanding of market demands, competitor landscape,
                                and user personas to shape the product development strategy.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Localization and Globalization</span></td>
                            <td>
                                Expertise in adapting content, interface, and functionality to fit a specific geographic
                                market's language, culture, and preferences to attract a global user base.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Legal and Compliance</span></td>
                            <td>
                                Deasil has experience working with regulations such as GDPR, COPPA, and HIPAA, which
                                become particularly important when the mobile app collects and stores user data.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Frontend Development</span></td>
                            <td>
                                Expertise in crafting the client-side experience, including handling of UI elements,
                                client-side scripting, and optimization.
                            </td>
                        </tr>
                        <tr>
                            <td><span
                                className="service-links nowrap strong">DevOps and Private Cloud Infrastructure</span>
                            </td>
                            <td>
                                Decades of experience deploying, monitoring, and scaling applications using a proven
                                blend of our private cloud services and containers.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Engineering</span></td>
                            <td>
                                Deasil develops custom data platforms to manage and manipulate large datasets
                                efficiently, especially for applications that rely heavily on data analytics.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Customer Engagement and Retention</span>
                            </td>
                            <td>
                                Deasil develops strategies for acquiring new users and keeping them engaged, often
                                through analytics, A/B testing, and customer feedback loops.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Backend Development</span></td>
                            <td>
                                Expertise in server-side technologies, databases, and APIs, crucial for building
                                scalable and secure mobile applications.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Accessibility</span></td>
                            <td>
                                Deasil implements best practices for making apps accessible to users with disabilities.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
};

export default MobileApplications;
