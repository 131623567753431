import * as React from 'react';
import Background from '../components/Background';
import Hero from '../components/Hero';
import Image from '../components/Image';
import TextLink from '../components/TextLink';

const About = () => {

    const diffusion = Math.floor(Math.random() * 4) + 1;

    return (
        <>
            <Hero page="about" classes="has-overlay"/>

            <section className="bg overlay-top mb-4">
                <h2 className="mr-1 ml-1">
                    A Distinguished Legacy
                </h2>
                <ul className="service-links ml-1">
                    <li>
                        Worked with notable giants, including Disney, Electronic Arts, NBC, Warner Brothers, and
                        many more.
                    </li>
                    <li>
                        Played a pivotal role in the digital transformation journeys of stars
                        like U2, Beyoncé, Nine Inch Nails, and more.
                    </li>
                    <li>
                        Developed groundbreaking technologies for top content providers turning into content
                        platforms, like NBC.com.
                    </li>
                    <li>
                        Expanded expertise across various <TextLink name="industry" text="industries"/>,
                        from entertainment to logistics, automotive, retail, and manufacturing.
                    </li>
                </ul>
                <TextLink name="contact" text="Contact Us" button="primary center mt-1 ml-3"/>
            </section>

            <section className="bg bg-secondary full">
                <h2 className="mr-1 ml-1 mb-3">
                    Pioneering Projects
                </h2>
                <div className="cols">
                    <p className="col-4 mr-1 ml-1">
                        Developed unique private-cloud retail management systems capable of rapid scaling during
                        high-activity seasons.
                    </p>
                    <p className="col-4 mr-1 ml-1">
                        Introduced advanced features in retail management like a multi-tier loyalty points system,
                        complex sales reporting, and forecasting.
                    </p>
                    <p className="col-4 mr-1 ml-1">
                        Developed <a href="https://gpulab.io/" title="GPULab" aria-label="GPULab - Opens in new tab"
                                     target="_blank"
                                     rel="noopener noreferrer">GPULab</a> and <a href="https://d4l.dev/" title="D4L"
                                                                                 aria-label="D4L - Opens in new tab"
                                                                                 target="_blank"
                                                                                 rel="noopener noreferrer">D4L</a> data
                        platforms to cater to data science needs.
                    </p>
                </div>
            </section>

            <section className="bg bg-primary full mt-0 mb-0 pb-3">
                <h2 className="mr-1 ml-1">
                    Technology by Deasil
                </h2>
                <div className="cols">
                    <div className="panel bg-white col-6 mr-1 ml-1">
                        <h3 className="center mb-1">GPULab</h3>
                        <a href="https://gpulab.io/" title="GPULab" aria-label="GPULab - Opens in new tab"
                           target="_blank" rel="noopener noreferrer">
                            <Image path="logos/GPULab-logo-Icon.svg" name="GPULab"
                                   classes="logo small client center mb-1"/>
                        </a>
                        <p>
                            <a href="https://gpulab.io/" title="GPULab" aria-label="GPULab - Opens in new tab"
                               target="_blank"
                               rel="noopener noreferrer">GPULab</a> is
                            Deasil's modern data science and machine learning platform providing standards-based,
                            turn-key, GPU-enabled research and development environments in Python, R, and Julia.
                        </p>
                    </div>
                    <div className="panel bg-white col-6 mr-1 ml-1">
                        <h3 className="center mb-1">D4L</h3>
                        <a href="https://d4l.dev/" title="D4L" aria-label="D4L - Opens in new tab" target="_blank"
                           rel="noopener noreferrer">
                            <Image path="logos/D4L-logo.png" name="D4L"
                                   classes="logo small client center mb-1"/>
                        </a>
                        <p>
                            <a href="https://d4l.dev/" title="D4L" aria-label="D4L - Opens in new tab" target="_blank"
                               rel="noopener noreferrer">D4L</a> is
                            Deasil's general data platform, harnessing the combinatorial effect of best-of-class
                            open-source software, open standards, and bare-metal hardware on a secure private cloud.
                        </p>
                    </div>
                </div>
                <div className="cols">
                    <div className="panel bg-white col-6 mr-1 ml-1">
                        <h3 className="center mb-1">DiffusionLab.io</h3>
                        <a href="https://diffusionlab.io/" title="DiffusionLab.io"
                           aria-label="DiffusionLab - Opens in new tab" target="_blank"
                           rel="noopener noreferrer">
                            <Image path={`DiffusionLab-render-${diffusion}.jpg`} name="DiffusionLab.io"
                                   classes="center mb-1" style={{width: 110}}/>
                        </a>
                        <p>
                            <a href="https://diffusionlab.io/" title="DiffusionLab.io"
                               aria-label="DiffusionLab - Opens in new tab" target="_blank"
                               rel="noopener noreferrer">DiffusionLab.io</a> is a fun exercise utilizing our
                            advanced data science platform and infrastructure. Images generated are created purely
                            by artificial intelligence models.
                        </p>
                    </div>
                    <div className="panel bg-white col-6 mr-1 ml-1">
                        <h3 className="center mb-1">Deasil Networks</h3>
                        <a href="https://deasilnet.com/" title="Deasil Networks"
                           aria-label="Deasil Networks - Opens in new tab" target="_blank"
                           rel="noopener noreferrer">
                            <Image path="logos/Deasil-Networks-logo-dark.svg" name="Deasil Networks"
                                   classes="logo center mb-2 mr-2 ml-2" style={{width: '90%'}}/>
                        </a>
                        <p>
                            <a href="https://deasilnet.com/" title="Deasil Networks"
                               aria-label="Deasil Networks - Opens in new tab" target="_blank"
                               rel="noopener noreferrer">Deasil Networks</a> provides on-demand dedicated hosting
                            services that can provide application-level support and maintenance, in addition to data
                            center infrastructure setup and support.
                        </p>
                    </div>
                </div>
            </section>

            <section className="bg bg-secondary full center mb-4">
                <Background path="bg-Digital-Tech-bw.jpg" opacity={0.18}/>
                <h3 className="mt-1">
                    Deasil has over 25 years of software development, systems integration, and infrastructure
                    management.
                </h3>
                <TextLink name="our-history" text="Our History" button="light center mt-2"/>
            </section>
        </>
    )
};

export default About;
