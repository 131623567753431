import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const PgAdmin = () => {
    return (
        <>
            <Hero page="oss-pgadmin"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/pgAdmin-logo.png" name="pgAdmin Logo" classes="logo client mr-2"/>
                    <div>
                        <p>
                            pgAdmin is a pivotal tool in the <TextLink name="oss-postgresql"/> ecosystem, offering a
                            dynamic, web-based platform to administer, manage, and monitor PostgreSQL databases. It
                            streamlines database-related tasks, enabling users to execute SQL queries, manage database
                            objects, and diagnose potential issues through its intuitive graphical interface. Moreover,
                            pgAdmin facilitates comprehensive user access and permission controls, visual
                            representations of data and structures, and advanced debugging capabilities, making it
                            indispensable for PostgreSQL administrators and developers seeking efficient database
                            management.
                        </p>
                    </div>
                </div>
                <p>
                    Deasil, recognizing the potent capabilities of pgAdmin, integrates it into its suite of
                    services, giving clients the luxury of a web-based interface for their PostgreSQL
                    deployments. By offering managed pgAdmin services, Deasil provides businesses with seamless,
                    secure, and uninterrupted access to their databases from anywhere, anytime. This integration
                    not only simplifies database administration but also bolsters the productivity of database
                    professionals. With Deasil's expert management of pgAdmin, clients gain the advantage of
                    top-notch PostgreSQL administration combined with the assurance of high availability,
                    security, and unparalleled performance.
                </p>
                <Image path="logos/pgAdmin-screenshot.png" name="pgAdmin screenshot" classes="center mb-1"/>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <h2>Deasil Custom Data Warehouses and Platform</h2>
                    <p>
                        Deasil excels in constructing custom comprehensive data platforms, prominently integrating the
                        power of pgAdmin and <TextLink name="oss-postgresql"/>. pgAdmin provides a sophisticated
                        web-based interface for
                        PostgreSQL management, ensuring efficient database administration and operations. Coupled with
                        PostgreSQL, one of the most advanced open-source relational database systems, Deasil offers
                        clients robust database solutions with high performance, extensibility, and reliability. These
                        tools act as the foundational elements of the data platforms, enabling streamlined data storage,
                        retrieval, and management.
                    </p>
                    <p>
                        Building on this foundational database setup, Deasil
                        integrates <TextLink name="oss-trino"/> for distributed SQL
                        querying, <TextLink name="oss-apache-superset"/> for data visualization and business
                        intelligence, and <TextLink name="oss-apache-nifi"/> for data flow automation and
                        transformation. The synergy of these tools allows businesses to seamlessly ingest, process,
                        analyze, and visualize data across diverse sources and
                        formats. <TextLink name="oss-opensearch"/> further augments the platform's capabilities by
                        providing powerful full-text search and analytics. At the same
                        time, <TextLink name="oss-jupyterhub"/> offers an interactive computing environment, enabling
                        data scientists and analysts to develop and share data-driven notebooks collaboratively.
                    </p>
                    <p>
                        To ensure the cohesive orchestration of these multifaceted tools, Deasil
                        leverages <TextLink name="oss-kubernetes"/>, the leading container orchestration system.
                        Kubernetes guarantees high availability, efficient resource utilization, and seamless
                        scalability of all components in the platform. With this
                        orchestrated approach, Deasil simplifies the deployment and management of
                        these <TextLink name="service-open-source-expertise" text="OSS solutions"/> and ensures a
                        resilient, high-performance, and integrated environment for end-to-end data operations, allowing
                        businesses to derive actionable insights easily.
                    </p>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Related Services and Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-apache-nifi"/></li>
                            <li><TextLink name="oss-apache-superset"/></li>
                            <li><TextLink name="oss-opensearch"/></li>
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-open-source-expertise"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default PgAdmin;
