import * as React from 'react';
import {Link} from 'react-router-dom';
import ContactBlock from '../../components/ContactBlock';
import Background from '../../components/Background';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import ServicePanel from '../../components/ServicePanel';

const CreativeAgencies = () => {
    return (
        <>
            <Hero page="industry-creative-agencies" classes="has-overlay"/>

            <section className="bg bg-primary overlay-top mb-4">
                <p>
                    Creative agencies integrate Deasil's vast technological capabilities to enhance their service
                    offerings, streamline operations, and deliver innovative, data-driven solutions for their clients.
                    Rather than reselling overpriced public cloud offerings, Deasil utilizes its
                    extensive <Link to="/service/compute-resources">in-house infrastructure</Link>. This provides
                    a significant advantage to creative agencies by allowing budget-friendly project proposals
                    when pitching to clients.
                </p>
                <p className="mb-0">
                    Creative agencies have unique needs for modernization, cost optimization, and meeting customer
                    expectations in the digital age. Deasil's comprehensive services and expertise in various technology
                    domains allow them to address these needs effectively, helping creative agencies modernize their
                    operations and meet customer expectations while controlling costs.
                </p>
            </section>

            <div className="divider spacer pt-2"></div>

            <section className="bg bg-primary full">
                <Background path="bg-Digital-Tech-bw.jpg" opacity={0.15}/>

                <h2>Propelling Creative Agencies</h2>

                <ul className="service-links light">
                    <li className="mb-2">
                        <strong>Custom Software Development</strong>: Creative agencies may require custom software
                        tools to streamline workflows, manage projects, or collaborate with clients. Deasil specializes
                        in in-house custom software development. Deasil creates tailor-made applications and platforms
                        to meet the specific needs of creative agencies, improving efficiency and productivity.
                    </li>
                    <li className="mb-2">
                        <strong>Data Management and Analytics</strong>: Creative agencies rely on data for
                        decision-making, including client preferences, market trends, and campaign performance. Deasil
                        offers custom data platforms, data science, and machine learning solutions. They can help
                        agencies collect, analyze, and visualize data to gain insights, make data-driven decisions,
                        and enhance marketing strategies.
                    </li>
                    <li className="mb-2">
                        <strong>Security and Access Control</strong>: Creative agencies handle sensitive client data
                        and intellectual property, requiring robust security measures and access controls. Deasil's
                        expertise in identity and access management ensures that data remains secure. They can
                        implement security protocols and tools like Keycloak to protect sensitive information.
                    </li>
                    <li className="mb-2">
                        <strong>Cost Optimization</strong>: Creative agencies often operate on tight budgets and must
                        optimize costs without compromising quality. Deasil's approach of not reselling overpriced
                        public cloud offerings helps agencies save costs. Deasil provides cost-effective infrastructure
                        solutions without sacrificing performance or reliability.
                    </li>
                    <li className="mb-2">
                        <strong>Digital Transformation</strong>: To stay competitive, creative agencies must adapt to
                        changing technologies and customer expectations. Deasil offers digital transformation services,
                        enabling agencies to modernize their processes and adopt the latest technologies like container
                        orchestration and interactive computing environments.
                    </li>
                    <li className="mb-2">
                        <strong>Open Source Solutions</strong>: Many creative agencies prefer open-source software to
                        reduce licensing costs and gain flexibility. Deasil's expertise in managing enterprise-grade
                        open-source solutions like Apache Cassandra, Apache Hive, and Kubernetes aligns with agencies'
                        preferences for cost-effective, cutting-edge, and customizable solutions.
                    </li>
                    <li className="mb-2">
                        <strong>Performance Analytics</strong>: Agencies must track website and campaign performance to
                        meet client expectations. Deasil offers SEO and performance analytics services, helping
                        agencies monitor and improve website performance and search engine rankings.
                    </li>
                </ul>
            </section>

            <section className="bg full cols wrap">
                <h2>Deasil's Platform Services and Custom Development</h2>

                <ServicePanel
                    name="service-custom-software-development"
                    children={<>Creative agencies collaborate with Deasil to create unique digital solutions, from
                        interactive websites to mobile apps and IoT-enabled experiences, catering to their
                        clients' niche requirements and target audiences.</>
                    }
                />
                <ServicePanel
                    name="service-hosting-managed-infrastructure"
                    children={<>Deasil ensures an agency's digital campaigns and client platforms are always
                        accessible, perform optimally, and are secure. Deasil understands high availability
                        and performance are crucial for important campaigns and events.</>
                    }
                />
                <ServicePanel
                    name="oss-data-visualization-business-intelligence"
                    children={<>Deasil's managed enterprise-grade OSS data visualization tools, including Apache
                        Superset, offer real-time visualization dashboards to clients, showcasing campaign
                        results, user interactions, and other crucial metrics.</>
                    }
                />
                <ServicePanel
                    name="service-etl-elt-operations"
                    title="Process Automation"
                    children={<>Deasil's ETL (Extract, Transform, Load) and ELT (Extract, Load, Transform) operations
                        ensure that manufacturing data is cleansed, transformed, and ready for analysis.
                        This streamlines data integration and enhances data quality, facilitating data-driven
                        decision-making.</>
                    }
                />
                <ServicePanel
                    name="service-data-science-environments"
                    title="Custom Data Platforms & Data Science"
                    children={<>Deasil builds platforms that consolidate data from various campaigns, helping
                        agencies make informed decisions based on analytics. Agencies utilize Deasil's
                        machine learning environments to develop AI models to tailor content recommendations,
                        automate repetitive creative processes, or forecast campaign outcomes.</>
                    }
                />
                <ServicePanel
                    name="service-seo-analytics"
                    children={<>Deasil helps agencies improve the visibility of digital campaigns and content,
                        ensuring that creative endeavors receive the attention they deserve in search engine
                        results.</>
                    }
                />
                <div className="panel cols col-6">
                    <Icon img="icon-Database-Architecture" name="Databases, Data Warehousing, & ETL" classes="primary"/>
                    <span>
                        <h3>
                            <Link to="/service/data-management-pipelines">Databases</Link>, <Link
                            to="/service/data-warehouses">Databases</Link>, & <Link
                            to="/service/etl-elt-operations">ETL</Link>
                        </h3>
                        <p>
                            Agencies must efficiently store, retrieve, and manage data from different campaigns.
                            Deasil's managed enterprise-grade OSS data integration tools,
                            including <Link to="/managed-oss/data-integration-etl#apache-nifi">Apache NiFi</Link>,
                            allow agencies to move and transform data effortlessly, ensuring seamless operations
                            for data-driven campaigns.
                        </p>
                    </span>
                </div>
                <ServicePanel
                    name="service-data-lakes"
                    children={<>Deasil's managed enterprise-grade OSS (S3 compatible) data storage solutions enable
                        agencies to store vast amounts of digital assets from video files to high-resolution
                        images, ensuring they are readily available and securely backed up.</>
                    }
                />
            </section>

            <ContactBlock label="Contact Deasil"/>
        </>
    )
};

export default CreativeAgencies;
