import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';

const DataVisualizationBusinessIntelligence = () => {
    return (
        <>
            <Hero page="oss-data-visualization-business-intelligence"/>

            <section className="bg full mb-4 cols">
                <span>
                    <p>
                        Deasil offers fully managed <TextLink name="oss-apache-superset"/> environments, a
                        cutting-edge data visualization and business intelligence tool developed initially at
                        Airbnb. With Superset, Deasil's clients craft interactive and intuitive dashboards that
                        transform complex datasets into understandable insights. These visualizations support
                        many data sources, ensuring a broad scope of data analytics for our clients. Alongside
                        Superset, Deasil employs <TextLink name="oss-trino" text="Trino, formerly known as Presto"/>, which
                        serves as a high-performance distributed SQL query engine. Trino facilitates swift and
                        scalable querying across an exceptional number of data sources, allowing Deasil to
                        seamlessly fetch and integrate highly disparate data, making the entire data
                        visualization and BI process as broad as your organization.
                    </p>
                    <p>
                        To optimize infrastructure and ensure consistent performance, Deasil encapsulates these
                        toolswithin <TextLink name="oss-container-management-infrastructure"
                                         text="custom containers: encapsulated environments"/> that
                        bundle applications and their necessary components. This approach guarantees portability,
                        scalability, and standardized environments. Deasil deploys containers in its
                        private <TextLink name="oss-kubernetes"/> clusters. Kubernetes is the leading container
                        orchestration platform, allowing Deasil to automate deployment, scaling, and management.
                        This allows all services to be continuously available, resilient, and adaptive to
                        ever-changing workloads. Deasil's data platform unification within Kubernetes provides a
                        streamlined, high-availability BI platform that can efficiently handle diverse client
                        needs at any scale.
                    </p>
                </span>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Data Visualization and Business Intelligence Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-apache-superset"/></li>
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-opensearch-dashboards"/></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <h3>Data Visualization and Business Intelligence Related Services:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="service-data-visualization-exploration"/></li>
                            <li><TextLink name="service-data-management-pipelines"/></li>
                            <li><TextLink name="service-data-science-environments"/></li>
                            <li><TextLink name="service-etl-elt-operations"/></li>
                            <li><TextLink name="service-data-warehouses"/></li>
                            <li><TextLink name="service-data-lakes"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default DataVisualizationBusinessIntelligence;
