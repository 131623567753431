import * as React from 'react';
import Icon from './Icon';

const AboutBlockPrivateClouds = ({
                               content1,
                               content2,
                               paragraphs = 1,
                               title = 'About Private Clouds',
                               classes = ''
                           }) => {

    const paragraph1 = (content1)
        ? content1
        : `Deasil does not re-sell overpriced public cloud services but utilizes its extensive private 
        infrastructure, providing simple fixed monthly pricing and enormous cost savings for our clients.`;

    return (
        <div className={`panel bg bg-white cols about pb-1 ${classes}`}>
            <Icon img="icon-Cloud-Secure" name={title} classes="primary"/>
            <span>
                <h3>{title}</h3>
                {paragraph1 &&
                    <p>
                        {paragraph1}
                    </p>
                }
                {paragraphs === 2 && content2 &&
                    <p>
                        {content2}
                    </p>
                }
            </span>
        </div>
    )
};

export default AboutBlockPrivateClouds;
