import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const Trino = () => {
    return (
        <>
            <Hero page="oss-trino"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/Trino-logo.svg" name="Trino" classes="logo client mr-2 mb-2"/>
                    <p>
                        Trino, formerly known as Presto, originated at Facebook in 2012 as a response to the growing
                        need for an interactive SQL query engine that could handle massive volumes of data across
                        different data sources. Facebook engineers designed Trino to break away from the limitations of
                        traditional MapReduce (Big Data/Hadoop) processing frameworks, aiming for a faster and more
                        flexible solution. Over the years, Trino gained traction and attracted contributions from
                        several other companies, evolving into an independent open-source project with a diverse
                        community of contributors.
                    </p>
                </div>
                <p>
                    The significance of Trino lies in its ability to query data across various data stores without
                    requiring data movement or transformation. This capability makes it an essential tool for companies
                    with multi-petabyte datasets distributed across different systems. Its architecture, designed for
                    high concurrency and parallel processing, ensures swift analytical queries, making it a favorite for
                    analysts and data scientists who need real-time insights.
                </p>
                <p>
                    Critical features of Trino include:
                </p>
                <div className="panel bg-white">
                    <ul className="service-links">
                        <li>
                            <strong>Extensible architecture</strong>, allowing connectors for various data sources like
                            Hadoop, Cassandra, RDBMS, and even proprietary data stores.
                        </li>
                        <li>
                            Support for <strong>high concurrency</strong>, ensuring many users can run queries
                            simultaneously.
                        </li>
                        <li>
                            <strong>In-memory and pipelined processing design</strong> maximizes throughput and lowers
                            query latency.
                        </li>
                        <li>
                            Support for <strong>ANSI SQL</strong>, which allows users to leverage their existing SQL
                            knowledge, eliminating the learning curve typically associated with new systems.
                        </li>
                    </ul>
                </div>
                <p>
                    Major organizations rely on Trino, including AWS, Netflix, Nielson, Lyft, LinkedIn, Instacart,
                    Pinterest, Salesforce, Robinhood, Shopify, Stripe, and Goldman Sachs.
                </p>
                <p>
                    Deasil provides robust, high-availability Trino clusters that meet enterprise-grade performance and
                    security requirements.
                </p>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <h2>Deasil's Comprehensive Custom Data Platforms</h2>
                    <p>
                        Deasil harnesses the power of Trino, a high-performance distributed SQL query engine, to create
                        a versatile foundation for its managed data platforms. Trino works seamlessly
                        with <TextLink name="oss-apache-hive"/> to enable clients to access and combine data from
                        different sources, from traditional relational databases
                        to <TextLink name="service-data-lakes" text="data lakes"/>. With its architecture
                        for querying massive datasets, <TextLink name="oss-trino"/> acts as the bridge between data
                        stored in disparate systems
                        like <TextLink name="oss-apache-cassandra"/>, <TextLink name="oss-postgresql"/>, <TextLink
                        name="oss-ceph"/>, and <TextLink name="oss-seaweedfs"/>. This symbiotic relationship ensures
                        clients can run interactive analytical queries at lightning speed, regardless of where the data
                        resides.
                    </p>
                    <p>
                        <TextLink name="oss-apache-nifi"/> plays a pivotal role in the data pipeline, automating data
                        flow between systems. Deasil integrates NiFi with Trino and the other components, creating a
                        streamlined data ingestion, transformation, and distribution process. The automated workflow
                        ensures that data from various sources feeds seamlessly into the data warehouse or data lake,
                        ready for querying and analysis. Furthermore, with <TextLink name="oss-opensearch"/>, a
                        powerful full-text search and analytics engine, clients can search, analyze, and visualize their
                        data in real time.
                    </p>
                    <p>
                        Deasil leverages <TextLink name="oss-apache-superset"/>, <TextLink
                        name="oss-opensearch-dashboards"/> and <TextLink name="oss-jupyterhub"/> for data
                        visualization and business intelligence. Apache Superset allows users to explore their data,
                        construct interactive dashboards, and share insights across the organization. JupyterHub, on the
                        other hand, offers a collaborative environment for data scientists to write and share code,
                        analyses, and visualizations. With both tools at their disposal, clients can choose the platform
                        that best fits their needs, whether interactive dashboards or detailed data science notebooks.
                    </p>
                    <p>
                        Lastly, security and access management remain paramount in any data-driven solution. Deasil
                        incorporates <TextLink name="oss-keycloak"/>, an open-source identity and access management
                        solution, ensuring that data remains protected and accessible only to authorized personnel. By
                        integrating Keycloak with the entire stack, Deasil guarantees a secure environment where users
                        can confidently access and analyze their data, knowing that robust authentication and
                        authorization mechanisms are in place.
                    </p>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Related Services and Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-opensearch"/></li>
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-apache-nifi"/></li>
                            <li><TextLink name="oss-postgresql"/></li>
                            <li><TextLink name="oss-apache-superset"/></li>
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-open-source-expertise"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Trino;
