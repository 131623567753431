import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';

const DatabasesDataWarehousing = () => {
    return (
        <>
            <Hero page="oss-databases-data-warehousing"/>

            <section className="bg full mb-4 cols">
                <span>
                    <p>
                        Deasil leverages several
                        enterprise-grade <TextLink name="service-open-source-expertise" text="OSS technologies"/> to
                        provide state-of-the-art Databases and Data Warehousing solutions.
                    </p>
                    <p>
                        Deasil's <TextLink name="oss-apache-cassandra"/> database provides massive scalability
                        via its distributed architecture, spanning
                        multiple <TextLink name="service-data-center-network-operations" text="data centers"/> and
                        ensuring <TextLink name="service-high-availability-performance-engineering"
                                           text="high availability"/>, an ideal choice for applications requiring
                        vast write and read throughput.
                    </p>
                    <p>
                        Deasil provides <TextLink name="oss-postgresql"/>, a robust and open-source relational
                        database system catering to projects requiring intricate queries, data integrity, and
                        relational structures while supporting advanced database functionalities. To bolster the
                        administration and management of PostgreSQL, Deasil offers <TextLink name="oss-pgadmin"/> to
                        streamline database management tasks.
                    </p>
                    <p>
                        Deasil provides <TextLink name="oss-apache-hive"/> and <TextLink name="oss-trino"/>,
                        forming a powerful and flexible SQL-accessible data warehouse. Apache Hive organizes and
                        stores massive datasets in AWS S3 or Deasil's
                        S3-compatible <TextLink name="oss-seaweedfs"/> using tables while applying a
                        schema-on-read strategy for data flexibility. Trino, a distributed SQL query engine,
                        directly queries data stored in Hive tables, bypassing Hive's slower MapReduce operations.
                        By accessing the Hive Metastore, Trino understands the data's structure and optimizes
                        query processing, leveraging its in-memory and parallel processing capabilities. Hive
                        manages and structures the data, while Trino ensures fast, ad-hoc querying of the same
                        datasets. Trino also connects to PostgreSQL and <TextLink name="oss-opensearch"/> to
                        provide a unified SQL-based data access layer across all our data management systems.
                    </p>
                    <p>
                        Through these technologies, Deasil offers a comprehensive and scalable database and data
                        warehousing suite tailored to meet diverse business needs, all while ensuring cost
                        efficiency through its private cloud and data center infrastructure. Although managed
                        services come at a price, they often offset costs by minimizing downtime, increasing
                        operational efficiency and reducing the need for in-house staff.
                    </p>
                </span>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Databases and Data Warehousing Applications:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-apache-cassandra"/></li>
                            <li><TextLink name="oss-apache-hive"/></li>
                            <li><TextLink name="oss-postgresql"/></li>
                            <li><TextLink name="oss-pgadmin"/></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <h3>Data Services:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="service-data-management-pipelines"/></li>
                            <li><TextLink name="service-etl-elt-operations"/></li>
                            <li><TextLink name="service-data-warehouses"/></li>
                            <li><TextLink name="service-data-lakes"/></li>
                            <li><TextLink name="service-data-visualization-exploration"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default DatabasesDataWarehousing;
