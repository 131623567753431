import React from 'react';
import Obfuscate from 'react-obfuscate';
import TextLink from '../components/TextLink';

const LegalContact = ({title}) => {
    return (
        <>
            <h2>Contact Us</h2>
            <p>
                If you have any questions about {title}, You can contact us:
            </p>
            <p>
                By email: <Obfuscate email="info@deasil.works" aria-label="Email">info@deasil.works</Obfuscate>
            </p>
            <p>
                By visiting this page on our
                website: <TextLink name="contact" text="https://deasil.works/contact"/>
            </p>
            <p>
                By phone number: <Obfuscate tel="818-945-0821" aria-label="Phone">818-945-0821</Obfuscate>
            </p>
        </>
    )
};

export default LegalContact;
