import * as React from 'react';
import ClientLogos from '../components/ClientLogos';
import Hero from '../components/Hero';
import Icon from '../components/Icon';
import TextLink from '../components/TextLink';

const HomePage = () => {
    return (
        <>
            <Hero page="home" classes="has-overlay"/>

            <section className="home bg bg-white overlay-top mb-1">
                <h3 className="mb-0">
                    Deasil is an in-house development company with full-service project management,
                    planning, design, development, and integration.
                </h3>
            </section>

            <section className="bg bg-secondary full mt-4 mb-0 pb-4">
                <div className="cols mb-3">
                    <div className="panel bg-white col-6 mr-1 ml-1">
                        <h3 className="center">Custom Software</h3>
                        <p>
                            We develop software for any platform and design custom platforms for any
                            domain&mdash;innovative custom solutions, for online, automotive, manufacturing,
                            academia, retail, marketing, and entertainment.
                        </p>
                    </div>
                    <div className="panel bg-white col-6 mr-1 ml-1">
                        <h3 className="center">Hosting & Infrastructure</h3>
                        <p>
                            Deasil owns and operates secure Data Center space in Los Angeles and Pasadena,
                            California; Las Vegas, Nevada; and Denver, Colorado. Call us to discuss
                            competitive pricing and the numerous advantages of private cloud hosting.
                        </p>
                    </div>
                </div>
            </section>

            <section className="bg bg-primary overlay-top mt-0 mb-0 pb-2">
                <h2>Custom Solutions</h2>
                <div className="cols">
                    <div className="panel col-4 bg-primary mb-2 mr-1 ml-1">
                        <Icon img="icon-Seal-Quality" name="Proven Expertise" classes="center mb-2"/>
                        <h3>25 Years of Experience</h3>
                        <p className="mb-1">
                            Our decades of experience, especially in
                            the <TextLink name="our-history" text="early days"/> of digital transformation, gives
                            us a unique edge in understanding and solving modern-day challenges.
                        </p>
                    </div>
                    <div className="panel col-4 bg-primary mb-2 mr-1 ml-1">
                        <Icon img="icon-Code-Engineering" name="Custom Solutions" classes="center mb-2"/>
                        <h3>Tailored Services</h3>
                        <p className="mb-1">
                            We do not rely on one-size-fits-all solutions. Instead, we
                            provide <TextLink name="service-custom-software-development"
                                              text="tailored services"/> best suited to your needs.
                        </p>
                    </div>
                    <div className="panel col-4 bg-primary mb-2 mr-1 ml-1">
                        <Icon img="icon-Profits-Chart" name="Cost-Effective" classes="center mb-2"/>
                        <h3>Cost-Effective</h3>
                        <p className="mb-1">
                            With our
                            extensive <TextLink name="service-hosting-managed-infrastructure"
                                                text="infrastructure and data center presence"/>, we
                            promise massive savings without compromising on performance.
                        </p>
                    </div>
                </div>

                <div className="center mb-2">
                    <TextLink name="contact" text="Contact Us" button="light center"/>
                </div>

                <div className="panel bg-primary mt-3 mb-2 mr-1 ml-1">
                    <h3>Vector Database Hosting and Management Services</h3>
                    <p>
                        Access the full potential of your vector data. Deasil Works specializes in high-quality
                        vector database hosting and management solutions specifically to meet your unique
                        requirements.
                    </p>
                    <div className="center mb-2">
                        <TextLink name="service-vector-database-hosting-management-services" text="Learn More"
                                  button="light center"/>
                    </div>
                </div>
            </section>

            <section className="bg-white full pt-2 pb-2">
                <ClientLogos/>
            </section>

            <section className="bg full">
                <h3 className="center mb-3">
                    We develop innovative custom solutions for any platform and design custom platforms for any
                    domain.
                </h3>
                <div className="panel cols mb-2">
                    <ul className="service-links mr-1 ml-1 mb-2">
                        <li><h4 className="inline"><TextLink name="industry-creative-agencies"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-manufacturing"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-retail"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-ecommerce"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-automotive"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-transportation"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-logistics"/></h4></li>
                    </ul>
                    <ul className="service-links mr-1 ml-1 mb-2">
                        <li><h4 className="inline"><TextLink name="industry-energy"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-academia"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-entertainment"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-entertainment"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-nonprofit"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-government"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-data-brokers"/></h4></li>
                    </ul>
                    <ul className="service-links mr-1 ml-1 mb-2">
                        <li><h4 className="inline"><TextLink name="industry-health"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-food-beverage"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-music"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-broadcasting"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-banking-finance"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-mortgage"/></h4></li>
                        <li><h4 className="inline"><TextLink name="industry-gaming"/></h4></li>
                    </ul>
                </div>
            </section>
        </>
    )
};

export default HomePage;
