import * as React from 'react';
import Hero from '../../components/Hero';
import Background from '../../components/Background';
import ContactBlock from '../../components/ContactBlock';
import ServicePanel from '../../components/ServicePanel';
import TextLink from '../../components/TextLink';

const Transportation = () => {
    return (
        <>
            <Hero page="industry-transportation" classes="has-overlay"/>

            <section className="bg bg-primary overlay-top mb-4">
                <p>
                    With over 25 years of experience in the software and infrastructure domain, Deasil is a pivotal
                    force in custom software development and managed services. Deasil's forte lies in its innovative
                    approach, powered by an array of <TextLink name="service-open-source-expertise"
                                                               text="enterprise-grade open source software solutions"/>, <TextLink
                    name="service-hosting-managed-infrastructure" text="bare-metal infrastructure"/>,
                    and <TextLink name="service-custom-software-development" text="custom software development"/>. When
                    viewed through the prism of the transportation sector, Deasil's offerings transform logistical
                    challenges into streamlined processes, harnessing the power of technology to fuel efficiency,
                    security, and growth.

                </p>
                <p className="mb-0">
                    The transportation industry has been undergoing rapid digital transformation, driven by increasing
                    customer demands, emerging technologies, and the need for more efficient and sustainable operations.
                    Deasil provides solutions aimed at many of the significant needs of the transportation industry when
                    it comes to digital transformation.

                </p>
            </section>

            <div className="divider spacer pt-2"></div>

            <section className="bg bg-primary full">
                <Background path="bg-Hexagons-bw.jpg" opacity={0.14}/>

                <h2>Improving Transportation</h2>

                <ul className="service-links light">
                    <li className="mb-2">
                        <strong>Real-time Data and Tracking</strong>: Transportation companies require real-time data
                        about vehicle locations, routes, and cargo status to enhance operational efficiency and improve
                        customer service. With our expertise in IoT, mobile, and web services, Deasil develops custom
                        software solutions that enable real-time tracking and data access.
                    </li>
                    <li className="mb-2">
                        <strong>Process Automation</strong>: Deasil automates manual and repetitive tasks for improved
                        efficiency and reduced error rates. Deasil offers process automation solutions, helping
                        transportation companies streamline operations and reduce overhead costs.
                    </li>
                    <li className="mb-2">
                        <strong>Integration of Systems</strong>: Integration of various systems is vital for the
                        seamless operations of transportation organizations, from ticketing to cargo management. With
                        our Data Integration & ETL expertise, Deasil ensures that different systems within the
                        transportation industry can communicate effectively and operate cohesively.
                    </li>
                    <li className="mb-2">
                        <strong>Enhanced Data Security</strong>: The transportation industry deals with vast amounts of
                        sensitive data, from customer information to cargo details. Deasil ensures robust data security
                        and access controls by offering solutions
                        in <TextLink name="oss-identity-access-management"/> and utilizing enterprise-grade OSS
                        solutions like <TextLink name="oss-keycloak"/>.
                    </li>
                    <li className="mb-2">
                        <strong>Scalable Infrastructure</strong>: As transportation companies grow, they need scalable
                        IT infrastructure to handle increased data and operations. Deasil
                        provides <TextLink name="service-hosting-managed-infrastructure"
                                           text="hosting, managed infrastructure"/>, and container orchestration
                        services, making it easy for companies to scale without the hefty costs of public cloud
                        offerings.
                    </li>
                    <li className="mb-2">
                        <strong>Data Visualization & Decision Making</strong>: Transportation companies need clear
                        visualizations of data for informed decision-making. With expertise
                        in <TextLink name="oss-data-visualization-business-intelligence"/> and tools
                        like <TextLink name="oss-apache-superset"/>, Deasil helps businesses gain insights from
                        their data.
                    </li>
                    <li className="mb-2">
                        <strong>Improved Customer Experience</strong>: With the rise of digital platforms, customers
                        expect seamless digital experiences when using transportation services. Deasil's expertise
                        in <TextLink name="service-seo-analytics" text="SEO, performance analytics"/>,
                        and <TextLink name="service-custom-software-development"
                                      text="custom software development"/> allows them to craft optimal user
                        experiences across various digital touchpoints.
                    </li>
                    <li className="mb-2">
                        <strong>Advanced Analytics and Forecasting</strong>: Accurate demand forecasting, route
                        optimization, and predictive maintenance are essential for transportation providers. Deasil's
                        capabilities
                        in <TextLink name="service-data-science-environments" text="data science"/> and <TextLink
                        name="service-machine-learning-infrastructure" text="machine learning platforms"/>, combined
                        with their expertise in <TextLink name="oss-databases-data-warehousing"
                                                          text="databases and data warehousing"/>, enable them to
                        provide advanced analytics solutions tailored to the transportation industry's requirements.
                    </li>
                </ul>
            </section>

            <section className="bg full cols wrap">
                <h2>Deasil's Platform Services and Custom Development</h2>

                <ServicePanel
                    name="service-machine-learning-infrastructure"
                    children={<>Deasil architects and manages cutting-edge machine learning infrastructure and
                        empowers transportation businesses to harness AI for logistical predictions, demand
                        forecasting, and route optimizations, driving efficiency and cost savings.</>
                    }
                />
                <ServicePanel
                    name="service-data-science-environments"
                    children={<>Harnessing the power of tools
                        including <TextLink name="oss-jupyterhub"/>, Deasil sets up comprehensive data science
                        environments. For transportation companies, this means
                        harnessing <TextLink name="service-machine-learning-infrastructure"
                                             text="AI and machine learning"/> for predictive maintenance, route
                        optimization, and demand forecasting.</>
                    }
                />
                <ServicePanel
                    name="service-high-availability-performance-engineering"
                    children={<>Ensuring systems are always operational is a Deasil hallmark. In transportation,
                        where time is often of the essence, Deasil's commitment to high availability and
                        performance engineering ensures businesses face minimal downtime and consistently deliver
                        on their service promises.</>
                    }
                />
                <ServicePanel
                    name="service-mobile-applications"
                    children={<>Deasil's prowess in mobile application development provides transportation businesses
                        with on-the-go solutions for real-time tracking, route optimization, and customer engagement.
                        These applications enhance transparency, boost client trust, and allow for rapid response
                        to real-time logistical adjustments.</>
                    }
                />
                <ServicePanel
                    name="service-custom-data-platforms"
                    children={<>Deasil designs bespoke data platforms tailored to unique business specifications. For
                        the transportation industry, these platforms are invaluable, offering real-time insights
                        into vehicle data, shipment tracking, and logistical forecasting. By leveraging these custom
                        platforms, transportation operations can predict bottlenecks, enhance efficiency, and
                        respond proactively to dynamic challenges.</>
                    }
                />
                <ServicePanel
                    name="service-sso-iam"
                    children={<>Deasil champions secure access and identity protection with robust Single Sign-On
                        (SSO) and Identity and Access Management solutions using tools
                        like <TextLink name="oss-keycloak"/>. Within transportation, these services fortify data
                        access, ensuring that sensitive logistical data remains in trusted hands and streamlining
                        authentication processes across multifaceted operational landscapes.</>
                    }
                />
                <ServicePanel
                    name="service-open-source-expertise"
                    children={<>Deasil expertly manages and deploys enterprise-grade open-source software solutions,
                        such as <TextLink name="oss-apache-cassandra"/>, <TextLink name="oss-apache-nifi"/>,
                        and <TextLink name="oss-kubernetes"/>. Within the transportation sector, this mastery ensures
                        that logistical and operations systems run on scalable, cost-effective platforms. By
                        harnessing the power of <TextLink name="service-open-source-expertise"
                                                          text="open-source tools"/>, transportation entities can
                        seamlessly adapt to changing landscapes, optimize route efficiencies, and minimize operational
                        overheads.</>
                    }
                />
                <ServicePanel
                    name="service-custom-software-development"
                    children={<>Deasil crafts tailored software solutions precisely tuned to individual business
                        needs. For transportation entities, this means bespoke tools that optimize route planning,
                        streamline cargo management, and automate routine tasks. These custom solutions empower
                        transportation businesses to improve service quality and operational agility.</>
                    }
                />
                <ServicePanel
                    name="service-hosting-managed-infrastructure"
                    children={<>Deasil leverages its vast infrastructure and data center capabilities to offer
                        unparalleled hosting and managed infrastructure services. Transportation businesses benefit
                        from high uptime, reliable data access, and robust system performance, ensuring seamless
                        operations even during peak demand.</>
                    }
                />
                <ServicePanel
                    name="service-compute-resources"
                    children={<>Deasil provisions a versatile range of computing resources, from bare metal servers
                        to intricate multi-cloud environments, ensuring scalability, agility, and performance for
                        the transportation sector. It allows businesses to adjust their computing resources per
                        demand surges and technological advancements.</>
                    }
                />
                <ServicePanel
                    name="service-data-center-network-operations"
                    children={<>Deasil operates state-of-the-art data centers underpinned by a robust network
                        infrastructure, which translates to high-speed data access, minimal latency, and seamless
                        operations integration across global locations for transportation businesses.</>
                    }
                />
                <ServicePanel
                    name="service-data-management-pipelines"
                    children={<>Deasil crafts efficient data management strategies and establishes streamlined
                        data pipelines. In transportation, this ensures that real-time data from sensors, GPS systems,
                        and other sources flow seamlessly, empowering businesses with up-to-the-minute insights.</>
                    }
                />
                <ServicePanel
                    name="service-etl-elt-operations"
                    children={<>Deasil specializes in ETL (Extract, Transform, Load) and ELT (Extract, Load,
                        Transform) operations, ensuring data is efficiently harvested, processed, and stored.
                        Transportation-based businesses can thus glean insights from vast data sets, driving
                        informed decision-making.</>
                    }
                />
                <ServicePanel
                    name="service-data-warehouses"
                    children={<>Deasil architects robust data warehouses, centralizing data storage and ensuring
                        easy retrieval. In transportation, this centralized data repository aids in comprehensive
                        analysis, enhancing operational foresight and strategy formulation.</>
                    }
                />
                <ServicePanel
                    name="service-data-lakes"
                    children={<>Harnessing tools like <TextLink name="oss-apache-hive"/>, Deasil creates expansive
                        data lakes, accommodating structured and unstructured data and providing a holistic view
                        of operations for transportation entities, from vehicle health data to customer feedback.</>
                    }
                />
                <ServicePanel
                    name="service-data-visualization-exploration"
                    children={<>Deasil empowers transportation businesses with intuitive data visualization tools
                        like <TextLink name="oss-apache-superset"/>, transforming raw data into visual insights so
                        companies can quickly grasp operational trends, challenges, and opportunities.</>
                    }
                />
                <ServicePanel
                    name="service-platform-data-migration"
                    children={<>Deasil facilitates seamless platform and data migrations, ensuring minimal operational
                        disruption, system upgrades, and transitions occur smoothly for the transportation sector,
                        keeping pace with technological evolution.</>
                    }
                />
                <ServicePanel
                    name="service-private-custom-clouds"
                    children={<>Avoiding generic public cloud offerings, Deasil provisions private and custom
                        cloud solutions. Transportation businesses benefit from tailored cloud environments
                        offering security and performance optimization.</>
                    }
                />
                <ServicePanel
                    name="service-legacy-systems-integration"
                    children={<>Deasil specializes in integrating legacy systems with modern infrastructure. For
                        transportation companies, this means leveraging existing investments while benefiting from
                        contemporary technological advancements.</>
                    }
                />
                <ServicePanel
                    name="service-digital-transformation"
                    children={<>Lastly, Deasil spearheads digital transformation initiatives, enabling
                        transportation businesses to transition from traditional operations to digitized,
                        automated, and AI-driven processes, paving the way for a future-ready and resilient
                        transportation industry.</>
                    }
                />
            </section>

            <ContactBlock label="Contact Deasil"/>
        </>
    )
};

export default Transportation;
