import * as React from 'react';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import TextLink from '../../components/TextLink';

const MachineLearningInfrastructure = () => {
    return (
        <>
            <Hero page="service-machine-learning-infrastructure"/>

            <section className="bg full">
                <div className="cols">
                    <Icon img="icon-Machine-Learning" name="Machine Learning Infrastructure" classes="primary"/>
                    <div>
                        <p>
                            In today's rapidly evolving technological landscape, Machine Learning (ML) has moved from
                            experimental to essential. For organizations striving to harness the power of data, a
                            robust machine-learning infrastructure is not just a luxury; it's a necessity.
                        </p>
                        <p className="mb-0">
                            However, designing, setting up, customizing, and managing such an infrastructure in-house
                            requires a highly specialized skillset not typically part of the training and experience
                            of data scientists, analysts, and developers. While public cloud providers offer many
                            packages of MLOps solutions as a service, they often come at a high price, lack
                            customization, and promote vendor lock-in.
                        </p>
                    </div>
                </div>
            </section>

            <section className="bg bg-primary full pt-2 pb-2">
                <h2>Why Open Standards and Avoiding Vendor Lock-In Matter at Deasil</h2>
                <p>
                    Vendor lock-in occurs when an organization relies on proprietary solutions controlled by a third
                    party, making it difficult and expensive to switch to another vendor later. The proprietary
                    components of Deasil's systems keep our networks and infrastructure reliable and performant
                    behind the scenes without locking in our clients.
                </p>
                <p>
                    Adopting open standards plays a crucial role in the fast-paced world of Machine Learning Operations
                    (MLOps). Deasil implements open standards and guidelines that are publicly available and
                    implementable by anyone, which facilitates interoperability, data exchange, and collaborative
                    development among different technologies and vendors. Open standards are pivotal in creating an
                    ecosystem that encourages innovation, enabling various tools, platforms, and systems to work while
                    remaining highly portable.
                </p>
            </section>

            <section className="bg bg-secondary mt-2 mb-2">
                <h2>Choose Deasil for Machine Learning Infrastructure</h2>

                <ul className="service-links light">
                    <li className="mb-1">
                        <span className="text-large"><strong>Expertise</strong></span><br/>
                        <p className="ml-2">
                            With over twenty-five years of experience designing, configuring, and managing private cloud
                            infrastructure, Deasil is no stranger to handling complex workloads. Over the past five
                            years, we've leveraged our decades of experience to offer MLOps solutions, seamlessly
                            extending our platform capabilities into data science and machine learning across many
                            industries.
                        </p>
                    </li>
                    <li className="mb-1">
                        <span className="text-large"><strong>Cost-Effective Solutions</strong></span><br/>
                        <p className="ml-2">
                            Deasil utilizes NVIDIA GPUs from its private,
                            <TextLink name="service-data-center-network-operations" text="secure data centers"/>. Unlike
                            other providers who mark up prices by reselling public cloud services, we own and manage our
                            infrastructure, resulting in more cost-effective solutions without compromising performance
                            or security.
                        </p>
                    </li>
                    <li className="mb-1">
                        <span className="text-large"><strong>Containerization</strong></span><br/>
                        <p className="ml-2">
                            Containerization packages an application and its dependencies into a "container," so that
                            it runs uniformly and consistently across different computing environments. At Deasil, our
                            expertise in managing intricate workloads
                            utilizing <TextLink name="oss-container-management-infrastructure"
                                                text="containerization"/> allows us to integrate
                            your ML pipeline elegantly, optimizing the various stages of model development,
                            training, and deployment.
                        </p>
                    </li>
                    <li className="mb-1">
                        <span className="text-large"><strong>The Power of Kubernetes</strong></span><br/>
                        <p className="ml-2">
                            <TextLink name="oss-kubernetes"/> is an orchestration platform that automates containerized
                            applications' deployment, scaling, network, security, and management. Deasil's years of
                            experience managing distributed systems with Kubernetes enable us to provide a robust
                            solution that can scale dynamically with your needs, offering high availability and fault
                            tolerance for your ML models and data science development environments.
                        </p>
                    </li>
                    <li className="mb-1">
                        <span className="text-large"><strong>Leveraging Seldon Core for MLOps</strong></span><br/>
                        <p className="ml-2">
                            Seldon Core is an open-source platform that makes deploying, scaling, and managing machine
                            learning models easier. With Deasil at the helm, you can fully exploit the benefits of
                            Seldon Core's capabilities, such as A/B testing, multi-armed bandits, and complex inference
                            graphs. Our in-depth knowledge and experience in big
                            data, <TextLink name="service-data-warehouses" text="data warehouses"/>, <TextLink
                            name="service-data-lakes" text="data lakes"/>, distributed <TextLink
                            name="oss-query-search-engines" text="query engines"/>, and analytics further
                            enhance the utility of Seldon Core, making your machine-learning models easier to train,
                            more accurate, reliable, and efficient.
                        </p>
                    </li>
                    <li className="mb-1">
                        <span className="text-large"><strong>Integrated Approach</strong></span><br/>
                        <p className="ml-2">
                            We bring our cross-disciplinary expertise to your MLOps pipeline, providing an integrated
                            solution incorporating containerization, Kubernetes, Seldon Core, and
                            many other utilities. This cohesive approach ensures smoother transitions between stages,
                            from data collection to model training and inference.
                        </p>
                    </li>
                    <li className="mb-1">
                        <span className="text-large"><strong>Enhanced Security and Compliance</strong></span><br/>
                        <p className="ml-2">
                            Utilizing containerization and Kubernetes within our secure, private data centers ensures
                            that your machine-learning models are agile and conform to the highest security standards.
                            Our security compliance and monitoring means you can focus on innovation without worrying
                            about data breaches or compliance violations.
                        </p>
                    </li>
                    <li className="mb-1">
                        <span className="text-large"><strong>Proven Reliability</strong></span><br/>
                        <p className="ml-2">
                            Our rich history of managing complex systems means we are well-equipped to handle the
                            complexities of deploying and managing a modern, containerized MLOps pipeline. When you
                            choose Deasil, you choose a partner with a track record of excellence in managing
                            mission-critical applications and services.
                        </p>
                    </li>
                    <li className="mb-1">
                        <span className="text-large"><strong>Cost-Effective Scalability</strong></span><br/>
                        <p className="ml-2">
                            Because we own and manage our infrastructure, scaling up as your needs grow doesn't mean
                            your costs will skyrocket. This cost-effective scalability ensures that your MLOps pipeline
                            can adapt and evolve without breaking the bank. Unlike public clouds, Deasil does not hide
                            or obscure fees and typically provides fixed monthly or annual costs.
                        </p>
                    </li>
                </ul>
            </section>

            <section className="bg bg-secondary full pt-3">
                <div className="panel bg-white">
                    <h2>Advantages and Features of Deasil Managed Seldon Core</h2>
                    <p>
                        Deasil brings expertise and experience to Legacy Systems Integration and offers invaluable
                        services
                        that address the complexities of modernizing these systems.
                    </p>

                    <table className="bg-light mb-4">
                        <thead>
                        <tr>
                            <th>Feature</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <span className="service-links nowrap strong">A/B Testing & Multi-Armed Bandits</span>
                            </td>
                            <td>
                                Seldon Core facilitates advanced experimentation like A/B testing and multi-armed bandit
                                algorithms, enabling you to optimize your models based on real-world performance.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Advanced Inference Graphs</span></td>
                            <td>
                                The platform supports complex inference graphs, which allow you to combine multiple
                                models and transformers to create sophisticated machine-learning pipelines.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Cost-Effectiveness</span></td>
                            <td>
                                By leveraging open-source software and integrating
                                with <TextLink name="oss-kubernetes"/>, Seldon Core provides a cost-effective way to
                                manage machine learning models at scale.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className="service-links nowrap strong">Customization with Pre- and Post-Processing</span>
                            </td>
                            <td>
                                You can easily add custom pre-processing and post-processing steps to your models,
                                making them more versatile and adaptable to specific business requirements.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data-Driven Optimization</span></td>
                            <td>
                                With its advanced testing and monitoring features, Seldon Core enables you to
                                continually optimize your models based on empirical data, ensuring they deliver the best
                                possible performance.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Enhanced Security</span></td>
                            <td>
                                The built-in security features make deploying models in sensitive or regulated
                                environments easier, providing peace of mind alongside performance.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Flexibility and Freedom</span></td>
                            <td>
                                Its multi-framework support and customization features mean you are not locked into any
                                particular technology, offering greater freedom in your MLOps choices.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Model Deployment & Orchestration</span>
                            </td>
                            <td>
                                Seldon Core provides a streamlined mechanism for deploying machine learning models onto
                                Kubernetes clusters. It enables the seamless orchestration of microservices that
                                comprise your ML models.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Model Monitoring & Analytics</span></td>
                            <td>
                                Seldon Core offers robust monitoring features, providing critical metrics like latency,
                                error rates, and throughput for deployed models, which are essential for ongoing
                                optimization.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Multi-Framework Support</span></td>
                            <td>
                                It's compatible with various machine learning frameworks like TensorFlow, PyTorch, and
                                Scikit-Learn, allowing you to work with the tools you're most comfortable with.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Rapid Experimentation</span></td>
                            <td>
                                Its support for complex inference graphs and A/B testing allows for rapid
                                experimentation, helping you quickly identify the most effective models and
                                configurations.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">REST and gRPC Endpoints</span></td>
                            <td>
                                It provides REST and gRPC endpoints, offering flexibility in integrating deployed models
                                into existing systems or applications.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Scalability & High Availability</span>
                            </td>
                            <td>
                                With native Kubernetes integration, Seldon Core provides out-of-the-box scalability and
                                high availability for your ML models, ensuring they meet performance criteria regardless
                                of load.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Security and Compliance</span></td>
                            <td>
                                By utilizing built-in authentication and encryption, Seldon Core ensures that your
                                models are secure and compliant with industry regulations.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Streamlined Operations</span></td>
                            <td>
                                Seldon Core simplifies the deployment and management of machine learning models, making
                                your MLOps more efficient.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Vendor Neutrality</span></td>
                            <td>
                                Being an open-source platform, Seldon Core prevents vendor lock-in, aligning with the
                                ethos of open standards in technology.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
};

export default MachineLearningInfrastructure;
