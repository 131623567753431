import * as React from 'react';
import Hero from '../../components/Hero';
import Background from '../../components/Background';
import ContactBlock from '../../components/ContactBlock';
import ServicePanel from '../../components/ServicePanel';
import TextLink from '../../components/TextLink';

const Retail = () => {
    return (
        <>
            <Hero page="industry-retail" classes="has-overlay"/>

            <section className="bg bg-primary overlay-top mb-4">
                <p>
                    For over 25 years, Deasil has championed custom software development, managed services, and
                    infrastructure. Working across diverse sectors, from retail to entertainment, Deasil's curated a
                    suite of offerings tailored to businesses of all sizes. Our expertise spans a broad spectrum,
                    from open-source software management to the intricacies of digital transformation. In particular,
                    the retail industry has immensely benefited from Deasil's innovative solutions, driving efficiency,
                    security, and growth.

                </p>
                <p className="mb-0">
                    The retail industry is undergoing a dynamic shift, influenced by the digital age, changing consumer
                    preferences, and a competitive market. Modernization, cost optimization, and customer satisfaction
                    are essential for retailers to maintain and grow their market position. Deasil's comprehensive
                    portfolio, from custom software solutions to data analytics, aligns perfectly with the retail
                    industry's evolving needs. By partnering with Deasil, retailers can modernize their operations,
                    optimize costs, and consistently deliver on customer expectations.
                </p>
            </section>

            <div className="divider spacer pt-2"></div>

            <section className="bg bg-primary full">
                <Background path="bg-Social-Network-Connections-bw.jpg" opacity={0.18}/>

                <h2>Improving Retail</h2>

                <ul className="service-links light">
                    <li className="mb-2">
                        <strong>Omnichannel Experience</strong>: Consumers expect a seamless experience across online,
                        mobile, and brick-and-mortar stores. Deasil's mobile, IoT, and web services expertise ensures an
                        integrated omnichannel shopping experience. Deasil's custom software development can create
                        interconnected platforms for both online and in-store touchpoints.
                    </li>
                    <li className="mb-2">
                        <strong>Data Analytics and Insights</strong>: Retailers must deeply understand customer
                        preferences, purchase history, and behavior to tailor their offerings. Through data
                        visualization, business intelligence tools like <TextLink name="oss-apache-superset"/>, and
                        data warehousing, Deasil helps retailers gain actionable insights from their data.
                    </li>
                    <li className="mb-2">
                        <strong>Inventory Management</strong>: Efficient and real-time inventory management is essential
                        to minimize stockouts or overstock situations. Deasil's custom data platforms and process
                        automation streamline inventory management, ensuring accurate stock levels across all channels.
                    </li>
                    <li className="mb-2">
                        <strong>Cost Optimization</strong>: Retailers seek to minimize overheads without compromising on
                        service quality. By leveraging its extensive infrastructure and data center presence, Deasil
                        offers cost-effective hosting and managed infrastructure services, bypassing the costs of
                        overpriced public cloud solutions.
                    </li>
                    <li className="mb-2">
                        <strong>Personalized Shopping Experience</strong>: Customers expect customized product
                        recommendations, marketing communications, and promotions. Utilizing data science and machine
                        learning platforms, Deasil can enable retailers to offer hyper-personalized shopping experiences
                        to their customers.
                    </li>
                    <li className="mb-2">
                        <strong>Secure Transactions and Data Protection</strong>: Ensuring customer transactional data's
                        safety and securing personal information is paramount. With Identity & Access Management tools
                        like <TextLink name="oss-keycloak"/>, Deasil provides robust security measures to protect
                        customer data.
                    </li>
                    <li className="mb-2">
                        <strong>Interactive In-Store Experiences</strong>: Brick-and-mortar stores integrate tech
                        solutions, including advanced kiosks and IoT devices, for enriched shopping experiences.
                        Deasil's Interactive Computing and development Environments expertise can assist retailers in
                        deploying cutting-edge tech in their physical stores.
                    </li>
                    <li className="mb-2">
                        <strong>Supply Chain Efficiency</strong>: Streamlined supply chains ensure timely product
                        availability and reduce costs. Process automation and custom software solutions help optimize
                        the supply chain, making it more responsive and efficient.
                    </li>
                    <li className="mb-2">
                        <strong>SEO and Online Presence</strong>: With the growth of online shopping, retailers need to
                        be visible and easily discoverable on search engines.
                        Deasil's <TextLink name="service-seo-analytics" text="SEO and performance analytics"/> services
                        can boost retailers' online presence, driving more traffic and sales.
                    </li>
                    <li className="mb-2">
                        <strong>Easy Integration with Third-Party Platforms</strong>: Retailers must often integrate
                        with third-party platforms like payment gateways, CRM systems, or marketing tools.
                        Deasil's <TextLink name="oss-data-integration-etl" text="Data
                        Integration & ETL expertise"/> ensures seamless integration with various third-party platforms,
                        enhancing operational efficiency.
                    </li>
                    <li className="mb-2">
                        <strong>Real-time Customer Support</strong>: Modern consumers expect instant support, be it
                        chatbots, helplines, or self-help portals. Deasil's custom software development capability can
                        implement real-time customer support tools and platforms, ensuring customers get timely
                        assistance.
                    </li>
                </ul>
            </section>

            <section className="bg full cols wrap">
                <h2>Deasil's Platform Services and Custom Development</h2>

                <ServicePanel
                    name="service-hosting-managed-infrastructure"
                    children={<>Deasil leverages its vast infrastructure and data center capabilities to offer premier
                        hosting and managed infrastructure services, bypassing the need for overpriced public cloud
                        offerings. Retailers benefit by ensuring a reliable online presence, saving substantially, and
                        maintaining exceptional site performance.</>
                    }
                />
                <ServicePanel
                    name="service-data-visualization-exploration"
                    children={<>Leveraging tools like <TextLink name="oss-apache-superset"/>, Deasil transforms data
                        into visually compelling insights. Retailers use these visualizations for intuitive data
                        exploration, driving strategies in sales, inventory management, and customer engagement.</>
                    }
                />
                <ServicePanel
                    name="service-platform-data-migration"
                    children={<>Deasil provides a seamless transition through its platform and data migration services.
                        Retailers aiming for system upgrades trust Deasil's expertise to ensure business continuity and
                        data integrity.</>
                    }
                />
                <ServicePanel
                    name="service-data-center-network-operations"
                    children={<>With its robust data center and network operations, Deasil guarantees seamless and
                        secure processes for digital frameworks. Retailers rely on this service to uphold a consistent
                        online presence, keeping them always accessible to their customer base.</>
                    }
                />
                <ServicePanel
                    name="service-custom-data-platforms"
                    children={<>Deasil crafts bespoke data platforms tailored to individual business needs. Retailers
                        utilize these custom platforms to assimilate large volumes of data, gaining insights into
                        inventory management, sales forecasting, and customer behavior. This data-driven approach
                        enables retail businesses to make informed decisions, streamline operations, and boost
                        profitability.</>
                    }
                />
                <ServicePanel
                    name="service-custom-software-development"
                    children={<>Harnessing its in-house expertise, Deasil produces tailored software solutions for
                        diverse platforms, including IoT and mobile. Retailers collaborate with Deasil to create
                        applications that enhance the shopping experience, foster customer loyalty, and open new
                        avenues for sales and engagement.</>
                    }
                />
                <ServicePanel
                    name="service-mobile-applications"
                    children={<>Deasil excels in designing user-centric mobile applications, including highly custom
                        Point-of-Sale solutions. In partnership with Deasil, retailers roll out apps that amplify
                        customer engagement, offer personalized promotions, and gather crucial data on shopping
                        behaviors, leading to increased sales and stronger brand loyalty.</>
                    }
                />
                <ServicePanel
                    name="service-seo-analytics"
                    children={<>Deasil propels businesses to greater online visibility with its sophisticated SEO and
                        analytics services. Retailers, backed by Deasil's insights, optimize their online strategies to
                        attract more customers and discern market trends.</>
                    }
                />
                <ServicePanel
                    name="service-data-management-pipelines"
                    children={<>Deasil specializes in establishing streamlined data management systems and efficient
                        pipelines. Retailers leverage these systems for quick insights, optimized inventory flow, and
                        crafting data-driven strategies.</>
                    }
                />
                <ServicePanel
                    name="service-data-science-environments"
                    children={<>Deasil sets up cutting-edge data science environments
                        like <TextLink name="oss-jupyterhub"/>. Retailers delve into these platforms for advanced
                        analytics, deriving insights on market trends, customer segmentation, and sales
                        optimization.</>
                    }
                />
                <ServicePanel
                    name="service-open-source-expertise"
                    children={<>Deasil excels in managing enterprise-grade open-source software (OSS) solutions,
                        such as <TextLink name="oss-apache-cassandra"/> and <TextLink name="oss-kubernetes"/>. The
                        retail industry leverages Deasil's OSS expertise to deploy systems that are scalable,
                        reliable, and cost-effective, aiding retailers in enhancing operational efficiency,
                        reducing costs, and seamlessly adapting to market changes.</>
                    }
                />
                <ServicePanel
                    name="service-etl-elt-operations"
                    children={<>With proficiency in ETL and ELT processes, Deasil ensures seamless data integration. By
                        partnering with Deasil, retailers can unify their varied data sources, including existing ERP
                        and CRM systems, gleaning a holistic view of operations and market behaviors.</>
                    }
                />
                <ServicePanel
                    name="service-data-warehouses"
                    children={<>Deasil equips businesses with robust data warehouses by constructing centralized data
                        repositories. Retailers benefit from this consolidation, drawing comprehensive insights and
                        ensuring data consistency, aiding decision-making processes.</>
                    }
                />
                <ServicePanel
                    name="service-data-lakes"
                    children={<>Deasil creates expansive data lakes to house structured and unstructured data.
                        Retailers plumb these depths for advanced analytics, enabling predictive modeling and trend
                        analysis.</>
                    }
                />
                <ServicePanel
                    name="service-digital-transformation"
                    children={<>Deasil is at the forefront of guiding businesses through digital transformation. In
                        collaboration with Deasil, retailers reinvent their operational frameworks, harnessing digital
                        tools to optimize every facet, from customer experiences to supply chain management.</>
                    }
                />
            </section>

            <ContactBlock label="Contact Deasil"/>
        </>
    )
};

export default Retail;
