import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const OpenSearchDashboards = () => {
    return (
        <>
            <Hero page="oss-opensearch-dashboards"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/OpenSearch-logo-dark.svg" name="OpenSearch" classes="logo client mr-2"/>
                    <p>
                        <TextLink name="oss-opensearch"/> Dashboards originated as a fork of Kibana when Amazon Web
                        Services (AWS) decided to
                        create the OpenSearch project, an open-source fork of Elasticsearch and Kibana. This decision
                        emerged due to concerns about the licensing changes made by Elastic, the company behind
                        Elasticsearch and Kibana.
                    </p>
                </div>
                <div className="cols">
                    <div>
                        <p>
                            OpenSearch Dashboards, like its counterpart OpenSearch, maintains the
                            Apache 2.0 open-source license, ensuring that the software remains free and open
                            for the community.
                        </p>
                        <p>
                            OpenSearch Dashboards serve as a crucial component in the OpenSearch ecosystem, offering
                            users a visual interface to interact with their indexed data. It allows users to create
                            dynamic dashboards, visualize data trends, and explore datasets in real time. The tool's
                            extensibility, through plugins and integrations, enhances its adaptability to varied use
                            cases, cementing its importance in data analysis, monitoring, and operational intelligence
                            spaces. The continued commitment to open source means businesses can leverage OpenSearch
                            Dashboards without concerns about licensing changes or restrictions.
                        </p>
                    </div>
                    <Image path="logos/OpenSearch-Dashboards-screenshot.png" name="OpenSearch Dashboards screenshot"
                           classes="ml-2" style={{width: 320, height: '100%'}}/>
                </div>

            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <h2>Deasil's Custom Data Platforms</h2>
                    <p>
                        Deasil crafts comprehensive data platforms by strategically harnessing the capabilities of
                        OpenSearch Dashboard and OpenSearch. OpenSearch Dashboard offers a user-friendly visual
                        interface, enabling businesses to interact dynamically with indexed data, craft insightful
                        visualizations, and generate real-time dashboards. When coupled with OpenSearch, a powerful
                        full-text search and analytics engine, clients benefit from an advanced data exploration
                        environment capable of rapidly processing large datasets. Together, these tools form the
                        cornerstone of Deasil's data visualization solutions, serving diverse industries ranging from
                        retail to energy.
                    </p>
                    <p>
                        To further enhance data integration and processing, Deasil
                        integrates <TextLink name="oss-apache-nifi"/>, a robust data flow automation tool,
                        and <TextLink name="oss-apache-superset"/> with <TextLink name="oss-trino"/>, a modern
                        SQL query engine. This combination ensures seamless data movement, transformation, and analytics
                        at scale. By leveraging <TextLink name="oss-postgresql"/> for relational data
                        management, <TextLink name="oss-apache-cassandra"/> for high availability and scalability,
                        and <TextLink name="oss-apache-hive"/> for data warehousing needs, Deasil offers a diverse
                        storage and processing ecosystem tailor-made for specific client requirements.
                        Including <TextLink name="oss-jupyterhub"/> facilitates interactive computing and development.
                        At the same time, <TextLink name="oss-keycloak"/> manages identity and access across all
                        platform components, ensuring that the data remains secure and accessible only to authorized
                        personnel.
                    </p>
                    <p>
                        Finally, all these components orchestrate within robust Kubernetes clusters atop Deasil's
                        private infrastructure. Kubernetes ensures that all services remain scalable, reliable, and
                        efficiently managed. By hosting on our private infrastructure, Deasil guarantees enhanced
                        security, cost savings, and performance optimization, free from the constraints and costs
                        associated with public cloud platforms.
                    </p>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Related Services and Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-opensearch"/></li>
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-apache-nifi"/></li>
                            <li><TextLink name="oss-postgresql"/></li>
                            <li><TextLink name="oss-apache-superset"/></li>
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-open-source-expertise"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default OpenSearchDashboards;
