import * as React from 'react';
import {Link} from 'react-router-dom';
import Background from '../../components/Background';
import ContactBlock from '../../components/ContactBlock';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import ServicePanel from '../../components/ServicePanel';
import TextLink from '../../components/TextLink';

const Manufacturing = () => {
    return (
        <>
            <Hero page="industry-manufacturing" classes="has-overlay"/>

            <section className="bg bg-primary overlay-top mb-4">
                <p>
                    Deasil's comprehensive range of services, extensive experience, and open-source expertise equips
                    manufacturers with the tools and technologies to optimize their operations, enhance data-driven
                    decision-making, and stay competitive in a rapidly evolving industry.
                </p>
                <p className="mb-0">
                    With its intricate processes, procedures, and vast supply chains, the manufacturing industry has
                    seen a growing need for digitization and modernization. Incorporating the latest technologies,
                    optimizing costs, and meeting evolving customer expectations are pivotal to its success.
                </p>
            </section>

            <div className="divider spacer pt-2"></div>

            <section className="bg bg-primary full">
                <Background path="bg-Geometric-Big-Data-bw.jpg" opacity={0.12}/>

                <h2>Improving Manufacturing</h2>

                <ul className="service-links light">
                    <li className="mb-2">
                        <strong>Smart Manufacturing & IoT Integration</strong>: Manufacturing units increasingly want
                        to incorporate IoT devices for real-time monitoring, predictive maintenance, and process
                        optimization. Deasil's expertise in IoT and custom software development can facilitate the
                        seamless integration of IoT devices, enabling more intelligent manufacturing processes.
                    </li>
                    <li className="mb-2">
                        <strong>Data-Driven Decision-Making</strong>: Manufacturers need to harness the power of data to
                        gain insights, optimize production, and predict market demands. Deasil's data science, machine
                        learning platforms, and data visualization & BI tools can help manufacturers derive actionable
                        insights from vast datasets.
                    </li>
                    <li className="mb-2">
                        <strong>Supply Chain Optimization</strong>: Modern supply chains must be flexible, transparent,
                        and responsive to dynamic market conditions. Process automation and custom data platforms
                        offered by Deasil can enhance supply chain management, ensuring timely production and
                        distribution.
                    </li>
                    <li className="mb-2">
                        <strong>Cost-Efficient Infrastructure</strong>: Manufacturers want to leverage cloud
                        infrastructure without the hefty costs associated with traditional public cloud offerings.
                        Utilizing its data center presence, Deasil offers cost-effective hosting and managed
                        infrastructure services, ensuring performance without breaking the bank.
                    </li>
                    <li className="mb-2">
                        <strong>Real-time Monitoring & Quality Control</strong>: Ensuring consistent product quality
                        requires real-time monitoring and instant feedback mechanisms. Deasil's IoT, data platforms,
                        and performance analytics can facilitate real-time monitoring and quality checks.
                    </li>
                    <li className="mb-2">
                        <strong>Integration with External Systems</strong>: Manufacturers often use various external
                        systems, from ERP to CRM, and need them to work in unison. Deasil's Data Integration & ETL
                        capabilities ensure smooth integration with multiple third-party systems.
                    </li>
                    <li className="mb-2">
                        <strong>Customer-Centric Production</strong>: As market demands change rapidly, manufacturers
                        need to be agile and adapt their production lines based on customer needs. Using data analytics
                        and business intelligence tools, Deasil helps manufacturers better understand market trends
                        and customer preferences.
                    </li>
                    <li className="mb-2">
                        <strong>Cybersecurity & Data Protection</strong>: Protecting sensitive data and intellectual
                        property and maintaining regulatory compliance are crucial. Identity & Access Management tools
                        like Keycloak, combined with Deasil's expertise in OSS solutions, ensure top-notch security
                        for manufacturers.
                    </li>
                    <li className="mb-2">
                        <strong>Digital Workspaces & Collaboration</strong>: In an increasingly globalized world,
                        manufacturing units and teams need digital platforms for collaboration. Deasil's Interactive
                        Computing & Development Environments can create digital media facilitating seamless
                        collaboration.
                    </li>
                    <li className="mb-2">
                        <strong>Automation & Process Optimization</strong>: To remain competitive, manufacturers must
                        continuously optimize processes and reduce manual interventions. Deasil's expertise in process
                        automation and its custom software development capabilities can lead to streamlined operations
                        and reduced overheads.
                    </li>
                </ul>
            </section>

            <section className="bg full cols wrap">
                <h2>Deasil's Platform Services and Custom Development</h2>

                <ServicePanel
                    name="service-data-science-environments"
                    children={<>Deasil creates data science environments for manufacturers, enabling them to leverage
                        advanced analytics and machine learning to optimize production processes, reduce waste,
                        and enhance product quality.</>
                    }
                />
                <ServicePanel
                    name="service-high-availability-performance-engineering"
                    children={<>Deasil prioritizes high availability and performance engineering to guarantee
                        manufacturers uninterrupted access to critical systems and data, ensuring that production
                        lines and operations remain efficient and responsive.</>
                    }
                />
                <ServicePanel
                    name="service-private-custom-clouds"
                    children={<>Deasil designs private and custom cloud solutions for manufacturing businesses,
                        providing the flexibility and security to manage their data and applications
                        effectively. This tailored approach enables manufacturers to optimize their cloud
                        resources and reduce costs.</>
                    }
                />
                <ServicePanel
                    name="service-machine-learning-infrastructure"
                    children={<>Deasil offers machine learning infrastructure solutions for manufacturers, enabling
                        them to harness the power of artificial intelligence and predictive analytics to improve
                        quality control, demand forecasting, and production efficiency.</>
                    }
                />
                <ServicePanel
                    name="service-custom-data-platforms"
                    children={<>Deasil specializes in developing custom data platforms tailored to the unique needs of
                        manufacturers. These platforms empower manufacturers to collect, store, and analyze
                        data from various sources, enabling real-time monitoring, predictive maintenance, and
                        quality control. By offering tailored data solutions, Deasil assists manufacturers in
                        optimizing their processes, reducing downtime, and achieving better production outcomes.</>
                    }
                />
                <ServicePanel
                    name="service-legacy-systems-integration"
                    children={<>Deasil specializes in integrating legacy systems with modern technology stacks,
                        ensuring manufacturers can leverage their existing investments while adopting new, innovative
                        solutions. This integration enhances operational efficiency and reduces the complexity
                        of managing legacy systems.</>
                    }
                />
                <ServicePanel
                    name="service-open-source-expertise"
                    children={<>Deasil leverages its expertise in open-source solutions such
                        as <TextLink name="oss-apache-cassandra"/>, <TextLink name="oss-apache-nifi"/>
                        , <TextLink name="oss-apache-superset"/>, and more to benefit the manufacturing
                        industry by providing cost-effective, scalable, and reliable data management and analytics
                        solutions. By implementing open-source technologies, Deasil helps manufacturers
                        streamline their data processes, improve data visibility, and make informed decisions,
                        ultimately enhancing operational efficiency and product quality.</>
                    }
                />
                <ServicePanel
                    name="service-sso-iam"
                    children={<>Deasil ensures robust Single Sign-On (SSO) and Identity and Access Management (IAM)
                        solutions for the manufacturing sector, leveraging enterprise-grade systems,
                        including <TextLink name="oss-keycloak"/>. Deasil helps manufacturers protect sensitive
                        data and intellectual property by implementing secure authentication and access control
                        mechanisms, enhancing data security and streamlining user access, and facilitating
                        smoother operations and collaboration within manufacturing organizations.</>
                    }
                />
                <ServicePanel
                    name="service-custom-software-development"
                    children={<>Deasil's services cater to manufacturing businesses by creating bespoke applications
                        to address specific challenges in production, inventory management, and supply chain
                        optimization. These tailored solutions improve process efficiency, reduce errors, and
                        enhance overall productivity in the manufacturing industry.</>
                    }
                />
                <ServicePanel
                    name="service-mobile-applications"
                    children={<>Deasil excels in developing mobile applications for manufacturers, to remotely access
                        critical data and perform tasks. This mobility enhances real-time decision-making,
                        empowers field workers, and facilitates efficient communication, which is invaluable in
                        the fast-paced manufacturing environment.</>
                    }
                />
                <ServicePanel
                    name="service-hosting-managed-infrastructure"
                    children={<>Deasil offers hosting and managed infrastructure services that
                        provide high availability, scalability, and performance for
                        manufacturing businesses. Deasil helps manufacturers minimize downtime, improve
                        reliability, and focus on their core operations by hosting mission-critical applications
                        and data.</>
                    }
                />
                <ServicePanel
                    name="service-compute-resources"
                    children={<>Deasil provides manufacturers with flexible computing resources, ranging from bare
                        metal servers to multi-cloud solutions. This versatility allows manufacturers to scale their
                        IT infrastructure according to their evolving needs, optimizing resource allocation and
                        cost-efficiency.</>
                    }
                />
                <ServicePanel
                    name="service-seo-analytics"
                    children={<>Deasil assists manufacturing businesses in improving their online visibility through
                        search engine optimization (SEO) and performance analytics, helping manufacturers
                        attract new customers, increase brand recognition, and stay competitive in the digital
                        marketplace.</>
                    }
                />
                <ServicePanel
                    name="service-data-management-pipelines"
                    children={<>Deasil's expertise in data management and pipelines helps manufacturing organizations
                        collect, process, and transport data efficiently, enabling better decision-making,
                        quality control, and predictive maintenance in the manufacturing sector.</>
                    }
                />
                <ServicePanel
                    name="service-etl-elt-operations"
                    children={<>Deasil's ETL (Extract, Transform, Load) and ELT (Extract, Load, Transform) operations
                        ensure that manufacturing data is cleansed, transformed, and ready for analysis.
                        This streamlines data integration and enhances data quality, facilitating data-driven
                        decision-making.</>
                    }
                />
                <div className="panel cols col-6">
                    <Icon img="icon-Code-Engineering"
                          name="Data Warehouses, Data Lakes, Data Visualization, and Exploration"
                          classes="primary medium"/>
                    <span>
                        <h3>
                            <Link to="/service/data-warehouses">Data Warehouses</Link>, <Link
                            to="/service/data-lakes">Data Lakes</Link>, <Link
                            to="/service/data-visualization-exploration">Data Visualization</Link>, and <Link
                            to="/service/data-visualization-exploration">Exploration</Link>
                        </h3>
                        <p className="text-small mb-2">
                            Deasil builds data warehouses and data lakes for manufacturers, providing centralized
                            data storage and robust visualization tools, allowing manufacturing organizations to
                            gain valuable insights, explore data trends, and make data-driven decisions for improved
                            efficiency and profitability.
                        </p>
                    </span>
                </div>
                <ServicePanel
                    name="service-platform-data-migration"
                    children={<>Deasil offers manufacturers platform and data migration services, ensuring a seamless
                        transition to new technologies and infrastructure, minimizing disruption and downtime,
                        and allowing manufacturers to maintain operational continuity.</>
                    }
                />
                <ServicePanel
                    name="service-digital-transformation"
                    children={<>Deasil drives digital transformation initiatives for manufacturing organizations by
                        modernizing their IT infrastructure, optimizing data management processes, and
                        implementing innovative technologies. This transformation enhances competitiveness,
                        agility, and overall business success in the manufacturing industry.</>
                    }
                />
            </section>

            <ContactBlock label="Contact Deasil"/>
        </>
    )
};

export default Manufacturing;
