import * as React from 'react';
import Hero from '../components/Hero';

const OurHistory = () => {

    return (
        <>
            <Hero page="our-history"/>

            <section className="bg bg-light full">
                <div className="panel">
                    <h2>Our History</h2>
                    <p>
                        Deasil's principals and core team have worked together since the 1990s! We have been innovating
                        since the beginning of the many eras of modern digital transformation, contributing to the early
                        years of <strong>Disney.com</strong>, to Research and Development and animation at the highly
                        disruptive <strong>Napster</strong>, and a handful
                        of <strong>Universal</strong> and <strong>Sony</strong> subsidiaries. We spent our early days on
                        the bleeding edge, in the open green fields of new media and disruptive technology. As a result,
                        our veins have equal parts infrastructure and software. We built our own distributed services
                        and expanded our bare-metal data center presence more than a decade before they called it a
                        cloud, not to sell cloud services but to solve nearly impossible matrix problems presented by
                        an unrelenting surge in demand for digital transformation, mobile, and data solutions.
                    </p>
                </div>
                <div className="panel">
                    <h2>Supporting the Entertainment Industry</h2>
                    <p>
                        Headquartered in the heart of the entertainment industry in the early 2000s, we became a key
                        innovator for some of the biggest stars looking to harness the booming internet, many of
                        which included <strong>U2</strong>, <strong>Black Eyed Peas</strong>, <strong>No
                        Doubt</strong>, <strong>Nine Inch Nails</strong>, and <strong>Beyoncé</strong>. Suddenly, we
                        faced the challenge of accommodating the data and communication demands of a global fan base
                        flocking to the internet to interact with their favorite artists and brands. Google was just
                        getting started, along with socials MySpace and Facebook. From massive international data
                        processing for <strong>Disney</strong> to pre-Youtube live video streaming, the team gained
                        experience developing technologies in demand across industries.
                    </p>
                </div>
                <div className="panel">
                    <h2>Enhancing the Major Platforms</h2>
                    <p>
                        While individual artists and brands were looking to innovate online in the early 2000s,
                        content providers such as <strong>Sony</strong>, <strong>Universal</strong>, <strong>Electronic
                        Arts</strong>, <strong>Warner Brothers</strong>, and <strong>NBC</strong> were expanding
                        their in-house development capabilities. As a result, they needed experienced platform
                        architects as they transformed themselves from content providers to content platforms.
                        Our team developed NBC.com's first software framework, powering its content platform and
                        unifying internal resources and development operations. In addition, we developed many
                        imaginative applications atop NBC's platform, including the first web applications
                        implementing real-time synchronization with broadcast events.
                    </p>
                </div>
                <div className="panel">
                    <h2>Handling Enterprise Data</h2>
                    <p>
                        With a mature and growing skillset, the team translated its early success with high-traffic,
                        big data, distributed computing, and platform development to solve problems for many
                        traditionally data-heavy enterprises, including logistics, automotive, retail, and
                        manufacturing. Our clients expanded from L.A. and Silicon Valley to the midwest as more
                        conventional industries sought to harness digital transformation into contemporary
                        technologies at the internet's scale.
                    </p>
                    <p>
                        For example, Deasil developed several solutions for a logistics client looking to expand
                        their capabilities beyond the archaic systems available at the time; this led to additional
                        projects for a top automaker looking to provide deeper access to their extensive parts
                        catalog to tens of thousands of independent service centers. In addition, a national
                        retailer engaged us to build a unique private-cloud, highly-available retail management
                        system in use by thousands of locations nationwide, designed explicitly for rapid scaling
                        over seasonal sales rushes, along with special features for the franchise and independent
                        operator management, a custom predicate language for complex coupon development,
                        multi-tier loyalty points system, and highly complex sales reporting, comparisons, and
                        forecasting.
                    </p>
                    <p>
                        From video content platforms to retail management, our first decade of projects firmly
                        established our mastery of distributed computing, moving, storing, and presenting data in
                        all its forms. In the following decade, our toolset and experience with data communication,
                        extraction, transformation, and management grew deeper.
                    </p>
                </div>
                <div className="panel">
                    <h2>The Language of Machines</h2>
                    <p>
                        Artificial Intelligence and Machine Learning are captivating popular media these days,
                        mainly due to the last decade of advances in infrastructure and automation. While many ideas
                        and algorithms have existed for nearly a century, they were challenging to develop, refine
                        and implement, primarily due to the complex and costly infrastructure and environments
                        needed to experiment, create and automate.
                    </p>
                    <p>
                        In 2010 the team began refining our infrastructure management systems, leading to several
                        solutions that fit the needs of our data science clients in academia and, later, the
                        energy industry, including advanced custom data science environments and our
                        own <a href="https://gpulab.io/" title="GPULab" aria-label="GPULab - Opens in new tab"
                               target="_blank"
                               rel="noopener noreferrer">GPULab</a> and <a href="https://d4l.dev/" title="D4L"
                                                                           aria-label="D4L - Opens in new tab"
                                                                           target="_blank"
                                                                           rel="noopener noreferrer">D4L</a> data
                        platforms.
                        From traditional ETL and data warehousing to inference and generative AI, we are ready
                        with the skills and toolset to solve your business' next move.
                    </p>
                </div>
            </section>
        </>
    )
};

export default OurHistory;
