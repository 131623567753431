import * as React from 'react';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const DataVisualizationExploration = () => {
    return (
        <>
            <Hero page="service-data-visualization-exploration"/>

            <section className="bg full pb-1">
                <div className="cols">
                    <Icon img="icon-Infographic" name="Data Visualization and Exploration" classes="primary"/>
                    <div>
                        <p className="mb-0">
                            At Deasil, we understand the importance of data-driven decisions in today's fast-paced,
                            competitive business landscape. That's why we offer comprehensive Data Visualization and
                            Exploration services anchored by the robust capabilities
                            of <TextLink name="oss-apache-superset"/>.
                        </p>
                    </div>
                </div>
            </section>

            <section className="bg bg-primary full">
                <div className="cols mb-2">
                    <div>
                        <h2>What is <TextLink name="oss-apache-superset"/>?</h2>
                        <p>
                            Apache Superset is an open-source data exploration and visualization platform designed to be
                            intuitive and interactive. It enables users to explore data using SQL and create and share a
                            range
                            of data visualizations, from simple line graphs to intricate geospatial analyses.
                        </p>
                    </div>
                    <Image path="logos/Apache-Superset-logo-light.svg" name="Apache Superset Logo"
                           classes="logo client ml-2"/>
                </div>

                <h3>Why Choose Deasil's Integrated Data Platform with Apache Superset?</h3>

                <div className="panel bg-white">
                    <table className="bg-light mb-4">
                        <thead>
                        <tr>
                            <th>Attribute</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Consultation and Support</span></td>
                            <td>
                                Deasil is a hands-on, full-service software development company with data analysts and
                                integrators ready to take on your visualization project anytime.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Customization</span></td>
                            <td>
                                The flexibility of Apache Superset enables you to tailor dashboards and reports to
                                meet the unique needs of your business.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Aggregation</span></td>
                            <td>
                                Deasil's integrated data platform harmonizes data from multiple sources, including
                                relational databases, object storage, and external APIs, enabling a unified view of
                                your business metrics.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Real-time Insights</span></td>
                            <td>
                                With a distributed query engine, gain real-time insights from your data, allowing
                                quicker, more informed decisions.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Scalability</span></td>
                            <td>
                                Deasil's platform, designed for businesses of all sizes, seamlessly scales alongside
                                your data needs without compromising performance or security.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Training</span></td>
                            <td>
                                Deasil offers in-depth training on all aspects of its data platform and custom
                                components.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <h3>Advantages of Apache Superset:</h3>

                <div className="panel bg-white">
                    <table className="bg-light mb-4">
                        <thead>
                        <tr>
                            <th>Attribute</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Ease of Use</span></td>
                            <td>
                                The intuitive interface makes it easy for non-technical users to create and explore
                                visualizations.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Open Source</span></td>
                            <td>
                                Being open-source means a community of developers continuously contributes to its
                                improvement, ensuring you benefit from the latest features and security updates.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Security</span></td>
                            <td>
                                Integrated with
                                Deasil's <TextLink name="service-sso-iam"
                                                   text="SSO and enterprise identity and access management"/> solution,
                                Apache superset provides built-in role-based access control. It ensures your data is
                                protected and accessible only by authorized users.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Speed</span></td>
                            <td>
                                Apache Superset easily handles large data sets, enabling fast query performance and data
                                visualization.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
};

export default DataVisualizationExploration;
