import * as React from 'react';
import {useLocation} from 'react-router-dom';
import {JsonLd} from 'react-schemaorg';
import {getPageContentFromPath} from './functions';

/**
 * Handle analytics events on route change or custom events
 *
 */
export const StructuredData = () => {
    const {pathname} = useLocation();

    const assembleJSON = (content) => {
        let json = {
            "@content": "https://schema.org",
            "description": ""
        };
        if (content.path === '/' || content.path === '/contact') {
            // Homepage, Contact page

            json["@type"] = 'Organization';
            json.title = 'Deasil Works';
            json.logo = window.location.origin + '/images/Deasil-Logo.png';
            json.address = {
                "@type": 'PostalAddress',
                streetAddress: '121 W. Lexington Drive',
                addressLocality: 'Glendale',
                addressRegion: 'CA',
                postalCode: '91203',
                addressCountry: 'USA'
            };
            json.telephone = '(818) 945-0821';

        } else if (content.path.indexOf('/case-study') !== -1) {
            // Case Study Articles

            json["@type"] = 'Article';
            json.title = content.title;
            json.headline = content.title;
            if (content.name) {
                json.name = content.name;
            }
            json.url = window.location.origin + pathname;
            json.publisher = {
                "@type": 'Organization',
                name: 'Deasil Works',
                logo: window.location.origin + '/images/Deasil-Logo.png'
            };
            if (content.schemaDescription) {
                json.description = content.schemaDescription.replace(/\n/g, '').trim();
            } else {
                json.description = 'Case Study of ' + content.title;
            }
            json.datePublished = content?.published ?? '2023-10-30';
            json.dateModified = content?.modified ?? '2023-10-30';
            const schemaImage = content?.schemaImage ?? null;
            if (schemaImage) {
                if (typeof schemaImage === 'object' && schemaImage.length > 0) {
                    // Add an array of images
                    json.image = [];
                    schemaImage.forEach(function (item) {
                        json.image.push(item);
                    });
                } else if (typeof schemaImage === 'string') {
                    // Add a single image
                    json.image = window.location.origin + '/images/' + schemaImage;
                }
            }
        } else {
            // All other pages

            json["@type"] = 'WebPage';
            json.title = content.title;
        }
        return json;
    };

    const content = getPageContentFromPath(pathname);
    const schema = (content) ? assembleJSON(content) : null;

    if (!content) {
        return (
            <>
            </>
        )
    }

    return (
        <>
            <JsonLd item={schema}/>
        </>
    );
};
