import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import TextLink from './TextLink';
import closeIcon from '../assets/images/icon-close.svg';
import menuIcon from '../assets/images/icon-menu.svg';

const MainNavigation = () => {

    const [mobileOpen, setMobileOpen] = useState(false);
    const [hovered, setHovered] = useState(null);

    const handleMenuHover = (el, hovering) => {
        setHovered((hovering) ? el : null);
    };

    const handleMobileMenuClick = () => {
        setMobileOpen(!mobileOpen);
    };

    const openClass = (mobileOpen) ? ' open' : '';

    return (
        <nav className={`nav-main${openClass}`}>
            <button type="button" className="menu-icon" onClick={() => handleMobileMenuClick()}>
                <img src={menuIcon} className="menu" alt="Menu"/>
                <img src={closeIcon} className="close" alt="Close"/>
            </button>

            {/* Menu: Mobile */}
            <ul className="nav-mobile" onClick={() => handleMobileMenuClick()}>
                <li><Link to="home" title="Home">Home</Link></li>
                <li>
                    <Link to="work" title="Work" aria-haspopup={true} aria-expanded={true}>Work</Link>
                    <ul>
                        <li>
                            <TextLink name="industry"/>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="services" title="Services" aria-haspopup={true} aria-expanded={true}>Services</Link>
                    <ul>
                        <li>
                            <TextLink name="service"/>
                        </li>
                        <li>
                            <TextLink name="oss"/>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="about" title="About" aria-haspopup={true} aria-expanded={true}>About</Link>
                    <ul>
                        <li>
                            <Link to="about/our-history" title="Our History">Our History</Link>
                            <Link to="about/team" title="The Deasil Team">The Deasil Team</Link>
                        </li>
                    </ul>
                </li>
                <li><Link to="contact" title="Contact">Contact</Link></li>
            </ul>

            {/* Menu: Desktop */}
            <ul className="mobile-hide">
                <li>
                    <Link to="work" title="Work"
                          className="has-submenu"
                          aria-haspopup={true}
                          aria-expanded={(hovered === 'work')}
                          onMouseEnter={() => handleMenuHover('work', true)}
                          onMouseLeave={() => handleMenuHover('work', false)}
                    >
                        Work
                    </Link>
                    <ul className="mega-menu">
                        <li>
                            <div className="submenu-section">
                                <span className="submenu-header">
                                    <TextLink name="industry"/>
                                </span>
                                <TextLink name="industry-creative-agencies"/>
                                <TextLink name="industry-manufacturing"/>
                                <TextLink name="industry-retail"/>
                                <TextLink name="industry-ecommerce"/>
                                <TextLink name="industry-automotive"/>
                                <TextLink name="industry-transportation"/>
                                <TextLink name="industry-logistics"/>
                            </div>
                        </li>
                        <li>
                            <div className="submenu-section">
                                <span className="submenu-header">&nbsp;</span>
                                <TextLink name="industry-energy"/>
                                <TextLink name="industry-academia"/>
                                <TextLink name="industry-entertainment"/>
                                <TextLink name="industry-nonprofit"/>
                                <TextLink name="industry-government"/>
                                <TextLink name="industry-data-brokers"/>
                                <TextLink name="industry-health"/>
                            </div>
                        </li>
                        <li>
                            <div className="submenu-section">
                                <span className="submenu-header">&nbsp;</span>
                                <TextLink name="industry-food-beverage"/>
                                <TextLink name="industry-music"/>
                                <TextLink name="industry-broadcasting"/>
                                <TextLink name="industry-banking-finance"/>
                                <TextLink name="industry-mortgage"/>
                                <TextLink name="industry-gaming"/>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="services" title="Services"
                          className="has-submenu"
                          aria-haspopup={true}
                          aria-expanded={(hovered === 'services')}
                          onMouseEnter={() => handleMenuHover('services', true)}
                          onMouseLeave={() => handleMenuHover('services', false)}
                    >
                        Services
                    </Link>
                    <ul className="mega-menu">
                        <li className="has-border">
                            <div className="submenu-section bg-light">
                                <span className="submenu-header">
                                    <TextLink name="service"/>
                                </span>
                                <TextLink name="service-open-source-expertise"/>
                                <TextLink name="service-custom-data-platforms"/>
                                <TextLink name="service-sso-iam"/>
                                <TextLink name="service-custom-software-development"/>
                                <TextLink name="service-mobile-applications"/>
                                <TextLink name="service-hosting-managed-infrastructure"/>
                                <TextLink name="service-compute-resources"/>
                                <TextLink name="service-data-center-network-operations"/>
                                <TextLink name="service-devops"/>
                                <TextLink name="service-high-availability-performance-engineering"/>
                                <TextLink name="service-seo-analytics"/>
                                <TextLink name="service-data-management-pipelines"/>
                                <TextLink name="service-data-science-environments"/>
                                <TextLink name="service-etl-elt-operations"/>
                                <TextLink name="service-data-warehouses"/>
                                <TextLink name="service-data-lakes"/>
                                <TextLink name="service-vector-database-hosting-management-services"/>
                                <TextLink name="service-data-visualization-exploration"/>
                                <TextLink name="service-platform-data-migration"/>
                                <TextLink name="service-private-custom-clouds"/>
                                <TextLink name="service-legacy-systems-integration"/>
                                <TextLink name="service-machine-learning-infrastructure"/>
                                <TextLink name="service-digital-transformation"/>
                            </div>
                        </li>
                        <li>
                            <div className="submenu-section">
                                <span className="submenu-header">
                                    <TextLink name="oss"/>
                                </span>
                                <TextLink name="oss-databases-data-warehousing"/>
                                <TextLink name="oss-data-integration-etl"/>
                                <TextLink name="oss-data-visualization-business-intelligence"/>
                                <TextLink name="oss-distributed-storage-systems"/>
                                <TextLink name="oss-query-search-engines"/>
                                <TextLink name="oss-identity-access-management"/>
                                <TextLink name="oss-interactive-computing-development-environments"/>
                                <TextLink name="oss-container-management-infrastructure"/>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="about" title="About"
                          className="has-submenu"
                          aria-haspopup={true}
                          aria-expanded={(hovered === 'about')}
                          onMouseEnter={() => handleMenuHover('about', true)}
                          onMouseLeave={() => handleMenuHover('about', false)}
                    >
                        About
                    </Link>
                    <ul>
                        <li><Link to="about/our-history" title="Our History">Our History</Link></li>
                        <li><Link to="about/team" title="The Deasil Team">The Deasil Team</Link></li>
                    </ul>
                </li>
                <li><Link to="contact" title="Contact">Contact</Link></li>
            </ul>
        </nav>
    )
};

export default MainNavigation;
