import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const JupyterHub = () => {
    return (
        <>
            <Hero page="oss-jupyterhub"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/JupyterHub-logo.svg" name="JupyterHub" classes="logo client mr-2"/>
                    <div>
                        <p>
                            Deasil leverages our extensive expertise
                            in <TextLink name="service-custom-software-development"
                                         text="custom software development"/> and <TextLink
                            name="service-hosting-managed-infrastructure" text="managed services"/> to offer
                            managed JupyterHub environments to our clients, enabling seamless multi-user access to
                            computational notebooks. These environments inherently
                            support <TextLink name="oss-jupyterlab"/>, the next-generation interface for the
                            Jupyter ecosystem, so users benefit from an advanced, interactive web-based platform for
                            data science, machine learning, and analytics tasks. By providing managed JupyterHub and
                            JupyterLab solutions, Deasil simplifies the deployment and scaling challenges organizations
                            often face, allowing them to focus on deriving insights and value from their data.
                            Furthermore, Deasil's commitment to utilizing
                            our <TextLink
                            name="service-data-center-network-operations"
                            text="robust infrastructure and data centers"/> ensures clients receive top-tier performance
                            at a fraction of the cost of traditional public cloud offerings.
                        </p>
                    </div>
                </div>
                <p>
                    Organizations today grapple with immense volumes of data, requiring robust tools to manage,
                    analyze, and extract actionable intelligence. A primary challenge they face is establishing a
                    consistent and collaborative environment for multiple data users. Disconnected tools previously
                    hampered real-time collaboration, code sharing, and data interpretation. JupyterHub directly
                    addresses these challenges.
                </p>
                <p>
                    JupyterHub grew out of the broader Jupyter Project, which had its genesis in the IPython project
                    in 2001. Originally, IPython served as an interactive command-line shell for Python. However,
                    the expanding demands of data science and interactive computing drove the creation of Jupyter.
                    Drawing its name from three primary programming
                    languages, <strong>Julia</strong>, <strong>Python</strong>, and <strong>R</strong>, JupyterHub then
                    took a step farther, offering a multi-user server that manages and supports multiple instances
                    of the individual Jupyter notebook server.
                </p>
                <div className="cols centered center-vertical mb-3">
                    <Image path="logos/Python-logo.svg" name="Python" classes="logo client mt-3 mr-2 ml-2"/>
                    <Image path="logos/Julia-logo.svg" name="Julia" classes="logo client small mr-2 ml-2"/>
                    <Image path="logos/R-logo.svg" name="R" classes="logo client x-small mr-2 ml-2"/>
                </div>
                <p>
                    JupyterHub has transformed the data science landscape. Its interactive computing environment
                    enables data scientists to process data, build models, and visualize results in real time.
                    Supporting a transparent and iterative workflow makes exploratory data analysis straightforward
                    and effective. Its adaptability to various programming languages and integration with big data
                    tools further solidify its place in the data science toolkit.
                </p>
                <p>
                    In the realms of analytics and BI, JupyterHub plays a pivotal role. Analysts can integrate code,
                    visual narratives, and explanatory text effortlessly. This capability not only uncovers insights
                    but also conveys them persuasively to decision-makers. Such a comprehensive approach ensures
                    organizations base their decisions on clear, actionable, data-driven insights.
                </p>
                <div className="cols">
                    <p className="mr-2">
                        JupyterHub promotes collaboration and efficiency in data-driven organizations. It enables
                        multiple users to work concurrently on the platform, sharing insights and building upon each
                        other's contributions. This collaboration reduces redundancy and expedites the transformation of
                        raw data into actionable intelligence. Its interactive nature also facilitates swift hypothesis
                        testing and decision-making.
                    </p>
                    <p className="mr-2">
                        JupyterHub excels in its integration capabilities. It can connect to data warehouses, interface
                        with machine learning platforms, and pull data from diverse sources. Such flexibility ensures
                        that organizations like Deasil can craft comprehensive data stories, regardless of their data's
                        origin, maximizing the potential of their data science and analytics efforts.
                    </p>
                </div>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg bg-white mb-0">
                    <h2>Deasil's Custom Data Platforms</h2>
                    <p>
                        At Deasil, JupyterHub is central to our
                        comprehensive <TextLink name="service-data-science-environments" text="data platform"/>.
                        JupyterHub allows our users to experience interactive computing in a multi-user environment,
                        giving them the tools they need to create, share, and collaborate on Jupyter notebooks. By
                        deeply integrating JupyterHub with Kubernetes, we ensure that our clients can scale their
                        operations effortlessly, maintain high resilience, and enjoy the benefits of centralized
                        management.
                    </p>
                    <p>
                        Deasil further complements JupyterHub by integrating it with robust data
                        solutions. <TextLink name="oss-opensearch"/> enhances our platform's search and analytics
                        capabilities, while <TextLink name="oss-trino"/> ensures high-speed SQL querying across
                        distributed datasets. Our incorporation of <TextLink name="oss-postgresql"/> offers a trusted
                        relational data storage solution, and with <TextLink name="oss-apache-cassandra"/>, we provide a
                        scalable, distributed database system. We utilize <TextLink name="oss-apache-nifi"/> to
                        streamline data routing and transformation and rely on <TextLink name="oss-apache-hive"/> to
                        facilitate data warehousing and efficient querying on large-scale data. We've integrated
                        <TextLink name="oss-seaweedfs"/> and <TextLink name="oss-ceph"/> to fortify our storage
                        capabilities, ensuring our distributed storage solutions remain scalable and fail-safe.
                    </p>
                    <p>
                        To provide our clients with a seamless and secure data visualization experience, we've also
                        integrated <TextLink name="oss-apache-superset"/>, a tool that offers intricate visual insights.
                        Deasil's platform's security remains paramount; we incorporate <TextLink name="oss-keycloak"/>,
                        a leading identity and access management solution. Keycloak guarantees governed and secure data
                        access, offering granular control over user permissions and authentications. We deliver a
                        high-performance, cost-effective solution by hosting all these components on our expansive
                        infrastructure and orchestrating them with <TextLink name="oss-kubernetes"/>. By bypassing the
                        high costs of public cloud offerings and leaning on our extensive infrastructure, we're proud to
                        offer our clients unmatched savings without compromising performance.
                    </p>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Related Services and Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-apache-nifi"/></li>
                            <li><TextLink name="oss-postgresql"/></li>
                            <li><TextLink name="oss-apache-superset"/></li>
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-open-source-expertise"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default JupyterHub;
