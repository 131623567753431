import * as React from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as DeasilD} from '../assets/images/Deasil-logo-d-gray.svg';
import defaults from '../defaults';
import '../assets/css/footer.css';

const Footer = () => {
    return (
        <footer>
            <div className="sub-footer">
                <DeasilD className="logo"/>
                <ul className="footer-nav">
                    <li>
                        <Link to="/privacy-policy" title="Privacy Policy">Privacy</Link>
                    </li>
                    <li>
                        <Link to="/terms-conditions" title="Terms & Conditions">Terms</Link>
                    </li>
                    <li>
                        <Link to="/deasil-vs-diesel" title="Not Diesel">Not Diesel</Link>
                    </li>
                </ul>
            </div>
            <div className="bottom-bar">
                <span className="copyright">
                    &copy; {defaults.copyright}
                </span>
            </div>
        </footer>
    )
};

export default Footer;
