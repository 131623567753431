import * as React from 'react';
import {Link} from 'react-router-dom';
import Background from '../../components/Background';
import ContactBlock from '../../components/ContactBlock';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import ServicePanel from '../../components/ServicePanel';
import TextLink from '../../components/TextLink';

const Entertainment = () => {
    return (
        <>
            <Hero page="industry-entertainment" classes="has-overlay"/>

            <section className="bg bg-primary overlay-top mb-4">
                <p className="mb-0">
                    Deasil stands at the pinnacle
                    of <TextLink name="service-custom-software-development" text="custom software development"/>,
                    managed services, hosting, and infrastructure, with over a quarter of a century of business
                    experience under our belt. Deasil's versatile portfolio caters to various sectors,
                    from <TextLink name="industry-creative-agencies" text="creative agencies"/> to the entertainment
                    industry. With a strong emphasis on in-house development, Deasil champions the cause of utilizing
                    its infrastructure to provide massive savings to its clientele without compromising on performance.
                    By integrating our wide array of services and expertise in
                    managing <TextLink name="oss" text="enterprise-grade OSS solutions"/>, Deasil provides a
                    comprehensive solution to the multifaceted needs of the entertainment industry.
                </p>
            </section>

            <div className="divider spacer pt-2"></div>

            <section className="bg bg-primary full">
                <Background path="bg-Abstract-Polygons-3D-bw.jpg" opacity={0.12}/>

                <h2>Improving Entertainment</h2>

                <ul className="service-links light">
                    <li className="mb-2">
                        <strong>Scalable Digital Platforms</strong>: The entertainment industry often experiences
                        traffic surges, especially during popular show releases or live events. Thus, platforms need to
                        scale accordingly without compromising performance. With
                        its <TextLink name="service-custom-software-development"
                                      text="custom software development capabilities for mobile, IoT, web services, and API back-ends"/>,
                        Deasil creates scalable applications tailored to handle such high traffic. Additionally, using
                        container orchestration tools like <TextLink name="oss-kubernetes"/> ensures smooth scalability.
                    </li>
                    <li className="mb-2">
                        <strong>Data Management and Analytics</strong>: An in-depth understanding of user behaviors,
                        preferences, and trends is vital to producing content that resonates with audiences and
                        optimizing content delivery. Deasil offers custom data platforms, data science, machine learning
                        platforms, and tools like <TextLink name="oss-apache-cassandra"/>, <TextLink name="oss-trino"/>,
                        and <TextLink name="oss-apache-nifi"/>. Deasil's expertise
                        in <TextLink name="oss-data-visualization-business-intelligence"/> helps the industry derive
                        actionable insights from raw data.
                    </li>
                    <li className="mb-2">
                        <strong>Content Distribution and Storage</strong>: Efficiently storing and distributing
                        high-quality content like 4K and 8K videos to global audiences is critical.
                        Through <TextLink name="oss-distributed-storage-systems"/>, tools like the
                        S3-compatible <TextLink name="oss-seaweedfs"/>, and our
                        vast <TextLink name="service-data-center-network-operations" text="data center presence"/>,
                        Deasil ensures quick and efficient content distribution while offering cost-effective storage
                        solutions.
                    </li>
                    <li className="mb-2">
                        <strong>User Experience and Personalization</strong>: Given the variety of content available,
                        delivering personalized content and ensuring an impeccable user experience across platforms is
                        crucial.
                        Deasil's <TextLink name="service-custom-software-development"
                                           text="custom software development"/> services provide platforms that are
                        intuitive and user-friendly. Data analytics tools allow for personalization based on user
                        behavior and preferences.
                    </li>
                    <li className="mb-2">
                        <strong>Security and Access Management</strong>: Protecting valuable intellectual property and
                        ensuring authorized users access only premium content is paramount. With expertise
                        in <TextLink name="oss-identity-access-management"/>, tools like <TextLink name="oss-keycloak"/>,
                        and custom-built security solutions, Deasil provides robust protection of assets and controlled
                        access to premium content.
                    </li>
                    <li className="mb-2">
                        <strong>Efficient Search and Retrieval</strong>: Users must swiftly find their desired content
                        amidst a vast collection. Leveraging <TextLink name="oss-trino" text="Query"/> & Search Engines
                        and tools like <TextLink name="oss-opensearch"/>, Deasil ensures efficient user content
                        discovery.
                    </li>
                </ul>
            </section>

            <section className="bg full cols wrap">
                <h2>Deasil's Platform Services and Custom Development</h2>

                <ServicePanel
                    name="service-high-availability-performance-engineering"
                    children={<>In an industry where downtimes can translate to significant losses, Deasil prioritizes
                        high availability. Its performance engineering services guarantee that entertainment platforms
                        function flawlessly, accommodating millions of concurrent users.</>
                    }
                />
                <ServicePanel
                    name="service-seo-analytics"
                    children={<>Understanding audience behavior is pivotal. Deasil's SEO and analytics tools provide
                        entertainment platforms with insights on viewer preferences, engagement metrics, and search
                        visibility, enabling data-driven decisions for content strategy.</>
                    }
                />
                <ServicePanel
                    name="service-custom-data-platforms"
                    children={<>In the entertainment arena, the correct data can make a vast difference. Deasil
                        architects bespoke data platforms that store and interpret entertainment metrics. From audience
                        preferences to viewing patterns, these platforms provide invaluable insights, ensuring content
                        creators and distributors remain ahead of the curve.</>
                    }
                />
                <ServicePanel
                    name="service-mobile-applications"
                    children={<>Deasil specializes in developing responsive and engaging mobile applications with mobile
                        devices as primary entertainment consumption platforms. These apps are optimized for streaming,
                        content exploration, and interactive experiences, ensuring viewers stay hooked and
                        entertained.</>
                    }
                />
                <ServicePanel
                    name="service-hosting-managed-infrastructure"
                    children={<>Reliability is critical in the entertainment sector. Deasil's vast infrastructure and
                        data center presence ensures that entertainment platforms receive optimal uptime. Coupled with
                        managed services, Deasil guarantees peak performance even during traffic surges, such as movie
                        releases or live events.</>
                    }
                />
                <ServicePanel
                    name="service-sso-iam"
                    children={<>Security and seamless access are paramount in the entertainment sphere. Deasil's
                        deployment of <TextLink name="oss-keycloak"/> ensures that users, viewers, or administrators
                        experience frictionless access with Single Sign-On (SSO) capabilities while maintaining robust
                        security standards to guard sensitive entertainment data.
                    </>
                    }
                />
                <ServicePanel
                    name="service-custom-software-development"
                    children={<>Deasil's in-house software development prowess enables entertainment entities to enjoy
                        tailored solutions. Whether ticketing systems, content management platforms, or streaming
                        interfaces, Deasil crafts unique software that enhances user experience and operational
                        efficiency.</>
                    }
                />
                <ServicePanel
                    name="service-compute-resources"
                    children={<>Deasil flexibly provides resources ranging from bare metal to multi-cloud setups. This
                        adaptability ensures that entertainment platforms can scale resources as needed, accommodating
                        everything from indie releases to blockbuster premieres.</>
                    }
                />
                <ServicePanel
                    name="service-data-center-network-operations"
                    children={<> Leveraging its extensive data center presence, Deasil offers robust network operations
                        for the entertainment industry, resulting in smooth content delivery, low-latency streaming,
                        and a seamless experience for global audiences.</>
                    }
                />
                <ServicePanel
                    name="service-devops"
                    children={<> With a keen focus on continuous integration and delivery, Deasil's DevOps services
                        ensure that entertainment platforms are agile, with quick feature rollouts and efficient bug
                        fixes, leading to minimal downtime and enriched viewer experiences.</>
                    }
                />
                <ServicePanel
                    name="service-data-management-pipelines"
                    children={<>Deasil constructs robust data management systems for the entertainment sector. With
                        structured pipelines, platforms can efficiently process, store, and retrieve data, be it user
                        profiles, content libraries, or viewing histories.</>
                    }
                />
                <ServicePanel
                    name="service-etl-elt-operations"
                    children={<>With efficient Extract, Transform, Load (ETL) and Extract, Load, Transform (ELT)
                        operations, Deasil ensures that entertainment data flows seamlessly across platforms, enhancing
                        data availability and consistency.</>
                    }
                />
                <div className="panel cols col-6">
                    <Icon img="icon-Database-Architecture"
                          name="Data Warehouses, Data Lakes, Data Visualization, and Exploration"
                          classes="primary medium"/>
                    <span>
                        <h3>
                            <Link to="/service/data-warehouses">Data Warehouses</Link>, <Link
                            to="/service/data-lakes">Data Lakes</Link>, <Link
                            to="/service/data-visualization-exploration">Data Visualization</Link>, and <Link
                            to="/service/data-visualization-exploration">Exploration</Link>
                        </h3>
                        <p className="text-small mb-2">
                            Storing massive entertainment datasets is streamlined with Deasil's data warehouses and
                            lakes. With intuitive data visualization tools, stakeholders can easily explore and
                            interpret data, driving informed content and marketing strategies.</p>
                    </span>
                </div>
                <ServicePanel
                    name="service-platform-data-migration"
                    children={<>Deasil's meticulous migration services ensure that entertainment platforms transition
                        data and systems with minimal disruption, whether moving to a new infrastructure or adopting new
                        technologies.</>
                    }
                />
                <ServicePanel
                    name="service-private-custom-clouds"
                    children={<>Deasil offers private and custom cloud solutions for entertainment entities,
                        prioritizing exclusivity and security, ensuring dedicated resources, enhanced security, and
                        tailored configurations, ideal for premium content platforms.</>
                    }
                />
                <ServicePanel
                    name="service-legacy-systems-integration"
                    children={<>Understanding the value of existing systems, Deasil adeptly integrates legacy systems
                        with modern infrastructures. This amalgamation ensures that entertainment platforms benefit from
                        the latest tech without discarding valuable older systems.</>
                    }
                />
                <ServicePanel
                    name="service-machine-learning-infrastructure"
                    children={<>Tapping into the potential of AI, Deasil's machine learning infrastructure enables
                        entertainment platforms to automate content curation, personalize viewer experiences, and
                        forecast industry trends.</>
                    }
                />
                <ServicePanel
                    name="service-digital-transformation"
                    children={<>Deasil champions the cause of digital transformation for the entertainment sector. By
                        adopting the latest technologies and strategies, entertainment platforms can revolutionize
                        their operations, innovatively engage audiences, and stay ahead in the competitive
                        landscape.</>
                    }
                />
                <ServicePanel
                    name="service-data-science-environments"
                    children={<>Deasil's prowess in creating data science environments empowers entertainment entities
                        to leverage machine learning and AI. This results in enhanced content recommendations, audience
                        segmentation, and predictive analytics.</>
                    }
                />
                <ServicePanel
                    name="service-open-source-expertise"
                    children={<>Deasil boasts profound expertise in managing enterprise-grade open-source solutions
                        tailored for the entertainment sector and leveraging tools
                        like <TextLink name="oss-apache-cassandra"/>, <TextLink name="oss-apache-nifi"/>, and <TextLink
                            name="oss-trino"/>. Deasil crafts solutions that ensure scalability and reliability for
                        entertainment platforms, whether streaming services or content distribution networks.</>
                    }
                />
            </section>

            <ContactBlock label="Contact Deasil"/>
        </>
    )
};

export default Entertainment;
