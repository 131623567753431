import * as React from 'react';
import {Link} from 'react-router-dom';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const DataIntegrationETL = () => {
    return (
        <>
            <Hero page="oss-data-integration-etl"/>

            <section className="bg full mb-4 cols">
                <div>
                    <p>
                        Deasil harnesses the capabilities
                        of <TextLink name="oss-apache-nifi"/>, a
                        premier data integration tool (developed by the NSA), to design, control, and monitor
                        data flows. Apache NiFi facilitates scalable and distributed data routing,
                        transformation, and mediation, keeping Deasil's data integration processes streamlined
                        and efficient. Deasilemploys <TextLink name="oss-apache-kafka"/>, an
                        open-source stream-processing platform renowned for handling massive real-time data
                        streaming for real-time ETL tasks and large-scale data transfers across different systems.
                    </p>
                    <p>
                        Deasil packages existing applications, custom software, and dependencies into containers,
                        enabling infrastructure optimization and consistency. Deasil's platform containers
                        encapsulate the complex environments for enterprise applications, ensuring portability
                        and uniformity across different development and deployment
                        stages. <TextLink name="oss-kubernetes"/> provides
                        Deasil with the tools to automate deployment, scaling, and management of the containers,
                        so data integration and ETL tasks operate smoothly with high availability and
                        resilience, irrespective of workload complexities.
                    </p>
                </div>
                <Link to="/managed-oss/apache-nifi" className="logo client ml-2">
                    <Image path="logos/Apache-NiFi-logo.svg" name="Apache NiFi" classes="logo client"/>
                </Link>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Data Integration & ETL Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-apache-nifi"/></li>
                            <li><TextLink name="oss-apache-kafka"/></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <h3>ETL and Data Streaming Services:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="service-etl-elt-operations"/></li>
                            <li><TextLink name="service-data-management-pipelines"/></li>
                            <li><TextLink name="service-platform-data-migration"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-data-science-environments"/></li>
                            <li><TextLink name="service-machine-learning-infrastructure"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default DataIntegrationETL;
