import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {CONTENT_SITE} from '../content/content-site';

/**
 * Load JSON file with environment variables
 *
 * @return {Promise<unknown>}
 */
export async function getEnvironmentConfig() {
    const configURL = process.env.PUBLIC_URL + '/static/config.json';
    return fetch(configURL)
        .then((response) => response.json())
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

/**
 * Handle scrolling to anchor on page load
 *
 */
export const useScrollToLocation = () => {
    const {hash, pathname} = useLocation();
    const scrolledRef = useRef(false);
    const pathRef = useRef(null);
    const hashRef = useRef(hash);

    useEffect(() => {
        if (hash) {
            // Reset reference if hash has changed
            if (hashRef.current !== hash) {
                hashRef.current = hash;
                scrolledRef.current = false;
            }

            // Scroll if scroll has not yet occurred (may have reset if hash changed)
            if (!scrolledRef.current) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({behavior: 'smooth'});
                    scrolledRef.current = true;
                    pathRef.current = pathname;
                }
            }
        } else if (!scrolledRef.current || pathname !== pathRef.current) {
            setTimeout(function () {
                const element = document.getElementById('content');
                element.scrollIntoView({behavior: 'smooth'});
                window.scrollTo({top: 0, behavior: 'smooth'});
            }, 200);
            scrolledRef.current = true;
        }
        pathRef.current = pathname;
    });
};

/**
 * Set page title upon route change
 *
 */
export const useSetPageTitle = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        const setPageTitle = () => {
            const content = getPageContentFromPath(pathname);
            if (content && content.title) {
                document.title = content.title + ' - Deasil';
            }
        };

        return setPageTitle();

    }, [pathname]);
};

/**
 * Retrieve content from content-site file
 *
 * @param path
 */
export const getPageContentFromPath = (path) => {
    const values = Object.values(CONTENT_SITE);
    const pages = values.filter((item) => {
        return item.path === path;
    });
    return (pages && pages.length > 0) ? pages[0] : null;
};

export const createCookie = (name, value, daysToExpire) => {
    const date = new Date();
    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
    document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
};

export const getCookie = (cookieKey) => {
    let cookieName = `${cookieKey}=`;
    let cookieArray = document.cookie.split(';');

    for (let cookie of cookieArray) {
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
};

export const deleteCookie = (name) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

export const deleteGACookies = () => {
    const cookies = document.cookie.split(';');
    cookies.forEach(function (cookie) {
        if (cookie.slice(0, 3) === '_ga' || cookie.slice(0, 4) === ' _ga') {
            deleteCookie(cookie);
        }
    });
};
