import * as React from 'react';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';

const DigitalTransformation = () => {
    return (
        <>
            <Hero page="service-digital-transformation"/>

            <section className="bg full pb-2">
                <h2>Leading the Way in Enterprise Digital Transformation for Over 25 Years</h2>
                <p>
                    Digital transformation is a broad and evolving concept anchored in adopting digital technology to
                    transform services or businesses, by replacing either manual processes with digital processes, or
                    older digital technology with newer digital technology.
                </p>
                <p>
                    With over a quarter-century of hands-on experience, Deasil specializes in harnessing the latest
                    enterprise-grade <TextLink name="service-open-source-expertise"
                                               text="Open Source Software (OSS)"/> and developing customer software to
                    offer tailor-made digital transformation solutions. Our seasoned experts design, configure, and
                    create data platforms that solve your specific challenges, empowering your business to excel in
                    today's data-centric world.
                </p>
                <p>
                    The difference between Deasil and other providers is clear
                    regarding <TextLink name="service-custom-data-platforms"
                                        text="data platforms"/>: We don't repackage overpriced public cloud services.
                    Leveraging our in-house hardware, infrastructure, and data center presence, we build custom,
                    cost-effective data solutions that meet your business needs without breaking the bank. The vast
                    open-source ecosystem has achieved critical momentum, with millions of contributors and thousands of
                    companies backing high-profile OSS applications. Deasil offers best-in-class OSS adoption.
                </p>
                <p className="quote">
                    Deasil configures and integrates the best enterprise-grade OSS applications and custom software for
                    your business.
                </p>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <div className="mb-4">
                        <p>
                            The following is a sampling of Deasil's experience with past and current digital
                            transformation across manufacturing, retail, entertainment, logistics, and transportation
                            industries, and academia:
                        </p>
                    </div>

                    <table className="bg-light mb-3">
                        <thead>
                        <tr>
                            <th>Concept</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="service-links nowrap strong">Collaborative Tools</span></td>
                            <td>
                                Software for easier collaboration between students and faculty.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Content Monetization</span></td>
                            <td>
                                Innovative ways to monetize content through subscription models and virtual goods.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Customer Analytics</span></td>
                            <td>
                                Using data analytics to understand customer behaviors and preferences.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Data Analytics</span></td>
                            <td>
                                Solutions to better understand consumer preferences, peak engagement times, and other
                                metrics to optimize content delivery.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">E-Commerce</span></td>
                            <td>
                                Expanding or enhancing online shopping capabilities.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">E-Learning Platforms</span></td>
                            <td>
                                Digital classrooms, online courses, and interactive learning modules.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">E-Ticketing and Digital Payments</span>
                            </td>
                            <td>
                                Making transactions seamless through digital solutions.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Industry 4.0</span></td>
                            <td>
                                Adoption of IoT devices, smart manufacturing, and integrated systems for more efficient
                                operations.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Inventory Management</span></td>
                            <td>
                                Real-time inventory tracking and automated restocking.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Real-Time Tracking</span></td>
                            <td>
                                GPS and IoT devices for real-time tracking of goods and vehicles.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Route Optimization</span></td>
                            <td>
                                Developing algorithms to optimize passenger and delivery routes.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Social Engagement</span></td>
                            <td>
                                Integrating and automating social media and community features to engage the audience.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Streaming Services</span></td>
                            <td>
                                Providing on-demand content through private and public cloud-based platforms.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Supply Chain Visibility</span></td>
                            <td>
                                Real-time tracking and analytics to optimize supply chain processes.
                            </td>
                        </tr>
                        <tr>
                            <td><span className="service-links nowrap strong">Virtual Labs and Simulations</span></td>
                            <td>
                                Enabling practical experiments and activities in a controlled, digital environment.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
};

export default DigitalTransformation;
