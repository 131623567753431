import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const ApacheNiFi = () => {
    return (
        <>
            <Hero page="oss-apache-nifi"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/Apache-NiFi-logo.svg" name="Apache NiFi" classes="logo client mr-2"/>
                    <p>
                        Apache NiFi is a pivotal component in Deasil's suite of offerings, serving as a robust,
                        automated, and configurable platform for data flow management. Its significance lies in
                        its ability to effortlessly empower users to design data movement processes across
                        various systems. It simplifies the complexity associated with data ingestion and
                        facilitates effective data routing, transformation, and system mediation. Deasil
                        leverages Apache NiFi's powerful user interface, enabling the design, control, and
                        monitoring of data flows with unprecedented ease and precision, offering clients an
                        intuitive experience in managing their data pipelines.
                    </p>
                </div>
                <p>
                    Apache NiFi supports numerous data sources with over 300 processors, formats, and sinks,
                    providing broad compatibility and the ability to handle different data ingress and egress
                    patterns. Clients benefit immensely from this flexibility as it allows them to connect
                    disparate data systems, transforming and enriching data in flight. Deasil harnesses these
                    capabilities to construct bespoke data integrations and workflows tailored to meet individual
                    client needs, enhancing their ability to make data-driven decisions.
                </p>
                <p>
                    NiFi's data provenance feature is another crucial asset harnessed by Deasil, providing a
                    visual chain of custody on data as it moves through the system. This functionality ensures
                    transparency, traceability, and auditability of the data flows, which is crucial for clients
                    dealing with sensitive or regulated data. It gives them confidence in the integrity and
                    compliance of their data processes, as they can track and verify the data's lifecycle from
                    inception to consumption.
                </p>
                <p>
                    In providing Apache NiFi as part of its comprehensive service palette, Deasil facilitates a
                    seamless and efficient process for data extraction, transformation, and loading (ETL).
                    Clients enjoy accelerated time to insight as the platform simplifies the integration of
                    various data sources while ensuring data accuracy and availability. The Deasil team extends
                    expert assistance in deploying, configuring, and optimizing Apache NiFi, enabling clients to
                    harness the full potential of their data assets while retaining operational efficiency and
                    reliability in their data management endeavors.
                </p>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg-white">
                    <h2>Deasil's Custom Data Platforms</h2>
                    <p>
                        Apache NiFi often plays a central role in Deasil's development of bespoke data
                        platforms tailored to clients' needs. With its ability to capture, route, and process
                        data in real-time, Apache NiFi is a pivotal component in the infrastructure.
                        Combining <TextLink name="oss-apache-cassandra"/> with
                        NiFi enhances Deasil's offerings by providing a scalable and high-performance NoSQL
                        database system that handles vast volumes of data across many commodity servers without
                        any single point of failure. Deasil's platforms further benefit from
                        including <TextLink name="oss-apache-hive"/> for structured data
                        summarization, <TextLink name="oss-apache-superset"/> for visualization,
                        and <TextLink name="oss-trino"/> for
                        high-speed querying across databases. <TextLink name="oss-opensearch"/> augments
                        search capabilities, while <TextLink name="oss-keycloak"/> ensures
                        robust security with its identity and access
                        management. <TextLink name="oss-postgresql"/> provides
                        relational database strengths, and <TextLink name="oss-jupyterhub"/> facilitates
                        interactive computing, augmenting the depth of data solutions available to clients.
                    </p>
                    <p>
                        Centralizing these components' orchestration is <TextLink name="oss-kubernetes"/>, which
                        Deasil uses to manage the entire ecosystem efficiently. With its container orchestration
                        capabilities, Kubernetes keeps applications running seamlessly while being scalable and
                        fault-tolerant. This meticulous integration of leading OSS solutions allows Deasil to deliver a
                        comprehensive, high-performance, cost-effective data platform uniquely shaped to fit each
                        client's requirements and budget, offering unparalleled value across various industries.
                    </p>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Related Services and Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-apache-superset"/></li>
                            <li><TextLink name="oss-opensearch"/></li>
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-open-source-expertise"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default ApacheNiFi;
