import * as React from 'react';
import {Link} from 'react-router-dom';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const InteractiveComputingDevelopmentEnvironments = () => {
    return (
        <>
            <Hero page="oss-interactive-computing-development-environments"/>

            <section className="bg full mb-4 cols">
                <span className="col-9">
                    <p>
                        Deasil offers managed <TextLink name="oss-jupyterhub"/> deployments to deliver
                        state-of-the-art Interactive Computing and Development Environments, empowering users to
                        create and share live code, equations, visualizations, and narrative text
                        documents. JupyterHub is a multi-user version of the JupyterLab and Jupyter Notebooks.
                        Jupyter Notebooks is designed for companies, classrooms, and research labs and offers a
                        centralized platform where developers and data scientists can collaborate on
                        projects. <TextLink name="oss-jupyterlab"/> is the latest evolution of Jupyter Notebooks.
                        This advanced interactive environment provides users with a sophisticated code editor,
                        capabilities for interactive data visualization, and tools to ensure streamlined
                        workflows. Deasil offers these capabilities as standalone services or integrated into its
                        custom data platforms, enabling seamless data science operations and analytics within the
                        broader ecosystem.
                    </p>
                    <p>
                        To ensure secure access to these interactive environments, Deasil
                        incorporates <TextLink name="oss-keycloak"/>, a leading
                        open-source <TextLink name="oss-identity-access-management"/> tool, guaranteeing robust
                        authentication and fine-grained authorization. By utilizing Keycloak, users can safely
                        access their Jupyter notebooks, ensuring data security and privacy. All these
                        components&mdash;JupyterHub, JupyterLab, and Keycloak&mdash;are packaged within custom
                        containers, ensuring consistent deployment and scalability. Deasil then orchestrates
                        these containers using <TextLink name="oss-kubernetes"/>, providing automated scaling,
                        load balancing, and high availability. The result is a cohesive, scalable, and highly
                        available interactive computing environment that meets the diverse needs of Deasil's
                        clientele, unified efficiently within our robust Kubernetes clusters.
                    </p>
                </span>
                <span className="col-3">
                    <Link to="/managed-oss/jupyterhub" className="ml-2">
                        <Image path="logos/JupyterHub-logo.svg" name="JupyterHub" classes="logo"/>
                    </Link>
                    <Link to="/managed-oss/jupyterhub" className="ml-2">
                        <Image path="logos/JupyterLab-logo-rectangle-dark.svg" name="JupyterLab" classes="logo mt-4"/>
                    </Link>
                </span>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg full">
                <div className="panel bg-white">
                    <h2>Data Science Platform</h2>
                    <p>
                        Deasil's unified data platform approach centers on integrating a curated set
                        of <TextLink name="service-open-source-expertise" text="OSS solutions"/> tailored for
                        comprehensive <TextLink name="service-data-science-environments" text="data science"/>,
                        analytics, and <TextLink name="service-machine-learning-infrastructure"
                                                 text="machine learning"/> endeavors. At the heart of this
                        platform lies JupyterHub, furnishing clients with an interactive computing environment to
                        prototype, develop, and visualize data-driven projects. JupyterHub's collaborative workspace is
                        amplified by <TextLink name="oss-trino"/>, a high-performance distributed SQL query engine
                        facilitating swift querying across vast datasets, including data residing
                        in <TextLink name="oss-postgresql"/>, a renowned relational data storage solution.
                        Furthermore, <TextLink name="oss-opensearch"/> enhances the platform's capabilities by providing
                        advanced full-text search, analytics, and visualization, ensuring clients can glean deep
                        insights and patterns from their data. Deasil's emphasis on distributed storage systems ensures
                        that this massive influx of data is stored efficiently, securely, and readily available for
                        processing. Meanwhile, <TextLink name="oss-apache-nifi"/> seamlessly integrates into this
                        architecture, streamlining data flows, automating ingestion processes, and facilitating
                        real-time data processing, creating a cohesive data pipeline from source to insight.
                    </p>
                    <p>
                        Deasil's meticulously architected data platform becomes invaluable for data science, analytics,
                        and machine learning tasks. Data scientists and analysts can navigate
                        vast <TextLink name="service-data-lakes" text="data lakes"/>, preprocess datasets, train machine
                        learning models, and deploy these models, all within the same unified environment. They can
                        swiftly move from data exploration to feature engineering and from model training to deployment
                        without disjointed toolsets or environments. The combinatorial effect of these technologies
                        ensures seamless integration and flow of data, allowing for agile and iterative data
                        experimentation. Deasil's orchestration of these components within its
                        private <TextLink name="oss-kubernetes"/> clusters provides scalability, fault tolerance, and
                        high availability, positioning its unified data platform as an indispensable tool for any
                        organization aiming to harness the full potential of its data.
                    </p>

                </div>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Interactive Computing and Development Environment Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-jupyterhub"/></li>
                            <li><TextLink name="oss-jupyterlab"/></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <h3>Interactive Computing and Development Environment Related Services:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="service-data-visualization-exploration"/></li>
                            <li><TextLink name="service-etl-elt-operations"/></li>
                            <li><TextLink name="service-data-management-pipelines"/></li>
                            <li><TextLink name="service-platform-data-migration"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-data-science-environments"/></li>
                            <li><TextLink name="service-machine-learning-infrastructure"/></li>
                            <li><TextLink name="service-data-warehouses"/></li>
                            <li><TextLink name="service-data-lakes"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default InteractiveComputingDevelopmentEnvironments;
