import * as React from 'react';
import TextLink from './TextLink';

const DeasilPromise = () => {
    return (
        <>
            <p>
                Deasil's dedication
                to <TextLink name="service-open-source-expertise"
                             text="cloud-native, vendor-neutral, standards-based"/> data
                gives our clients the confidence to invest in ubiquitous and portable technology, ensuring long-term
                business continuity.
            </p>
            <p>
                Deasil does not re-sell overpriced public cloud services, but utilizes
                its <TextLink name="service-compute-resources"
                              title="Compute Resources from Bare Metal to Multi-Cloud"
                              text="extensive private infrastructure"/>, providing
                simple fixed monthly pricing and enormous cost savings for our clients.
            </p>
        </>
    )
};

export default DeasilPromise;
