import * as React from 'react';
import {CONTENT_SITE} from './content/content-site';
import ComputeResourcesBareMetalMultiCloud from './routes/service/ComputeResourcesBareMetalMultiCloud';
import CustomDataPlatforms from './routes/service/CustomDataPlatforms';
import CustomSoftwareDevelopment from './routes/service/CustomSoftwareDevelopment';
import DataCenterNetworkOperations from './routes/service/DataCenterNetworkOperations';
import DataLakes from './routes/service/DataLakes';
import DataManagementPipelines from './routes/service/DataManagementPipelines';
import DataScienceEnvironments from './routes/service/DataScienceEnvironments';
import DataVisualizationExploration from './routes/service/DataVisualizationExploration';
import DataWarehouses from './routes/service/DataWarehouses';
import DevelopmentOperations from './routes/service/DevelopmentOperations';
import DigitalTransformation from './routes/service/DigitalTransformation';
import ETLELTOperations from './routes/service/ETLELTOperations';
import HighAvailabilityPerformanceEngineering from './routes/service/HighAvailabilityPerformanceEngineering';
import HostingManagedInfrastructure from './routes/service/HostingManagedInfrastructure';
import LegacySystemsIntegration from './routes/service/LegacySystemsIntegration';
import MachineLearningInfrastructure from './routes/service/MachineLearningInfrastructure';
import MobileApplications from './routes/service/MobileApplications';
import OpenSourceExpertise from './routes/service/OpenSourceExpertise';
import PlatformDataMigration from './routes/service/PlatformDataMigration';
import PrivateCustomClouds from './routes/service/PrivateCustomClouds';
import SEOAnalytics from './routes/service/SEOAnalytics';
import Services from './routes/service/Services';
import SSOIdentityAccessManagement from './routes/service/SSOIdentityAccessManagement';
import VectorDatabaseHostingManagementServices from './routes/service/VectorDatabaseHostingManagementServices';

const serviceRoutes = [
    {
        path: CONTENT_SITE['service'].path,
        element: <Services/>
    },
    {
        path: CONTENT_SITE['service-compute-resources'].path,
        element: <ComputeResourcesBareMetalMultiCloud/>
    },
    {
        path: CONTENT_SITE['service-custom-data-platforms'].path,
        element: <CustomDataPlatforms/>
    },
    {
        path: CONTENT_SITE['service-custom-software-development'].path,
        element: <CustomSoftwareDevelopment/>
    },
    {
        path: CONTENT_SITE['service-data-center-network-operations'].path,
        element: <DataCenterNetworkOperations/>
    },
    {
        path: CONTENT_SITE['service-data-lakes'].path,
        element: <DataLakes/>
    },
    {
        path: CONTENT_SITE['service-data-management-pipelines'].path,
        element: <DataManagementPipelines/>
    },
    {
        path: CONTENT_SITE['service-data-science-environments'].path,
        element: <DataScienceEnvironments/>
    },
    {
        path: CONTENT_SITE['service-data-visualization-exploration'].path,
        element: <DataVisualizationExploration/>
    },
    {
        path: CONTENT_SITE['service-data-warehouses'].path,
        element: <DataWarehouses/>
    },
    {
        path: CONTENT_SITE['service-devops'].path,
        element: <DevelopmentOperations/>
    },
    {
        path: CONTENT_SITE['service-digital-transformation'].path,
        element: <DigitalTransformation/>
    },
    {
        path: CONTENT_SITE['service-etl-elt-operations'].path,
        element: <ETLELTOperations/>
    },
    {
        path: CONTENT_SITE['service-high-availability-performance-engineering'].path,
        element: <HighAvailabilityPerformanceEngineering/>
    },
    {
        path: CONTENT_SITE['service-hosting-managed-infrastructure'].path,
        element: <HostingManagedInfrastructure/>
    },
    {
        path: CONTENT_SITE['service-legacy-systems-integration'].path,
        element: <LegacySystemsIntegration/>
    },
    {
        path: CONTENT_SITE['service-machine-learning-infrastructure'].path,
        element: <MachineLearningInfrastructure/>
    },
    {
        path: CONTENT_SITE['service-mobile-applications'].path,
        element: <MobileApplications/>
    },
    {
        path: CONTENT_SITE['service-open-source-expertise'].path,
        element: <OpenSourceExpertise/>
    },
    {
        path: CONTENT_SITE['service-platform-data-migration'].path,
        element: <PlatformDataMigration/>
    },
    {
        path: CONTENT_SITE['service-private-custom-clouds'].path,
        element: <PrivateCustomClouds/>
    },
    {
        path: CONTENT_SITE['service-seo-analytics'].path,
        element: <SEOAnalytics/>
    },
    {
        path: CONTENT_SITE['service-sso-iam'].path,
        element: <SSOIdentityAccessManagement/>
    },
    {
        path: CONTENT_SITE['service-vector-database-hosting-management-services'].path,
        element: <VectorDatabaseHostingManagementServices/>
    }
];

export default serviceRoutes;
