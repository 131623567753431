import * as React from 'react';
import {Link} from 'react-router-dom';
import Hero from '../components/Hero';

const NotDiesel = () => {
    return (
        <>
            <Hero page="deasil-vs-diesel"/>

            <section className="huddle">
                <h1>Deasil Works vs. Diesel Fuel</h1>
                <p>
                    Although we power many things, Deasil is a company, not a truck. We are also not a fuel, despite
                    the fact that Google will ask if you meant diesel fuel or some clothing brand. We are also not the
                    U.S. Justice Department's National License Plate Reader Network.
                </p>
                <p>
                    So what is Deasil? The dictionary definition defines the word
                    deasil as:
                </p>
                <p className="quote">
                    "clockwise or in a direction following the apparent course of the sun: considered as lucky or
                    auspicious."
                </p>
                <p>
                    In other words, the right direction for things. Which is what Deasil
                    is <Link to="/about" title="About Us">about</Link>: the right solution for your brand, company,
                    or cause. <Link to="/contact" title="Contact Us">Contact Us</Link> today
                    to discover more.
                </p>
            </section>
        </>
    )
};

export default NotDiesel;
