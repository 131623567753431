import * as React from 'react';
import {useRef, useState} from 'react';

/**
 * Hero Background image, titles, and optional video
 *
 * @param title
 * @param image
 * @param video
 * @param heightPercent
 * @param top
 * @returns {JSX.Element}
 * @constructor
 */
const HeroBackground = ({title, image, video, heightPercent, top}) => {

    const bgRef = useRef(null);
    const [bgRatio, setBgRatio] = useState((image && heightPercent) ? heightPercent * .5625 : 0);

    const handleImageLoad = () => {
        let ratio = 0;
        if (image !== '') {
            const w = bgRef.current.clientWidth;
            const h = bgRef.current.clientHeight;
            ratio = (image !== '' && w && h) ? ((h / w) * 100) : 0;
        }
        if (ratio > 0 && heightPercent !== 100) {
            ratio *= (heightPercent * 0.01);
        }
        setBgRatio(ratio);
    };

    const bgSrc = process.env.PUBLIC_URL + '/static/' + image;
    const videoSrc = process.env.PUBLIC_URL + '/static/' + video;
    const bgStyle = {
        backgroundImage: `url(${bgSrc})`,
        paddingTop: bgRatio + '%',
        top: top + '%'
    };
    const videoStyle = {
        top: top + '%'
    };
    const heightClass = (bgRatio >= 25) ? ' has-title' : '';

    return (
        <div className={`hero-image${heightClass}`} role="presentation" style={bgStyle}>
            {image !== '' &&
                <img src={bgSrc} alt={`${title} Header`} ref={bgRef}
                     width="1280" height="720" onLoad={handleImageLoad}/>
            }
            {video !== '' &&
                <video playsInline={true} preload="auto" autoPlay muted loop style={videoStyle}>
                    <source src={videoSrc} type="video/mp4"/>
                </video>
            }
        </div>
    )
};

export default HeroBackground;
