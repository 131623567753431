import * as React from 'react';
import {CONTENT_SITE} from './content/content-site';
import CaseStudyScreenPrinting from './routes/client/CaseStudyScreenPrinting';
import Work from './routes/Work';

const clientRoutes = [
    {
        path: CONTENT_SITE['case-study'].path,
        element: <Work/>
    },
    {
        path: CONTENT_SITE['case-study-screen-printing'].path,
        element: <CaseStudyScreenPrinting/>
    },

];

export default clientRoutes;
