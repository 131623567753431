import * as React from 'react';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';

const CustomDataPlatforms = () => {
    return (
        <>
            <Hero page="service-custom-data-platforms"/>

            <section className="bg full mb-4 cols">
                <span>
                    <p>
                        Deasil builds data platforms capable of solving nearly every challenge that exists
                        today. How is this possible? SAAS and closed-source applications cannot adapt,
                        innovate, or keep pace with new and
                        mature <TextLink name="service-open-source-expertise" text="open-source solutions"/> evolving
                        daily. The vast open-source ecosystem has achieved critical momentum, with millions of
                        contributors and thousands of companies backing high-profile OSS applications.
                        Therefore, best-in-class OSS adoption and interoperability are the focus of Deasil.
                    </p>
                    <p className="quote">
                        Deasil integrates the best enterprise-grade OSS applications specializing in your
                        data problems.
                    </p>
                    <p>
                        Deasil designs, constructs, and maintains custom modern data platforms backed by an
                        experienced team of software developers, systems integrators, and platform architects.
                        Deasil harnesses the combinatorial effect of best-of-class open-source software, open
                        standards, and bare-metal hardware. Deasil is not a public cloud (AWS/Google) reseller;
                        we assemble custom, secure, private-cloud data solutions.
                    </p>
                    <p className="quote">
                        Harness the real power of bare-metal distributed computing at a fraction of the cost of
                        the public cloud... with NO vendor lock-in.
                    </p>
                </span>
            </section>
        </>
    )
};

export default CustomDataPlatforms;
