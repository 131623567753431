import * as React from 'react';
import Icon from './Icon';

const AboutBlockVendorNeutralTechnology = ({
                                               content1,
                                               content2,
                                               paragraphs = 2,
                                               title = 'About Vendor Neutral Technology',
                                               classes = ''
                                           }) => {

    const paragraph1 = (content1)
        ? content1
        : `The best client reaches out to us for continuous improvement and maintenance, not the client who feels 
        stuck with a solution simply because the migration cost is too high. Deasil does not have investors; we 
        operate on the product of our effort and not investor expectations. We built Deasil on the anticipation 
        of continuous innovation and cost-effectiveness, not lock-in.`;

    const paragraph2 = (content2)
        ? content2
        : `Deasil's dedication to cloud-native, vendor-neutral, standards-based data gives our clients the 
        confidence to invest in ubiquitous and portable technology, ensuring long-term business continuity.`;

    return (
        <div className={`panel bg bg-white cols about pb-1 ${classes}`}>
            <Icon img="icon-Variety" name={title} classes="primary mt-1"/>
            <span>
                <h3>{title}</h3>
                {paragraph1 &&
                    <p>
                        {paragraph1}
                    </p>
                }
                {paragraphs === 2 && paragraph2 &&
                    <p>
                        {paragraph2}
                    </p>
                }
            </span>
        </div>
    )
};

export default AboutBlockVendorNeutralTechnology;
