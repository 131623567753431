import * as React from 'react';
import ContactBlock from '../../components/ContactBlock';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';

const Industries = () => {
    return (
        <>
            <Hero page="industry" classes="has-overlay"/>

            <section className="bg bg-primary overlay-top mb-4">
                <p className="mb-0">
                    We develop software for any platform and design custom platforms for any domain—innovative custom
                    solutions, for online, automotive, manufacturing, academia, retail, marketing, and entertainment.
                </p>
            </section>
            <section className="bg full">
                <div className="cols">
                    <div className="col-4 mr-1 ml-1">
                        <ul className="service-links">
                            <li><TextLink name="industry-creative-agencies"/></li>
                            <li><TextLink name="industry-manufacturing"/></li>
                            <li><TextLink name="industry-retail"/></li>
                            <li><TextLink name="industry-ecommerce"/></li>
                            <li><TextLink name="industry-automotive"/></li>
                            <li><TextLink name="industry-transportation"/></li>
                            <li><TextLink name="industry-logistics"/></li>
                        </ul>
                    </div>
                    <div className="col-4 mr-1 ml-1">
                        <ul className="service-links">
                            <li><TextLink name="industry-energy"/></li>
                            <li><TextLink name="industry-academia"/></li>
                            <li><TextLink name="industry-entertainment"/></li>
                            <li><TextLink name="industry-nonprofit"/></li>
                            <li><TextLink name="industry-government"/></li>
                            <li><TextLink name="industry-data-brokers"/></li>
                            <li><TextLink name="industry-health"/></li>
                        </ul>
                    </div>
                    <div className="col-4 mr-1 ml-1">
                        <ul className="service-links">
                            <li><TextLink name="industry-food-beverage"/></li>
                            <li><TextLink name="industry-music"/></li>
                            <li><TextLink name="industry-broadcasting"/></li>
                            <li><TextLink name="industry-banking-finance"/></li>
                            <li><TextLink name="industry-mortgage"/></li>
                            <li><TextLink name="industry-gaming"/></li>
                        </ul>
                    </div>
                </div>
            </section>

            <ContactBlock label="Contact Deasil"/>
        </>
    )
};

export default Industries;
