import * as React from 'react';
import DeasilLogo from '../assets/images/Deasil-logo-d-gray.svg';
import TextLink from './TextLink';

const AboutBlockDeasil = ({children}) => {

    const content = (children)
        ? children
        : <p>
            With over 25 years in the business, Deasil has established itself as a powerhouse in software development,
            hosting, and managed infrastructure services. Deasil's experience includes a wide array
            of <TextLink name="industry" text="industries"/> ranging from creative agencies and entertainment to
            government and healthcare; Deasil's extensive capabilities
            encompass <TextLink name="service-custom-software-development"
                                text="custom software development"/>, <TextLink name="service-data-science-environments"
                                                                                text="data science"/>, <TextLink
            name="service-hosting-managed-infrastructure" text="hosting"/>, and <TextLink
            name="service-digitaltransformation" text="digital transformation"/>. Renowned for its prowess in
            enterprise-grade <TextLink name="service-open-source-expertise" text="open-source solutions"/> and a
            staunch commitment to cost efficiency, Deasil has garnered a prestigious clientele,
            including Disney, Electronic Arts, and Toyota.
            The team's <TextLink name="our-history" text="rich history"/> in innovation since the early '90s
            stands as a testament to our ability to navigate and lead in the ever-evolving digital landscape.
        </p>;

    return (
        <div className={`panel bg bg-white cols ml-0 pt-3 pl-1 pb-1`}>
            <img src={DeasilLogo} alt="Deasil Logo" className="logo client small mt-2"/>
            <span>
                <h2>Deasil</h2>
                {content}
            </span>
        </div>
    )
};

export default AboutBlockDeasil;
