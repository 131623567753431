import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const JupyterLab = () => {
    return (
        <>
            <Hero page="oss-jupyterlab"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/JupyterLab-logo-rectangle-dark.svg" name="JupyterLab"
                           classes="logo client mr-2"/>
                    <div>
                        <p>
                            At Deasil, we recognize the increasing need
                            for <TextLink name="service-data-science-environments"
                                          text="powerful interactive development environments"/> in
                            today's digital landscape. As part of our commitment to providing top-tier solutions, we
                            offer JupyterLab, an advanced evolution of the popular Jupyter Notebook. JupyterLab
                            provides an integrated environment where developers, data scientists, and analysts can work
                            with notebooks, code, and data in a highly interactive manner.
                        </p>
                    </div>
                </div>
                <p>
                    With data-driven decision-making becoming the norm across industries, there's a growing
                    demand for tools that allow quick experimentation, visualization, and data manipulation.
                    JupyterLab meets this need by offering a platform where users can seamlessly switch between
                    coding, visualizing results, and documenting their findings. Its ability to support multiple
                    languages and data formats makes it an indispensable tool for interdisciplinary projects,
                    fostering collaboration and ensuring workflow consistency.
                </p>
                <p>
                    Deasil takes pride in offering a broad spectrum of kernels for JupyterLab,
                    including <strong>Python</strong>, <strong>Xeus Python</strong>, <strong>R</strong>
                    , <strong>GNU Octave</strong>, and <strong>Julia</strong>. With its vast library ecosystem,
                    Python has become a cornerstone of data science, while Xeus Python offers a robust implementation
                    for Jupyter's interactive widgets. R remains a favorite for statisticians and data miners, given its
                    rich statistical and graphical methods suite. GNU Octave, closely compatible with MATLAB, finds
                    its strength in numerical computations, while Julia, with its high-performance characteristics, is
                    rapidly gaining traction in big data and analytics tasks. By supporting these kernels, Deasil
                    ensures that professionals across industries can utilize the best tools tailored to their needs in
                    finance, healthcare, engineering, or research.
                </p>
                <div className="cols centered center-vertical mb-3">
                    <Image path="logos/Python-logo.svg" name="Python" classes="logo client mt-3 mr-2 ml-2"/>
                    <Image path="logos/Xeus-Python-logo.svg" name="Xeus Python"
                           classes="logo client small mt-2 mr-2 ml-2"/>
                    <Image path="logos/R-logo.svg" name="R" classes="logo client x-small mr-2 ml-2"/>
                    <Image path="logos/GNU-Octave-logo.svg" name="GNU Octave" classes="logo client x-small mr-2 ml-2"/>
                    <Image path="logos/Julia-logo.svg" name="Julia" classes="logo client small mr-2 ml-2"/>
                </div>
                <p>
                    The significance of these kernels extends beyond their capabilities. Modern data science has
                    infiltrated nearly every industry, from automating transportation logistics to predicting
                    retail market trends. Python and R, for instance, play pivotal roles in analyzing vast
                    datasets, running simulations, and implementing machine learning algorithms. With its speed,
                    Julia is revolutionizing real-time analytics, especially in sectors like energy and finance.
                    Meanwhile, GNU Octave's numerical prowess is invaluable in fields like engineering and
                    physics. By offering these kernels, Deasil empowers businesses to harness the full potential
                    of data, driving innovation and efficiency.
                </p>
                <p>
                    Deasil leverages <TextLink name="oss-jupyterhub"/> to provision JupyterLab environments
                    attached to GPUs, ensuring accelerated computations vital for tasks such as deep learning. Doing so
                    allows data scientists and researchers to run complex models and algorithms efficiently, drastically
                    reducing the time required for training and inference.
                </p>
                <p>
                    Deasil's JupyterLab environments come pre-configured with leading machine learning
                    libraries, including <strong>TensorFlow</strong> and <strong>PyTorch</strong>, two of the most
                    prominent deep learning frameworks, <strong>Keras</strong> for neural network
                    abstractions, <strong>Scikit-learn</strong> for traditional machine learning methods,
                    and <strong>OpenCV</strong> for computer vision tasks. By bundling these libraries, Deasil
                    ensures that professionals can immediately dive into their projects, be it neural network
                    training, image recognition, or data clustering, without the hassle of setting up their
                    environments. This ready-to-use approach aligns with our mission to facilitate seamless and
                    efficient workflows, ultimately driving exceptional outcomes for our clients.
                </p>
            </section>

            <section className="bg bg-primary full">
                <div className="panel bg bg-white mb-0">
                    <h2>Deasil's Custom Data Platforms</h2>
                    <p>
                        JupyterLab environment complements Deasil's Custom Data Platforms, integrating seamlessly with
                        robust <TextLink name="service-open-source-expertise" text="enterprise-grade OSS"/> data
                        systems. <TextLink name="oss-opensearch"/> enhances the platform with robust search and analytic
                        capabilities, while <TextLink name="oss-trino"/> ensures rapid SQL query execution on large
                        datasets across multiple and diverse databases. <TextLink name="oss-postgresql"/> provides our
                        clients with a trusted relational database solution. At the same
                        time, <TextLink name="oss-apache-cassandra"/> delivers a high-performance distributed
                        database service, and <TextLink name="oss-apache-nifi"/> streamlines data flow between systems.
                        We incorporate <TextLink name="oss-apache-hive"/> to manage large-scale data warehousing with
                        ease. Additionally, Deasil's platform data storage and management rest on the power
                        of <TextLink name="oss-seaweedfs"/> and <TextLink name="oss-ceph"/>, guaranteeing
                        efficient and scalable distributed storage operations compatible with AWS S3.
                    </p>
                    <p>
                        Through the orchestration power of <TextLink name="oss-kubernetes"/>, every component in
                        our platform communicates seamlessly, ensuring our clients experience unparalleled data
                        processing, analysis, and security while enjoying significant cost savings.
                    </p>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Related Services and Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-jupyterhub"/></li>
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-apache-nifi"/></li>
                            <li><TextLink name="oss-postgresql"/></li>
                            <li><TextLink name="oss-apache-superset"/></li>
                            <li><TextLink name="service-high-availability-performance-engineering"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-compute-resources"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-open-source-expertise"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default JupyterLab;
