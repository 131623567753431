import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';

const ApacheSpark = () => {
    return (
        <>
            <Hero page="oss-apache-spark"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/Apache-Spark-logo.svg" name="Apache Spark" classes="logo client mr-2"/>
                    <p>
                    </p>
                </div>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>
        </>
    )
};

export default ApacheSpark;
