import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';

const Keycloak = () => {
    return (
        <>
            <Hero page="oss-keycloak"/>

            <section className="bg full">
                <div className="cols">
                    <Image path="logos/Keycloak-logo-text.png" name="Keycloak" classes="logo client mr-2"/>
                    <p>
                        At its core, Keycloak offers a comprehensive set of IAM features, including Single Sign-On
                        (SSO), identity brokering, and social login, allowing users to authenticate via third-party
                        identity providers like Google or Facebook. It supports OpenID Connect (OIDC) and SAML 2.0,
                        which streamlined integrations with modern applications and third-party systems. A standout
                        feature is its fine-grained authorization services, which permit administrators to define
                        intricate policies. Additionally, its extensible nature, through custom themes and providers,
                        ensures adaptability to specific business needs. Unlike many proprietary solutions, Keycloak
                        provides customization flexibility without locking businesses into a particular ecosystem,
                        promoting adaptability and independence.
                    </p>
                </div>
                <p>
                    Keycloak, initially developed by Red Hat, emerged as a response to the growing need for a robust,
                    scalable Identity and Access Management (IAM) solution that caters to modern application
                    architectures. As businesses began shifting to cloud-based and microservices-oriented architectures,
                    the need for a centralized, easy-to-integrate authentication and authorization mechanism became
                    evident. Recognizing this, Red Hat introduced Keycloak, aiming to simplify security without
                    sacrificing capability, thus ensuring that developers could secure apps and services with minimal
                    effort. Over the years, Keycloak has grown in popularity, benefiting from contributions from the
                    open-source community and evolving with changing security requirements and standards.
                </p>
                <p>
                    Deasil recognizes Keycloak's pivotal role in modern security architectures and offers it as a
                    managed service. Deasil uses its expertise to deploy, configure, and maintain Keycloak, ensuring
                    clients receive seamless authentication and authorization without the hassle of system management.
                    Deasil offers Keycloak to highlight the importance of vendor-neutral solutions. As an open-source
                    tool, Keycloak ensures businesses aren't bound to a proprietary system that sets terms, prices, or
                    future directions. Organizations maintain complete control over user data, steering clear of vendor
                    lock-in and the risks of proprietary systems controlling user identities. In today's digital
                    environment, where user data is crucial, this autonomy lets businesses decide what's best for them
                    without outside interference.
                </p>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>
        </>
    )
};

export default Keycloak;
