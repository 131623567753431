import * as React from 'react';
import {Link} from 'react-router-dom';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import TextLink from '../../components/TextLink';

const IdentityAccessManagement = () => {
    return (
        <>
            <Hero page="oss-identity-access-management"/>

            <section className="bg full mb-4 cols">
                <div>
                    <p>
                        Deasil provides managed <TextLink name="oss-keycloak"/>, a widely recognized
                        open-source <strong>Identity and Access Management (IAM)</strong> solution, to
                        deliver advanced authentication and authorization services. Keycloak provides
                        comprehensive user federation, single sign-on, token-based authentication, and
                        role-based access controls, enabling Deasil to secure access to applications and
                        services. When offering IAM as a service, Deasil leverages Keycloak's capabilities
                        to provide clients with customizable authentication workflows, social login
                        integrations, and user management dashboards. Furthermore, when integrating IAM into
                        its custom data platforms, Deasil uses Keycloak for single-sign-on, seamlessly
                        authenticating and authorizing users, ensuring they have appropriate access to data
                        resources, thereby upholding the principles of data security and privacy.
                    </p>
                    <p>
                       To support the persistence and management of identity data, Deasil integrates Keycloak
                        with <TextLink name="oss-postgresql"/>, a robust and open-source relational database
                        system. PostgreSQL efficiently stores user credentials, roles, permissions, and other
                        associated identity data. Deasil ensures consistent and scalable IAM deployments by
                        packaging Keycloak and PostgreSQL within custom
                        containers. <TextLink name="oss-kubernetes"/>, the container orchestration platform,
                        is then employed to manage these containers, automating their deployment, scaling, and
                        recovery processes. With Kubernetes at the helm, Deasil ensures that the IAM services
                        and components are always available, resilient, and adaptive to the varying demands of
                        clients, thus providing a unified, scalable, and secure identity management solution.
                    </p>
                </div>
                <Link to="/managed-oss/keycloak" className="logo client ml-2">
                    <Image path="logos/Keycloak-logo-text.png" name="Keycloak" classes="logo client"/>
                </Link>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Identity and Access Management Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-keycloak"/></li>
                            <li><TextLink name="oss-postgresql"/></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <h3>Identity and Access Management Related Services:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="service-sso-iam"/></li>
                            <li><TextLink name="service-private-custom-clouds"/></li>
                            <li><TextLink name="service-mobile-applications"/></li>
                            <li><TextLink name="service-custom-software-development"/></li>
                            <li><TextLink name="service-data-visualization-exploration"/></li>
                            <li><TextLink name="service-data-science-environments"/></li>
                            <li><TextLink name="service-devops"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default IdentityAccessManagement;
