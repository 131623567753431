import * as React from 'react';
import Icon from './Icon';

const AboutBlockPrivacy = ({
                               content1,
                               content2,
                               paragraphs = 1,
                               title = 'About Privacy',
                               classes = ''
                           }) => {

    const paragraph1 = (content1)
        ? content1
        : `Deasil protects its clients and will never disclose confidential or proprietary information regarding 
        their intellectual property, processes, or industry secrets. Our clients' success is our success.`;

    return (
        <div className={`panel bg bg-white cols about pb-1 ${classes}`}>
            <Icon img="icon-Secure-Key" name={title} classes="primary mt-1"/>
            <span>
                <h3>{title}</h3>
                {paragraph1 &&
                    <p>
                        {paragraph1}
                    </p>
                }
                {paragraphs === 2 && content2 &&
                    <p>
                        {content2}
                    </p>
                }
            </span>
        </div>
    )
};

export default AboutBlockPrivacy;
