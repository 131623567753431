import * as React from 'react';
import DeasilPromise from '../../components/DeasilPromise';
import Hero from '../../components/Hero';
import TextLink from '../../components/TextLink';

const QuerySearchEngines = () => {
    return (
        <>
            <Hero page="oss-query-search-engines"/>

            <section className="bg full mb-4 cols">
                <span>
                    <p>
                        Deasil offers <TextLink name="oss-trino"/> and <TextLink name="oss-opensearch"/> as
                        managed services or as part of a broader custom data platform. Trino is a
                        high-performance distributed SQL query engine that delivers efficient querying across
                        various data sources, enabling OLAP (Online Analytical Processing) capabilities.
                        Trino's architecture facilitates swift data exploration and analysis, allowing
                        clients to gain insights from large datasets spanning multiple data stores without
                        data movement, ensuring that Deasil's clientele can run complex analytical queries
                        on vast datasets in real time.
                    </p>
                    <p>
                        Complementing Trino, Deasil integrates OpenSearch, a search and analytics engine
                        forked from Elasticsearch. OpenSearch allows Deasil to provide robust full-text
                        search capabilities, visualizations, and analytical functions. OpenSearch's flexibility
                        in handling structured and unstructured data ensures that clients can dive deep into
                        their data, extracting meaningful patterns and insights.
                    </p>
                    <p>
                        To ensure that these powerful querying and search tools operate seamlessly and scale
                        according to demand, Deasil packages Trino and OpenSearch within custom containers.
                        These containers encapsulate the environment and dependencies the applications need to
                        function, ensuring consistent performance across various deployment scenarios. Deasil
                        orchestrates these containers by deploying them in our
                        private <TextLink name="oss-kubernetes"/> clusters, automating their deployment, scaling,
                        security, and management. Kubernetes' orchestration capabilities guarantee that Trino
                        and OpenSearch containers are always available, fault-tolerant, and operate efficiently.
                        This integration within Kubernetes provides Deasil's clients with a unified, scalable,
                        and resilient platform for advanced querying, search, and data analysis tasks.
                    </p>
                </span>
            </section>

            <section className="bg bg-secondary mt-4 mb-4">
                <DeasilPromise/>
            </section>

            <section className="bg bg-primary full mt-4 mb-4">
                <div className="bg bg-none cols mr-3 ml-3">
                    <div className="col-6">
                        <h3>Query and Search Engine Technologies:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="oss-trino"/></li>
                            <li><TextLink name="oss-opensearch"/></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <h3>Query and Search Engine Related Services:</h3>
                        <ul className="service-links light">
                            <li><TextLink name="service-data-visualization-exploration"/></li>
                            <li><TextLink name="service-etl-elt-operations"/></li>
                            <li><TextLink name="service-data-management-pipelines"/></li>
                            <li><TextLink name="service-platform-data-migration"/></li>
                            <li><TextLink name="service-devops"/></li>
                            <li><TextLink name="service-data-science-environments"/></li>
                            <li><TextLink name="service-machine-learning-infrastructure"/></li>
                            <li><TextLink name="service-data-warehouses"/></li>
                            <li><TextLink name="service-data-lakes"/></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};

export default QuerySearchEngines;
